import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { Search, ExpandMore } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

import VideoBanner from "../../components/VideoBanner";
import EventsService from "../../services/events";
import pageStyles from "./styles/faqStyles";
import Model from "../../hooks/Model";
import Layout from "../layout";

const Faq = () => {
  const { t } = useTranslation();
  const styles = pageStyles();

  const [searchTxt, setSearchTxt] = React.useState("");
  const [allQuestions, setAllQuestions] = React.useState([]);
  const [filteredQuestions, setFilteredQuestions] = React.useState([]);
  const [openedQuestion, setOpenedQuestion] = React.useState(-1);

  const changeInput = (event) => {
    event.persist();
    setSearchTxt(event.target.value);
  };

  const onKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      onSearch();
    } else if (event.keyCode === 27) {
      setSearchTxt("");
      setFilteredQuestions(allQuestions);
    }
  };

  const renderQuestions = () => {
    if (filteredQuestions.length < 1) {
      return (
        <div className={styles.lblEmpty}>
          {t("pages.dashboard.faq.noFindAny")}
        </div>
      );
    }

    return filteredQuestions.map((question, index) => (
      <Accordion
        key={question.title}
        expanded={openedQuestion === index}
        onChange={() => setOpenedQuestion((ov) => (ov === index ? -1 : index))}
        className={[
          styles.itemQuestion,
          openedQuestion === index ? styles.selectedQuestion : "",
        ].join(" ")}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <h3 className={styles.headingQuestion}>{question.title}</h3>
        </AccordionSummary>
        <AccordionDetails>
          <p className={styles.descriptionQuestion}>{question.description}</p>
        </AccordionDetails>
      </Accordion>
    ));
  };

  const onSearch = () => {
    if (!searchTxt) {
      setFilteredQuestions(allQuestions);
    } else {
      const keyWords = searchTxt.toLowerCase().split(" ");
      const newQuestions = allQuestions.filter((question) => {
        const titleWords = question.title.toLowerCase().split(" ");
        // const descriptionWords = question.description.toLowerCase().split(' ')
        const commonWords = keyWords.filter((word) =>
          titleWords.includes(word)
        );

        return commonWords.length > 0;
      });
      setFilteredQuestions(newQuestions);
    }
  };

  const getQuestions = async () => {
    try {
      Model.setData("loading", true);
      const questionsDatabase = await EventsService.getQuestions();
      setAllQuestions(questionsDatabase);
      setFilteredQuestions(questionsDatabase);
    } catch (e) {
      console.log(t("pages.dashboard.faq.errorGetQuestions"), e);
    } finally {
      Model.setData("loading", false);
    }
  };

  React.useEffect(() => {
    Model.setData("opacity", 0.7);
    getQuestions();
    //eslint-disable-next-line
  }, []);

  return (
    <Layout>
      <VideoBanner
        title={t("pages.dashboard.faq.faqs")}
        description={t("pages.dashboard.faq.searchKeyWords")}
        image={require("../../assets/images/dummy/banner4.png")}
        video={require("../../assets/videos/tutorial_intro.mp4")}
      />
      <div className={styles.rowFilter}>
        <div className={styles.contSearch}>
          <input
            placeholder={t("pages.dashboard.faq.keyWords")}
            className={styles.inputSearch}
            value={searchTxt}
            onChange={changeInput}
            onKeyDown={onKeyDown}
          />
          <Search className={styles.iconSearch} />
        </div>
        <button className={styles.buttonSearch} onClick={onSearch}>
          {t("pages.dashboard.faq.search")}
        </button>
      </div>
      <div className={styles.listQuestions}>{renderQuestions()}</div>
    </Layout>
  );
};

export default Faq;
