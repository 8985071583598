import { makeStyles } from "@material-ui/core/styles";
import { colors } from "../../styleguide";
import Commons from "../../utils/styles";

const useStyles = makeStyles({
  container: {
    ...Commons.flexRow,
    ...Commons.defaultButton,
    alignItems: "center",
    padding: 18,
    width: "calc(100% - 24px)",
    backgroundColor: colors.black,
    boxShadow: colors.shadow,
    borderRadius: 12,
  },
  icon: {
    marginRight: 12,
    fontSize: 48,
    color: colors.white,
    [Commons.smallQuery]: {
      marginRight: 6,
      fontSize: 24,
    },
  },
  infoContainer: {
    flex: 1,
  },
  title: {
    margin: 0,
    fontWeight: "700",
    fontSize: 18,
    color: colors.white,
    textAlign: "left",
    [Commons.smallQuery]: {
      fontSize: 16,
      textAlign: "center",
    },
  },
  description: {
    margin: "12px 0 0 0",
    fontSize: 16,
    color: colors.white,
    textAlign: "left",
    [Commons.smallQuery]: {
      display: "none",
    },
  },
  bg_purple: {
    backgroundImage: `linear-gradient(to right, ${colors.secondary}50 , ${colors.secondary})`,
  },
  bg_orange: {
    backgroundImage: `linear-gradient(to right, ${colors.orange}50 , ${colors.orange})`,
  },
  bg_green: {
    backgroundImage: `linear-gradient(to right, ${colors.primary}50 , ${colors.primary})`,
  },
  bg_red: {
    backgroundImage: `linear-gradient(to right, ${colors.red}50 , ${colors.red})`,
  },
});

export default useStyles;
