import { makeStyles } from "@material-ui/core/styles";
import { colors } from "../../styleguide";
import Commons from "../../utils/styles";

const useStyles = makeStyles({
  contBanner: {
    position: "relative",
    paddingTop: 210,
    marginTop: -90,
    [Commons.smallQuery]: {
      paddingTop: 120,
    },
    "&.isMobile": {
      paddingTop: 120,
    },
  },
  bgContBanner: {
    width: "100%",
    height: 540,
    position: "absolute",
    top: 0,
    left: 0,
    [Commons.smallQuery]: {
      height: 360,
    },
    "&.isMobile": {
      height: 360,
    },
  },
  imageBanner: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  shadowBanner: {
    width: "100%",
    height: 30,
    background: "linear-gradient(180deg, gray 0%, white 100%)",
    position: "absolute",
    top: "100%",
  },
  cardContBanner: {
    display: "grid",
    gridAutoRows: "1fr",
    minHeight: 570,
    borderRadius: 24,
    boxShadow: colors.shadow,
    maxWidth: 1200,
    margin: "0 auto",
    width: "90%",
    backgroundColor: colors.black,
    marginBottom: 12,
    gridTemplateColumns: "repeat(2, 1fr)",
    position: "relative",
    [Commons.smallQuery]: {
      ...Commons.flexColumn,
      height: "auto",
    },
    "&.isMobile": {
      ...Commons.flexColumn,
      height: "auto",
    },
  },
  typeTag: {
    ...Commons.centerHorizontal,
    position: "absolute",
    top: -24,
    "& span": {
      display: "none",
      color: colors.white,
      textShadow: "2px 2px black",
    },
    "&:hover span": {
      display: "block",
    },
  },
  contImageCardBanner: {
    height: "calc(100% - 48px)",
    width: "calc(100% - 48px)",
    backgroundColor: colors.black,
    position: "relative",
    cursor: "pointer",
    flex: 4,
    padding: 24,
    borderRadius: "24px 0 0 24px",
    overflow: "hidden",
    [Commons.smallQuery]: {
      height: 240,
      flex: "unset",
      borderRadius: 24,
    },
    "&.isMobile": {
      height: 240,
      flex: "unset",
      borderRadius: 24,
    },
  },
  imageCardBanner: {
    width: "calc(100% - 48px)",
    height: "calc(100% - 48px)",
    position: "absolute",
    objectFit: "cover",
    borderRadius: 24,
  },
  contImageEyeBanner: {
    ...Commons.flexCenter,
    position: "absolute",
    backgroundColor: `${colors.gray50}90`,
    width: "calc(100% - 48px)",
    height: "calc(100% - 48px)",
    opacity: 0,
    transition: "opacity 0.2s",
    borderRadius: 24,
    "& svg": {
      color: colors.white,
      fontSize: 60,
    },
    "&:hover": {
      opacity: 1,
    },
  },
  contInfoBanner: {
    ...Commons.flexColumn,
    justifyContent: "center",
    alignItems: "flex-start",
    flex: 3,
    padding: "24px 36px",
    backgroundColor: "#2B2B2B",
    height: "100%",
    position: "relative",
    boxSizing: "border-box",
    borderRadius: "0 24px 24px 0",
    [Commons.smallQuery]: {
      borderRadius: 24,
      width: "100%",
    },
    "&.isMobile": {
      borderRadius: 24,
      width: "100%",
    },
  },
  title: {
    fontWeight: "bold",
    fontSize: 36,
    margin: 0,
    color: colors.themeColor,
    [Commons.smallQuery]: {
      fontSize: 24,
    },
    "&.isMobile": {
      fontSize: 24,
    },
  },
  subtitle: {
    color: colors.white,
    fontWeight: "600",
    fontSize: 18,
    margin: "18px 0",
    [Commons.smallQuery]: {
      fontSize: 14,
      margin: "12px 0",
    },
    "&.isMobile": {
      fontSize: 14,
      margin: "12px 0",
    },
  },
  rowInfoBanner: {
    ...Commons.flexRow,
    padding: "18px 0 6px 0",
    [Commons.smallQuery]: {
      padding: "12px 0 6px 0",
    },
    "&.isMobile": {
      padding: "12px 0 6px 0",
    },
  },
  iconRowInfoBanner: {
    color: colors.themeColor,
    fontSize: 30,
    [Commons.smallQuery]: {
      fontSize: 24,
    },
    "&.isMobile": {
      fontSize: 24,
    },
  },
  textRowInfoBanner: {
    flex: 1,
    margin: 0,
    marginLeft: 12,
    color: colors.white,
    fontSize: 18,
    [Commons.smallQuery]: {
      fontSize: 15,
    },
    "&.isMobile": {
      fontSize: 15,
    },
  },
  imgOrganizer: {
    ...Commons.coverBackground,
    minWidth: 48,
    maxWidth: 48,
    minHeight: 48,
    maxHeight: 48,
    backgroundColor: colors.white,
    borderRadius: 24,
    border: `3px solid ${colors.themeColor}`,
    cursor: "pointer",
  },
  dataOrganizer: {
    margin: "0 12px",
    flex: 1,
    cursor: "pointer",
  },
  nameOrganizer: {
    textAlign: "left",
    fontSize: 18,
    fontWeight: "bold",
    margin: 0,
    color: colors.white,
  },
  subOrganizer: {
    textAlign: "left",
    fontSize: 14,
    color: colors.white,
    margin: 0,
  },
  visitOrganizer: {
    textAlign: "center",
    fontSize: 14,
    color: colors.white,
    margin: 0,
    backgroundColor: colors.themeColor,
    width: "fit-content",
    borderRadius: 12,
    padding: "0px 18px",
    fontWeight: "bold",
    marginTop: 6,
  },
  statusContainerOrganizer: {
    ...Commons.flexRow,
    flex: 1,
    [Commons.smallQuery]: {
      ...Commons.flexColumn,
    },
    "&.isMobile": {
      ...Commons.flexColumn,
    },
  },
  verifiedOrganizer: {
    color: colors.themeColor,
    fontSize: 36,
  },
  noVerifiedOrganizer: {
    color: colors.red,
    fontSize: 36,
  },
  textVerifiedOrganizer: {
    margin: 0,
    fontSize: 12,
    marginLeft: 6,
    color: colors.white,
    [Commons.smallQuery]: {
      textAlign: "center",
      fontSize: 10,
    },
    "&.isMobile": {
      textAlign: "center",
      fontSize: 10,
    },
  },
  rowInfoCancelled: {
    ...Commons.flexRow,
    border: `3px solid ${colors.themeColor}`,
    padding: 12,
    marginTop: 30,
    borderRadius: 12,
    [Commons.smallQuery]: {
      ...Commons.flexColumn,
    },
  },
  labelInfoCancelled: {
    color: colors.themeColor,
    fontWeight: "bold",
    textAlign: "left",
    fontSize: 24,
    flex: 1,
    [Commons.smallQuery]: {
      fkex: "unset",
      textAlign: "center",
    },
  },
  infoCancelled: {
    color: colors.white,
    fontSize: 14,
    textAlign: "right",
    flex: 2,
    marginLeft: 12,
    "& span": {
      color: colors.themeColor,
      cursor: "pointer",
      fontWeight: "bold",
      fontSize: 15,
    },
    [Commons.smallQuery]: {
      marginLeft: 0,
      fkex: "unset",
      textAlign: "center",
      fontSize: 15,
    },
  },
});

export default useStyles;
