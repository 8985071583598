import React from "react";
import {
  Bookmark,
  Share,
  Close,
  Link,
  CropFree,
  PersonAdd,
} from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import moment from "moment";
import _ from "lodash";

import {
  verifyEmail,
  renderShortPrice,
  removeChild,
  currencyFormat,
  returnTax,
  returnCurrentLink,
} from "../../utils/validations";
import LocalStorage, {
  returnSessionStorage as SessionStorage,
} from "../../utils/localStorage";
import MercadoPagoScript from "../../components/MercadoPagoScript";
import VideoTutorial from "../..//assets/videos/tutorialCompra.mp4";
import ContentEvent from "../../components/ContentEvent";
import BannerEvent from "../../components/BannerEvent";
import { isBookmarked } from "../../utils/validations";
import DialogForm from "../../components/dialogForm";
import EventsService from "../../services/events";
import { Store } from "../../hooks/main_store";
import pageStyles from "./styles/eventStyles";
import SelectQuantity from "./selectQuantity";
import { keyMp } from "../../utils/constants";
import AuthService from "../../services/auth";
import * as Routes from "../../utils/routes";
import Model from "../../hooks/Model";
import Layout from "../layout";

const Event = ({ history }) => {
  const { t } = useTranslation();
  const isPreview = history?.location?.state?.isPreview;
  const urlParams = new URLSearchParams(window.location.search);
  const { state } = React.useContext(Store);
  let localDataEvent = null;

  if (!isPreview) {
    localDataEvent = SessionStorage(state.isIncognito).getItem(
      urlParams.get("event")
    );
  }

  const styles = pageStyles();
  const returnEvent =
    state.eventSelected || (localDataEvent && JSON.parse(localDataEvent)) || {};
  const [scrollYvalue, setScrollYvalue] = React.useState(0);
  const [eventInfo, setEventInfo] = React.useState(returnEvent || {});
  const [mercadoPago, setMercadoPago] = React.useState(null);
  const [statsMember, setStatsMember] = React.useState({});
  const [pixelPushed, setPixelPushed] = React.useState(false);
  const [sell2render, setSell2render] = React.useState([]);
  const [responseSell2render, setResponseSell2render] = React.useState([]);
  const [vc, setVc] = React.useState({
    artists: returnEvent?.artists || [],
    contacts: [],
    showModal: false,
    interested: false,
    showPrompt: false,
    typeSlctd: null,
    quantity: null,
    showBtnRef: false,
    showBtnQr: false,
    showPoster: false,
    showVideo: false,
    isReferred: false,
    showBtnPay: false,
    email: state?.userInfo?.email || "",
    name: "",
    numId: "",
    lastName: "",
    promoCode: "",
    showPromoCode: false,
  });
  const changeVc = (data) => setVc((ov) => ({ ...ov, ...data }));

  const onBook = async () => {
    try {
      let currentData = state.bookmarks || [];
      let increase = 0;
      const indexSearch = _.findIndex(state.bookmarks, {
        uid: eventInfo?.uid,
      });

      Model.setData("loading", true);
      if (!state.uid) {
        return Model.setData("openAuth", true);
      }
      if (indexSearch > -1) {
        currentData.splice(indexSearch, 1);
        increase = -1;
      } else {
        currentData.push({
          uid: eventInfo?.uid,
          created: moment().format("x"),
        });
        increase = 1;
      }

      await AuthService.updateBookmarks(state.uid, eventInfo?.uid, increase);
      Model.setData("bookmarks", currentData);
      Model.updateAlerts({
        message:
          increase > 0
            ? t("pages.events.indexEvent.nowFollow")
            : t("pages.events.indexEvent.unFollow"),
        variant: "success",
      });
    } catch (e) {
      console.log(t("pages.events.indexEvent.errorUpdate"), e);
    } finally {
      Model.setData("loading", false);
    }
  };

  const bookTickets = async () => {
    try {
      // Get the action to complete.
      Model.setData("dialog", { open: false });
      Model.setData("loading", true);

      const isTest =
        process.env.NODE_ENV === "development" ||
        window?.location?.host?.includes?.("test.")
          ? "yes"
          : null;
      const isMemberContact = eventInfo?.enablePromoters && vc.showBtnPay;
      let ref = isMemberContact ? urlParams.get("ref") : null;
      let external_ref = urlParams.get("external_ref");

      // With local value of promoter saved
      if (!ref && isMemberContact) {
        ref =
          LocalStorage(state.isIncognito).getItem(`@ref_${eventInfo?.uid}`) ||
          null;
      }
      if (!external_ref) {
        external_ref =
          LocalStorage(state.isIncognito).getItem(
            `@external_ref_${eventInfo?.uid}`
          ) || null;
      }
      LocalStorage(state.isIncognito).removeItem(`@ref_${eventInfo?.uid}`);
      LocalStorage(state.isIncognito).removeItem(
        `@external_ref_${eventInfo?.uid}`
      );

      // Avoid use the same user id as contact
      external_ref = external_ref === state.uid ? null : external_ref;
      ref = ref === state.uid ? null : ref;

      const emailLower = vc.email.toLowerCase().replace(/ /g, "");
      const data = {
        isTest,
        name: vc.name,
        lastName: vc.lastName,
        email: emailLower,
        numId: vc.numId,
        quantity: parseInt(vc.quantity),
        typeSlctd: vc.typeSlctd,
        eventSelected: eventInfo?.uid,
        ref: ref,
        isReferred: vc.isReferred,
        promoCode: vc.promoCode,
        external_ref: isMemberContact ? null : external_ref,
        currency: state.defaultCurrency,
      };

      const response = await EventsService.bookAticket(data);
      if (
        vc.isReferred ||
        eventInfo?.prices?.[vc.typeSlctd]?.[
          state.defaultCurrency === "USD" ? "usdPrice" : "price"
        ] === 0
      ) {
        setTimeout(() => {
          Model.setData("dialog", {
            open: true,
            title: t("pages.events.indexEvent.sellRegistered"),
            text: t("pages.events.indexEvent.inSomeMinutesEmail", {
              email: vc.email,
            }),
            txtRight: t("pages.events.indexEvent.gotIt"),
            fnRight: () => {
              Model.setData("dialog", { open: false, isReferred: false });
              if (
                !vc.isReferred &&
                eventInfo?.prices?.[vc.typeSlctd]?.price === 0
              ) {
                history.push(Routes.MY_TRANSACTIONS);
              }
            },
          });
        }, 1000);
      } else if (response.url) {
        window.open(response.url, "_self");
      } else if (!response.idPreference || !mercadoPago) {
        throw new Error(
          t("pages.events.indexEvent.noAvailableBooking", {
            error: response || t("pages.events.indexEvent.errorData"),
          })
        );
      } else {
        // Go to Pay
        setTimeout(() => {
          mercadoPago.checkout({
            preference: {
              id: response.idPreference,
            },
            autoOpen: true,
          });
        }, 1000);
      }
    } catch (e) {
      Model.updateAlerts({
        message: e?.message || String(e),
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
      Model.setData("dialog", { open: false, isReferred: false });
    }
  };

  const onSelectTickets = (typeSlctd, quantity) => {
    const emailValue =
      state?.userInfo?.email || AuthService.currentUser()?.email;
    if (!emailValue && state.uid) {
      return window?.location?.reload();
    }
    if (!state?.emailVerified) {
      return Model.setData("dialog", {
        open: true,
        title: t("pages.events.indexEvent.beforeContinue"),
        text: state.isOnPluginPartner
          ? t("pages.events.indexEvent.logInAccount")
          : t("pages.events.indexEvent.mustVerifyEmail", {
              label: !state.uid ? t("pages.events.indexEvent.createAnd") : "",
            }),
        txtLeft: t("commons.cancel"),
        fnLeft: () => {
          Model.setData("dialog", { open: false });
        },
        txtRight: state.isOnPluginPartner
          ? t("commons.continue")
          : t("pages.events.indexEvent.goToProfile"),
        fnRight: () => {
          Model.setData("dialog", { open: false });
          if (state.uid) {
            history.push("/profile");
          } else {
            Model.setData("openAuth", true);
          }
        },
      });
    }

    if (parseInt(quantity) > 0 && typeSlctd !== null) {
      if (state.uid) {
        if (vc.isReferred) {
          Model.setData("dialog", { open: false });
          changeVc({
            showPrompt: true,
            showModal: false,
            typeSlctd,
            quantity,
            email: vc.isReferred ? "" : emailValue || "",
            name: "",
            numId: "",
            lastName: "",
            promoCode: "",
            showPromoCode: false,
          });
        } else {
          if (
            (eventInfo?.prices?.length === 1 &&
              eventInfo?.prices?.[0]?.price === 0) ||
            state.defaultCurrency === "USD"
          ) {
            changeVc({
              showPrompt: true,
              showModal: false,
              typeSlctd,
              quantity,
              email: vc.isReferred ? "" : emailValue || "",
              name: "",
              numId: "",
              lastName: "",
              promoCode: "",
              showPromoCode: false,
            });
          } else {
            Model.setData("dialog", {
              open: true,
              title: t("pages.events.indexEvent.promoCode"),
              text: t("pages.events.indexEvent.youHaveCode"),
              txtLeft: t("commons.no"),
              fnLeft: () => {
                Model.setData("dialog", { open: false });
                changeVc({
                  showPrompt: true,
                  showModal: false,
                  typeSlctd,
                  quantity,
                  email: vc.isReferred ? "" : emailValue || "",
                  name: "",
                  numId: "",
                  lastName: "",
                  promoCode: "",
                  showPromoCode: false,
                });
              },
              txtRight: t("commons.yes"),
              fnRight: () => {
                Model.setData("dialog", { open: false });
                changeVc({
                  showPrompt: true,
                  showModal: false,
                  typeSlctd,
                  quantity,
                  email: vc.isReferred ? "" : emailValue || "",
                  name: "",
                  numId: "",
                  lastName: "",
                  promoCode: "",
                  showPromoCode: true,
                });
              },
            });
          }
        }
      } else {
        Model.setData("openAuth", true);
      }
    } else {
      Model.updateAlerts({
        message: t("pages.events.indexEvent.completeData"),
        variant: "error",
      });
    }
  };

  const showConfirmDialog = () => {
    changeVc({ showPrompt: false });
    Model.setData("dialog", {
      open: true,
      title: t("pages.events.indexEvent.confirmSelection"),
      text: t("pages.events.indexEvent.youWantBuyTickets", {
        name: vc.isReferred ? "" : vc.name,
        action: vc.isReferred
          ? t("pages.events.indexEvent.register")
          : t("pages.events.indexEvent.purchase"),
        quantity: vc.quantity,
        multiple: vc.quantity > 1 ? "s" : "",
        ticketName:
          eventInfo?.prices?.[vc.typeSlctd]?.label ||
          (vc.isReferred ? "Cortesia" : "ERROR"),
        description:
          vc.isReferred || eventInfo?.prices?.[vc.typeSlctd]?.price === 0
            ? t("pages.events.indexEvent.dataWillSave", { email: vc.email })
            : t("pages.events.indexEvent.youWillBeRedirect"),
      }),
      txtLeft: t("commons.cancel"),
      fnLeft: () => Model.setData("dialog", { open: false }),
      txtRight: t("commons.yesConfirm"),
      fnRight: bookTickets,
    });
  };

  const confirmPromoCode = async () => {
    try {
      Model.setData("loading", true);

      const responseConfirmPromoCode = await EventsService.confirmPromoCode({
        uidEvent: eventInfo?.uid,
        promoCode: vc.promoCode,
      });

      Model.setData("dialog", {
        open: true,
        title: t("pages.events.indexEvent.confirmDiscount"),
        text: t("pages.events.indexEvent.infoPromoCodeEntered", {
          name: vc.promoCode,
          discount: responseConfirmPromoCode?.valuePromoCode
            ? `$${renderShortPrice(
                responseConfirmPromoCode?.valuePromoCode
              )} COP`
            : `${responseConfirmPromoCode?.percentPromoCode}%`,
        }),
        txtLeft: t("commons.cancel"),
        fnLeft: () => Model.setData("dialog", { open: false }),
        txtRight: t("pages.events.indexEvent.yesPay"),
        fnRight: () => {
          Model.setData("dialog", { open: false });
          setTimeout(showConfirmDialog, 500);
        },
      });
    } catch (e) {
      Model.updateAlerts({
        message: t("pages.events.indexEvent.errorPromoCode") + e,
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  const onSendForm = () => {
    if (!vc.name || !vc.lastName || !vc.numId || !vc.email) {
      Model.updateAlerts({
        message: t("pages.events.indexEvent.completeData"),
        variant: "error",
      });
    } else if (!verifyEmail(vc.email)) {
      Model.updateAlerts({
        message: t("pages.events.indexEvent.emailHasBadFormat"),
        variant: "error",
      });
    } else if (vc.promoCode) {
      confirmPromoCode();
    } else {
      showConfirmDialog();
    }
  };

  const onBuyTickets = () => {
    if (
      (eventInfo?.organizer?.secret ||
        eventInfo?.organizer?.bitcoin ||
        (eventInfo?.prices?.length === 1 &&
          eventInfo?.prices?.[0]?.price === 0)) &&
      eventInfo.acceptOnlinePayments
    ) {
      changeVc({ showModal: true, isReferred: false });
    } else {
      Model.setData("dialog", {
        open: true,
        title: t("pages.events.indexEvent.buyTickets"),
        text: t("pages.events.indexEvent.partnerNoCanSell"),
        txtLeft: eventInfo?.organizer?.phone
          ? t("pages.events.indexEvent.goToWhatsapp")
          : "",
        fnLeft: () => {
          Model.setData("dialog", { open: false });
          window.open(
            "https://wa.me/" +
              eventInfo?.organizer?.phone?.replace(/[^0-9]/g, ""),
            "_blank"
          );
        },
        txtRight: t("pages.events.indexEvent.gotIt"),
        fnRight: () => Model.setData("dialog", { open: false }),
      });
    }
  };
  const getContacts = async () => {
    try {
      const ref = urlParams.get("ref");
      const external_ref = urlParams.get("external_ref");
      let refIsReal = false;
      LocalStorage(state.isIncognito).setItem(
        `@external_ref_${eventInfo?.uid}`,
        external_ref
      );
      // Logistics
      if (eventInfo?.logistics?.indexOf?.(state.uid) > -1) {
        changeVc({ showBtnQr: true });
      }
      // Promoters
      const responseContacts = eventInfo?.contacts || [];
      const withCollaborators = [
        ...responseContacts,
        ...(eventInfo?.collaborators || []),
      ];
      withCollaborators.forEach((uid) => {
        if (uid === state.uid) {
          changeVc({ showBtnRef: true });
        }
        if (uid === ref) {
          refIsReal = true;
          LocalStorage(state.isIncognito).setItem(
            `@ref_${eventInfo?.uid}`,
            ref
          );
        }
      });
      changeVc({ contacts: responseContacts.sort(() => Math.random() - 0.5) });
      if (!!ref && refIsReal) {
        changeVc({ showBtnPay: true });
      }
    } catch (e) {
      console.log(t("pages.events.indexEvent.errorGetContacts"), e);
    }
  };

  const getEvent = async (uid) => {
    try {
      Model.setData("loading", true);
      const infoEvent = await EventsService.getEvent(uid);
      changeVc({
        artists: infoEvent?.artists || [],
      });
      setEventInfo(infoEvent);
      Model.setData("eventSelected", infoEvent);
      if (
        state?.infoPartner?.uid &&
        state?.infoPartner?.logo &&
        !state.fistLoad
      ) {
        Model.setData("isWhiteLabel", !!infoEvent?.organizer?.isWhiteLabel);
      }
    } catch (e) {
      console.log(t("pages.events.indexEvent.errorGetEvents"), e);
    } finally {
      Model.setData("loading", false);
    }
  };

  const getSellings = async () => {
    try {
      let pricesSells = {};
      let pricesSellsFinal = {};
      const labelPrice = state.defaultCurrency === "USD" ? "usdPrice" : "price";
      const sellingsArray = await EventsService.getSellings({
        eventUid: eventInfo?.uid,
        uid: state.uid,
      });

      if (sellingsArray?.forEach) {
        setResponseSell2render(sellingsArray);
        sellingsArray
          .filter((sell) => sell?.[labelPrice] > 0)
          .forEach((sell) => {
            if (pricesSells?.[sell?.[labelPrice]]?.push) {
              pricesSells[sell[labelPrice]].push(sell);
            } else {
              pricesSells[sell[labelPrice]] = [sell];
            }
          });
        Object.keys(pricesSells).forEach((keyPrice) => {
          pricesSellsFinal[keyPrice] = pricesSells[keyPrice].sort(
            (a, b) => a?.createdAt - b?.createdAt
          );
        });
        setSell2render(pricesSellsFinal);
      }
    } catch (e) {
      console.log(t("pages.events.indexEvent.errorGetOffers"), e);
    }
  };

  const getStatsMemberUser = async () => {
    try {
      const infoStats = await EventsService.getStatsMemberUser(eventInfo?.uid);
      setStatsMember(infoStats);
    } catch (e) {
      console.log(t("pages.events.indexEvent.errorGetStats"), e);
    }
  };

  const renderClaims = () =>
    eventInfo?.prices?.map(
      (price) =>
        `\n\n${price?.label}: ${currencyFormat(
          returnTax(price?.price, "platform") * 0.1,
          "COP",
          0
        )} aprox.`
    );

  const onCopyLink = () => {
    navigator.clipboard.writeText(
      `https://www.fravents.com/events?event=${eventInfo.uid}${
        state?.userInfo?.acceptedReferProgram
          ? `&external_ref=${state.uid}`
          : ""
      }`
    );
    Model.updateAlerts({
      message: t("pages.events.indexEvent.linkCopied"),
      variant: "success",
    });
    if (
      state?.userInfo?.acceptedReferProgram &&
      !eventInfo?.organizer?.preferentialRate
    ) {
      Model.setData("dialog", {
        open: true,
        title: t("pages.events.indexEvent.linkReadyRef"),
        text: t("pages.events.indexEvent.shareLink", {
          amount: renderClaims(),
        }),
        txtRight: t("pages.events.indexEvent.gotIt"),
        fnRight: () => Model.setData("dialog", { open: false }),
      });
    }
  };

  const renderBarActionsPayment = () =>
    parseInt(eventInfo.date) < parseInt(moment().format("x")) ? null : (
      <div className={styles.contBuy}>
        <div
          className={[
            styles.buttonBook,
            vc.interested ? styles.buttonBookSlctd : "",
          ].join(" ")}
          onClick={onBook}
        >
          <Bookmark />
        </div>
        {state.isOnPluginPartner ? null : (
          <div
            className={styles.buttonBook}
            style={{ marginLeft: 12 }}
            onClick={onCopyLink}
          >
            <Share />
            {state.loading ||
            !state?.userInfo?.acceptedReferProgram ||
            eventInfo?.organizer?.preferentialRate ? null : (
              <div className={styles.floatLabelButton}>
                <span>{t("pages.events.indexEvent.refAndEarn")}</span>
                <div />
              </div>
            )}
          </div>
        )}
        <div style={{ flex: 1 }} />
        {eventInfo.status === "finished" ? (
          <div className={styles.lblPrivate}>
            {t("pages.events.indexEvent.eventFinished")}
          </div>
        ) : state?.emailVerified || !state.uid ? (
          eventInfo?.private && !vc.showBtnPay ? (
            <div className={styles.lblPrivate}>
              {t("pages.events.indexEvent.getTicketsByLink")}
            </div>
          ) : (
            <button className={styles.buttonBuy} onClick={onBuyTickets}>
              {eventInfo?.prices?.length === 1 &&
              eventInfo?.prices?.[0]?.price === 0
                ? t("pages.events.indexEvent.registerFree")
                : t("pages.events.indexEvent.buyTickets")}
            </button>
          )
        ) : (
          <button
            className={styles.buttonVerify}
            onClick={() => history.push("/profile")}
          >
            {t("pages.events.indexEvent.toBuyVerifyEmail")}
          </button>
        )}
      </div>
    );

  const onScrollLayout = (event) => {
    setScrollYvalue(event?.srcElement?.scrollTop || 0);
  };

  React.useEffect(() => {
    let pricesSells = {};
    let pricesSellsFinal = {};
    const labelPrice = state.defaultCurrency === "USD" ? "usdPrice" : "price";
    const sellingsArray = [...(responseSell2render || [])];

    if (sellingsArray?.forEach) {
      sellingsArray
        .filter((sell) => sell?.[labelPrice] > 0)
        .forEach((sell) => {
          if (pricesSells?.[sell?.[labelPrice]]?.push) {
            pricesSells[sell[labelPrice]].push(sell);
          } else {
            pricesSells[sell[labelPrice]] = [sell];
          }
        });
      Object.keys(pricesSells).forEach((keyPrice) => {
        pricesSellsFinal[keyPrice] = pricesSells[keyPrice].sort(
          (a, b) => a?.createdAt - b?.createdAt
        );
      });
      setSell2render(pricesSellsFinal);
    }
    //eslint-disable-next-line
  }, [state.defaultCurrency]);

  React.useEffect(() => {
    if (eventInfo?.uid) {
      getSellings();
      if (vc.showBtnRef) {
        getStatsMemberUser();
      }
    }
    //eslint-disable-next-line
  }, [eventInfo?.uid, vc.showBtnRef]);

  React.useLayoutEffect(() => {
    // Facebook Pixel
    const scriptTag = document.createElement("script");
    const noScriptTag = document.createElement("noscript");
    const imgTag = document.createElement("img");

    if (eventInfo?.fbPx && !pixelPushed) {
      imgTag.height = 1;
      imgTag.width = 1;
      imgTag.src = `https://www.facebook.com/tr?id=${eventInfo?.fbPx}&ev=PageView&noscript=1`;

      scriptTag.innerHTML = `!function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window,document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '${eventInfo?.fbPx}'); 
      fbq('track', 'PageView');`;

      noScriptTag.appendChild(imgTag);

      document.getElementsByTagName("head")[0].appendChild(scriptTag);
      document.getElementsByTagName("head")[0].appendChild(noScriptTag);

      setPixelPushed(true);
    }
    return () => {
      const allTags = document.getElementsByTagName("script");
      removeChild(scriptTag);
      removeChild(noScriptTag);
      for (let i = 0; i < allTags.length; i++) {
        if (allTags[i].src.includes("fbevents")) {
          removeChild(allTags[i]);
        }
        if (allTags[i].src.includes("connect.facebook.net/signals")) {
          removeChild(allTags[i]);
        }
      }
    };
    //eslint-disable-next-line
  }, [eventInfo?.fbPx]);

  React.useEffect(() => {
    if (isBookmarked(eventInfo?.uid, state.bookmarks)) {
      changeVc({ interested: true });
    } else {
      changeVc({ interested: false });
    }
    //eslint-disable-next-line
  }, [state.bookmarks]);

  React.useEffect(() => {
    const event = urlParams.get("event");
    if (!event && state?.eventSelected?.uid && !isPreview) {
      SessionStorage(state.isIncognito).setItem(
        state.eventSelected.uid,
        JSON.stringify(state.eventSelected)
      );
      return (window.location.href += `?event=${state.eventSelected.uid}`);
    } else if (state.eventSelected) {
      setEventInfo(state.eventSelected);
      // EventsService.logEvent(state?.eventSelected?.name)
      //   .then(() => {})
      //   .catch(() => {});
      getContacts();
      if (!state.eventSelected?.organizer) {
        getEvent(state.eventSelected.uid);
      }
    } else if (event) {
      getEvent(event);
    } else {
      history.push("/");
    }
    //eslint-disable-next-line
  }, [state.eventSelected, state.uid, state.userInfo]);

  React.useEffect(() => {
    Model.setData("opacity", 0.7);
    Model.setData("loading", true);
    //eslint-disable-next-line
  }, []);

  return (
    <Layout
      hideFooterLinks={!!state?.isOnPluginPartner || !!state?.isWhiteLabel}
      onScrollDash={onScrollLayout}
    >
      <MercadoPagoScript
        onEndLoad={() => {
          setMercadoPago(
            new window.MercadoPago(keyMp, {
              locale: "es-CO",
            })
          );
        }}
      />

      <BannerEvent
        eventInfo={eventInfo}
        changeVc={changeVc}
        history={history}
        BarActions={renderBarActionsPayment}
      />

      {!state.isOnPluginPartner &&
      (vc.showBtnRef ||
        ((eventInfo.partner === state.uid || eventInfo.editor === state.uid) &&
          eventInfo?.enablePromoters &&
          (vc.showBtnRef ||
            eventInfo.partner === state.uid ||
            eventInfo.editor === state.uid)) ||
        vc.showBtnQr ||
        eventInfo.partner === state.uid ||
        eventInfo.editor === state.uid) ? (
        <>
          <p className={styles.infoPartnerBtns}>
            {t("pages.events.indexEvent.youAreMember")}
          </p>
          <div className={styles.rowPartnerBtns}>
            {vc.showBtnRef ? (
              <div className={styles.contStats}>
                <p>{t("pages.events.indexEvent.boughtTickets")}</p>{" "}
                <b>{statsMember?.sales || 0}</b>
              </div>
            ) : null}
            {eventInfo?.enablePromoters &&
            (vc.showBtnRef ||
              eventInfo.partner === state.uid ||
              eventInfo.editor === state.uid) ? (
              <button
                className={styles.btnRef}
                onClick={() => {
                  navigator.clipboard.writeText(
                    `https://www.fravents.com/events?event=${eventInfo.uid}&ref=${state.uid}`
                  );
                  Model.updateAlerts({
                    message: t("pages.events.indexEvent.linkCopied"),
                    variant: "success",
                  });
                }}
              >
                <Link />
                <div>{t("pages.events.indexEvent.copyLink")}</div>
              </button>
            ) : !eventInfo?.enablePromoters && vc.showBtnRef ? (
              <button
                className={styles.btnRef}
                onClick={() => {
                  Model.updateAlerts({
                    message: t("pages.events.indexEvent.blockRefLink"),
                    variant: "warning",
                  });
                }}
              >
                <Link />
                <div>{t("pages.events.indexEvent.linkRefBlocked")}</div>
              </button>
            ) : null}
            {eventInfo.partner === state.uid ||
            eventInfo.editor === state.uid ? (
              <button
                className={styles.btnRef}
                onClick={() => changeVc({ showModal: true, isReferred: true })}
              >
                <PersonAdd />
                <div>{t("pages.events.indexEvent.addTicket")}</div>
              </button>
            ) : null}
            {vc.showBtnQr ||
            eventInfo.partner === state.uid ||
            eventInfo.editor === state.uid ? (
              <button
                className={styles.btnRef}
                onClick={() => {
                  Model.setData("eventSelected", eventInfo);
                  history.push("validate-ticket", { event: eventInfo });
                }}
              >
                <CropFree />
                <div>{t("pages.events.indexEvent.validateTicket")}</div>
              </button>
            ) : null}
          </div>
        </>
      ) : null}

      <ContentEvent
        eventInfo={eventInfo}
        artists={vc.artists}
        contacts={vc.contacts}
        sells={sell2render}
        history={history}
        scrollYvalue={scrollYvalue}
        getSellings={getSellings}
        onBook={onBook}
      />

      {/* <button
            className={styles.btnTutorial}
            onClick={() => changeVc({ showVideo: true })}
          >
            <Visibility />
            Ver tutorial - <b>¿Cómo comprar?</b>
          </button> */}

      <SelectQuantity
        onClose={() => changeVc({ showModal: false })}
        onDone={onSelectTickets}
        data={
          vc.isReferred &&
          (state?.uid === eventInfo?.partner ||
            state?.uid === eventInfo?.editor)
            ? {
                ...eventInfo,
                prices: [
                  ...eventInfo.prices,
                  {
                    label: "Cortesia",
                    price: 0,
                    stock: "no limit",
                  },
                ],
              }
            : eventInfo
        }
        show={vc.showModal}
        isReferred={vc.isReferred}
      />

      <DialogForm
        show={vc.showPrompt}
        title={t("pages.events.indexEvent.completeBuyData")}
        text={t("pages.events.indexEvent.toContinueWithBuy", {
          quantity: vc.quantity,
          multiple: vc.quantity > 1 ? "s" : "",
          type: eventInfo?.prices?.[vc.typeSlctd]?.label || "Cortesia",
        })}
        subtext={
          vc.isReferred
            ? t("pages.events.indexEvent.confirmBuyRef")
            : t("pages.events.indexEvent.toSendDataAcceptTC", {
                provider:
                  state.defaultCurrency === "USD"
                    ? "Cryptomus"
                    : "Mercado Pago",
              })
        }
        subtextLink={returnCurrentLink(
          Routes.RENDER_LEGAL_DOC + "?document=buyers"
        )}
        leftBtText={t("commons.cancel")}
        funcLeft={() =>
          changeVc({
            showPrompt: false,
            email: "",
            name: "",
            numId: "",
            lastName: "",
            promoCode: "",
            isReferred: false,
          })
        }
        rightBtText={t("commons.accept")}
        funcRight={onSendForm}
        inputs={[
          { label: t("commons.name"), value: "name" },
          { label: t("commons.lastName"), value: "lastName" },
          {
            label: t("commons.email"),
            value: "email",
            type: "email",
            disabled: !vc.isReferred,
          },
          { label: t("commons.numId"), value: "numId" },
          !vc.isReferred &&
          vc.showPromoCode &&
          parseInt(
            (state.defaultCurrency === "COP" &&
              eventInfo?.prices?.[vc.typeSlctd]?.price) ||
              (state.defaultCurrency === "USD" &&
                eventInfo?.prices?.[vc.typeSlctd]?.usdPrice) ||
              "0"
          ) > 0
            ? {
                label: t("pages.events.indexEvent.promoCode"),
                value: "promoCode",
              }
            : null,
        ]}
        vc={vc}
        changeVc={changeVc}
      />
      {vc.showPoster || vc.showVideo ? (
        <div
          className={styles.modalPoster}
          onClick={() => changeVc({ showPoster: false, showVideo: false })}
        >
          <button
            className={styles.btnClosePoster}
            onClick={() => changeVc({ showPoster: false, showVideo: false })}
          >
            <Close />
          </button>
          {vc.showPoster ? (
            <img
              className={styles.imgPoster}
              alt="Poster"
              src={eventInfo.imageBanner}
            />
          ) : (
            <video className={styles.videoTutorial} autoPlay controls>
              <source src={VideoTutorial} type="video/mp4" />
              {t("commons.noSupportVideos")}
            </video>
          )}
        </div>
      ) : null}
    </Layout>
  );
};

export default Event;
