import { makeStyles } from "@material-ui/core/styles";
import { colors } from "../../styleguide";
import Commons from "../../utils/styles";

const useStyles = makeStyles({
  contModal: {
    ...Commons.flexCenter,
  },
  contForm: {
    backgroundColor: colors.white,
    borderRadius: 12,
    overflow: "hidden",
    width: "90%",
    maxWidth: 420,
    border: "none",
  },
  rowTitle: {
    ...Commons.flexRow,
    padding: "18px 12px",
    borderBottom: `1px solid ${colors.themeColor}`,
  },
  title: {
    margin: 0,
    fontWeight: 600,
    flex: 1,
    marginRight: 12,
    fontSize: 24,
  },
  closeBtn: {
    ...Commons.defaultButton,
    ...Commons.flexCenter,
    backgroundColor: colors.themeColor,
    width: 36,
    height: 36,
    borderRadius: "50%",
    boxShadow: colors.shadow,
    "& svg": {
      color: colors.white,
    },
  },
  contInputs: {
    padding: "24px 18px",
    borderBottom: `1px solid ${colors.themeColor}`,
  },
  input: {
    borderRadius: 6,
    boxShadow: colors.shadow,
    padding: "18px 12px",
    width: "calc(100% - 24px)",
    outline: "none",
    touchAction: "manipulation",
    border: `1px solid ${colors.grayLight}`,
  },
  inputPass: {
    paddingRight: 42,
  },
  rowPass: {
    ...Commons.flexRow,
    margin: "12px 0px",
  },
  eyeBtn: {
    ...Commons.defaultButton,
    position: "absolute",
    right: 6,
    top: 12,
    opacity: 0.5,
    color: colors.black,
  },
  noBgBtn: {
    ...Commons.defaultButton,
    padding: "12px 0px",
    color: colors.themeColor,
    fontWeight: 600,
    width: "100%",
    margin: "6px 0px 12px 0px",
  },
  termsLbl: {
    fontSize: 12,
    textAlign: "center",
    padding: 12,
    color: colors.gray50,
    marginBottom: 12,
    "& a": {
      color: colors.themeColor,
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
  fullBtn: {
    ...Commons.defaultButton,
    width: "100%",
    padding: "12px 0px",
    color: colors.white,
    backgroundColor: colors.themeColor,
    borderRadius: 12,
    fontWeight: 700,
    fontSize: 14,
    boxShadow: colors.shadow,
  },
  footerModal: {
    padding: "12px 0px 6px 0px",
  },
});

export default useStyles;
