import { makeStyles } from "@material-ui/core/styles";
import { colors } from "../../styleguide";
import Commons from "../../utils/styles";

const useStyles = makeStyles({
  "@keyframes scaleLogo": {
    "0%": {
      transform: "scale(1)",
    },
    "50%": {
      transform: "scale(1.5)",
    },
    "100%": {
      transform: "scale(1)",
    },
  },
  contLoader: {
    ...Commons.flexCenter,
    flexDirection: "column",
    backgroundColor: "rgba(255, 255, 255, 1)",
    position: "fixed",
    height: "100%",
    width: "100vw",
    zIndex: 10,
    left: "0px",
    top: "0px",
    "& svg": {
      color: colors.themeColor,
    },
  },
  logo: {
    width: 90,
    animation: "$scaleLogo 2s infinite",
  },
  poweredLabel: {
    margin: "18px 0",
    textAlign: "center",
    color: colors.black,
    fontSize: 24,
    fontWeight: "700",
  },
});

export default useStyles;
