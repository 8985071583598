import _ from "lodash";
import api from "./api";

const getPartner = async (uid) => {
  try {
    const response = await api.post("/partner/getPartner", { uidPartner: uid });
    if (response.ok && response.status === 200) {
      return response.data || {};
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw _.get(e, "message", e);
  }
};

const searchPartner = async (url) => {
  try {
    const response = await api.post("/partner/searchPartner", { url });
    if (response.ok && response.status === 200) {
      return response.data || {};
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw _.get(e, "message", e);
  }
};

const availablePluginToken = async () => {
  try {
    const response = await api.post("/partner/availablePluginToken");
    if (response.ok && response.status === 200) {
      return response.data || "";
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw _.get(e, "message", e);
  }
};

const disablePluginToken = async () => {
  try {
    const response = await api.post("/partner/disablePluginToken");
    if (response.ok && response.status === 200) {
      return response.data || "";
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw _.get(e, "message", e);
  }
};

const getPluginToken = async () => {
  try {
    const response = await api.post("/partner/getPluginToken");
    if (response.ok && response.status === 200) {
      return response.data || "";
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw _.get(e, "message", e);
  }
};

const getUidPartner = async (token) => {
  try {
    const response = await api.post("/partner/getUidPartner", { token });
    if (response.ok && response.status === 200) {
      return response?.data || "";
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw _.get(e, "message", e);
  }
};

const acceptLinkNequiPartner = async (data) => {
  try {
    const response = await api.post("/partner/acceptLinkNequiPartner", data);
    if (response.ok && response.status === 200) {
      return response?.data || {};
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw _.get(e, "message", e);
  }
};

const acceptLinkBitcoinPartner = async (data) => {
  try {
    const response = await api.post("/partner/acceptLinkBitcoinPartner", data);
    if (response.ok && response.status === 200) {
      return response?.data || {};
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw _.get(e, "message", e);
  }
};

const sendFormPartnerProgram = async (data) => {
  try {
    const response = await api.post("/partner/sendFormPartnerProgram", data);
    if (response.ok && response.status === 200) {
      return response?.data || {};
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw _.get(e, "message", e);
  }
};

const acceptPromoterCode = async () => {
  try {
    const response = await api.post("/partner/acceptPromoterCode");

    if (response.ok && response.status === 200) {
      return response?.data || {};
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw _.get(e, "message", e);
  }
};

export default {
  getPartner,
  searchPartner,
  availablePluginToken,
  disablePluginToken,
  getPluginToken,
  getUidPartner,
  acceptLinkNequiPartner,
  acceptLinkBitcoinPartner,
  sendFormPartnerProgram,
  acceptPromoterCode,
};
