import React from "react";
import { Assignment, AccountTree, Group } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

import pageStyles from "./styles";

const TemplateEventSelectorModal = ({
  onClose = () => {},
  open = false,
  onSubmit = () => {},
}) => {
  const { t } = useTranslation();
  const styles = pageStyles();
  const [selectedTemplate, setSelectedTemplate] = React.useState("");

  const options = [
    {
      label: t("components.templateEventSelectorModal.freeList"),
      description: t(
        "components.templateEventSelectorModal.freeListDescription"
      ),
      icon: () => <Assignment />,
      value: "free",
    },
    {
      label: t("components.templateEventSelectorModal.sellByStages"),
      description: t(
        "components.templateEventSelectorModal.sellByStagesDescription"
      ),
      icon: () => <AccountTree />,
      value: "byTypes",
    },
    {
      label: t("components.templateEventSelectorModal.withoutBlockers"),
      description: t(
        "components.templateEventSelectorModal.withoutBlockersDescription"
      ),
      icon: () => <Group />,
      value: "forAll",
    },
  ];

  const renderOptions = () =>
    options.map((option) => (
      <div
        key={option.label}
        className={[
          styles.option,
          selectedTemplate === option.value ? styles.selectedOption : "",
        ].join(" ")}
        onClick={() => setSelectedTemplate(option.value)}
      >
        {option?.icon()}
        <h3 className={styles.labelOption}>{option.label}</h3>
        <p className={styles.descriptionOption}>{option.description}</p>
      </div>
    ));

  const onContinue = () => {
    if (selectedTemplate) {
      onSubmit(selectedTemplate);
    }
  };

  if (!open) return null;

  return (
    <div className={styles.container}>
      <div
        className={styles.backdrop}
        onClick={() => {
          onClose();
          setSelectedTemplate("");
        }}
      />
      <div className={styles.card}>
        <h2 className={styles.title}>
          {t("components.templateEventSelectorModal.selectFormat")}
        </h2>
        <p className={styles.description}>
          {t("components.templateEventSelectorModal.indentifyFeatures")}
        </p>
        <div className={styles.rowOptions}>{renderOptions()}</div>
        <button
          className={[
            styles.submit,
            selectedTemplate ? "" : styles.disabledSubmit,
          ].join(" ")}
          onClick={onContinue}
        >
          {selectedTemplate
            ? t("components.templateEventSelectorModal.continue")
            : t("components.templateEventSelectorModal.lessSelectOption")}
        </button>
      </div>
    </div>
  );
};

export default TemplateEventSelectorModal;
