import React from "react";
import { Menu, MenuItem } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { MoreVert } from "@material-ui/icons";

import { verifyEmail } from "../../utils/validations";
import DialogForm from "../../components/dialogForm";
import Avatar from "../../assets/images/avatar.jpeg";
import AdminService from "../../services/admin";
import { Store } from "../../hooks/main_store";
import pageStyles from "./styles/teamStyles";
import Model from "../../hooks/Model";
import Layout from "../layout";

const Team = ({ history, location }) => {
  const { t } = useTranslation();
  const styles = pageStyles();
  const event = location?.state?.event || {};
  const { state } = React.useContext(Store);

  const [vc, setVc] = React.useState({
    tabSelected: 0,
    promoters: [],
    logistics: [],
    collaborators: [],
    showPrompt: false,
    email: "",
    anchorMenu: null,
    userSelected: null,
  });
  const changeVc = (data) => setVc((ov) => ({ ...ov, ...data }));

  const returnText = (prop) => {
    const texts = [
      {
        title: t("pages.admin.team.teamAccessLink"),
        createButton: t("pages.admin.team.addPromoter"),
        showPrompt: t("pages.admin.team.promoter"),
        subtext: t("pages.admin.team.rememberAddPromoter"),
        dataList: vc.promoters,
        name: t("pages.admin.team.promotersM"),
        ref: "promoters",
      },
      {
        title: t("pages.admin.team.thisTeamQrAccess"),
        createButton: t("pages.admin.team.addLogistic"),
        showPrompt: t("pages.admin.team.logistic"),
        subtext: t("pages.admin.team.eachUserCanScan"),
        dataList: vc.logistics,
        name: t("pages.admin.team.logisticM"),
        ref: "logistics",
      },
      {
        title: t("pages.admin.team.yourColaboratorsHelp"),
        createButton: t("pages.admin.team.addColaborator"),
        showPrompt: t("pages.admin.team.colaborator"),
        subtext: t("pages.admin.team.whenAddColaborator"),
        dataList: vc.collaborators,
        name: t("pages.admin.team.colaborators"),
        ref: "collaborators",
      },
    ];

    return texts[vc.tabSelected][prop];
  };

  const openMenu = (userSelected, event) => {
    changeVc({
      anchorMenu: event.currentTarget,
      userSelected,
    });
  };

  const closeMenu = () => {
    changeVc({
      anchorMenu: null,
      userSelected: null,
    });
  };

  const sendNewUser = async (email) => {
    try {
      Model.setData("loading", true);
      Model.setData("dialog", { open: false });

      const roleRef = returnText("ref");
      const newUser = await AdminService.addUserMyTeam({
        eventUid: event.uid,
        email: email,
        role: roleRef,
      });

      changeVc({
        [roleRef]: [...vc[roleRef], newUser],
      });
      Model.updateAlerts({
        message: t("pages.admin.team.userAdded"),
        variant: "success",
      });
    } catch (e) {
      Model.updateAlerts({
        message: String(e),
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  const deleteUser = async (uidUser) => {
    try {
      Model.setData("loading", true);
      Model.setData("dialog", { open: false });
      const roleRef = returnText("ref");
      const listUsersAux = vc[roleRef];
      await AdminService.deleteUserMyTeam({
        eventUid: event.uid,
        uidUser,
        role: roleRef,
      });
      listUsersAux.splice(vc.userSelected?.index, 1);
      changeVc({
        [roleRef]: listUsersAux,
      });
      Model.updateAlerts({
        message: t("pages.admin.team.userDeleted"),
        variant: "success",
      });
    } catch (e) {
      Model.updateAlerts({
        message: String(e),
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  const onDeleteUser = () => {
    closeMenu();
    Model.setData("dialog", {
      open: true,
      title: t("pages.admin.team.confirmAction"),
      text: t("pages.admin.team.wantDeleteUser", {
        name: returnText("name"),
        email: vc.userSelected?.email,
      }),
      txtLeft: t("commons.cancel"),
      fnLeft: () => Model.setData("dialog", { open: false }),
      txtRight: t("commons.yesConfirm"),
      fnRight: () => deleteUser(vc.userSelected?.uid),
    });
  };

  const copyLink = () => {
    closeMenu();
    navigator.clipboard.writeText(
      `http${process.env.NODE_ENV === "production" ? "s" : ""}://${
        window?.location?.host
      }/events?event=${event.uid}&ref=${vc.userSelected?.uid}`
    );
    Model.updateAlerts({
      message: t("pages.admin.team.linkCopied"),
      variant: "success",
    });
  };

  const goToDetail = () => {
    closeMenu();
    if (vc.tabSelected === 2) {
      history.push(`/partnerProfile?ref=${vc.userSelected?.uid}`);
    } else {
      history.push("/detailMember", { userSelected: vc.userSelected, event });
    }
  };

  const renderUsers = () => {
    const dataList = returnText("dataList");
    if (dataList.length < 1) {
      return (
        <div className={styles.lblEmpty}>
          {t("pages.admin.team.thereNoUser")}
        </div>
      );
    }
    return dataList.map((item, index) => (
      <div className={styles.itemListUser} key={"item-list-user-" + index}>
        <div
          className={styles.imgUser}
          style={{ backgroundImage: `url(${item?.imageProfile || Avatar})` }}
        ></div>
        <div className={[styles.infoUser, styles.rowInfo].join(" ")}>
          <div className={styles.contTitleUser}>
            <p className={styles.titleUser}>{item?.name}</p>
            <p className={styles.emailUser}>{item?.email}</p>
          </div>
          <button
            onClick={(event) => openMenu({ ...item, index }, event)}
            className={styles.btnMore}
          >
            <MoreVert />
          </button>
        </div>
      </div>
    ));
  };

  const renderTabs = () =>
    [
      t("pages.admin.team.promoters"),
      t("pages.admin.team.logistic"),
      t("pages.admin.team.colaboratorsM"),
    ].map((tab, index) => (
      <button
        className={vc.tabSelected === index ? "" : styles.disabledTab}
        onClick={() => changeVc({ tabSelected: index })}
        key={tab}
      >
        {tab}
      </button>
    ));

  const addNewUser = () => {
    try {
      const dataList = returnText("dataList");
      const emailLower = vc.email.toLowerCase().replace(/ /g, "");
      let existUser = false;

      changeVc({ email: emailLower });

      if (verifyEmail(emailLower)) {
        changeVc({ showPrompt: false });
        dataList.forEach((itemUser) => {
          if (itemUser.email === emailLower) {
            existUser = true;
          }
        });

        if (
          !existUser &&
          (vc.tabSelected === 2 ? emailLower !== state?.userInfo?.email : true)
        ) {
          Model.setData("dialog", {
            open: true,
            title: t("pages.admin.team.confirmAddUser"),
            text: t("pages.admin.team.wantAddUser", {
              name: returnText("name"),
              email: emailLower,
            }),
            txtLeft: t("commons.cancel"),
            fnLeft: () => Model.setData("dialog", { open: false }),
            txtRight: t("commons.yesConfirm"),
            fnRight: () => sendNewUser(emailLower),
          });
        } else {
          throw new Error(t("pages.admin.team.userAlreadyBe"));
        }
      } else {
        throw new Error(t("pages.admin.team.enterValidEmail"));
      }
    } catch (e) {
      Model.updateAlerts({
        message: String(e),
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  const getMyTeam = async () => {
    try {
      Model.setData("loading", !state.teamEventPartner?.[event.uid]);

      if (state.teamEventPartner?.[event.uid]) {
        changeVc(state.teamEventPartner?.[event.uid]);
      }

      const dataTeam = await AdminService.getMyTeam(event.uid);
      const teamEventPartner = {
        logistics: dataTeam?.logistics || [],
        promoters: dataTeam?.promoters || [],
        collaborators: dataTeam?.collaborators || [],
      };
      changeVc(teamEventPartner);
      Model.setData("teamEventPartner", {
        ...state.teamEventPartner,
        [event.uid]: teamEventPartner,
      });
    } catch (e) {
      console.log(t("pages.admin.team.errorGetTeam"), e);
    } finally {
      Model.setData("loading", false);
    }
  };

  React.useEffect(() => {
    if (event) {
      getMyTeam();
    } else {
      history.push("/");
      Model.updateAlerts({
        message: t("pages.admin.team.invalidData"),
        variant: "error",
      });
    }
    return () => {};
    //eslint-disable-next-line
  }, []);

  return (
    <Layout title="EQUIPO">
      <div className={styles.rowTabs}>{renderTabs()}</div>
      <p className={styles.title}>{returnText("title")}</p>
      <button
        className={styles.createButton}
        onClick={() => changeVc({ showPrompt: true, email: "" })}
      >
        {returnText("createButton")}
      </button>
      <div className={styles.gridUsers}>{renderUsers()}</div>
      <Menu
        anchorEl={vc.anchorMenu}
        open={Boolean(vc.anchorMenu)}
        onClose={closeMenu}
        PaperProps={{ style: { width: 200 } }}
      >
        <MenuItem onClick={goToDetail}>
          {t("pages.admin.team.viewDetails")}
        </MenuItem>
        {vc.tabSelected === 0 ? (
          <MenuItem onClick={copyLink}>
            {t("pages.admin.team.copyLink")}
          </MenuItem>
        ) : null}
        <MenuItem onClick={onDeleteUser}>
          {t("pages.admin.team.delete")}
        </MenuItem>
      </Menu>
      <div className={styles.blank} />
      <DialogForm
        show={vc.showPrompt}
        title={`${t("pages.admin.team.add")} ${returnText("showPrompt")}`}
        text={t("pages.admin.team.pleaseEnterCompleteEmail")}
        subtext={returnText("subtext")}
        leftBtText={t("commons.cancel")}
        funcLeft={() => changeVc({ showPrompt: false, email: "" })}
        rightBtText={t("commons.yesConfirm")}
        funcRight={addNewUser}
        inputs={[
          {
            label: t("commons.email"),
            value: "email",
            type: "email",
          },
        ]}
        vc={vc}
        changeVc={changeVc}
      />
    </Layout>
  );
};

export default Team;
