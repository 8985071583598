import { makeStyles } from "@material-ui/styles";
import Commons from "../../../utils/styles";
import { colors } from "../../../styleguide";

const styles = makeStyles({
  container: {
    position: "relative",
    backgroundColor: colors.black,
    width: "100%",
    overflow: "hidden",
    "& canvas": {
      width: "100vw !important",
      height: "75vw !important",
      maxWidth: 1200,
      maxHeight: 900,
      margin: "0 auto",
    },
    "& .border-white canvas": {
      borderBottom: `6px solid ${colors.white}`,
    },
    "& .border-blue canvas": {
      borderBottom: `6px solid ${colors.blue}`,
    },
    "& .react-pdf__Page__annotations.annotationLayer": {
      display: "none",
    },
  },
  cover: {
    position: "absolute",
    width: "100%",
    height: "450vw",
    maxHeight: 5412,
    backgroundColor: colors.white,
    top: 0,
    left: 0,
    opacity: 0,
  },
  showFormButton: {
    ...Commons.defaultButton,
    color: colors.white,
    backgroundColor: colors.themeColor,
    boxShadow: colors.shadow,
    position: "relative",
    padding: 18,
    borderRadius: 12,
    margin: "30px auto",
    width: "calc(90% - 26px)",
    textAlign: "center",
    fontSize: 36,
    fontWeight: 700,
    [Commons.smallQuery]: {
      margin: "12px auto",
      fontSize: 24,
    },
  },
  disableFormButton: {
    backgroundColor: colors.gray50,
  },
  title: {
    color: colors.white,
    fontWeight: "700",
    fontSize: 36,
    margin: "36px auto 24px auto",
    textAlign: "center",
    backgroundColor: colors.black,
    width: "fit-content",
    display: "block",
    padding: "12px 30px",
    borderRadius: 60,
    border: `6px solid ${colors.themeColor}`,
    boxShadow: colors.shadow,
    [Commons.smallQuery]: {
      maxWidth: "calc(90% - 48px)",
      fontSize: 24,
      padding: "6px 18px",
    },
  },
  description: {
    color: colors.mainxText,
    fontSize: 24,
    margin: "0 auto 60px auto",
    textAlign: "center",
    maxWidth: 900,
    width: "90%",
    [Commons.smallQuery]: {
      fontSize: 18,
    },
  },
  row: {
    ...Commons.flexRow,
    justifyContent: "center",
    alignItems: "flex-start",
    gap: 30,
    paddingTop: 48,
    [Commons.smallQuery]: {
      alignItems: "center",
      flexDirection: "column",
      gap: 60,
    },
  },
  cardPrice: {
    borderRadius: 24,
    boxShadow: colors.shadow,
    background: `linear-gradient(${colors.themeColor}25, ${colors.themeColor}75)`,
    width: "calc(40% - 60px)",
    maxWidth: 600,
    margin: "0 0 30px 0",
    padding: 30,
    minHeight: 240,
    [Commons.smallQuery]: {
      padding: 18,
      width: "calc(100% - 60px)",
    },
  },
  imageRole: {
    width: 180,
    height: 180,
    border: `6px solid ${colors.white}`,
    borderRadius: "50%",
    backgroundColor: colors.themeColor,
    margin: "-90px auto 30px auto",
    display: "block",
    boxShadow: colors.shadow,
    objectFit: "contain",
  },
  titleCardPrice: {
    fontWeight: "700",
    fontSize: 30,
    color: colors.black,
    textAlign: "center",
    textTransform: "uppercase",
  },
  textCardPrice: {
    textAlign: "center",
    fontSize: 18,
    color: colors.black,
    margin: "18px auto",
    minHeight: 90,
    display: "block",
    [Commons.smallQuery]: {
      minHeight: "unset",
    },
  },
  amountCardPrice: {
    fontWeight: "800",
    fontSize: 42,
    color: colors.black,
    textAlign: "center",
    marginBottom: 30,
    [Commons.smallQuery]: {
      fontSize: 42,
    },
  },
  contBenefits: {
    minHeight: 186,
    [Commons.smallQuery]: {
      minHeight: "unset",
    },
  },
  ButtonCardPrice: {
    backgroundColor: colors.white,
    borderRadius: 12,
    textAlign: "center",
    padding: "18px 0",
    width: "80%",
    margin: "30px auto",
    fontSize: 24,
    color: colors.themeColor,
    cursor: "pointer",
    fontWeight: "600",
    border: `3px solid ${colors.themeColor}`,
    transition: "all 0.3s",
    "&:hover": {
      backgroundColor: colors.themeColor,
      color: colors.white,
    },
    [Commons.smallQuery]: {
      padding: "18px 0",
      fontSize: 24,
    },
  },
  rowFunction: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: 12,
    [Commons.smallQuery]: {
      padding: 12,
    },
  },
  iconFunction: {
    color: colors.themeColor,
  },
  textFunction: {
    color: colors.black,
    margin: 0,
    marginLeft: 12,
    flex: 1,
    fontSize: 18,
    fontWeight: "600",
  },
  textNote: {
    marginTop: 18,
    marginBottom: 30,
    color: colors.black,
  },
  viewMoreFeat: {
    textAlign: "center",
    fontWeight: "600",
    fontSize: 18,
    margin: "18px auto 0 auto",
    borderRadius: 30,
    padding: "6px 18px",
    width: "fit-content",
    cursor: "pointer",
    transition: "all 0.3s",
    backgroundColor: colors.themeColor,
    color: colors.white,
    "&:hover": {
      backgroundColor: colors.white,
      color: colors.themeColor,
    },
  },
});

export default styles;
