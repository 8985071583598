import React from "react";
import ReactDOM from "react-dom";
import moment from "moment";

import { returnSessionStorage as SessionStorage } from "./utils/localStorage";
import * as serviceWorker from "./serviceWorker";
import i18next from "./languages/i18n";
import "./languages/i18n";
import App from "./App";
import "./index.css";

const codeVerify = moment().format("x").slice(0, 5);
const isTest = window?.location?.host?.includes?.("test.");

const initialFunction = () => {
  const isAllowed = SessionStorage(false).getItem("allow_test_user");

  if (isTest && isAllowed !== "allowed") {
    const code = prompt(i18next.t("system.beforeTest"), "...");

    if (code === codeVerify) {
      SessionStorage(false).setItem("allow_test_user", "allowed");
      ReactDOM.render(<App />, document.getElementById("root"));
    } else if (code != null) {
      alert(i18next.t("system.errorTestCode"));
      initialFunction();
    }
  } else {
    ReactDOM.render(<App />, document.getElementById("root"));
  }
};

initialFunction();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
