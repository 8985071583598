import React from "react";
import { useTranslation } from "react-i18next";
import { Paper } from "@material-ui/core";

import FraventsLogo from "../../assets/images/Iso-blanco.svg";
import pageStyles from "./styles/videoTutorialStyles";
import Model from "../../hooks/Model";
import Layout from "../layout";

const ContactPublish = () => {
  const { t } = useTranslation();
  const styles = pageStyles();
  const videoRef = React.useRef(null);

  const moveVideo = (seconds) => {
    if (videoRef.current) {
      videoRef.current.currentTime = seconds;
      videoRef.current.play();
    }
  };

  const renderSectionsVideo = () => {
    const sections = [
      { label: t("pages.admin.videoTutorial.introduction"), seconds: 0 },
      { label: t("pages.admin.videoTutorial.enter"), seconds: 30 },
      { label: t("pages.admin.videoTutorial.dashboard"), seconds: 162 },
      { label: t("pages.admin.videoTutorial.createEvent"), seconds: 184 },
      { label: t("pages.admin.videoTutorial.manageTeam"), seconds: 273 },
      { label: t("pages.admin.videoTutorial.viewAnalytics"), seconds: 364.5 },
      { label: t("pages.admin.videoTutorial.validateTickets"), seconds: 441 },
      { label: t("pages.admin.videoTutorial.final"), seconds: 717 },
    ];
    return sections.map((section, index) => (
      <div
        className={styles.optionChapter}
        key={index + "-item-section"}
        onClick={() => moveVideo(section.seconds)}
      >
        <span>{section.label}</span>
        {index > 0 && index % 4 === 0 ? <br /> : null}
      </div>
    ));
  };

  React.useEffect(() => {
    Model.setData("loading", false);
    //eslint-disable-next-line
  }, []);

  return (
    <Layout>
      <p className={styles.title}>{t("pages.admin.videoTutorial.welcome")}</p>

      <div className={styles.contLogo}>
        <img alt="Fravents" src={FraventsLogo} className={styles.logo} />
      </div>

      <p className={styles.description}>
        {t("pages.admin.videoTutorial.hiHereIsVideo")}{" "}
        <a href="mailto:help@fravents.com">help@fravents.com</a>{" "}
        {t("pages.admin.videoTutorial.withBriefMessage")}
        <br />
        <br /> {t("pages.admin.videoTutorial.bestRegards")}
      </p>

      <p className={styles.title}>
        {t("pages.admin.videoTutorial.videoTutorial")}
      </p>
      <p className={styles.descriptionLabel}>
        {t("pages.admin.videoTutorial.youCanSkip")} <br />
        <br />
      </p>

      <Paper elevation={3} className={styles.cardVideo}>
        <div className={styles.chapters}>{renderSectionsVideo()}</div>
        <video controls ref={videoRef}>
          <source
            src={require("../../assets/videos/fravents_tutorial.mp4")}
            type="video/mp4"
          ></source>
        </video>
      </Paper>

      <div className={styles.blank} />
    </Layout>
  );
};

export default ContactPublish;
