/* eslint-disable */
import React from "react";
import { PlayCircleFilled } from "@material-ui/icons";
import pageStyles from "./styles";

const VideoBanner = ({
  title,
  description,
  video,
  image,
  onComplete,
  seconds2complete,
}) => {
  const styles = pageStyles();
  const videoRef = React.useRef(null);
  const [played, setPlayed] = React.useState(false);
  const [callback, setCallback] = React.useState(null);
  const [secondsPlayed, setSecondsPlayed] = React.useState(0);

  const openFullscreen = () => {
    if (videoRef.current?.requestFullscreen) {
      videoRef.current?.requestFullscreen();
    } else if (videoRef.current?.webkitRequestFullscreen) {
      videoRef.current?.webkitRequestFullscreen();
    } else if (videoRef.current?.msRequestFullscreen) {
      videoRef.current?.msRequestFullscreen();
    }
  };

  const closeFullscreen = () => {
    if (document?.exitFullscreen) {
      document.exitFullscreen();
    } else if (document?.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document?.msExitFullscreen) {
      document.msExitFullscreen();
    }
  };

  const fullscreen = () => {
    if (document.fullscreenElement === null) {
      openFullscreen();
    } else {
      closeFullscreen();
    }
  };

  const onPlayVideo = (avoidType) => {
    if (videoRef.current) {
      if (played) {
        if (avoidType === "pause") {
          videoRef.current.pause();
        }

        fullscreen();

        if (onComplete) {
          clearInterval(callback);
        }

        if (seconds2complete && secondsPlayed >= seconds2complete) {
          if (onComplete) {
            onComplete(secondsPlayed);
          }
        }
      } else {
        videoRef.current.play();
        fullscreen();

        if (onComplete) {
          setCallback(
            setInterval(() => {
              setSecondsPlayed((ov) => ov + 1);
            }, 1000)
          );
        }
      }
      setPlayed((ov) => !ov);
    }
  };

  const sendEnd = () => {
    clearInterval(callback);
    fullscreen();
    setPlayed(false);

    if (onComplete) {
      onComplete(secondsPlayed);
    }
  };

  React.useEffect(() => {
    return () => clearInterval(callback);
  }, [callback]);

  return (
    <div className={styles.contentBanner}>
      <img src={image} alt="FAQs Banner" className={styles.imageBanner} />
      <div className={styles.filterBg}></div>
      <div className={styles.infoBanner}>
        <p className={styles.titleBanner}>{title}</p>
        <p className={styles.descriptionBanner}>{description}</p>
      </div>
      <div className={styles.videoBannerContainer}>
        <video
          ref={videoRef}
          className={styles.videoBanner}
          onEnded={sendEnd}
          onPause={() => onPlayVideo("pause")}
        >
          <source src={video} type="video/mp4"></source>
        </video>
        <div className={styles.toggleVideo} onClick={onPlayVideo}>
          {played ? null : <PlayCircleFilled />}
        </div>
      </div>
      <div className={styles.shadowBanner} />
    </div>
  );
};

export default VideoBanner;
