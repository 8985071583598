import React from "react";
import {
  People,
  CropFree,
  ConfirmationNumber,
  Edit,
  Delete,
} from "@material-ui/icons";
import { useTranslation } from "react-i18next";

import {
  TEAM,
  SALES,
  VALIDATE_TICKET,
  EVENT_FORM,
  ADMIN,
} from "../../utils/routes";
import Avatar from "../../assets/images/avatar.jpeg";
import AdminService from "../../services/admin";
import { Store } from "../../hooks/main_store";
import Model from "../../hooks/Model";
import pageStyles from "./styles";

const Features = ({ history, location }) => {
  const { t } = useTranslation();
  const styles = pageStyles();
  const { state } = React.useContext(Store);
  const [showDelete, setShowDelete] = React.useState(false);
  const [hideImage, setHideImage] = React.useState(false);
  const event = location?.state?.event || state?.eventSelected;

  const tabs = [
    {
      label: t("components.navigationEvent.team"),
      icon: <People />,
      route: TEAM,
    },
    {
      label: t("components.navigationEvent.sells"),
      icon: <ConfirmationNumber />,
      route: SALES,
    },
    {
      label: t("components.navigationEvent.validate"),
      icon: <CropFree />,
      route: VALIDATE_TICKET,
    },
    {
      label: t("components.navigationEvent.edit"),
      icon: <Edit />,
      route: EVENT_FORM, //
    },
  ].filter((tab) =>
    event?.isCancelled
      ? tab.label !== t("components.navigationEvent.edit")
      : true
  );

  const deteleEvent = async () => {
    try {
      Model.setData("loading", true);

      await AdminService.deteleEvent({ eventUid: event.uid });

      history.push(ADMIN);
      Model.updateAlerts({
        message: t("components.navigationEvent.eventSuccessDelete"),
        variant: "success",
      });
    } catch (e) {
      Model.updateAlerts({
        message: t("components.navigationEvent.errorDeleteEvent") + String(e),
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  const go2page = (route) => {
    if (route === "delete") {
      if (showDelete) {
        Model.setData("dialog", {
          open: true,
          title: t("components.navigationEvent.deleteEvent"),
          text: t("components.navigationEvent.doYouAgreeDeleteEvent", {
            event: event.name,
          }),
          txtLeft: t("commons.no"),
          fnLeft: () => Model.setData("dialog", { open: false }),
          txtRight: t("commons.yes"),
          fnRight: () => {
            Model.setData("dialog", { open: false });
            deteleEvent();
          },
        });
      } else {
        Model.setData("dialog", {
          open: true,
          title: t("components.navigationEvent.deleteEvent"),
          text: t("components.navigationEvent.youCanNotDeleteEvent"),
          txtRight: t("commons.ok"),
          fnRight: () => {
            Model.setData("dialog", { open: false });
          },
        });
      }
    } else {
      Model.setData("eventSelected", event);
      if (route === EVENT_FORM) {
        history.push(EVENT_FORM, { draftEvent: event });
      } else {
        history.push(route, { event: event });
      }
    }
  };

  const verifyAvailableDelete = async () => {
    try {
      setShowDelete(false);
      const dataSales = await AdminService.getMySales(event.uid);

      if ((dataSales?.allTickets || []).length > 0) {
        setShowDelete(false);
      } else {
        setShowDelete(true);
      }
    } catch (e) {
      setShowDelete(false);
    }
  };

  const returnTabs = () => {
    return [
      ...tabs,
      {
        label: t("components.navigationEvent.delete"),
        icon: <Delete />,
        route: "delete",
        disabled: !showDelete,
      },
    ];
  };

  React.useEffect(() => {
    let routeExists = false;

    tabs.forEach((tab) => {
      if (tab.route === location?.pathname) {
        routeExists = true;
      }
    });

    if (
      event?.uid &&
      routeExists &&
      (event?.partner === state.uid || event?.editor === state.uid)
    ) {
      Model.setData("showNavigationEvent", true);
      verifyAvailableDelete();
    } else {
      Model.setData("showNavigationEvent", false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event]);

  return state.showNavigationEvent ? (
    <div className={styles.navigationEventContainer}>
      <div className={styles.blackHeader} />
      <div className={styles.containerTabs}>
        <div className={styles.rowTabs}>
          <img
            alt="Event"
            src={event?.imageBanner || Avatar}
            className={[
              styles.imageEvent,
              hideImage ? "hideImageEvent" : "",
            ].join(" ")}
            onClick={() => setHideImage(true)}
          />
          {returnTabs().map((option) => (
            <div
              className={[
                styles.option,
                option.route === location?.pathname
                  ? styles.selectedOption
                  : "",
                option.disabled ? styles.disabledOption : "",
              ].join(" ")}
              key={option.label}
              onClick={() => go2page(option.route)}
            >
              <div className={styles.imageContainer}>{option.icon}</div>
              {<h3 className={styles.title}>{option.label}</h3>}
            </div>
          ))}
        </div>
      </div>
    </div>
  ) : null;
};

export default Features;
