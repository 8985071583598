import React from "react";
import { useTranslation } from "react-i18next";
import { Close } from "@material-ui/icons";

import { commonPlatformTax, commonMpTax } from "../../utils/constants";
import { currencyFormat } from "../../utils/validations";
import pageStyles from "./styles/selectQuantityStyles";
import Model from "../../hooks/Model";

const SelectQuantity = ({
  onClose = () => {},
  onDone = () => {},
  onSave = () => {},
  data = {},
  show = false,
}) => {
  const { t, i18n } = useTranslation();
  const styles = pageStyles();
  const [vc, setVc] = React.useState({
    types: data?.prices || [],
    quantity: "1",
    showError: "",
    typeSlctd: null,
  });
  const changeVc = (data) => setVc((ov) => ({ ...ov, ...data }));

  const renderPrice = (price) => {
    if (price <= 0) {
      return t("commons.free");
    } else if (price <= 999) {
      return `$ ${price}`;
    } else if (Math.floor(price / 1000) <= 999) {
      return `$ ${Math.floor(price / 1000)}k`;
    } else {
      return `$ ${Math.floor(price / 1000000)}M`;
    }
  };

  const isCurrentPhase = (index) => {
    let currentPhase = -1;

    if (vc.types.length < 2) {
      return true;
    }

    vc.types.forEach((price, indexPrice) => {
      if (price?.stock > 0) {
        if (currentPhase === -1) {
          currentPhase = indexPrice;
        }
      }
    });

    return index === currentPhase;
  };

  const renderTypes = () =>
    vc.types?.map((type, index) => {
      const availablePhase = data?.saleWithPhases
        ? isCurrentPhase(index)
        : true;
      const disabled = !type.stock || type.stock < 1 || !availablePhase;

      const returnSublabel = () => {
        if (type.stock < 1) {
          return (
            <span className={styles.fewProducts}>
              {t("pages.shop.selectQuantity.soldOut")}
            </span>
          );
        }
        if (data?.saleWithPhases && !availablePhase) {
          return (
            <span className={styles.fewProducts}>
              {t("pages.shop.selectQuantity.soon")}
            </span>
          );
        }
        if (type.stock < 11 && type.stock > 0) {
          return (
            <span
              className={[
                styles.fewProducts,
                vc.typeSlctd === index ? styles.typeSlctd : "",
              ].join(" ")}
            >
              {type.stock} {t("pages.shop.selectQuantity.available")}
              {i18n.language === "en" ? "" : "s"}
            </span>
          );
        }
      };

      return (
        <button
          className={[
            styles.btnType,
            vc.typeSlctd === index ? styles.typeSlctd : "",
            disabled ? styles.disabled : "",
            type?.hide ? styles.noDisplay : "",
          ].join(" ")}
          onClick={() => {
            changeVc({ showError: "" });
            if (!disabled) {
              changeVc({ typeSlctd: index, quantity: "1" });
            }
          }}
          key={"item-type-" + index}
          disabled={disabled}
        >
          <span>{type.label}</span>
          <span>{renderPrice(type.price)}</span>
          {returnSublabel()}
        </button>
      );
    });

  const changeQuantity = (event) => {
    if (vc.typeSlctd === null) {
      return Model.updateAlerts({
        message: t("pages.shop.selectQuantity.firstSelect"),
        variant: "error",
      });
    }
    let newValue = parseInt(vc.quantity);
    changeVc({ showError: "" });
    if (!event || (event?.target && !event?.target?.value)) {
      changeVc({ quantity: "" });
    } else {
      if (typeof event === "number") {
        newValue = newValue + event;
      } else if (event?.target?.value) {
        newValue = parseInt(event.target.value.replace(/[^0-9]/g, ""));
      }
      if (newValue <= 0) {
        newValue = 1;
      } else if (newValue > vc.types[vc.typeSlctd].stock) {
        newValue =
          vc.types[vc.typeSlctd].stock > 10 ? 10 : vc.types[vc.typeSlctd].stock;
        changeVc({
          showError: t("pages.shop.selectQuantity.noCanPassQuantity"),
        });
      } else if (newValue > 10) {
        newValue = 10;
        changeVc({
          showError: t("pages.shop.selectQuantity.quantityMax"),
        });
      }
      changeVc({ quantity: String(newValue) });
    }
  };

  const renderTotal = () => {
    const quantity = parseInt(vc.quantity) || 0;
    const price = vc.types?.[vc.typeSlctd]?.price || 0;
    return currencyFormat(
      quantity * price + (data?.clientPayTax ? returnTax() : 0)
    );
  };

  const returnTax = () => {
    const quantity = parseInt(vc.quantity) || 0;
    const price = vc.types?.[vc.typeSlctd]?.price || 0;
    const platformTax = quantity * price * commonPlatformTax;
    const providerTax = quantity * price * commonMpTax[0] + commonMpTax[1];

    if (!data?.clientPayTax || quantity < 1 || vc.typeSlctd === null) {
      return 0;
    }

    return platformTax + providerTax;
  };

  React.useEffect(() => {
    if (!show) {
      changeVc({
        quantity: "1",
        showError: "",
        typeSlctd: null,
      });
    }
    //eslint-disable-next-line
  }, [show]);

  React.useEffect(() => {
    changeVc({
      types: data?.prices || [],
    });
    //eslint-disable-next-line
  }, [data]);

  return show ? (
    <div className={styles.container}>
      <button className={styles.closeBtn} onClick={onClose}>
        <Close />
      </button>
      <div className={styles.contForm}>
        <p className={styles.label}>
          {t("pages.shop.selectQuantity.selectTypeQuantity")}
        </p>
        <div className={[styles.rowTypes, styles.centerTypes].join(" ")}>
          {renderTypes()}
        </div>
        <p className={styles.sublabelPrices}>
          {data?.clientPayTax
            ? `${t("pages.shop.selectQuantity.plusTax")} ${currencyFormat(
                returnTax()
              )}`
            : t("pages.shop.selectQuantity.alreadyIncludedTax")}
        </p>
        <p className={styles.label}>
          {t("pages.shop.selectQuantity.quantity")}
        </p>
        <div className={styles.rowQuantity}>
          <button
            className={styles.changeQuantityBtn}
            onClick={() => changeQuantity(-1)}
          >
            -
          </button>
          <input
            className={styles.inputQuantity}
            value={vc.quantity}
            onChange={changeQuantity}
          />
          <button
            className={styles.changeQuantityBtn}
            onClick={() => changeQuantity(1)}
          >
            +
          </button>
        </div>
        <p className={styles.labelError}>{vc.showError}</p>
        <p className={styles.label}>
          {t("pages.shop.selectQuantity.totalPrice")}
        </p>
        <p className={styles.price}>{renderTotal()} COP</p>
        <div className={styles.row}>
          <button
            className={styles.saveCartBtn}
            onClick={() => onSave(vc.typeSlctd, vc.quantity)}
          >
            {t("pages.shop.selectQuantity.addCart")}
          </button>
          <button
            className={styles.payBtn}
            onClick={() => onDone(vc.typeSlctd, vc.quantity)}
          >
            {t("pages.shop.selectQuantity.continuePay")}
          </button>
        </div>
      </div>
    </div>
  ) : null;
};

export default SelectQuantity;
