import React from "react";
import { Carousel } from "react-responsive-carousel";
import { useTranslation } from "react-i18next";
import { PlayArrow } from "@material-ui/icons";
import { Link } from "react-router-dom";

import * as AvatarImages from "../../assets/images/tutorial";
import { VIDEO_TUTORIAL } from "../../utils/routes";
import { features } from "../../utils/constants";
import { Store } from "../../hooks/main_store";
import * as Routes from "../../utils/routes";
import Model from "../../hooks/Model";
import pageStyles from "./styles";

const Features = ({ showFeatures = false }) => {
  const { t } = useTranslation();
  const styles = pageStyles();
  const { state } = React.useContext(Store);
  const roles = [
    {
      label: t("components.features.participants"),
      text: t("components.features.participantsDescription"),
      button: t("components.features.viewEvents"),
      image: AvatarImages.party,
      url: Routes.ALL_EVENTS,
    },
    {
      label: t("components.features.reseller"),
      text: t("components.features.resellerDescription"),
      button: t("components.features.goSell"),
      image: AvatarImages.idea,
      url: Routes.MY_TRANSACTIONS,
    },
    {
      label: t("components.features.promoter"),
      text: t("components.features.promoterDescription"),
      button: t("components.features.promoteNow"),
      image: AvatarImages.secret,
      url: `${Routes.PROFILE}?tab=1`,
    },
    {
      label: t("components.features.eventOrganizer"),
      text: t("components.features.eventOrganizerDescription"),
      button: t("components.features.moreInfo"),
      image: AvatarImages.coding,
      url: Routes.CONTACT_PUBLISH,
    },
  ];

  const goTo = (route, isClick) => {
    if (
      !state?.uid &&
      (route === Routes.MY_TRANSACTIONS || route.indexOf(Routes.PROFILE) > -1)
    ) {
      if (isClick) {
        Model.setData("openAuth", true);
      }
      return Routes.HOME;
    } else {
      return route;
    }
  };

  if (!showFeatures) {
    return (
      <div className={styles.rowRoles}>
        {roles.map((role) => (
          <div className={styles.containerRole} key={role.label}>
            <img
              alt="organizer"
              src={role.image}
              className={styles.imageRole}
            />
            <div className={styles.infoRole}>
              <h4 className={styles.labelRole}>{role.label}</h4>
              <p className={styles.textRole}>{role.text}</p>
              <Link
                className={styles.buttonRole}
                to={goTo(role.url)}
                onClick={() => goTo(role.url, true)}
              >
                {role.button}
              </Link>
            </div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <>
      <div className={styles.container}>
        {features.map((feature) => (
          <div className={styles.feature} key={feature.title}>
            <div className={styles.imageContainer}>{feature.image}</div>
            <div className={styles.infoContainer}>
              <h3 className={styles.title}>{feature.title}</h3>
              <p className={styles.description}>{feature.description}</p>
            </div>
          </div>
        ))}
        <div className={styles.feature}>
          <Link className={styles.moreButton} to={VIDEO_TUTORIAL}>
            <PlayArrow />
            &nbsp; {t("components.features.viewVideoTutorial")}
          </Link>
        </div>
      </div>
      <div className={[styles.container, styles.mobile].join(" ")}>
        <Carousel
          autoPlay
          infiniteLoop
          showStatus={false}
          showThumbs={false}
          className={styles.contCarousel}
        >
          {features
            .map((feature, index) =>
              index % 2 === 0 ? (
                <div key={feature.title} className={styles.rowTwoFeatures}>
                  <div className={styles.feature}>
                    <div className={styles.imageContainer}>{feature.image}</div>
                    <div className={styles.infoContainer}>
                      <h3 className={styles.title}>{feature.title}</h3>
                      <p className={styles.description}>
                        {feature.description}
                      </p>
                    </div>
                  </div>
                  {features[index + 1] ? (
                    <div className={styles.feature}>
                      <div className={styles.imageContainer}>
                        {features[index + 1].image}
                      </div>
                      <div className={styles.infoContainer}>
                        <h3 className={styles.title}>
                          {features[index + 1].title}
                        </h3>
                        <p className={styles.description}>
                          {features[index + 1].description}
                        </p>
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : null
            )
            .filter((value) => !!value)}
          <div className={styles.feature}>
            <Link className={styles.moreButton} to={VIDEO_TUTORIAL}>
              <PlayArrow />
              &nbsp; {t("components.features.viewVideoTutorial")}
            </Link>
          </div>
        </Carousel>
      </div>
    </>
  );
};

export default Features;
