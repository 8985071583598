import { makeStyles } from "@material-ui/styles";
import Commons from "../../utils/styles";
import { colors } from "../../styleguide";

const styles = makeStyles({
  modalQr: {
    ...Commons.flexCenter,
    flexDirection: "column",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.9)",
    zIndex: 2,
  },
  closeButton: {
    ...Commons.defaultButton,
    position: "absolute",
    zIndex: 1,
    top: 12,
    right: 12,
    padding: 12,
    "& svg": {
      color: colors.white,
      fontSize: 36,
    },
  },
  textQr: {
    color: colors.white,
    textAlign: "center",
    width: "80%",
    fontSize: 17,
    maxWidth: 900,
  },
  contQr: {
    maxWidth: 300,
    maxHeight: 300,
    border: "3px solid white",
    width: "80vw",
    height: "80vw",
  },
  viewFinder: {
    width: "90vw",
    maxWidth: 600,
  },
  modalAnswer: {
    ...Commons.flexCenter,
    flexDirection: "column",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 2,
    opacity: 1,
    animation: "$fadeIn 1s ease-in-out",
    backgroundColor: colors.primary,
    "& svg": {
      color: colors.white,
      fontSize: 180,
    },
  },
  titleModalAnswer: {
    textAlign: "center",
    color: colors.white,
    fontSize: 24,
    fontWeight: "bold",
    margin: "12px 0 18px 0",
  },
  descriptionModalAnswer: {
    textAlign: "center",
    color: colors.black,
    fontSize: 18,
    margin: 0,
    backgroundColor: colors.white,
    borderRadius: 12,
    width: "calc(90% - 48px)",
    maxWidth: 420,
    padding: 24,
    whiteSpace: "pre-wrap",
  },
  buttonModalAnswer: {
    ...Commons.defaultButton,
    backgroundColor: colors.white,
    color: colors.black,
    padding: "6px 18px",
    borderRadius: 6,
    marginTop: 18,
    fontSize: 18,
    textAlign: "center",
  },
  modalRed: {
    animation: "$fadeIn 1s ease-in-out",
    backgroundColor: colors.red,
  },
  "@keyframes fadeIn": {
    "0%": {
      opacity: 0,
    },
    "50%": {
      opacity: 1,
    },
  },
});

export default styles;
