import { makeStyles } from "@material-ui/styles";
import Commons from "../../../utils/styles";
import { colors } from "../../../styleguide";

const styles = makeStyles({
  banners: {
    minWidth: 900,
    [Commons.smallQuery]: {
      minWidth: "unset",
    },
  },
  tableContainer: {
    minWidth: 600,
    width: "100%",
    [Commons.smallQuery]: {
      minWidth: "unset",
    },
  },
  rowHeadTable: {
    "& th": {
      borderBottom: `1px solid ${colors.themeColor}`,
      textAlign: "left",
      color: colors.black,
      fontWeight: "700",
      padding: "12px 0",
      fontSize: 18,
    },
  },
  rowBodyTable: {
    "& td": {
      borderBottom: `1px solid ${colors.grayBlack}`,
    },
  },
  itemListEvent: {
    ...Commons.flexRow,
    width: "100%",
    margin: "12px 0px",
  },
  imgLEvent: {
    ...Commons.coverBackground,
    minWidth: 72,
    width: 72,
    height: 72,
    borderRadius: 12,
    backgroundColor: colors.black,
    boxShadow: colors.shadow,
    objectFit: "cover",
  },
  infoLEvent: {
    marginLeft: 18,
    width: "calc(100% - 90px)",
  },
  rowInfo: {
    ...Commons.flexRow,
  },
  contTitleEvent: {
    flex: 1,
    marginRight: 12,
    maxWidth: "calc(100% - 30px)",
  },
  titleEvent: {
    fontWeight: "600",
    fontSize: 18,
    margin: 0,
    maxHeight: 24,
    overflow: "hidden",
  },
  dateEvent: {
    fontWeight: "300",
    fontSize: 15,
    margin: 0,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginBottom: 12,
  },
  btTicket: {
    ...Commons.flexColumn,
    boxShadow: "0px 3px 6px #00000029",
    backgroundColor: colors.white,
    borderRadius: 12,
    padding: "6px 12px",
    margin: "0 6px",
    cursor: "pointer",
    justifyContent: "center",
    "& svg": {
      fontSize: 24,
      color: colors.themeColor,
    },
    "& span": {
      fontSize: 12,
    },
    "&:hover": {
      color: colors.white,
      backgroundColor: colors.themeColor,
      "& svg": {
        color: colors.white,
      },
    },
    [Commons.smallQuery]: {
      display: "none",
      "& span": {
        display: "none",
      },
    },
  },
  btTicketMobile: {
    ...Commons.flexRow,
    boxShadow: "0px 3px 6px #00000029",
    backgroundColor: colors.white,
    height: 30,
    borderRadius: 12,
    padding: "0 12px",
    margin: "0 6px",
    cursor: "pointer",
    justifyContent: "center",
    display: "none",
    "& svg": {
      fontSize: 24,
      color: colors.themeColor,
    },
    [Commons.smallQuery]: {
      display: "flex",
    },
  },
  sublblEvent: {
    fontWeight: "300",
    fontSize: 12,
    margin: 0,
    flex: 1,
  },
  blank: {
    height: 60,
  },
  lblEmpty: {
    textAlign: "center",
    color: colors.black,
    fontSize: 18,
    margin: "30px auto",
  },
  linkInLbl: {
    color: colors.themeColor,
    fontWeight: "600",
    textDecoration: "underline",
    cursor: "pointer",
  },
  rowTabs: {
    ...Commons.flexRow,
    backgroundColor: colors.themeColor,
    marginBottom: 30,
    "& button": {
      ...Commons.defaultButton,
      color: colors.white,
      textAlign: "center",
      padding: "18px 0px",
      flex: 1,
      fontWeight: "600",
      fontSize: 16,
    },
  },
  containerPagination: {
    ...Commons.flexRow,
    padding: "18px 0",
    justifyContent: "flex-end",
  },
});

export default styles;
