import { makeStyles } from "@material-ui/styles";
import { colors } from "../../../styleguide";

const styles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    position: "relative",
    background: "white",
    height: "100vh",
    width: "100vw",
  },
  img: {
    width: 180,
  },
  text: {
    textAlign: "center",
    fontSize: 24,
    maxWidth: 390,
    margin: 30,
  },
  button: {
    backgroundColor: colors.themeColor,
    padding: "12px 24px",
    borderRadius: 30,
    color: colors.white,
  },
});

export default styles;
