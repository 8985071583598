import { makeStyles } from "@material-ui/core/styles";
import { colors } from "../../styleguide";
import Commons from "../../utils/styles";

const useStyles = makeStyles({
  navigationEventContainer: {
    width: "100%",
    marginBottom: -90,
    position: "relative",
    zIndex: 1,
  },
  blackHeader: {
    backgroundColor: colors.black,
    height: 89,
    width: "100%",
  },
  containerTabs: {
    width: "100%",
    backgroundColor: colors.themeColor,
    boxShadow: colors.shadow,
  },
  rowTabs: {
    ...Commons.flexRow,
    width: "100%",
    maxWidth: 780,
    margin: "0 auto",
    position: "relative",
  },
  imageEvent: {
    width: 90,
    height: 90,
    borderRadius: "50%",
    border: `3px solid ${colors.themeColor}`,
    objectFit: "cover",
    position: "relative",
    top: 36,
    marginTop: -36,
    backgroundColor: colors.white,
    transition: "all 0.3s",
    [Commons.smallQuery]: {
      width: 54,
      height: 54,
      top: 66,
      marginTop: 0,
      position: "absolute",
      borderWidth: 2,
      left: 6,
      "&.hideImageEvent": {
        left: -120,
      },
    },
  },
  option: {
    ...Commons.flexRow,
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    cursor: "pointer",
    borderBottom: "3px solid transparent",
    height: 60,
    [Commons.smallQuery]: {
      flexDirection: "column",
    },
  },
  selectedOption: {
    borderColor: colors.white,
  },
  disabledOption: {
    opacity: 0.5,
    cursor: "not-allowed",
  },
  imageContainer: {
    color: colors.white,
  },
  title: {
    color: colors.white,
    textAlign: "center",
    fontSize: 18,
    fontWeight: "bold",
    margin: 0,
    marginLeft: 12,
    marginTop: -6,
    [Commons.smallQuery]: {
      marginLeft: 0,
      fontSize: 14,
    },
  },
});

export default useStyles;
