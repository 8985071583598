import { makeStyles } from "@material-ui/styles";
import Commons from "../../utils/styles";

const styles = makeStyles({
  rowBanners: {
    ...Commons.flexRow,
    gap: 18,
    marginBottom: 12,
  },
  textImageBanner: {
    maxWidth: "50%",
    flex: 1,
    [Commons.smallQuery]: {
      flex: "unset",
      maxWidth: "100%",
    },
  },
  hideMobile: {
    [Commons.smallQuery]: {
      display: "none !important",
    },
  },
  hideDesktop: {
    display: "none !important",
    [Commons.smallQuery]: {
      display: "flex !important",
    },
  },
});

export default styles;
