import React from "react";
import useStyles from "./styles/inputStyles";
import PropTypes from "prop-types";

const Input = (props) => {
  const styles = useStyles();
  return (
    <div
      className={[
        styles.container,
        props.fullWidth ? styles.fullWidth : "",
        props.customStyles,
        styles[props.variant],
      ].join(" ")}
    >
      <input
        className={[
          styles.input,
          props.withoutBorder ? styles.noBorder : "",
        ].join(" ")}
        placeholder={props.placeholder}
        onChange={props.onChange}
        onClick={props.onClick}
        value={props.value}
        type={props.type}
        accept={props.accept}
        disabled={props.disabled}
      />
      <p className={styles.errorMsg}>{props.errorMsg}</p>
    </div>
  );
};

Input.defaultProps = {
  withoutBorder: false,
  onChange: () => {},
  onClick: () => {},
  variant: "normal",
  errorMsg: "Error",
  fullWidth: false,
  customStyles: "",
  placeholder: "",
  value: "",
  type: "text",
};

Input.propTypes = {
  variant: PropTypes.oneOf(["normal", "error", "success"]),
  customStyles: PropTypes.string,
  placeholder: PropTypes.string,
  withoutBorder: PropTypes.bool,
  errorMsg: PropTypes.string,
  fullWidth: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  value: PropTypes.string,
  type: PropTypes.oneOf(["text", "password", "number", "file"]),
};

export default Input;
