import React from "react";
import { useTranslation } from "react-i18next";
import * as Icons from "@material-ui/icons";
import moment from "moment";

import { renderPricePoster } from "../../utils/validations";
import TypesEvent from "../../components/TypesEvent";
import ItemEvent from "../../components/ItemEvent";
import EventsService from "../../services/events";
import pageStyles from "./styles/allEventsStyles";
import { Store } from "../../hooks/main_store";
import Model from "../../hooks/Model";
import Layout from "../layout";

const AllEvents = ({ history }) => {
  const { t } = useTranslation();
  const styles = pageStyles();
  const { state } = React.useContext(Store);
  const [searched, setSearched] = React.useState(false);
  const [searchTxt, setSearchTxt] = React.useState("");
  const [selectedType, setSelectedType] = React.useState("");

  const changeInput = () => (event) => {
    event.persist();
    setSearched(false);
    setSelectedType("");
    setSearchTxt(event.target.value);
  };

  const onKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      onSearch();
    } else if (event.keyCode === 27) {
      setSearched(false);
      setSearchTxt("");
    }
  };

  const changeTypeFilter = (type) => setSelectedType(type);

  const returnEvents = (pastEvents = false) =>
    searched
      ? state.searchedEvents
      : state.events.filter(
          (item) =>
            item.status === "published" &&
            (pastEvents
              ? parseInt(item.date) + 8640000 < parseInt(moment().format("x"))
              : parseInt(item.date) + 8640000 >
                parseInt(moment().format("x"))) &&
            (selectedType ? item.type === selectedType : true)
        );

  const onSelectEvent = (item) => {
    Model.setData("eventSelected", item);
    history.push("/events");
  };

  const renderEvents = () => {
    if (returnEvents().length < 1) {
      return (
        <div className={styles.lblEmpty}>
          {t("pages.events.allEvents.weDontHaveEvents")}
        </div>
      );
    }

    return returnEvents().map((item, index) => (
      <ItemEvent
        key={"item-list-event-" + index}
        item={item}
        onClick={onSelectEvent}
        callback={getEvents}
        customClasses={styles.itemListEvent}
      />
    ));
  };

  const renderRowItems = () => {
    return state?.systemSettings?.eventsBanner
      ?.map(
        (item) =>
          state.events[
            state.events.findIndex((itemInfo) => itemInfo.uid === item)
          ]
      )
      .filter((item) => !!item)
      .map((item, index) => (
        <div
          className={styles.itemCardEvent}
          key={"item-row-event-" + index}
          onClick={() => onSelectEvent(item)}
        >
          <div className={styles.contPosterCardEvent}>
            <img
              src={item?.imageBanner}
              alt={item.name}
              className={styles.imagePoster}
            />
            <div className={styles.badgePrice}>
              {renderPricePoster(item?.prices, item.acceptUsd ? "USD" : "COP")}
            </div>
            <div className={styles.contNamePoster}>
              <h4 className={styles.namePoster}>{item.name}</h4>
              <p className={styles.descriptionPoster}>{item.description}</p>
            </div>
          </div>
          <div className={styles.datePoster}>
            {moment(item.date, "x").format("DD MMM YYYY | ha")}
          </div>
        </div>
      ));
  };

  const onSearch = async () => {
    if (searchTxt.length >= 3) {
      try {
        Model.setData("loading", true);
        await EventsService.searchEvent(searchTxt, state.events);
        setSearched(true);
      } catch (e) {
        Model.updateAlerts({
          message: `${t("pages.events.allEvents.errorGetEvents")}${String(e)}`,
          variant: "error",
        });
      } finally {
        Model.setData("loading", false);
      }
    } else {
      Model.updateAlerts({
        message: t("pages.events.allEvents.enterAtLeast"),
        variant: "error",
      });
    }
  };

  const getEvents = async () => {
    try {
      Model.setData("loading", !(state?.events?.length > 0));
      await EventsService.getEvents();
    } catch (e) {
      console.log(t("pages.events.allEvents.errorGetEvents"), e);
    } finally {
      Model.setData("loading", false);
    }
  };

  React.useEffect(() => {
    Model.setData("opacity", 0.7);
    getEvents();
    return () => {};
    //eslint-disable-next-line
  }, []);

  return (
    <Layout>
      <div className={styles.contentBanner}>
        <img
          src={require("../../assets/images/dummy/banner3.png")}
          alt="Banner events"
          className={styles.imageBanner}
        />
        <div className={styles.filterBg}></div>
        <div className={styles.infoBanner}>
          <p className={styles.titleBanner}>
            {t("pages.events.allEvents.eventsOnFire")}{" "}
            <span role="img" aria-label="fire">
              🔥
            </span>
          </p>
          <p className={styles.descriptionBanner}>
            {t("pages.events.allEvents.theseAndMoreEvents")}{" "}
            <span role="img" aria-label="fire">
              🤓
            </span>
          </p>
        </div>
        <div className={styles.rowEventsBanner}>{renderRowItems()}</div>
        <div className={styles.shadowBanner} />
      </div>
      <div className={styles.rowFilter}>
        <div className={styles.contSearch}>
          <input
            placeholder={t("pages.events.allEvents.whatSearch")}
            className={styles.inputSearch}
            value={searchTxt}
            onChange={changeInput("searchTxt")}
            onKeyDown={onKeyDown}
          />
          <Icons.Search className={styles.iconSearch} />
        </div>
        <button className={styles.buttonSearch} onClick={onSearch}>
          {t("pages.events.allEvents.search")}
        </button>
      </div>

      {searched ? null : (
        <TypesEvent onClick={changeTypeFilter} value={selectedType} />
      )}

      <div className={styles.listOfEvents}>{renderEvents()}</div>
    </Layout>
  );
};

export default AllEvents;
