import React from "react";
import { useTranslation, Trans } from "react-i18next";
import { CropFree } from "@material-ui/icons";

import pageStyles from "./styles/validateTicketStyles";
import AdminService from "../../services/admin";
import { Store } from "../../hooks/main_store";
import Model from "../../hooks/Model";
import Layout from "../layout";

const ValidateTicket = ({ history, location }) => {
  const { t, i18n } = useTranslation();
  const styles = pageStyles();
  const { state } = React.useContext(Store);
  const [allSales, setAllSales] = React.useState([]);
  const [vc, setVc] = React.useState({
    searchText: "",
    sales2show: [],
    infoTicketVerified: null,
  });

  const changeVc = (value) => setVc((ov) => ({ ...ov, ...value }));

  const changeInput = (name) => (event) => {
    event.persist();
    setVc((oldValues) => ({
      ...oldValues,
      [name]: event.target.value,
    }));
  };

  const onKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      onSearch();
    } else if (event.keyCode === 27) {
      changeVc({ searchText: "", sales2show: [] });
    }
  };

  const beInside = (text1, text2) =>
    String(text1)
      .toLowerCase()
      .replace(/ /g, "")
      .indexOf(String(text2).toLocaleLowerCase().replace(/ /g, "")) > -1;

  const onSearch = () => {
    let auxSales = [];
    if (vc.searchText.length > 2) {
      Model.setData("loading", true);
      auxSales = allSales.filter((sale) => {
        const flagName = beInside(sale?.client?.name, vc.searchText);
        const flagLastName = beInside(sale?.client?.lastName, vc.searchText);
        const flagDoc = beInside(sale?.client?.numId, vc.searchText);
        const flagEmail = beInside(sale?.client?.email, vc.searchText);
        return flagName || flagLastName || flagDoc || flagEmail;
      });
      if (auxSales.length > 0) {
        changeVc({ sales2show: auxSales });
      } else {
        Model.updateAlerts({
          message: t("pages.admin.validateTicket.noFoundSell"),
          variant: "error",
        });
      }
      setTimeout(
        () => Model.setData("loading", false),
        process.env.NODE_ENV === "development" ? 0 : 1000
      );
    } else {
      Model.updateAlerts({
        message: t("pages.admin.validateTicket.enterAtLeast"),
        variant: "error",
      });
    }
  };

  const renderAvailableTicketsLabel = (tickets = [], onlyBool = false) => {
    let availableTickets = 0;
    tickets.forEach((ticket) => {
      if (
        ticket?.status === "pending" &&
        !ticket.validatedBy &&
        !ticket.resaleBookedBy &&
        !ticket.isSelling &&
        !ticket.transferedEmail &&
        !ticket.transferedTo
      ) {
        availableTickets++;
      }
    });
    if (onlyBool) {
      return availableTickets > 0;
    } else {
      return `${availableTickets} ticket${
        availableTickets === 1 ? "" : "s"
      }\n${t("pages.admin.validateTicket.available")}${
        availableTickets === 1 || i18n.language === "en" ? "" : "s"
      }`;
    }
  };

  const onValidateNext = async (sale, index) => {
    try {
      Model.setData("dialog", { open: false });
      Model.setData("loading", true);
      await AdminService.validateNextTicket(sale?.uid);

      let auxSalesShow = [...vc.sales2show];
      let flag2validate = false;
      auxSalesShow[index].tickets = auxSalesShow[index].tickets.map(
        (ticket) => {
          if (ticket.status === "pending" && !flag2validate) {
            flag2validate = true;
            return { ...ticket, status: "validated" };
          } else {
            return ticket;
          }
        }
      );
      changeVc({ sales2show: auxSalesShow });

      await getMySales();

      const newTickets = [...(sale?.tickets || [])];
      newTickets.splice(0, 1);
      Model.updateAlerts({
        message:
          t("pages.admin.validateTicket.ticketValidated") +
          renderAvailableTicketsLabel(newTickets),
        variant: "success",
      });
    } catch (e) {
      Model.updateAlerts({
        message:
          t("pages.admin.validateTicket.errorValidateTicket") + String(e),
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  const validateTicket = (sale, index) => {
    Model.setData("dialog", {
      open: true,
      title: t("pages.admin.validateTicket.validateTicket"),
      text: t("pages.admin.validateTicket.wantValidateTickets", {
        name: sale?.client?.name || "-",
        quantity: renderAvailableTicketsLabel(sale?.tickets || []),
      }),
      txtLeft: t("commons.cancel"),
      txtRight: t("pages.admin.validateTicket.yesValidate"),
      fnRight: () => onValidateNext(sale, index),
      fnLeft: () => Model.setData("dialog", { open: false }),
    });
  };

  const renderSales = () => {
    if (vc.sales2show.length < 1) {
      return (
        <p className={styles.noItemsLabel}>
          {vc.searchText.length < 3
            ? t("pages.admin.validateTicket.enterAnyData")
            : t("pages.admin.validateTicket.thereNoItems")}
        </p>
      );
    } else {
      return vc.sales2show.map((sale, index) => (
        <div
          key={"item-sale-ticket-" + index}
          className={styles.itemSaleTicket}
        >
          <div className={styles.infoSale}>
            <span className={styles.nameSale}>
              {sale?.client?.name} {sale?.client?.lastName}
            </span>
            <span className={styles.docSale}>
              {sale?.client?.numId ||
                t("pages.admin.validateTicket.idNoRegistered")}
            </span>
            <span className={styles.emailSale}>{sale?.client?.email}</span>
          </div>
          <div className={styles.actionSale}>
            {(sale?.tickets || [])?.[0]?.label ||
              t("pages.admin.validateTicket.transfered")}
          </div>
          <div className={styles.actionSale}>
            {renderAvailableTicketsLabel(sale?.tickets || [], true) ? (
              <button
                className={styles.buttonValidateSale}
                onClick={() => validateTicket(sale, index)}
              >
                {t("pages.admin.validateTicket.validate")}
              </button>
            ) : null}
            <span className={styles.subLabelButtonSale}>
              {renderAvailableTicketsLabel(sale?.tickets || [])}
            </span>
          </div>
        </div>
      ));
    }
  };

  const getMySales = async () => {
    try {
      Model.setData("opacity", 0.7);
      Model.setData(
        "loading",
        !state.infoValidateTicketsEventPartner?.[state.eventSelected.uid]
      );

      if (state.infoValidateTicketsEventPartner?.[state.eventSelected.uid]) {
        setAllSales(
          state.infoValidateTicketsEventPartner?.[state.eventSelected.uid]
        );
      }

      const responseSales = await AdminService.getMySales(
        state.eventSelected.uid
      );
      const allTickets = (responseSales?.allTickets || []).filter(
        (sale) => sale?.status === "payed"
      );
      setAllSales(allTickets);
      Model.setData("infoValidateTicketsEventPartner", {
        ...state.infoValidateTicketsEventPartner,
        [state.eventSelected.uid]: allTickets,
      });
    } catch (e) {
      Model.updateAlerts({
        message: t("pages.admin.validateTicket.errorGetInfo") + String(e),
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  React.useEffect(() => {
    if (!vc.searchText) {
      changeVc({ sales2show: [] });
    }
    return () => {};
    //eslint-disable-next-line
  }, [vc.searchText]);

  React.useEffect(() => {
    if (state.eventSelected) {
      getMySales(state.eventSelected.uid);
    } else if (location?.state?.event) {
      Model.setData("eventSelected", location?.state?.event);
    } else {
      history.push("/");
    }
    return () => {};
    //eslint-disable-next-line
  }, [state.eventSelected]);

  return (
    <Layout>
      <div className={styles.container}>
        <p className={styles.title}>
          {t("pages.admin.validateTicket.validateTicket")} -{" "}
          {state?.eventSelected?.name}
        </p>

        <p className={styles.description}>
          <Trans
            i18nKey="pages.admin.validateTicket.youCanValidate"
            components={{ bold: <b /> }}
          />
        </p>

        <div className={styles.contInputSearch}>
          <input
            placeholder={t("pages.admin.validateTicket.buyerData")}
            className={styles.inputSearch}
            value={vc.searchText}
            onChange={changeInput("searchText")}
            onKeyDown={onKeyDown}
          />
          <button className={styles.buttonSearch} onClick={onSearch}>
            {t("pages.admin.validateTicket.search")}
          </button>
        </div>

        <button
          className={styles.buttonScan}
          onClick={() => Model.setData("showQrScanModal", true)}
        >
          <CropFree />
          &nbsp;&nbsp;{t("pages.admin.validateTicket.scanQrCode")}
        </button>

        <div className={styles.listSales}>{renderSales()}</div>

        <div className={styles.blank} />
      </div>
    </Layout>
  );
};

export default ValidateTicket;
