import React from "react";
import { Route, Redirect } from "react-router-dom";
import { Store } from "../hooks/main_store";
import Model from "../hooks/Model";

const PrivateRoute = ({
  component: Component,
  onlyAdmin,
  withShop,
  ...rest
}) => {
  const { state } = React.useContext(Store);
  const [endLoad, setEndLoad] = React.useState(!!state.endLoadPrivateRoute);
  const [unsubscribe, setUnsubscribe] = React.useState(null);
  const [unsubscribeLoading, setUnsubscribeLoading] = React.useState(null);
  const isPartner = state?.userInfo?.isPartner;
  const availableShop = state?.userInfo?.availableShop;

  React.useEffect(() => {
    clearTimeout(unsubscribe);
    clearTimeout(unsubscribeLoading);
    if (!state.loading) {
      setUnsubscribe(
        setTimeout(() => {
          setEndLoad(true);
          if (!state.uid) {
            Model.setData("openAuth", true);
          }
        }, 1000)
      );
    } else {
      setUnsubscribeLoading(
        setTimeout(() => {
          setEndLoad(true);
        }, 3000)
      );
    }
    //eslint-disable-next-line
  }, [state.loading, state.uid, isPartner, availableShop]);

  React.useEffect(() => {
    if (endLoad) {
      Model.setData("endLoadPrivateRoute", true);
    }
    //eslint-disable-next-line
  }, [endLoad]);

  return endLoad || state.endLoadPrivateRoute ? (
    <Route
      {...rest}
      render={(props) =>
        state.uid &&
        (onlyAdmin ? state?.userInfo?.isPartner : true) &&
        (withShop ? state?.userInfo?.availableShop : true) ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  ) : null;
};

export default PrivateRoute;
