import React from "react";
import {
  Event,
  CropFree,
  ConfirmationNumber,
  Share,
  Security,
  BarChart,
  Web,
  AttachMoney,
} from "@material-ui/icons";
import i18next from "../languages/i18n";

export const keyMp =
  process.env.NODE_ENV === "production" &&
  !window?.location?.host?.includes?.("test.")
    ? "APP_USR-1f0b5a78-d42b-440b-a5f8-3d5bd40b41f5"
    : "TEST-102d6a76-f4b3-49ff-9f85-9540b19dc1ff";

export const features = [
  {
    title: i18next.t("constants.titlePublishEvent"),
    description: i18next.t("constants.bodyPublishEvent"),
    image: <Event />,
  },
  {
    title: i18next.t("constants.titleCreateEvent"),
    description: i18next.t("constants.bodyCreateEvent"),
    image: <ConfirmationNumber />,
  },
  {
    title: i18next.t("constants.titleScanQr"),
    description: i18next.t("constants.bodyScanQr"),
    image: <CropFree />,
  },
  {
    title: i18next.t("constants.titleManagePromoters"),
    description: i18next.t("constants.bodyManagePromoters"),
    image: <Share />,
  },
  {
    title: i18next.t("constants.titleTeamLogistics"),
    description: i18next.t("constants.bodyTeamLogistics"),
    image: <Security />,
  },
  {
    title: i18next.t("constants.titleAnalytics"),
    description: i18next.t("constants.bodyAnalytics"),
    image: <BarChart />,
  },
  {
    title: i18next.t("constants.titleWebsite"),
    description: i18next.t("constants.bodyWebsite"),
    image: <Web />,
  },
  {
    title: i18next.t("constants.titleEarnNow"),
    description: i18next.t("constants.bodyEarnNow"),
    image: <AttachMoney />,
  },
];

export const languages = [
  { label: i18next.t("components.initialSettingsModal.spanish"), value: "es" },
  { label: i18next.t("components.initialSettingsModal.english"), value: "en" },
];

export const currencies = [
  { label: "Peso COP", value: "COP" },
  // { label: "Dolar USD", value: "USD" },
];

export const commonPlatformTax = 0.08;
export const commonMpTax = [0.0329, 952];
