import { makeStyles } from "@material-ui/styles";
import Commons from "../../../utils/styles";
import { colors } from "../../../styleguide";

const styles = makeStyles({
  rowFilter: {
    ...Commons.flexRow,
    margin: "60px auto 42px auto",
    justifyContent: "center",
    backgroundColor: colors.black,
    width: "fit-content",
    borderRadius: 30,
    padding: "12px 30px",
    boxShadow: colors.shadow,
  },
  contSearch: {
    border: `2px solid ${colors.themeColor}`,
    borderRadius: 24,
    marginRight: 12,
    width: "80vw",
    maxWidth: 600,
    position: "relative",
    height: 42,
    [Commons.smallQuery]: {
      width: "50vw",
    },
  },
  inputSearch: {
    ...Commons.defaultInput,
    height: "100%",
    width: "calc(100% - 60px)",
    padding: "0 12px",
    color: colors.white,
    "&::placeholder": {
      color: colors.themeColor,
    },
  },
  iconSearch: {
    position: "absolute",
    right: 18,
    color: colors.themeColor,
    fontSize: 30,
    top: 6,
  },
  buttonSearch: {
    ...Commons.defaultButton,
    backgroundColor: colors.themeColor,
    color: colors.black,
    borderRadius: 12,
    padding: "12px 18px",
  },
  listQuestions: {
    margin: "0 auto 60px auto",
    width: "90%",
    maxWidth: 1024,
  },
  lblEmpty: {
    textAlign: "center",
    color: colors.black,
    fontSize: 18,
    margin: "30px auto",
  },
  itemQuestion: {
    border: `1px solid ${colors.grayLight}`,
    borderRadius: 6,
  },
  selectedQuestion: {
    borderColor: colors.themeColor,
  },
  headingQuestion: {
    margin: 0,
    padding: "12px 0",
    fontSize: 24,
  },
  descriptionQuestion: {
    margin: "-12px 0 0 0",
    padding: "0 0 12px 0",
    fontSize: 18,
  },
});

export default styles;
