export const callMe = require("./IMG_4253.PNG");
export const heartHand = require("./IMG_4254.PNG");
export const hi = require("./IMG_4255.PNG");
export const happyCry = require("./IMG_4256.PNG");
export const heartEyes = require("./IMG_4257.PNG");
export const headBlow = require("./IMG_4258.PNG");
export const sleep = require("./IMG_4259.PNG");
export const starEyes = require("./IMG_4260.PNG");
export const cry = require("./IMG_4261.PNG");
export const idea = require("./IMG_4262.PNG");
export const relax = require("./IMG_4263.PNG");
export const ill = require("./IMG_4264.PNG");
export const angry = require("./IMG_4265.PNG");
export const inLove = require("./IMG_4266.PNG");
export const party = require("./IMG_4267.PNG");
export const kiss = require("./IMG_4268.PNG");
export const rudeness = require("./IMG_4269.PNG");
export const ups = require("./IMG_4270.PNG");
export const secret = require("./IMG_4271.PNG");
export const yoga = require("./IMG_4272.PNG");
export const scary = require("./IMG_4273.PNG");
export const done = require("./IMG_4274.PNG");
export const bad = require("./IMG_4275.PNG");
export const yei = require("./IMG_4276.PNG");
export const hug = require("./IMG_4277.PNG");
export const brofist = require("./IMG_4278.PNG");
export const quiet = require("./IMG_4279.PNG");
export const show = require("./IMG_4280.PNG");
export const pray = require("./IMG_4281.PNG");
export const block = require("./IMG_4282.PNG");
export const promise = require("./IMG_4283.PNG");
export const silence = require("./IMG_4284.PNG");
export const think = require("./IMG_4285.PNG");
export const surpriseHand = require("./IMG_4286.PNG");
export const winkTongue = require("./IMG_4287.PNG");
export const coding = require("./IMG_4288.PNG");
export const raiseHand = require("./IMG_4289.PNG");
export const noUnderstan = require("./IMG_4290.PNG");
export const upset = require("./IMG_4291.PNG");
export const sad = require("./IMG_4292.PNG");
export const surprise = require("./IMG_4293.PNG");
export const smallAngry = require("./IMG_4294.PNG");
export const wink = require("./IMG_4295.PNG");
export const superSad = require("./IMG_4296.PNG");
export const happy = require("./IMG_4297.PNG");
