import React from "react";
import {
  Edit,
  MoreVert,
  AddCircle,
  Visibility,
  CropFree,
  Block,
  Publish,
  Category,
  TrendingUp,
  GroupAdd,
  Extension,
  Settings,
} from "@material-ui/icons";
import { Menu, MenuItem, ListItemIcon, ListItemText } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { useTranslation } from "react-i18next";

import LayoutListEvents from "../../components/LayoutListEvents";
import ActionsBanner from "../../components/ActionsBanner";
import { Store } from "../../hooks/main_store";
import ShopService from "../../services/shop";
import pageStyles from "./styles/adminStyles";
import SalesShopView from "./sales-shop";
import SalesTeamView from "./sales-team";
import Model from "../../hooks/Model";
import Layout from "../layout";
import moment from "moment";

const AdminShop = ({ history }) => {
  const { t, i18n } = useTranslation();
  const banners = [
    {
      title: t("pages.admin.shop.newPlugin"),
      description: t("pages.admin.shop.integrateItInWebsite"),
      color: "purple",
      onPress: () => history.push("/infoPlugin"),
      Icon: (props = {}) => <Extension {...props} />,
    },
    {
      title: t("pages.admin.shop.setUpAccount"),
      description: t("pages.admin.shop.linkAccounts"),
      color: "orange",
      onPress: () => history.push("/profile?tab=2"),
      Icon: (props = {}) => <Settings {...props} />,
    },
  ];
  const urlParams = new URLSearchParams(window.location.search);
  const tabBefore = parseInt(urlParams.get("tab") || 0);
  const styles = pageStyles();
  const { state } = React.useContext(Store);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedProduct, setSelectedProduct] = React.useState(null);
  const [vc, setVc] = React.useState({
    tabSelected: tabBefore,
    currentPage: 1,
    totalPages: 1,
  });

  const changeVc = (data) => setVc((ov) => ({ ...ov, ...data }));

  const handlePageChange = (e, value) => {
    changeVc({ currentPage: value });
  };

  const onSelectProduct = (productSelected, route) => {
    Model.setData("productSelected", productSelected);

    if (route === "/detail-product") {
      history.push(`/detail-product?product=${productSelected.uid}`, {
        product: productSelected,
      });
    } else {
      history.push(route, { productSelected });
    }
  };

  const changeStatus = async (item) => {
    try {
      Model.setData("loading", true);

      let beforeProducts = [...state.productsPartner];
      const infoProduct = await ShopService.changeStatusProduct(item?.uid);
      const indexProduct = beforeProducts.findIndex(
        (product) => product.uid === item?.uid
      );

      beforeProducts[indexProduct] = infoProduct;
      Model.setData("productsPartner", beforeProducts);
      Model.updateAlerts({
        message: t("pages.admin.shop.successChange"),
        variant: "success",
      });
    } catch (e) {
      Model.updateAlerts({
        message: t("pages.admin.shop.errorChangeProductState") + e,
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  const onChageStatus = (product) => {
    const isPublished = product?.status === "published";

    Model.setData("dialog", {
      open: true,
      title: t("pages.admin.shop.changeProductState"),
      text: t("pages.admin.shop.areSureChangeShowProduct", {
        isAvailable: isPublished ? (i18n.language === "en" ? "un" : "des") : "",
        name: product?.name,
        isNoYet: isPublished
          ? i18n.language === "en"
            ? "already no "
            : "ya no"
          : "",
      }),
      txtLeft: t("commons.cancel"),
      fnLeft: () => {
        Model.setData("dialog", { open: false, isReferred: false });
      },
      txtRight: t("pages.admin.shop.labelAvailable", {
        preLabel: isPublished
          ? i18n.language === "en"
            ? "Una"
            : "Desh"
          : i18n.language === "en"
          ? "A"
          : "H",
      }),
      fnRight: () => {
        Model.setData("dialog", { open: false, isReferred: false });
        changeStatus(product);
      },
    });
  };

  const actions = [
    {
      label: t("pages.admin.shop.validate"),
      icon: <CropFree />,
      callBack: () => Model.setData("showQrScanModal", true),
    },
    {
      label: t("pages.admin.shop.edit"),
      icon: <Edit />,
      callBack: (item) => history.push("productForm", { draftProduct: item }),
    },
    {
      label: t("pages.admin.shop.detail"),
      icon: <Visibility />,
      callBack: (item) => onSelectProduct(item, "/detail-product"),
    },
  ];

  const handleMoreVert = (event, info) => {
    setAnchorEl(event.currentTarget);
    setSelectedProduct(info);
  };

  const returnActions = (status) => {
    const localActions = [
      ...actions,
      status === "published"
        ? {
            label: t("pages.admin.shop.unavailable"),
            icon: <Block />,
            callBack: onChageStatus,
          }
        : {
            label: t("pages.admin.shop.available"),
            icon: <Publish />,
            callBack: onChageStatus,
          },
    ];

    const filteredActions = localActions.filter((item) =>
      vc.tabSelected === 0 || vc.tabSelected === 1
        ? true
        : item?.label !== t("pages.admin.shop.edit")
    );

    return filteredActions;
  };

  const renderProducts = () => {
    const filteredProducts = state.productsPartner;
    if (filteredProducts.length < 1) {
      return (
        <tr>
          <td colSpan={2}>
            <div className={styles.lblEmpty}>
              {t("pages.admin.shop.weNoHaveAnyProduct")}
            </div>
          </td>
        </tr>
      );
    }
    return filteredProducts
      .slice((vc.currentPage - 1) * 10, vc.currentPage * 10 - 1)
      .map((item, index) => (
        <tr key={"item-list-product-" + index}>
          <td>
            <div className={styles.itemListEvent}>
              <img
                className={styles.imgLEvent}
                src={item?.images[0]}
                alt="product"
              />
              <div className={styles.infoLEvent}>
                <div className={styles.rowInfo}>
                  <div className={styles.contTitleEvent}>
                    <p className={styles.titleEvent}>{item?.name}</p>
                    <p
                      className={styles.dateEvent}
                      style={
                        item?.status === "pending" ? { fontWeight: 500 } : {}
                      }
                    >
                      {t("pages.admin.shop.remaningItems")}:{" "}
                      {item?.prices?.reduce?.(
                        (total, current) => total + (current?.stock || 0),
                        0
                      ) || 0}
                    </p>
                  </div>
                </div>
                <div className={styles.rowInfo}>
                  <p className={styles.sublblEvent}>
                    {t("pages.admin.shop.dateCreate")}:{" "}
                    {moment(item?.created, "x").format("ddd DD MMM, YYYY")}
                  </p>
                </div>
              </div>
            </div>
          </td>
          <td>
            <div className={styles.rowInfo}>
              <div
                className={styles.btTicketMobile}
                onClick={(e) => handleMoreVert(e, item)}
              >
                <MoreVert className={styles.iconBtnTicket} />
              </div>
              {returnActions(item?.status).map((action) => (
                <div
                  key={action.label}
                  className={styles.btTicket}
                  onClick={() => action.callBack(item)}
                >
                  {action.icon}
                  <span>{action.label}</span>
                </div>
              ))}
            </div>
          </td>
        </tr>
      ));
  };

  const getProducts = async () => {
    try {
      Model.setData("loading", state.productsPartner?.length < 1);
      await ShopService.getProducts(state.uid);
    } catch (e) {
      console.log(t("pages.admin.shop.errorGetProducts"), e);
    } finally {
      Model.setData("loading", false);
    }
  };

  const renderContent = () => {
    if (vc.tabSelected === 1) {
      return (
        <>
          <table
            cellPadding={0}
            cellSpacing={0}
            className={styles.tableContainer}
          >
            <thead className={styles.rowHeadTable}>
              <tr>
                <th>{t("pages.admin.shop.products")}</th>
                <th>{t("pages.admin.shop.options")}</th>
              </tr>
            </thead>
            <tbody className={styles.rowBodyTable}>{renderProducts()}</tbody>
          </table>
          <div className={styles.containerPagination}>
            <Pagination
              count={vc.totalPages}
              page={vc.currentPage}
              onChange={handlePageChange}
            />
          </div>
          <Menu
            elevation={3}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => {
              setAnchorEl(null);
              setSelectedProduct(null);
            }}
          >
            {actions.map((action) => (
              <MenuItem
                key={action.label}
                onClick={() => action.callBack(selectedProduct)}
              >
                <ListItemIcon>{action.icon}</ListItemIcon>
                <ListItemText primary={action.label} />
              </MenuItem>
            ))}
          </Menu>
          <div className={styles.blank} />
        </>
      );
    } else if (vc.tabSelected === 2) {
      return <SalesTeamView history={history} />;
    }

    return <SalesShopView history={history} />;
  };

  React.useEffect(() => {
    changeVc({
      totalPages: Math.ceil((state.productsPartner?.length || 0) / 10) || 1,
      currentPage: 1,
    });
    return () => {};
    //eslint-disable-next-line
  }, [state.productsPartner, vc.tabSelected]);

  React.useEffect(() => {
    getProducts();
    return () => {};
    //eslint-disable-next-line
  }, []);

  return (
    <Layout blackHeader>
      <LayoutListEvents
        tabSelected={vc.tabSelected}
        topBtns={[
          {
            label: t("pages.admin.shop.sells"),
            callBack: () => changeVc({ tabSelected: 0 }),
            icon: <TrendingUp />,
          },
          {
            label: t("pages.admin.shop.products"),
            callBack: () => changeVc({ tabSelected: 1 }),
            icon: <Category />,
          },
          {
            label: t("pages.admin.shop.team"),
            callBack: () => changeVc({ tabSelected: 2 }),
            icon: <GroupAdd />,
          },
        ]}
        bottomBtns={[
          {
            label: t("pages.admin.shop.createNewOne"),
            icon: <AddCircle />,
            callBack: () => {
              history.push("/productForm");
            },
            highlighted: true,
          },
        ]}
      >
        <ActionsBanner banners={banners} className={styles.banners} />

        {renderContent()}
      </LayoutListEvents>
    </Layout>
  );
};

export default AdminShop;
