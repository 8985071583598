import { makeStyles } from "@material-ui/core/styles";
import { colors } from "../../styleguide";
import Commons from "../../utils/styles";

const useStyles = makeStyles({
  containerSupport: {
    ...Commons.flexColumn,
    position: "absolute",
    bottom: 12,
    right: 30,
    cursor: "pointer",
    transition: "all 0.3s",
    // zIndex: 1,
    "&:hover .optionHide": {
      display: "block",
    },
    [Commons.smallQuery]: {
      right: 6,
      bottom: 18,
    },
  },
  optionHelp: {
    backgroundColor: colors.themeColor,
    color: colors.black,
    borderRadius: 6,
    marginBottom: 6,
    padding: "3px 6px",
    width: "100%",
    textAlign: "center",
    display: "none",
    fontSize: 15,
  },
  imageAvatar: {
    height: "auto",
    width: 90,
    top: -24,
    [Commons.smallQuery]: {
      height: 72,
    },
  },
  circleAvatar: {
    ...Commons.flexCenter,
    height: 78,
    width: 78,
    backgroundColor: colors.black,
    border: `6px solid ${colors.themeColor}`,
    borderRadius: "50%",
    overflow: "hidden",
    position: "relative",
    zIndex: 0,
    [Commons.smallQuery]: {
      height: 60,
      width: 60,
      borderWidth: 4,
    },
  },
  labelSupport: {
    color: colors.white,
    backgroundColor: colors.black,
    border: `4px solid ${colors.themeColor}`,
    borderRadius: 12,
    textAlign: "center",
    width: 78,
    fontSize: 15,
    marginTop: -12,
    position: "relative",
    padding: "3px 6px",
    fontWeight: "600",
    [Commons.smallQuery]: {
      borderWidth: 3,
      fontSize: 12,
      width: 60,
    },
  },
  rowContentTutorial: {
    ...Commons.flexRow,
    position: "absolute",
    bottom: 12,
    right: 30,
    width: "100%",
    maxWidth: 500,
    backgroundColor: colors.black,
    border: `4px solid ${colors.themeColor}`,
    borderRadius: 24,
    boxShadow: colors.shadow,
    transition: "all 0.3s",
    zIndex: 1,
    [Commons.smallQuery]: {
      width: "calc(100% - 36px)",
      right: 18,
      flexDirection: "column",
    },
  },
  infoContentTutorial: {
    padding: 30,
  },
  titleTutorial: {
    color: colors.white,
    margin: "0 0 12px 0",
    fontWeight: "700",
    fontSize: 24,
    [Commons.smallQuery]: {
      fontSize: 18,
    },
  },
  descriptionTutorial: {
    color: colors.white,
    margin: 0,
    flex: 1,
    fontSize: 17,
    [Commons.smallQuery]: {
      fontSize: 16,
    },
  },
  contentAvatarTutorial: {
    borderBottom: `6px solid ${colors.white}`,
    minWidth: 150,
    borderRadius: "0 0 50% 50%",
    overflow: "hidden",
    marginLeft: -18,
    marginRight: 18,
    [Commons.smallQuery]: {
      minWidth: 120,
      maxWidth: 120,
      margin: 0,
      position: "absolute",
      top: -92,
      left: 0,
      borderRadius: "0 0 30px 18px",
      borderColor: colors.themeColor,
    },
  },
  avatarTutorial: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    marginBottom: -12,
  },
  closeTutorial: {
    ...Commons.defaultButton,
    ...Commons.flexCenter,
    position: "absolute",
    top: -18,
    right: -18,
    backgroundColor: colors.black,
    border: `4px solid ${colors.themeColor}`,
    borderRadius: "50%",
    width: 48,
    height: 48,
    boxShadow: colors.shadow,
    "& svg": {
      color: colors.white,
      fontSize: 30,
    },
  },
  spaceWithTabs: {
    [Commons.smallQuery]: {
      bottom: 102,
    },
  },
  callbackButton: {
    ...Commons.defaultButton,
    backgroundColor: colors.themeColor,
    color: colors.white,
    padding: "6px 12px",
    textAlign: "center",
    borderRadius: 6,
    marginTop: 18,
    width: "100%",
    fontWeight: "600",
    fontSize: 16,
  },
});

export default useStyles;
