import React from "react";
import Layout from "../layout";
import SalesShop from "../admin/sales-shop";
import pageStyles from "./styles/shopReportsStyles";

const ShopReports = ({ history }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const refPartner = urlParams.get("ref");
  const styles = pageStyles();

  React.useEffect(() => {
    if (!refPartner) {
      history.push("/");
    }
    //eslint-disable-next-line
  }, []);

  return (
    <Layout blackHeader>
      <div className={styles.container}>
        {refPartner ? (
          <SalesShop
            history={history}
            refPartner={refPartner}
            onError={() => history.push("/")}
          />
        ) : null}
      </div>
    </Layout>
  );
};

export default ShopReports;
