import { makeStyles } from "@material-ui/styles";
import Commons from "../../../utils/styles";
import { colors } from "../../../styleguide";

const styles = makeStyles({
  container: {
    width: "100%",
    maxWidth: 700,
    margin: "36px auto 0 auto",
  },
  title: {
    color: colors.mainxText,
    fontWeight: "700",
    fontSize: 25,
    margin: 12,
    textAlign: "center",
  },
  description: {
    width: "calc(100% - 60px)",
    padding: "0 30px",
    color: colors.black,
    margin: "0 auto",
    fontSize: 15,
    textAlign: "center",
    whiteSpace: "break-spaces",
  },
  blank: {
    height: 60,
  },
  contInputSearch: {
    ...Commons.flexRow,
    width: "calc(100% - 60px)",
    margin: "18px auto 12px auto",
  },
  inputSearch: {
    ...Commons.defaultInput,
    flex: 1,
    border: "2px solid black",
    borderRadius: 6,
    padding: "12px 6px",
    outline: "none",
  },
  buttonSearch: {
    ...Commons.defaultButton,
    marginLeft: 12,
    backgroundColor: colors.themeColor,
    color: colors.white,
    fontWeight: 600,
    padding: "14px 6px",
    borderRadius: 6,
    boxShadow: colors.shadow,
  },
  buttonScan: {
    ...Commons.defaultButton,
    ...Commons.flexRow,
    width: "calc(100% - 60px)",
    margin: "0 auto",
    backgroundColor: colors.themeColor,
    color: colors.white,
    fontWeight: 600,
    padding: "10px 6px",
    borderRadius: 6,
    boxShadow: colors.shadow,
    justifyContent: "center",
  },
  listSales: {
    width: "calc(100% - 72px)",
    padding: "0 6px",
    margin: "18px auto",
    border: "1px solid lightgray",
    borderRadius: 6,
  },
  noItemsLabel: {
    fontSize: 16,
    textAlign: "center",
    color: colors.gray50,
  },
  itemSaleTicket: {
    ...Commons.flexRow,
    borderBottom: "1px solid lightgray",
    padding: "12px 0",
    "&:last-child": {
      border: "none",
    },
  },
  infoSale: {
    ...Commons.flexColumn,
    flex: 1,
    alignItems: "flex-start",
  },
  nameSale: {
    fontWeight: 600,
    fontSize: 18,
  },
  docSale: {
    fontSize: 14,
    color: colors.gray50,
  },
  emailSale: {
    fontSize: 14,
    color: colors.gray50,
  },
  actionSale: {
    ...Commons.flexColumn,
    textAlign: "center",
    margin: "0 6px",
    fontSize: 14,
  },
  buttonValidateSale: {
    ...Commons.defaultButton,
    backgroundColor: colors.themeColor,
    color: colors.white,
    padding: "12px 6px",
    borderRadius: 6,
    marginBottom: 6,
    fontWeight: 600,
  },
  subLabelButtonSale: {
    fontSize: 10,
    color: colors.gray50,
    textAlign: "center",
    lineHeight: "10px",
    whiteSpace: "pre-wrap",
  },
});

export default styles;
