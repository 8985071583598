import { makeStyles } from "@material-ui/styles";
import Commons from "../../../utils/styles";
import { colors } from "../../../styleguide";

const styles = makeStyles({
  title: {
    color: colors.mainxText,
    fontWeight: "700",
    fontSize: 36,
    margin: 12,
    textAlign: "center",
    marginTop: 36,
    marginBottom: 24,
  },
  contLogo: {
    ...Commons.flexCenter,
    minWidth: 120,
    maxWidth: 120,
    minHeight: 120,
    maxHeight: 120,
    borderRadius: "50%",
    backgroundColor: colors.black,
    margin: "0 auto",
    border: "3px solid white",
    boxShadow: colors.shadow,
    zIndex: 1,
    marginBottom: -24,
  },
  logo: {
    minWidth: "80%",
    maxWidth: "80%",
    minHeight: "80%",
    maxHeight: "80%",
    borderRadius: "50%",
    objectFit: "contain",
  },
  description: {
    backgroundColor: colors.black,
    width: "calc(90% - 60px)",
    boxShadow: colors.shadow,
    padding: 30,
    color: colors.white,
    margin: "0 auto",
    borderRadius: 30,
    border: "3px solid white",
    fontSize: 18,
    textAlign: "justify",
    maxWidth: 840,
    "& a": {
      color: colors.themeColor,
    },
  },
  blank: {
    height: 60,
  },
  row: {
    ...Commons.flexRow,
    justifyContent: "center",
    alignItems: "flex-start",
    gap: 30,
    [Commons.smallQuery]: {
      alignItems: "center",
      flexDirection: "column",
    },
  },
  cardPrice: {
    borderRadius: 24,
    boxShadow: colors.shadow,
    background: `linear-gradient(${colors.themeColor}50, ${colors.themeColor})`,
    width: "calc(40% - 60px)",
    maxWidth: 600,
    margin: "0 0 30px 0",
    padding: 30,
    [Commons.smallQuery]: {
      padding: 18,
      width: "calc(100% - 60px)",
    },
  },
  titleCardPrice: {
    fontWeight: "700",
    fontSize: 30,
    color: colors.black,
    textAlign: "center",
    textTransform: "uppercase",
  },
  textCardPrice: {
    textAlign: "center",
    fontSize: 16,
    color: colors.black,
    margin: 12,
  },
  amountCardPrice: {
    fontWeight: "800",
    fontSize: 48,
    color: colors.black,
    textAlign: "center",
    marginBottom: 30,
    [Commons.smallQuery]: {
      fontSize: 42,
    },
  },
  ButtonCardPrice: {
    backgroundColor: colors.white,
    borderRadius: 12,
    textAlign: "center",
    padding: "18px 0",
    width: "80%",
    margin: "0 auto",
    marginBottom: 30,
    fontSize: 24,
    color: colors.themeColor,
    [Commons.smallQuery]: {
      padding: "18px 0",
      fontSize: 24,
    },
  },
  rowFunction: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: 12,
    [Commons.smallQuery]: {
      padding: 12,
    },
  },
  iconFunction: {
    color: colors.white,
  },
  textFunction: {
    color: colors.white,
    margin: 0,
    marginLeft: 12,
    flex: 1,
    fontSize: 18,
    fontWeight: "600",
  },
  textNote: {
    marginTop: 18,
    marginBottom: 30,
    color: colors.white,
  },
  stepsContainer: {
    width: "90%",
    maxWidth: 900,
    margin: "0 auto",
    marginTop: 60,
    [Commons.smallQuery]: {
      display: "none !important",
    },
  },
  showOnMobile: {
    display: "none !important",
    "& .MuiTimelineOppositeContent-root": {
      display: "none !important",
    },
    [Commons.smallQuery]: {
      display: "flex !important",
    },
  },
  labelStep: {
    fontWeight: "700",
    color: colors.black,
    fontSize: 24,
    paddingTop: 4,
  },
  contTextStep: {
    padding: 18,
    borderRadius: 24,
  },
  titleStep: {
    fontWeight: "700",
    color: colors.themeColor,
    fontSize: 24,
    marginBottom: 12,
  },
  textStep: {
    fontSize: 16,
    color: colors.black,
  },
  buttonCta: {
    ...Commons.defaultButton,
    textAlign: "center",
    fontSize: 30,
    fontWeight: "700",
    borderRadius: 12,
    backgroundColor: colors.themeColor,
    color: colors.white,
    boxShadow: colors.shadow,
    width: "90%",
    maxWidth: 900,
    margin: "0 auto",
    padding: "24px 0",
    marginTop: 60,
    border: `3px solid ${colors.themeColor}`,
  },
  disabledBtn: {
    backgroundColor: colors.grayBlack,
    color: colors.black,
  },
  rowFeatures: {
    ...Commons.flexRow,
    justifyContent: "center",
    alignItems: "flex-start",
    gap: 30,
    width: "90%",
    maxWidth: 900,
    margin: "0 auto",
    [Commons.smallQuery]: {
      alignItems: "center",
      flexDirection: "column",
    },
  },
  itemFeature: {
    flex: 1,
    backgroundColor: colors.black,
    boxShadow: colors.shadow,
    padding: "30px 18px",
    color: colors.white,
    borderRadius: 30,
    border: "3px solid white",
  },
  titleItemFeature: {
    margin: "0 0 12px 0",
    fontWeight: "bold",
    fontSize: 18,
  },
  descriptionItemFeature: {
    margin: 0,
    fontSize: 16,
  },
});

export default styles;
