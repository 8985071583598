import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "@material-ui/core";
import _ from "lodash";

import { showAuthError } from "../../utils/validations";
import { block } from "../../assets/images/tutorial";
import PartnerService from "../../services/partner";
import LocalStorage from "../../utils/localStorage";
import pageStyles from "./styles/page404Styles";
import { Store } from "../../hooks/main_store";
import Model from "../../hooks/Model";

const Page404 = ({ location, history }) => {
  const { t } = useTranslation();
  const { state } = React.useContext(Store);
  const [tokenPluginPartner, setTokenPluginPartner] = React.useState("");
  const styles = pageStyles();

  const searchPartner = async (url) => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const selectedTabNum = parseInt(urlParams.get("selectedTab") || 0);
      Model.setData("opacity", 1);
      Model.setData("loading", true);

      const referencePartner = await PartnerService.searchPartner(url);
      if (referencePartner) {
        history.push(
          `/partnerProfile?ref=${referencePartner}&selectedTab=${selectedTabNum}`
        );
      }
    } catch (e) {
      Model.updateAlerts({
        message: `Error: ${showAuthError(
          _.get(e, "message", t("commons.unknowError"))
        )}`,
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
      Model.setData("opacity", 0.7);
    }
  };

  React.useEffect(() => {
    const url = location.pathname.replace("/", "");
    const tokenPlugin = LocalStorage(state.isIncognito).getItem(
      "token_plugin_partner"
    );

    if (tokenPlugin) {
      setTokenPluginPartner(tokenPlugin);
    }

    if (url) {
      searchPartner(url);
    } else {
      Model.setData("loading", false);
    }
    return () => {};
    //eslint-disable-next-line
  }, []);

  return (
    <div className={styles.container}>
      <img alt="Error 404" className={styles.img} src={block} />
      <p className={styles.text}>
        {t("pages.auth.page404.weDidntFindPage")}
        <br />
        <br />
        <code>{location.pathname}</code>
        <br />
        <br />
        <b>ERROR: </b>404
      </p>
      <Link
        href={
          state.isOnPluginPartner
            ? `/pluginPartner?ref=${tokenPluginPartner}`
            : "/"
        }
        className={styles.button}
      >
        {t("pages.auth.page404.getMeOut")}
      </Link>
    </div>
  );
};

export default Page404;
