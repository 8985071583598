import { makeStyles } from "@material-ui/styles";
import Commons from "../../utils/styles";
import { colors } from "../../styleguide";

const styles = makeStyles({
  container: {
    ...Commons.flexRow,
    alignItems: "flex-start",
  },
  leftContainer: {
    width: 300,
    backgroundColor: colors.grayLight,
    height: "100%",
    position: "relative",
    overflow: "visible",
    transition: "all 0.2s",
    [Commons.smallQuery]: {
      position: "fixed",
      bottom: 0,
      left: 0,
      height: "auto",
      width: "100%",
      backgroundColor: "rgba(10, 9, 7, 0.8)",
      zIndex: 1,
      borderRadius: "48px 48px 0 0",
    },
  },
  hideContainer: {
    width: 60,
    [Commons.smallQuery]: {
      width: "100%",
      paddingBottom: 0,
    },
  },
  rightContainer: {
    height: "100%",
    width: "calc(100% - 384px)",
    padding: "30px 42px 0 42px",
    overflow: "auto",
    maxHeight: 738,
    transition: "all 0.2s",
    [Commons.smallQuery]: {
      width: "calc(100% - 24px)",
      padding: "18px 12px 24px 12px",
      maxHeight: "none",
      overflow: "hide",
    },
  },
  fullContainer: {
    width: "calc(100% - 84px)",
    [Commons.smallQuery]: {
      width: "calc(100% - 24px)",
    },
  },
  contImgProfile: {
    width: "50%",
    aspectRatio: "1 / 1",
    border: `4px solid ${colors.themeColor}`,
    backgroundColor: colors.white,
    borderRadius: "50%",
    overflow: "hidden",
    margin: "42px auto 30px auto",
    [Commons.smallQuery]: {
      display: "none",
    },
  },
  imageProfile: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  actionsContainer: {
    ...Commons.flexColumn,
    width: "100%",
    height: 540,
    [Commons.smallQuery]: {
      ...Commons.flexRow,
      height: 90,
    },
  },
  moreHeight: {
    height: 658,
    [Commons.smallQuery]: {
      height: 90,
    },
  },
  blankFlex: {
    flex: 1,
    [Commons.smallQuery]: {
      display: "none",
    },
  },
  btnAction: {
    ...Commons.flexRow,
    ...Commons.defaultButton,
    color: colors.black,
    fontWeight: "600",
    fontSize: 18,
    padding: "30px 18px",
    width: "100%",
    textAlign: "left",
    borderBottom: `1px solid ${colors.grayBlack}`,
    "&:hover": {
      backgroundColor: `${colors.themeColor}25`,
      color: colors.themeColor,
      borderColor: colors.themeColor,
      [Commons.smallQuery]: {
        backgroundColor: "transparent",
      },
    },
    "& svg": {
      marginRight: 12,
    },
    [Commons.smallQuery]: {
      ...Commons.flexColumn,
      flex: 1,
      textAlign: "center",
      padding: 0,
      borderBottom: "none",
      width: "auto",
      fontSize: 12,
      color: colors.grayLight,
      "& svg": {
        marginRight: 0,
        marginBottom: 6,
        fontSize: 30,
      },
    },
  },
  btnActionSelected: {
    backgroundColor: `${colors.themeColor}25`,
    color: colors.themeColor,
    borderColor: colors.themeColor,
    [Commons.smallQuery]: {
      backgroundColor: "transparent",
      "& span": {
        display: "block",
      },
    },
  },
  btnActionHighlighted: {
    backgroundColor: colors.themeColor,
    color: colors.white,
    [Commons.smallQuery]: {
      backgroundColor: "transparent",
      "& span": {
        display: "block",
      },
    },
  },
  toggleContainerButton: {
    ...Commons.defaultButton,
    ...Commons.flexCenter,
    position: "absolute",
    top: 90,
    right: -18,
    backgroundColor: colors.themeColor,
    borderRadius: "50%",
    boxShadow: colors.shadow,
    width: 36,
    height: 36,
    zIndex: 1,
    "& svg": {
      color: colors.white,
      fontSize: 24,
    },
    [Commons.smallQuery]: {
      display: "none",
    },
  },
  smallIcon: {
    fontSize: "12px !important",
    "& svg": {
      marginRight: 0,
    },
    [Commons.smallQuery]: {
      fontSize: "15px !important",
    },
  },
  hideLabel: {
    display: "none",
  },
});

export default styles;
