import React from "react";
import {
  AccountBox,
  AddAPhoto,
  AlternateEmail,
  Palette,
  VpnKey,
  Visibility,
  VisibilityOff,
  HighlightOff,
  Phone,
  Facebook,
  Twitter,
  Instagram,
  YouTube,
  Description,
  Edit,
  Delete,
  Link,
  People,
  Language,
  MonetizationOn,
  PowerSettingsNew,
  Settings,
  Store as StoreIcon,
  ArrowBack,
} from "@material-ui/icons";
import { useTranslation, Trans } from "react-i18next";
import { Tooltip } from "@material-ui/core";
import moment from "moment";
import _ from "lodash";

import {
  showAuthError,
  verifyEmail,
  sizeImage,
  currencyFormat,
  linkMp,
  linkNequi,
  linkBitcoin,
  returnCurrentLink,
} from "../../utils/validations";
import * as AvatarImages from "../../assets/images/tutorial";
import { languages, currencies } from "../../utils/constants";
import NoImage from "../../assets/images/noimage.jpeg";
import SwitchBoth from "../../components/SwitchBoth";
import DialogForm from "../../components/dialogForm";
import Avatar from "../../assets/images/avatar.jpeg";
import LocalStorage from "../../utils/localStorage";
import pageStyles from "./styles/profileStyles";
import { Store } from "../../hooks/main_store";
import AuthService from "../../services/auth";
import * as Routes from "../../utils/routes";
import Model from "../../hooks/Model";
import Layout from "../layout";

const colorsRand = ["#61D095", "#FF7F11", "#40CFFF", "#6758F3", "#FC1978"];

const Profile = ({ history }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const viewUrlParam = urlParams.get("view");
  const styles = pageStyles();
  const { state } = React.useContext(Store);
  const inputFileRef = React.useRef(null);
  const { t, i18n } = useTranslation();

  const [isMobile, setIsMobile] = React.useState(false);
  const [showContentView, setShowContentView] = React.useState(false);
  const [filterPromo, setFilterPromo] = React.useState(
    viewUrlParam || "tickets"
  );

  const [selectedTab, setSelectedTab] = React.useState(
    isNaN(parseInt(urlParams.get("tab"))) ? 0 : parseInt(urlParams.get("tab"))
  );
  const [vc, setVc] = React.useState({
    name: state?.userInfo?.name || "",
    email: state?.userInfo?.email || "",
    imageProfile: state?.userInfo?.imageProfile || "",
    password: "",
    showPrompt: false,
    currentPassword: "",
    showPassword: false,
    newImage: "",
    phone: state?.userInfo?.phone,
    description: "",
    url: "",
    logo: "",
    newLogo: "",
    banner: "",
    newBanner: "",
    nequiClientId: "",
    nequiClientSecret: "",
    nequiXApiKey: "",
    social: {},
    bitcoinAddress: "",
  });
  const changeVc = (data) => setVc((ov) => ({ ...ov, ...data }));

  const changeTab = (index) => {
    setSelectedTab(index);
    setShowContentView(true);
  };

  const changeInput = (name) => (event) => {
    event.persist();
    if (name === "phone") {
      changeVc({
        [name]: event.target.value.replace(/[^+]\D/g, "").slice(0, 15),
      });
    } else if (name.includes("social.")) {
      changeVc({
        social: {
          ...vc.social,
          [name.replace("social.", "")]: event.target.value,
        },
      });
    } else {
      changeVc({ [name]: event.target.value });
    }
  };

  const signOut = () => {
    AuthService.signOut(() => {
      history.push(
        state?.isWhiteLabel
          ? `/${
              state.isOnPluginPartner ? "pluginPartner" : "partnerProfile"
            }?ref=${state?.infoPartner?.uid}`
          : "/"
      );
    }, state).finally(() => {});
  };

  const showAlert = () => {
    Model.setData("dialog", {
      open: true,
      title: t("pages.profile.index.logOut"),
      text: t("pages.profile.index.wantlogOut"),
      txtLeft: t("commons.no"),
      fnLeft: () => Model.setData("dialog", { open: false }),
      txtRight: t("commons.yes"),
      fnRight: signOut,
    });
  };

  const renderItemsNavigation = () => {
    const items = [
      {
        index: 0,
        onClick: () => changeTab(0),
        icon: <Edit />,
        label: t("pages.profile.index.personalInfo"),
      },
      {
        index: 1,
        onClick: () => changeTab(1),
        icon: <People />,
        label: t("pages.profile.index.refers"),
      },
      {
        index: 2,
        onClick: () => changeTab(2),
        icon: <StoreIcon />,
        label: t("pages.profile.index.partnerData"),
      },
      {
        index: 3,
        onClick: () => changeTab(3),
        icon: <Settings />,
        label: t("pages.profile.index.settings"),
      },
      {
        onClick: showAlert,
        icon: <PowerSettingsNew />,
        label: t("pages.profile.index.logOut"),
      },
    ].filter((data) =>
      state?.userInfo?.isPartner
        ? true
        : data.label !== t("pages.profile.index.partnerData")
    );

    return items.map((info) => (
      <button
        key={info.label}
        className={[
          styles.itemListNavigation,
          selectedTab === info.index ? "selected" : "",
        ].join(" ")}
        onClick={info.onClick}
      >
        {info.icon}
        <span>{info.label}</span>
      </button>
    ));
  };

  const acceptRefer = () => {
    Model.setData("loading", true);
    AuthService.updateMe(state.uid, { acceptedReferProgram: true })
      .then(() => {
        Model.updateAlerts({
          message: t("pages.profile.index.infoUpdated"),
          variant: "success",
        });
      })
      .catch((e) => {
        Model.updateAlerts({
          message: `Error: ${_.get(e, "message", t("commons.unknowError"))}`,
          variant: "error",
        });
      })
      .finally(() => Model.setData("loading", false));
  };

  const onAcceptReferTerms = () => {
    Model.setData("dialog", {
      open: true,
      title: state?.emailVerified
        ? t("pages.profile.index.referProgram")
        : t("pages.profile.index.beforeContinue"),
      text: state?.emailVerified
        ? t("pages.profile.index.areSureAcceptTerms")
        : t("pages.profile.index.mustVerifyEmail"),
      txtLeft: state?.emailVerified
        ? t("pages.profile.index.noOtherDay")
        : t("commons.cancel"),
      fnLeft: () => Model.setData("dialog", { open: false }),
      txtRight: state?.emailVerified
        ? t("pages.profile.index.agree")
        : t("pages.profile.index.goToMyProfile"),
      fnRight: () => {
        Model.setData("dialog", { open: false });
        if (state?.emailVerified) {
          acceptRefer();
        } else {
          changeTab(0);
        }
      },
      link: returnCurrentLink(Routes.RENDER_LEGAL_DOC + "?document=promoter"),
    });
  };

  const deleteImagePartner = (refImage) => {
    Model.setData("dialog", {
      open: true,
      title: `${t("pages.profile.index.deletePhoto")} (${refImage})`,
      text: t("pages.profile.index.wantDeletePhoto"),
      txtLeft: t("commons.no"),
      fnLeft: () => Model.setData("dialog", { open: false }),
      txtRight: t("commons.yes"),
      fnRight: () => {
        updateMe({ [refImage]: "" });
        Model.setData("dialog", { open: false });
      },
    });
  };

  const onChangeSettings = (label = "", value = "") => {
    if (value) {
      Model.setData("loading", true);
      AuthService.updateMe(state.uid, {
        [label]: value,
      })
        .then(() => {
          if (label === "defaultLanguage") {
            i18n.changeLanguage(value, () => {
              LocalStorage(state.isIncognito).setItem(label, value);
            });
          }
          Model.setData(label, value);
          Model.updateAlerts({
            message: t("components.initialSettingsModal.infoUpdated"),
            variant: "success",
          });
        })
        .catch((e) => {
          Model.updateAlerts({
            message: `Error: ${_.get(e, "message", t("commons.unknowError"))}`,
            variant: "error",
          });
        })
        .finally(() => {
          Model.setData("loading", false);
        });
    } else {
      Model.updateAlerts({
        message: t("components.initialSettingsModal.selectAll"),
        variant: "warning",
      });
    }
  };

  const renderLanguages = () =>
    languages.map((language) => (
      <button
        key={language.value}
        className={[
          styles.optionButton,
          state.defaultLanguage === language.value ? styles.selectedOption : "",
        ].join(" ")}
        onClick={() => onChangeSettings("defaultLanguage", language.value)}
      >
        {language.label}
      </button>
    ));

  const renderCurrencies = () =>
    currencies.map((currency) => (
      <button
        key={currency.value}
        className={[
          styles.optionButton,
          state.defaultCurrency === currency.value ? styles.selectedOption : "",
        ].join(" ")}
        onClick={() => onChangeSettings("defaultCurrency", currency.value)}
      >
        {currency.label}
      </button>
    ));

  const onFocusInput = (id) => {
    const element = document.getElementById(id);
    if (element?.focus) {
      element.focus();
    }
  };

  const returnInputs = () => {
    const inputs = [
      {
        label: t("commons.name"),
        placeholder: t("pages.profile.index.whatIsCompleteName"),
        ref: "name",
        icon: () => <AccountBox />,
      },
      {
        label: t("commons.email"),
        placeholder: t("pages.profile.index.whereSendEmail"),
        ref: "email",
        icon: () => <AlternateEmail />,
      },
      {
        label: t("commons.phone"),
        placeholder: "E: 573001234567",
        ref: "phone",
        icon: () => <Phone />,
      },
      {
        label: t("pages.profile.index.newPassword"),
        placeholder: t("pages.profile.index.typeHere"),
        ref: "password",
        icon: () => <VpnKey />,
        type: "password",
      },
    ];
    return inputs.map((input, index) => {
      const verified = state?.emailVerified && input.ref === "email";
      return (
        <div
          className={styles.rowInput}
          key={"item-input-" + index}
          onClick={() => onFocusInput("user-item-input-" + index)}
        >
          {input.icon()}
          <div className={styles.contInput}>
            <p className={styles.labelInput}>
              {input.label}{" "}
              {input.ref === "email" ? (
                <Tooltip
                  title={
                    verified
                      ? t("pages.profile.index.thanksVerifyEmail")
                      : t("pages.profile.index.ifNoVerifyEmail")
                  }
                  arrow
                >
                  <span className={verified ? styles.green : styles.red}>
                    {verified
                      ? t("pages.profile.index.verified")
                      : t("pages.profile.index.noVerified")}
                  </span>
                </Tooltip>
              ) : null}
            </p>
            <div className={styles.rowInputPass}>
              <input
                className={styles.input}
                placeholder={input.placeholder}
                value={vc[input.ref]}
                onChange={changeInput(input.ref)}
                type={
                  input.type === "password" && !vc.showPassword
                    ? "password"
                    : "text"
                }
                maxLength={input.ref === "email" ? 320 : 50}
                id={"user-item-input-" + index}
              />
              {input.type === "password" ? (
                <button
                  onClick={() => changeVc({ showPassword: !vc.showPassword })}
                  className={styles.eyeBtn}
                >
                  {vc.showPassword ? <VisibilityOff /> : <Visibility />}
                </button>
              ) : null}
            </div>
          </div>
        </div>
      );
    });
  };

  const returnInputsPartner = () => {
    const inputs = [
      {
        label: t("pages.profile.index.logo"),
        ref: "newLogo",
        refReal: "logo",
        icon: <AccountBox />,
      },
      {
        label: t("pages.profile.index.banner"),
        ref: "newBanner",
        refReal: "banner",
        icon: <AccountBox />,
      },
      {
        label: t("pages.profile.index.description"),
        placeholder: t("pages.profile.index.tellMoreAbout"),
        ref: "description",
        icon: <Description />,
      },
      {
        label: t("pages.profile.index.customUrl"),
        placeholder: t("pages.profile.index.contactUsToGet"),
        ref: "url",
        icon: <Link />,
      },
      {
        label: "Facebook",
        placeholder: t("pages.profile.index.onlyId"),
        ref: "social.facebook",
        icon: <Facebook />,
      },
      {
        label: "Twitter",
        placeholder: t("pages.profile.index.onlyId"),
        ref: "social.twitter",
        icon: <Twitter />,
      },
      {
        label: "Instagram",
        placeholder: t("pages.profile.index.onlyId"),
        ref: "social.instagram",
        icon: <Instagram />,
      },
      {
        label: "YouTube",
        placeholder: t("pages.profile.index.onlyId"),
        ref: "social.youtube",
        icon: <YouTube />,
      },
    ];
    return inputs.map((input, index) => {
      const isNotImage = input.refReal !== "logo" && input.refReal !== "banner";
      return (
        <div
          className={[
            styles.rowInput,
            input.ref !== "description" && input.ref !== "url"
              ? styles.rowInput50
              : "",
          ].join(" ")}
          key={"item-input-" + index}
          onClick={() => onFocusInput("parter-item-input-" + index)}
        >
          {isNotImage ? (
            input.icon
          ) : (
            <img
              alt="placeimage"
              className={styles.imagePrevInput}
              src={vc[input.refReal] || NoImage}
            />
          )}
          <div className={styles.contInput}>
            <p className={styles.labelInput}>{input.label}</p>
            <div className={styles.rowInputPass}>
              {isNotImage ? (
                <input
                  className={styles.input}
                  placeholder={input.placeholder}
                  value={
                    input.ref.includes("social.")
                      ? vc?.social?.[input.ref.replace("social.", "")]
                      : vc[input.ref]
                  }
                  onChange={changeInput(input.ref)}
                  type="text"
                  maxLength={input.ref === "description" ? 300 : 50}
                  disabled={input.ref === "url"}
                  id={"parter-item-input-" + index}
                />
              ) : (
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) =>
                    sizeImage(e.target.files[0], 5 * 1024 * 1024, () =>
                      changeVc({ [input.ref]: e.target.files[0] })
                    )
                  }
                  ref={inputFileRef}
                />
              )}
            </div>
          </div>
          {!isNotImage && vc[input.refReal] ? (
            <button
              className={styles.deleteImageBtn}
              onClick={() => deleteImagePartner(input.refReal)}
            >
              <Delete />
            </button>
          ) : null}
        </div>
      );
    });
  };

  const isColorSelected = (color) => {
    const currentColor = LocalStorage(state.isIncognito).getItem("themeColor");
    return currentColor === color;
  };

  const renderThemeColors = () => {
    const currentColor = LocalStorage(state.isIncognito).getItem("themeColor");
    const colors = [...colorsRand];
    if (currentColor) {
      colors.push("rand");
    }
    return colors.map((color, index) => (
      <button
        key={"item-color-" + index}
        style={{ backgroundColor: color }}
        className={[
          styles.colorBtn,
          isColorSelected(color) ? styles.bordered : "",
        ].join(" ")}
        onClick={() => {
          if (isColorSelected(color)) {
            LocalStorage(state.isIncognito).setItem("themeColor", color);
          } else {
            if (color === "rand") {
              LocalStorage(state.isIncognito).removeItem("themeColor");
            } else {
              LocalStorage(state.isIncognito).setItem("themeColor", color);
            }
            Model.setData("loading", true);
            window.location.reload();
          }
        }}
      >
        {color === "rand" ? <HighlightOff /> : null}
      </button>
    ));
  };

  const sendEmailVerify = async () => {
    try {
      Model.setData("loading", true);
      await AuthService.sendEmailVerification();
      Model.updateAlerts({
        message: t("pages.profile.index.emailSent"),
        variant: "success",
      });
    } catch (e) {
      Model.updateAlerts({
        message: `${t("pages.profile.index.errorEmailSent")}: ${_.get(
          e,
          "message",
          t("commons.unknowError")
        )}`,
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  const showSaveBtn = () => {
    return (
      vc.name &&
      vc.email &&
      (state?.userInfo?.name !== vc.name ||
        state?.userInfo?.email !== vc.email ||
        state?.userInfo?.phone !== vc.phone ||
        vc.password)
    );
  };

  const showSaveBtnPartner = () => {
    return (
      state?.userInfo?.description !== vc.description ||
      state?.userInfo?.social?.fabebook !== vc.social?.fabebook ||
      state?.userInfo?.social?.twitter !== vc.social?.twitter ||
      state?.userInfo?.social?.instagram !== vc.social?.instagram ||
      state?.userInfo?.social?.youtube !== vc.social?.youtube
    );
  };

  const updateMe = (data) => {
    AuthService.updateMe(state.uid, data)
      .then(() => {
        Model.updateAlerts({
          message: t("pages.profile.index.infoUpdated"),
          variant: "success",
        });
      })
      .catch((e) => {
        Model.updateAlerts({
          message: `Error: ${_.get(e, "message", t("commons.unknowError"))}`,
          variant: "error",
        });
      });
  };

  const reauthenticate = async () => {
    try {
      Model.setData("loading", true);
      await AuthService.reauthenticate(
        state?.userInfo?.email,
        vc.currentPassword
      );
      const emailLower = vc.email.toLowerCase().replace(/ /g, "");
      const data2update = { name: vc.name, phone: vc.phone };
      if (emailLower !== state?.userInfo?.email) {
        await AuthService.updateEmail(emailLower);
        data2update.email = emailLower;
      }
      if (vc.password) {
        await AuthService.updatePassword(vc.password);
      }
      updateMe(data2update);
    } catch (e) {
      console.log(e);
      Model.updateAlerts({
        message: `Error: ${showAuthError(
          _.get(e, "message", e || t("commons.unknowError"))
        )}`,
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
      changeVc({
        showPrompt: false,
        currentPassword: "",
        password: "",
        showPassword: false,
      });
    }
  };

  const saveData = () => {
    const emailLower = vc.email.toLowerCase().replace(/ /g, "");
    if (
      emailLower !== state?.userInfo?.email ||
      vc.phone !== state?.userInfo?.phone ||
      vc.password
    ) {
      if (emailLower !== state?.userInfo?.email && !verifyEmail(emailLower)) {
        Model.updateAlerts({
          message: t("pages.profile.index.badEmailFormat"),
          variant: "error",
        });
      } else if (vc.password && vc.password.length < 6) {
        Model.updateAlerts({
          message: t("pages.profile.index.passwordMustHave"),
          variant: "error",
        });
      } else {
        changeVc({ showPrompt: true, currentPassword: "" });
      }
    } else {
      updateMe({ name: vc.name });
    }
  };

  const saveDataPartner = () => {
    updateMe({ social: vc.social, description: vc.description });
  };

  const openFile = () => {
    if (inputFileRef?.current) {
      inputFileRef.current.click();
    }
  };

  const updatePartnerImages = async (file, label, current) => {
    try {
      Model.setData("loading", true);
      const referenceFile = await AuthService.updatePartnerImages(
        state.uid,
        file,
        label
      );
      updateMe({ [label]: referenceFile });
    } catch (e) {
      Model.updateAlerts({
        message: `Error: ${showAuthError(
          _.get(e, "message", t("commons.unknowError"))
        )}`,
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
      changeVc({ [current]: "" });
    }
  };

  const updateProfileImage = async () => {
    try {
      Model.setData("loading", true);
      const referenceFile = await AuthService.updateProfileImage(
        state.uid,
        vc.newImage
      );
      updateMe({ imageProfile: referenceFile });
    } catch (e) {
      Model.updateAlerts({
        message: `Error: ${showAuthError(
          _.get(e, "message", t("commons.unknowError"))
        )}`,
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
      changeVc({ newImage: "" });
    }
  };

  const linkNequiProvider = async () => {
    try {
      if (!vc.nequiClientId || !vc.nequiClientSecret || !vc.nequiXApiKey) {
        return Model.updateAlerts({
          message: t("pages.profile.index.pleaseFillAll"),
          variant: "error",
        });
      } else if (validatePaymentByRol("nequi")) {
        return Model.updateAlerts({
          message: t("pages.profile.index.ifYouWantUnlink"),
          variant: "warning",
        });
      } else if (!state.userInfo?.isPartnerVerified && selectedTab > 0) {
        Model.setData("dialog", {
          open: true,
          title: t("pages.profile.index.linkNequi"),
          text: t("pages.profile.index.beforeContinueLinkNewAccount"),
          txtRight: "Listo",
          fnRight: () => {
            Model.setData("dialog", { open: false });
          },
        });
      } else {
        Model.setData("dialog", {
          open: true,
          title: t("pages.profile.index.linkNequi"),
          text: t("pages.profile.index.rememberNequi"),
          txtLeft: t("commons.cancel"),
          fnLeft: () => {
            Model.setData("dialog", { open: false });
          },
          txtRight: t("commons.continue"),
          fnRight: () => {
            Model.setData("dialog", { open: false });
            linkNequi({
              client_id: vc.nequiClientId,
              client_secret: vc.nequiClientSecret,
              xApiKey: vc.nequiXApiKey,
              uid: state.uid,
            });
          },
        });
      }
    } catch (e) {
      console.log("Error Nequi: ", String(e));
    } finally {
      Model.setData("loading", false);
    }
  };

  const linkBitcoinProvider = async () => {
    try {
      if (validatePaymentByRol("bitcoin")) {
        return Model.updateAlerts({
          message: t("pages.profile.index.ifYouWantUnlink"),
          variant: "warning",
        });
      } else if (!vc.bitcoinAddress) {
        return Model.updateAlerts({
          message: t("pages.profile.index.pleaseFillAll"),
          variant: "error",
        });
      } else if (!state.userInfo?.isPartnerVerified && selectedTab > 0) {
        Model.setData("dialog", {
          open: true,
          title: t("pages.profile.index.linkBitcoinCash"),
          text: t("pages.profile.index.beforeContinueLinkNewAccount"),
          txtRight: t("commons.ready"),
          fnRight: () => {
            Model.setData("dialog", { open: false });
          },
        });
      } else {
        Model.setData("dialog", {
          open: true,
          title: t("pages.profile.index.linkBitcoinCash"),
          text: t("pages.profile.index.rememberBitcoinCash"),
          txtLeft: t("commons.cancel"),
          fnLeft: () => {
            Model.setData("dialog", { open: false });
          },
          txtRight: t("commons.continue"),
          fnRight: () => {
            Model.setData("dialog", { open: false });
            linkBitcoin({
              walletAddress: vc.bitcoinAddress,
              uid: state.uid,
            });
          },
        });
      }
    } catch (e) {
      console.log("Error USDT Cash: ", String(e));
    } finally {
      Model.setData("loading", false);
    }
  };

  const linkMpProvider = async () => {
    try {
      if (validatePaymentByRol("mercadopago")) {
        Model.updateAlerts({
          message: t("pages.profile.index.ifYouWantUnlink"),
          variant: "warning",
        });
        // return window.open("https://www.mercadopago.com.co/home").focus();
      } else if (!state.userInfo?.isPartnerVerified && selectedTab > 0) {
        Model.setData("dialog", {
          open: true,
          title: t("pages.profile.index.linkMercadoPago"),
          text: t("pages.profile.index.beforeContinueLinkMercadoPago"),
          txtRight: t("commons.ready"),
          fnRight: () => {
            Model.setData("dialog", { open: false });
          },
        });
      } else {
        linkMp();
      }
    } catch (e) {
      console.log("Error link: ", String(e));
    } finally {
      Model.setData("loading", false);
    }
  };

  const validatePaymentByRol = (payment = "") => {
    if (selectedTab === 0) {
      return state?.userInfo?.secret?.[payment];
    } else if (selectedTab === 2) {
      return (
        state?.userInfo?.secret?.[payment] && state?.userInfo?.secret?.verified
      );
    }
  };

  const renderBenefitsInfo = () => {
    if (state?.userInfo?.promoterCode?.percent) {
      return (
        <>
          <p className={styles.labelSection}>
            {"🎉"} {t("pages.profile.index.activatedBenefits")}
          </p>
          <div className={styles.rowBenefit}>
            <p className={styles.nameBenefit}>
              {t("pages.profile.index.preferentialCommissionRate")}
            </p>
            <p className={styles.valueBenefit}>
              {state?.userInfo?.promoterCode?.percent}{" "}
              <span className={styles.badgeBenefit}>%</span>
            </p>
            <p className={styles.descriptionBenefit}>
              {t("pages.profile.index.promoterDiscountDescription")}
            </p>
          </div>
        </>
      );
    }
  };

  const renderLinkAccounts = () => {
    return (
      <>
        <p className={styles.labelSection}>
          {"🔗"} {t("pages.profile.index.linkAccounts")}
        </p>
        <p className={styles.descriptionSection}>
          {t("pages.profile.index.allInfoAboutLinkAccounts")}
        </p>
        {process.env.NODE_ENV === "development" && (
          <>
            {" "}
            <p className={styles.labelSection}>FROIN</p>
            <p className={styles.descriptionSection}>
              <Trans
                i18nKey="pages.profile.index.remeberWhatFroin"
                components={{ bold: <b /> }}
              />
            </p>
            <button
              className={[
                styles.saveBtn,
                validatePaymentByRol("froin") ? "" : styles.themeColor,
              ].join(" ")}
              // onClick={linkMpProvider}
            >
              {validatePaymentByRol("froin")
                ? t("pages.profile.index.alreadyLink")
                : t("pages.profile.index.linkYour")}
              {t("pages.profile.index.yourAccount")} FROIN
            </button>
          </>
        )}

        <p className={styles.labelSection}>Mercado pago</p>
        <p className={styles.descriptionSection}>
          <Trans
            i18nKey="pages.profile.index.remeberWhatMercadoPago"
            components={{ bold: <b /> }}
          />
        </p>
        <button
          className={[
            styles.saveBtn,
            validatePaymentByRol("mercadopago") ? "" : styles.themeColor,
          ].join(" ")}
          onClick={linkMpProvider}
        >
          {validatePaymentByRol("mercadopago")
            ? t("pages.profile.index.alreadyLink")
            : t("pages.profile.index.linkYour")}
          {t("pages.profile.index.yourAccount")} Mercado Pago
        </button>

        {process.env.NODE_ENV === "development" ? (
          <>
            <p className={styles.labelSection}>Nequi</p>
            {validatePaymentByRol("nequi") ? null : (
              <>
                <div className={styles.rowInputsWrap}>
                  <div className={styles.rowInput}>
                    <div className={styles.contInput}>
                      <p className={styles.labelInput}>Client ID</p>
                      <div className={styles.rowInputPass}>
                        <input
                          className={styles.input}
                          placeholder={
                            t("pages.profile.index.typeHereYour") + " Client ID"
                          }
                          value={vc.nequiClientId}
                          onChange={changeInput("nequiClientId")}
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.rowInput}>
                    <div className={styles.contInput}>
                      <p className={styles.labelInput}>Client secret</p>
                      <div className={styles.rowInputPass}>
                        <input
                          className={styles.input}
                          placeholder={
                            t("pages.profile.index.typeHereYour") +
                            " Client secret"
                          }
                          value={vc.nequiClientSecret}
                          onChange={changeInput("nequiClientSecret")}
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.rowInput}>
                    <div className={styles.contInput}>
                      <p className={styles.labelInput}>X Api Key</p>
                      <div className={styles.rowInputPass}>
                        <input
                          className={styles.input}
                          placeholder={
                            t("pages.profile.index.typeHereYour") + " X Api Key"
                          }
                          value={vc.nequiXApiKey}
                          onChange={changeInput("nequiXApiKey")}
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            <button
              className={[
                styles.saveBtn,
                validatePaymentByRol("nequi") ? "" : styles.themeColor,
              ].join(" ")}
              onClick={linkNequiProvider}
            >
              {validatePaymentByRol("nequi")
                ? t("pages.profile.index.alreadyLink")
                : t("pages.profile.index.linkYour")}
              {t("pages.profile.index.yourAccount")} Nequi
            </button>

            <p className={styles.labelSection}>USDT wallet</p>
            <p className={styles.descriptionSection}>
              <Trans
                i18nKey="pages.profile.index.allUsersPayCryptos"
                components={{ bold: <b /> }}
              />
            </p>
            {validatePaymentByRol("bitcoin") ? (
              <p className={styles.descriptionSection}>
                {t("pages.profile.index.walletAddressLinked")}:{" "}
                <b>{state.userInfo.secret.bitcoin.walletAddress}</b>
              </p>
            ) : (
              <>
                <div className={styles.rowInputsWrap}>
                  <div className={styles.rowInput}>
                    <div className={styles.contInput}>
                      <p className={styles.labelInput}>
                        {t("pages.profile.index.walletAddress")}
                      </p>
                      <div className={styles.rowInputPass}>
                        <input
                          className={styles.input}
                          placeholder={t("pages.profile.index.typeHereAddress")}
                          value={vc.bitcoinAddress}
                          onChange={changeInput("bitcoinAddress")}
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            <button
              className={[
                styles.saveBtn,
                validatePaymentByRol("bitcoin") ? "" : styles.themeColor,
              ].join(" ")}
              onClick={linkBitcoinProvider}
            >
              {validatePaymentByRol("bitcoin")
                ? t("pages.profile.index.alreadyLink")
                : t("pages.profile.index.linkYour")}
              {t("pages.profile.index.yourAccount")} USDT wallet
            </button>
          </>
        ) : null}
      </>
    );
  };

  const renderViews = () => {
    if (selectedTab === 0) {
      return (
        <>
          <p className={styles.labelSection}>
            {"📋"} {t("pages.profile.index.personalInfo")}
          </p>
          {returnInputs()}
          <div className={styles.rowBtns}>
            {!state?.emailVerified ? (
              <button className={styles.verifyBtn} onClick={sendEmailVerify}>
                {t("pages.profile.index.verifyEmail")}
              </button>
            ) : null}
            <button
              className={[
                styles.saveBtn,
                showSaveBtn() ? styles.themeColor : "",
              ].join(" ")}
              onClick={saveData}
              disabled={!showSaveBtn()}
            >
              {showSaveBtn()
                ? t("pages.profile.index.saveNewData")
                : t("pages.profile.index.yourDataIsUpdated")}
            </button>
          </div>
          {renderLinkAccounts()}
        </>
      );
    } else if (selectedTab === 1) {
      return (
        <>
          <p className={styles.labelSection}>
            {"🎉"} {t("pages.profile.index.referProgram")}
          </p>
          {state?.userInfo?.acceptPromoCode ? (
            <SwitchBoth
              onChange={(value) => setFilterPromo(value)}
              value={filterPromo}
              values={[
                { label: t("pages.profile.index.byTickets"), value: "tickets" },
                { label: t("pages.profile.index.byClients"), value: "clients" },
              ]}
              className={styles.switchContainer}
            />
          ) : null}
          {filterPromo === "tickets" || !state?.userInfo?.acceptPromoCode ? (
            <>
              {state?.userInfo?.acceptedReferProgram ? (
                <div className={styles.containerStatsRefer}>
                  <p className={styles.titleStatsRefer}>
                    {t("pages.profile.index.youHaveInAccount")}
                  </p>
                  <div className={styles.quantityStatsRefer}>
                    {currencyFormat(
                      state?.userInfo?.referStats?.earnedQuantity || 0,
                      "COP",
                      0
                    )}{" "}
                    COP
                  </div>
                  <p className={styles.descriptionStatsRefer}>
                    {t("pages.profile.index.untilToday")}{" "}
                    <b>
                      {state?.userInfo?.referStats?.referedQuantity || 0} ticket
                      {state?.userInfo?.referStats?.referedQuantity === 1
                        ? ""
                        : "s"}
                    </b>{" "}
                    {t("pages.profile.index.confirmed")}
                    {state?.userInfo?.referStats?.referedQuantity === 1
                      ? ""
                      : i18n.language === "en"
                      ? ""
                      : "s"}
                    .
                  </p>
                  <div className={styles.separatorStatsRefer} />
                  <p className={styles.titleStatsRefer}>
                    {t("pages.profile.index.howCanRefer")}
                  </p>
                  <img
                    src={require("../../assets/images/dummy/refer_event.png")}
                    alt="refer_event"
                    className={styles.imageDummyStatsRefer}
                  />
                  <p className={styles.descriptionStatsRefer}>
                    <Trans
                      i18nKey="pages.profile.index.inEachEvent"
                      components={{ bold: <b />, break: <br /> }}
                    />{" "}
                    <a href="mailto:help@fravents.com">help@fravents.com</a>{" "}
                    {t("pages.profile.index.toRequest")}
                  </p>
                </div>
              ) : (
                <div className={styles.referCard}>
                  <div className={styles.infoContainerRefer}>
                    <p className={styles.titleRefer}>
                      {t("pages.profile.index.referEventsEarn")}
                    </p>
                    <p className={styles.descriptionRefer}>
                      {t("pages.profile.index.weWantYouEarn")}{" "}
                      <a
                        href={returnCurrentLink(
                          Routes.RENDER_LEGAL_DOC + "?document=promoter"
                        )}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t("pages.profile.index.termsConditions")}
                      </a>{" "}
                      {t("pages.profile.index.andAvailableFunction")}
                    </p>
                    <button
                      className={styles.buttonRefer}
                      onClick={onAcceptReferTerms}
                    >
                      {t("pages.profile.index.acceptTC")}
                    </button>
                  </div>
                  <img
                    src={AvatarImages.party}
                    alt="refer"
                    className={styles.imageRefer}
                  />
                </div>
              )}
            </>
          ) : (
            <>
              <div className={styles.containerStatsRefer}>
                <p className={styles.titleStatsRefer}>
                  {t("pages.profile.index.youHaveInAccount")}
                </p>
                <div className={styles.quantityStatsRefer}>
                  {currencyFormat(
                    state?.userInfo?.referStats?.earnedClientsQuantity || 0,
                    "COP",
                    0
                  )}{" "}
                  COP
                </div>
                <p className={styles.descriptionStatsRefer}>
                  {t("pages.profile.index.lastUpdated")}{" "}
                  <b>
                    {moment(
                      state?.userInfo?.referStats?.referedDate,
                      "x"
                    ).format("DD MMM YYYY")}
                  </b>
                </p>
                <div className={styles.separatorStatsRefer} />
                <p className={styles.titleStatsRefer}>
                  {t("pages.profile.index.howCanRefer")}
                </p>
                <div className={styles.rowStats}>
                  <div className={styles.itemRowStats}>
                    <p className={styles.descriptionStatsRefer}>
                      <br />
                      {t("pages.profile.index.thisIsYourCode")}
                    </p>
                    <p className={styles.code2ref}>
                      {state?.userInfo?.referStats?.code2ref || "-"}
                    </p>
                  </div>
                  <div className={styles.itemRowStats}>
                    <p className={styles.descriptionStatsRefer}>
                      <br />
                      {t("pages.profile.index.yourReferralsReceive")}
                    </p>
                    <p className={styles.code2ref}>
                      {state?.userInfo?.referStats?.percentCode2ref || "0"}%
                    </p>
                  </div>
                </div>
                <img
                  src={require("../../assets/images/dummy/refcode.gif")}
                  alt="refer_event"
                  className={styles.imageDummyStatsRefer}
                />
                <p className={styles.descriptionStatsRefer}>
                  <Trans
                    i18nKey="pages.profile.index.sureEnterCode"
                    components={{ bold: <b />, break: <br /> }}
                  />{" "}
                  <a href="mailto:help@fravents.com">help@fravents.com</a>{" "}
                  {t("pages.profile.index.toRequest")}
                </p>
              </div>
            </>
          )}
        </>
      );
    } else if (state?.userInfo?.isPartner && selectedTab === 2) {
      return (
        <>
          <p className={styles.labelSection}>
            {"📋"} {t("pages.profile.index.partnerData")}
            {" - "}
            {state.userInfo?.isPartnerVerified
              ? t("pages.profile.index.iconVerified")
              : t("pages.profile.index.iconNoVerified")}
          </p>
          <p className={styles.descriptionSection}>
            {t("pages.profile.index.weGiveYouPage")}
          </p>
          <div className={styles.rowInputsWrap}>{returnInputsPartner()}</div>

          <div className={styles.rowBtns}>
            <button
              className={[styles.saveBtn, styles.themeColor].join(" ")}
              onClick={() =>
                window.open(
                  `http${process.env.NODE_ENV === "production" ? "s" : ""}://${
                    window?.location?.host
                  }/${vc.url || `partnerProfile?ref=${state.uid}`}`,
                  "blank"
                )
              }
            >
              <span>{t("pages.profile.index.viewPage")}</span>
            </button>
            <button
              className={[
                styles.saveBtn,
                showSaveBtnPartner() ? styles.themeColor : "",
              ].join(" ")}
              onClick={saveDataPartner}
              disabled={!showSaveBtnPartner()}
            >
              {showSaveBtnPartner()
                ? t("pages.profile.index.savePartnerData")
                : t("pages.profile.index.yourDataIsUpdated")}
            </button>
          </div>
          {renderBenefitsInfo()}
          {renderLinkAccounts()}
        </>
      );
    } else if (selectedTab === 3) {
      return (
        <>
          <p className={styles.labelSection}>
            {"🔧"} {t("pages.profile.index.settings")}
          </p>
          <div className={styles.rowInput}>
            <MonetizationOn />
            <div className={styles.contInput}>
              <p className={styles.labelInput}>
                {t("pages.profile.index.currency")}
              </p>
              <div className={styles.rowOptions}>{renderCurrencies()}</div>
            </div>
          </div>
          <div className={styles.rowInput}>
            <Language />
            <div className={styles.contInput}>
              <p className={styles.labelInput}>
                {t("pages.profile.index.language")}
              </p>
              <div className={styles.rowOptions}>{renderLanguages()}</div>
            </div>
          </div>
          <div className={styles.rowInput}>
            <Palette />
            <div className={styles.contInput}>
              <p className={styles.labelInput}>
                {t("pages.profile.index.themeColor")}
              </p>
              <div className={styles.rowOptions}>{renderThemeColors()}</div>
            </div>
          </div>
        </>
      );
    }
  };

  function handleWindowSizeChange() {
    const isPresetView = !!(viewUrlParam || urlParams.get("tab"));
    const deviceIsMobile = window.innerWidth <= 780;
    setIsMobile(deviceIsMobile);
    setShowContentView(isPresetView);
    setSelectedTab(
      deviceIsMobile && !isPresetView
        ? null
        : isNaN(parseInt(urlParams.get("tab")))
        ? 0
        : parseInt(urlParams.get("tab"))
    );
  }
  React.useEffect(() => {
    handleWindowSizeChange();
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
    //eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (vc.newLogo) {
      updatePartnerImages(vc.newLogo, "logo", "newLogo");
    }
    //eslint-disable-next-line
  }, [vc.newLogo]);

  React.useEffect(() => {
    if (vc.newBanner) {
      updatePartnerImages(vc.newBanner, "banner", "newBanner");
    }
    //eslint-disable-next-line
  }, [vc.newBanner]);

  React.useEffect(() => {
    if (vc.newImage) {
      updateProfileImage();
    }
    //eslint-disable-next-line
  }, [vc.newImage]);

  React.useEffect(() => {
    if (state.userInfo) {
      changeVc(state.userInfo);
    }
    //eslint-disable-next-line
  }, [state.userInfo]);

  React.useEffect(() => {
    // AuthService.getMe(state.uid)
    //   .then(() => {})
    //   .catch(() => {});
    //eslint-disable-next-line
  }, []);

  return (
    <Layout blackHeader>
      {isMobile && showContentView ? (
        <button
          className={styles.returnButton}
          onClick={() => setShowContentView(false)}
        >
          <ArrowBack />
          <span>{t("pages.profile.index.return")}</span>
        </button>
      ) : null}
      <div className={styles.rowContent}>
        {!isMobile || (isMobile && !showContentView) ? (
          <div className={styles.menuNavigation}>
            <div className={styles.contBanner}>
              <Tooltip arrow title={t("pages.profile.index.maxFileSize")}>
                <button className={styles.changeImgBtn} onClick={openFile}>
                  <img
                    alt="profile"
                    className={styles.imgProfile}
                    src={state?.userInfo?.imageProfile || Avatar}
                  />
                  <div className={styles.iconChangePhoto}>
                    <AddAPhoto />
                  </div>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) =>
                      sizeImage(e.target.files[0], 5 * 1024 * 1024, () =>
                        changeVc({ newImage: e.target.files[0] })
                      )
                    }
                    className={styles.inputImage}
                    ref={inputFileRef}
                  />
                </button>
              </Tooltip>
              <p className={styles.nameUser}>
                {state?.userInfo?.name || t("pages.profile.index.hereYourName")}
              </p>
              <p className={styles.emailUser}>
                {state?.userInfo?.email ||
                  t("pages.profile.index.hereYourEmail")}
              </p>
            </div>

            <div className={styles.listItemsNavigation}>
              {renderItemsNavigation()}
            </div>
          </div>
        ) : null}
        {!isMobile || (isMobile && showContentView) ? (
          <div className={styles.scrollCont}>
            <div className={styles.contForm}>{renderViews()}</div>
          </div>
        ) : null}
      </div>

      <DialogForm
        show={vc.showPrompt}
        title={t("pages.profile.index.enterCurrentPassword")}
        text={t("pages.profile.index.toUpdateWeNeedPassword")}
        leftBtText={t("commons.cancel")}
        funcLeft={() => changeVc({ showPrompt: false, currentPassword: "" })}
        rightBtText={t("commons.accept")}
        funcRight={reauthenticate}
        inputs={[
          {
            label: t("commons.password"),
            value: "currentPassword",
            type: "password",
          },
        ]}
        vc={vc}
        changeVc={changeVc}
      />
    </Layout>
  );
};

export default Profile;
