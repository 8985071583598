import { makeStyles } from "@material-ui/core/styles";
import { colors } from "../../styleguide";
import Commons from "../../utils/styles";

const useStyles = makeStyles({
  container: {
    ...Commons.flexRow,
    alignItems: "flex-start",
    flexWrap: "wrap",
    padding: "0 12px",
    width: "calc(100% - 24px)",
    gap: 30,
    marginBottom: 30,
    [Commons.smallQuery]: {
      flexDirection: "column",
      display: "none",
    },
  },
  mobile: {
    display: "none",
    [Commons.smallQuery]: {
      display: "block",
      marginBottom: -12,
    },
  },
  contCarousel: {
    width: "100%",
  },
  rowTwoFeatures: {
    ...Commons.flexCenter,
    flexDirection: "column",
    gap: 12,
    paddingBottom: 36,
  },
  feature: {
    ...Commons.flexRow,
    width: "calc((100% / 3) - 20px)",
    alignItems: "flex-start",
    [Commons.smallQuery]: {
      width: "100%",
      alignItems: "center",
    },
  },
  imageContainer: {
    ...Commons.flexCenter,
    backgroundColor: colors.black,
    border: `4px solid ${colors.themeColor}`,
    borderRadius: "50%",
    minWidth: 54,
    minHeight: 54,
    boxShadow: colors.shadow,
    "& svg": {
      color: colors.white,
      fontSize: 30,
    },
  },
  infoContainer: {
    flex: 1,
    marginLeft: 18,
  },
  title: {
    margin: "0 0 6px 0",
    fontWeight: "700",
    fontSize: 20,
    color: colors.black,
  },
  description: {
    margin: 0,
    fontSize: 16,
    color: colors.black,
  },
  moreButton: {
    ...Commons.defaultButton,
    ...Commons.flexCenter,
    backgroundColor: colors.themeColor,
    color: colors.white,
    textAlign: "center",
    padding: "30px 0",
    boxShadow: colors.shadow,
    fontSize: 24,
    width: "100%",
    borderRadius: 18,
    fontWeight: "600",
    textDecoration: "none",
    "&:hover": {
      opacity: 0.75,
    },
    "& svg": {
      fontSize: 30,
    },
  },
  rowRoles: {
    ...Commons.flexRow,
    alignItems: "flex-start",
    width: "calc(100% - 24px)",
    gap: 24,
    padding: 12,
    [Commons.smallQuery]: {
      flexDirection: "column",
    },
  },
  containerRole: {
    ...Commons.flexColumn,
    flex: 1,
    backgroundColor: colors.white,
    boxShadow: colors.shadow,
    borderRadius: 30,
    border: `4px solid ${colors.grayLight}`,
    padding: 12,
    minHeight: 360,
    justifyContent: "space-between",
    transition: "all 0.2s",
    position: "relative",
    "&:hover": {
      backgroundColor: colors.black,
      borderColor: colors.themeColor,
      "& h4": {
        color: colors.white,
      },
      "& p": {
        color: colors.white,
      },
      "& img": {
        transform: "scale(1.1)",
      },
    },
    [Commons.smallQuery]: {
      flexDirection: "row",
      minHeight: "unset",
      width: "calc(100% - 32px)",
    },
  },
  imageRole: {
    height: 120,
    width: "auto",
    transition: "all 0.2s",
    [Commons.smallQuery]: {
      height: 90,
      position: "absolute",
      top: -30,
      left: -12,
    },
  },
  infoRole: {
    ...Commons.flexColumn,
    justifyContent: "space-between",
    flex: 1,
  },
  labelRole: {
    textAlign: "center",
    fontSize: 22,
    fontWeight: "600",
    margin: "12px 0",
    color: colors.black,
    [Commons.smallQuery]: {
      fontSize: 18,
    },
  },
  textRole: {
    textAlign: "center",
    fontSize: 16,
    margin: "0 0 24px 0",
    color: colors.black,
    [Commons.smallQuery]: {
      fontSize: 14,
    },
  },
  buttonRole: {
    ...Commons.defaultButton,
    textAlign: "center",
    backgroundColor: colors.themeColor,
    color: colors.white,
    padding: "12px 24px",
    marginBottom: 12,
    borderRadius: 30,
    fontSize: 14,
    fontWeight: "600",
    width: "calc(100% - 48px)",
    textDecoration: "none",
    [Commons.smallQuery]: {
      marginBottom: 0,
    },
  },
});

export default useStyles;
