import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

import pageStyles from "./styles/detailMemberStyles";
import Avatar from "../../assets/images/avatar.jpeg";
import AdminService from "../../services/admin";
import Model from "../../hooks/Model";
import Layout from "../layout";

const DetailMember = ({ history, location, hideLayout }) => {
  const { t } = useTranslation();
  const styles = pageStyles();
  const userSelected = location?.state?.userSelected;
  const event = location?.state?.event;

  const [vc, setVc] = React.useState({
    activity: [],
    userSelected: userSelected || {},
  });
  const changeVc = (data) => setVc((ov) => ({ ...ov, ...data }));

  const renderActivity = () => {
    if (vc.activity.length < 1) {
      return (
        <p className={styles.labelEmpty}>
          {t("pages.admin.detailMember.emptyRegisters")}
        </p>
      );
    }
    return vc.activity.map((ticket, index) => (
      <div
        key={"item-promoter-" + index}
        className={styles.itemSale}
        onClick={() => {
          Model.setData("ticketSelected", {
            uid: ticket.uid,
            hideQr: true,
            isAdmin: true,
            data: {
              ...ticket,
            },
            assignedPromoter: userSelected,
          });
          history.push("/detailTickets");
        }}
      >
        <div className={styles.nameItemSale}>
          {ticket?.client?.name || "-"}
          <br />
          <span>
            {ticket?.client?.email ||
              t("pages.admin.detailMember.withoutEmail")}
          </span>
        </div>
        {ticket?.activityType === "promoter" ? (
          <div className={styles.ticketsItemSale}>
            {t("pages.admin.detailMember.refer")}
          </div>
        ) : null}
        {ticket?.activityType === "logistic" ? (
          <div className={styles.ticketsItemSale}>
            {t("pages.admin.detailMember.scaned")}
          </div>
        ) : null}
        <div className={styles.dateItemSale}>
          {moment(ticket.created, "x").format("YYYY-MM-DD HH:mm")}
        </div>
      </div>
    ));
  };

  const getInfoMember = async () => {
    try {
      Model.setData("loading", true);
      const dataUser = await AdminService.getInfoMember({
        eventUid: event?.uid,
        userId: userSelected?.uid,
      });
      changeVc({
        activity: dataUser.activity,
        userSelected: {
          ...dataUser.userInfo,
          uid: userSelected?.uid,
        },
      });
    } catch (e) {
      Model.updateAlerts({
        message: t("pages.admin.detailMember.errorGetInfo") + String(e),
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  const returnQuantity = (role) => {
    const filteredData = vc.activity?.filter?.((a) => a.activityType === role);
    let quantity = 0;
    if (role === "promoter") {
      filteredData.forEach((data) => {
        quantity += data?.tickets?.length || 0;
      });
    }
    if (role === "logistic") {
      quantity = filteredData?.length;
    }
    return quantity;
  };

  React.useEffect(() => {
    if (userSelected) {
      getInfoMember();
    } else {
      history.push("/");
    }
    //eslint-disable-next-line
  }, []);

  const Content = () => (
    <>
      <div className={styles.container}>
        <img
          src={userSelected?.imageProfile || Avatar}
          className={styles.imgProfile}
          alt="profile"
        />
        <div className={styles.contInfo}>
          <p>
            <b>{t("commons.name")}: </b>
            {userSelected?.name || "-"}
          </p>
          <p>
            <b>{t("commons.email")}: </b>
            {userSelected?.email || "-"}
          </p>
          <p>
            <b>{t("commons.phone")}: </b>
            {userSelected?.phone || "-"}
          </p>
        </div>
      </div>
      <div className={[styles.container, styles.containerActivity].join(" ")}>
        <p className={styles.titleActivity}>
          {t("pages.admin.detailMember.history")}
        </p>
        <p className={styles.textActivity}>
          {t("pages.admin.detailMember.refers")}: {returnQuantity("promoter")}{" "}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          {t("pages.admin.detailMember.scaneds")}: {returnQuantity("logistic")}
        </p>
        <div className={styles.listActivity}>{renderActivity()}</div>
      </div>
    </>
  );

  if (hideLayout) {
    return <Content />;
  }

  return (
    <Layout>
      <Content />
    </Layout>
  );
};

export default DetailMember;
