import { makeStyles } from "@material-ui/styles";
import Commons from "../../../utils/styles";
import { colors } from "../../../styleguide";

const styles = makeStyles({
  container: {
    margin: "60px auto",
    border: `3px solid ${colors.themeColor}`,
    padding: 24,
    width: "calc(90% - 48px)",
    maxWidth: 900,
    borderRadius: 12,
  },
  title: {
    fontSize: 24,
    color: colors.black,
    fontWeight: "700",
    margin: 0,
  },
  description: {
    fontSize: 18,
    color: colors.black,
    margin: "12px 0",
  },
  codeContainer: {
    backgroundColor: colors.grayLight,
    padding: 12,
    borderRadius: 12,
    boxShadow: colors.shadow,
    margin: "12px 0",
    position: "relative",
  },
  code: {
    fontSize: 18,
    fontStyle: "italic",
    wordBreak: "break-all",
  },
  floatContainer: {
    ...Commons.flexCenter,
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: colors.grayLight,
    top: 0,
    left: 0,
  },
  floatButton: {
    ...Commons.defaultButton,
    backgroundColor: colors.themeColor,
    color: colors.white,
    fontSize: 18,
    padding: 12,
    borderRadius: 12,
  },
  bgOpacity: {
    backgroundColor: `${colors.grayLight}90`,
  },
  disableButton: {
    ...Commons.defaultButton,
    backgroundColor: colors.red,
    color: colors.white,
    textAlign: "center",
    padding: "12px 0",
    borderRadius: 12,
    marginTop: 18,
    width: "100%",
    fontSize: 18,
  },
  link: {
    color: colors.themeColor,
    fontWeight: "700",
  },
});

export default styles;
