import React from "react";
import pageStyles from "./styles";
import TextImageBanner from "../TextImageBanner";

const ActionsBanner = ({ banners = [], className = "" }) => {
  const styles = pageStyles();

  const renderHeaderBanners = () => {
    return banners.map((banner) => (
      <TextImageBanner
        key={banner.title + banner.description}
        className={[
          styles.textImageBanner,
          banner?.onlyDesktop ? styles.hideMobile : "",
          banner?.onlyMobile ? styles.hideDesktop : "",
        ].join(" ")}
        {...banner}
      />
    ));
  };

  return (
    <div className={[styles.rowBanners, className].join(" ")}>
      {renderHeaderBanners()}
    </div>
  );
};

export default ActionsBanner;
