import { makeStyles } from "@material-ui/styles";
import Commons from "../../../utils/styles";
import { colors } from "../../../styleguide";

const styles = makeStyles({
  container: {
    margin: 0,
    padding: 0,
    height: "100%",
    overflow: "hidden",
    backgroundColor: "white",
    maxWidth: "100vw",
    display: "flex",
    flexDirection: "row",
  },
  pluginLayout: {
    border: `6px solid ${colors.themeColor}`,
    borderRadius: 24,
    height: "calc(100% - 12px)",
  },
  contAll: {
    flex: 1,
    width: "calc(100% - 71px)",
  },
  selectEventLabel: {
    width: "100%",
    backgroundColor: colors.black,
    color: colors.white,
    textAlign: "center",
    fontSize: 24,
    padding: "24px 0",
    fontWeight: "700",
    marginBottom: -60,
    zIndex: 2,
    position: "relative",
    "& span": {
      fontSize: 15,
      fontWeight: "500",
      cursor: "pointer",
    },
  },
  floatLogoContainer: {
    ...Commons.centerHorizontal,
    ...Commons.flexCenter,
    position: "absolute",
    bottom: -30,
    backgroundColor: colors.black,
    padding: "0px 18px",
    borderRadius: 36,
    cursor: "pointer",
    "& span": {
      color: colors.white,
      fontSize: 16,
    },
    "& img": {
      height: 42,
      marginLeft: 6,
    },
  },
  floatLeftBottom: {
    position: "fixed",
    left: 12,
    bottom: 12,
    transform: "none",
  },
  btLogout: {
    padding: "6px 18px",
    backgroundColor: colors.black,
    "& span": {
      fontWeight: "700",
    },
  },
  contentDash: {
    width: "100%",
    height: "calc(100% - 90px)",
    overflow: "auto",
    paddingTop: 90,
    [Commons.smallQuery]: {
      height: "calc(100% - 78px)",
      paddingTop: 78,
    },
  },
  contentChildren: {
    width: "100%",
    minHeight: "75%",
    [Commons.smallQuery]: {
      minHeight: "100vh",
    },
  },
  blackHeader: {
    backgroundColor: colors.black,
    height: 89,
    width: "100%",
    marginTop: -89,
  },
  childrenNoFooter: {
    minHeight: "calc(100vh - 71px)",
  },
  paddingFooter: {
    height: 72,
  },
  blankTabs: {
    [Commons.smallQuery]: {
      minHeight: 90,
      width: "100%",
      backgroundColor: colors.black,
    },
  },
  hasNavigationEvent: {
    height: "calc(100% - 140px)",
  },
});

export default styles;
