import React from "react";
import { CloudUpload, Delete } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import * as XLSX from "xlsx";

import AdminService from "../../services/admin";
import { sizeImage } from "../../utils/validations";
import Model from "../../hooks/Model";
import pageStyles from "./styles";

const AddTicketsList = ({
  inputFileRef,
  onCancel = () => {},
  onConfirm = () => {},
  event = {},
}) => {
  const { t, i18n } = useTranslation();
  const styles = pageStyles();
  const [newTickets, setNewTickets] = React.useState([]);

  const labelsExcel = [
    t("components.addTicketsList.name"),
    t("components.addTicketsList.lastName"),
    t("components.addTicketsList.numId"),
    t("components.addTicketsList.email"),
    t("components.addTicketsList.typeTicket"),
    t("components.addTicketsList.quantity"),
  ];

  const labelEquity = {
    [t("components.addTicketsList.name")]: "name",
    [t("components.addTicketsList.lastName")]: "lastName",
    [t("components.addTicketsList.numId")]: "numId",
    [t("components.addTicketsList.email")]: "email",
    [t("components.addTicketsList.typeTicket")]: "label",
    [t("components.addTicketsList.quantity")]: "quantity",
  };

  const downloadFormat = () => {
    Model.setData("dialog", { open: false });
    window
      .open(
        `https://fravents.com/files/${
          i18n.language === "en"
            ? "FRAVETS_Format_upload_tickets_sells"
            : "FRAVETS_Formato_para_subir_compras"
        }.xlsx`,
        "_blank"
      )
      .focus();
  };

  const showAlertUpload = () => {
    Model.setData("dialog", {
      open: true,
      title: t("components.addTicketsList.alertUploadInfo"),
      text: t("components.addTicketsList.rememberUploadInfo"),
      txtAux: t("commons.format"),
      fnAux: downloadFormat,
      txtLeft: t("commons.cancel"),
      fnLeft: () => Model.setData("dialog", { open: false }),
      txtRight: t("commons.yesConfirm"),
      fnRight: () => {
        Model.setData("dialog", { open: false });
        if (inputFileRef?.current) {
          inputFileRef.current.click();
        }
      },
    });
  };

  const onUpload = async () => {
    try {
      Model.setData("loading", true);
      Model.setData("dialog", { open: false });

      const data2send = newTickets.map((ticketInfo) => {
        const newInfo = {};
        labelsExcel.forEach((labelInfo) => {
          newInfo[labelInfo] = ticketInfo[labelEquity[labelInfo]];
        });
        return newInfo;
      });
      const dataSales = await AdminService.uploadNewTickets({
        eventUid: event.uid,
        data: data2send,
      });
      onConfirm();
      Model.updateAlerts({
        message: dataSales,
        variant: "warning",
      });
      setNewTickets([]);
      onCancel();
    } catch (e) {
      Model.updateAlerts({
        message:
          t("components.addTicketsList.errorUploadList") +
          (e?.message || String(e)),
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  const showConfirmMessage = () => {
    if (newTickets.length > 0) {
      Model.setData("dialog", {
        open: true,
        title: t("components.addTicketsList.confirmBuys"),
        text: t("components.addTicketsList.rememberConfirmBuys"),
        txtLeft: t("commons.cancel"),
        fnLeft: () => Model.setData("dialog", { open: false }),
        txtRight: t("commons.agree"),
        fnRight: onUpload,
      });
    } else {
      Model.updateAlerts({
        message: t("components.addTicketsList.firstUploadFile"),
        variant: "warning",
      });
    }
  };

  const showCancelMessage = () => {
    if (newTickets.length > 0) {
      Model.setData("dialog", {
        open: true,
        title: t("components.addTicketsList.cancelProcess"),
        text: t("components.addTicketsList.confirmCancelProcess"),
        txtLeft: t("commons.cancel"),
        fnLeft: () => Model.setData("dialog", { open: false }),
        txtRight: t("commons.yesConfirm"),
        fnRight: () => {
          onCancel();
          Model.setData("dialog", { open: false });
        },
      });
    } else {
      onCancel();
    }
  };

  const uploadNewTickets = async (data2send) => {
    try {
      if (data2send && data2send?.length > 0) {
        Model.setData("loading", true);
        let countErrors = 0;
        const dataToUpload = data2send
          .map((beforeInfo) => {
            const newInfo = {};
            labelsExcel.forEach((labelInfo) => {
              newInfo[labelEquity[labelInfo]] = beforeInfo[labelInfo];
            });
            return newInfo;
          })
          .filter((dataTicket) => {
            let flag2upload = true;
            let labelExists = false;
            // Verify if doesn't have empty felds
            labelsExcel.forEach((labelInfo) => {
              if (!dataTicket[labelEquity[labelInfo]]) {
                flag2upload = false;
              }
            });
            // Verify if label of ticket is correct
            if (event?.prices?.length > 0) {
              event.prices.forEach(({ label }) => {
                if (label === dataTicket?.label) {
                  labelExists = true;
                }
              });
            }
            //Count errors
            if (!(flag2upload && labelExists)) {
              countErrors++;
            }

            return flag2upload && labelExists;
          });

        setNewTickets(dataToUpload);

        if (inputFileRef?.current) {
          inputFileRef.current.value = "";
          inputFileRef.current.type = "text";
          inputFileRef.current.type = "file";
        }

        if (countErrors > 0) {
          throw new Error(
            t("components.addTicketsList.thereSomeErrors", {
              countErrors: countErrors,
              multiple: countErrors === 1 ? "" : "s",
            })
          );
        }
      } else {
        throw new Error(t("components.addTicketsList.noFoundValidTicket"));
      }
    } catch (e) {
      Model.updateAlerts({
        message:
          t("components.addTicketsList.errorUploadExcel") +
          (e?.message || String(e)),
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  const uploadExcel = (e) => {
    sizeImage(e.target.files[0], 5 * 1024 * 1024, () => {
      const reader = new FileReader();
      reader.onload = function (evt) {
        const data = evt.target.result;
        const datalog = XLSX.read(data, { type: "binary" });
        const firstSheetName = datalog.SheetNames[0];
        //reading only first sheet data
        const jsonData = XLSX.utils.sheet_to_json(
          datalog.Sheets[firstSheetName]
        );
        if (jsonData[0]) {
          const repeatKeys = {};
          let isValid = true;
          Object.keys(jsonData[0]).forEach((key) => {
            if (!repeatKeys[key]) {
              repeatKeys[key] = true;
              if (!labelsExcel.includes(key)) {
                isValid = false;
              }
            } else {
              isValid = false;
            }
          });
          if (!isValid) {
            Model.updateAlerts({
              message: t("components.addTicketsList.invalidFile"),
              variant: "error",
            });
          } else {
            uploadNewTickets(jsonData);
          }
        } else {
          Model.updateAlerts({
            message: t("components.addTicketsList.invalidFile"),
            variant: "error",
          });
        }
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    });
  };

  const onDeleteTicket = (indexTicket) => {
    setNewTickets((ov) => {
      const beforeArray = [...ov];
      beforeArray.splice(indexTicket, 1);
      return beforeArray;
    });
  };

  const returnNewTickets = () => {
    if (newTickets.length < 1) {
      return (
        <p className={styles.emptyMessage}>
          {t("components.addTicketsList.noAddSellsYet")}
        </p>
      );
    }

    return (
      <table className={styles.tableTickets} cellPadding={0} cellSpacing={0}>
        <thead>
          <tr className={styles.headTableTickets}>
            <td width="15%">{t("components.addTicketsList.name")}</td>
            <td width="15%">{t("components.addTicketsList.lastName")}</td>
            <td width="15%">{t("components.addTicketsList.numId")}</td>
            <td width="25%">{t("components.addTicketsList.email")}</td>
            <td width="20%">{t("components.addTicketsList.typeTicket")}</td>
            <td width="5%">{t("components.addTicketsList.quantity")}</td>
            <td width="5%"></td>
          </tr>
        </thead>
        <tbody>
          {newTickets.map((ticket, index) => (
            <tr
              className={styles.bodyTableTickets}
              key={`item-new-ticket-${index}`}
            >
              <td>{ticket?.name}</td>
              <td>{ticket?.lastName}</td>
              <td>{ticket?.numId}</td>
              <td>{ticket?.email}</td>
              <td>{ticket?.label}</td>
              <td>{ticket?.quantity}</td>
              <td>
                <button
                  className={styles.deleteBtn}
                  onClick={() => onDeleteTicket(index)}
                >
                  <Delete />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div className={styles.containerAddTickets}>
      <div className={styles.rowCard}>
        <div className={styles.columnInfoCard}>
          <h2 className={styles.titleInfoCard}>
            {t("components.addTicketsList.addTickets")}
          </h2>
          <p className={styles.descriptionIfoCard}>
            {t("components.addTicketsList.useOurFormat")}
          </p>
        </div>
        <button className={styles.uploadButton} onClick={showAlertUpload}>
          <CloudUpload />
          {t("components.addTicketsList.uploadExcel")}
          <div className={styles.sublabelButton}>
            {t("components.addTicketsList.maxPerOne")}
          </div>
        </button>
        <input
          className={styles.inputExcel}
          type="file"
          accept=".xlsx, .xls, .csv"
          ref={inputFileRef}
          onChange={uploadExcel}
        />
      </div>

      <div className={styles.cloumnCard}>
        <div className={styles.columnInfoCard}>
          <h2 className={styles.titleInfoCard}>
            {t("components.addTicketsList.pendingConfirm")}
          </h2>
          <p className={styles.descriptionIfoCard}>
            {t("components.addTicketsList.thisIsPreview")}
          </p>
        </div>
        {returnNewTickets()}
        <div className={styles.rowBottomTable}>
          <button className={styles.cancelBtn} onClick={showCancelMessage}>
            {t("commons.cancel")}
          </button>
          <button className={styles.confirmBtn} onClick={showConfirmMessage}>
            {t("commons.confirm")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddTicketsList;
