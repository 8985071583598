import { makeStyles } from "@material-ui/core/styles";
import { colors } from "../../styleguide";
import Commons from "../../utils/styles";

const useStyles = makeStyles({
  "@keyframes moveBottomInfinite": {
    "0%": {
      bottom: 12,
    },
    "50%": {
      bottom: 30,
    },
    "100%": {
      bottom: 12,
    },
  },
  container: {
    ...Commons.flexRow,
    maxWidth: 1200,
    margin: "0 auto",
    width: "90%",
    alignItems: "flex-start",
    padding: "42px 0",
    [Commons.smallQuery]: {
      ...Commons.flexColumn,
      flexDirection: "column-reverse",
      padding: "24px 6px",
    },
    "&.isMobile": {
      ...Commons.flexColumn,
      flexDirection: "column-reverse",
      padding: "24px 6px",
    },
  },
  containerRowMoreEvents: {
    maxWidth: 1200,
    margin: "0 auto",
    width: "90%",
    alignItems: "flex-start",
    padding: "0 0 42px 0",
    marginTop: -42,
    [Commons.smallQuery]: {
      padding: "0 6px 24px 6px",
    },
    "&.isMobile": {
      padding: "0 6px 24px 6px",
    },
  },
  rowEvents: {
    overflowX: "auto",
    whiteSpace: "nowrap",
    maxWidth: 1200,
    width: "100%",
    marginTop: 24,
  },
  leftContainer: {
    flex: 3,
    marginRight: 42,
    [Commons.smallQuery]: {
      marginRight: 0,
      width: "100%",
    },
    "&.isMobile": {
      marginRight: 0,
      width: "100%",
    },
  },
  rightContainer: {
    flex: 2,
    [Commons.smallQuery]: {
      marginBottom: 24,
      width: "calc(100% - 24px)",
    },
    "&.isMobile": {
      marginBottom: 24,
      width: "calc(100% - 24px)",
    },
  },
  grayContainer: {
    marginBottom: 24,
    backgroundColor: colors.grayLight,
    borderRadius: 24,
    padding: "30px 12px",
  },
  label: {
    color: colors.themeColor,
    fontSize: 30,
    fontWeight: "700",
    margin: "6px 0",
  },
  description: {
    whiteSpace: "pre-wrap",
  },
  imageDescription: {
    width: "100%",
    height: "auto",
    backgroundColor: colors.gray50,
    borderRadius: 6,
    boxShadow: colors.shadow,
    marginTop: 12,
  },
  labelGray: {
    color: colors.black,
    fontSize: 30,
    fontWeight: "700",
    margin: "0 0 30px 0",
    textAlign: "center",
  },
  labelGrayCenter: {
    color: colors.black,
    fontSize: 16,
    fontWeight: "500",
    margin: "-12px 0 30px 0",
    textAlign: "center",
  },
  labelEmptySells: {
    color: colors.black,
    fontSize: 16,
    fontWeight: "500",
    margin: "30px auto",
    textAlign: "center",
    width: "75%",
  },
  headerSells: {
    ...Commons.flexRow,
    width: "100%",
    padding: "12px 0",
    backgroundColor: colors.grayBlack,
    borderRadius: 12,
    "& div": {
      width: "calc(100% / 3)",
      textAlign: "center",
      fontWeight: "700",
    },
  },
  rowSell: {
    ...Commons.flexRow,
    padding: "12px 0",
    borderBottom: `1px solid ${colors.white}`,
    "& div": {
      width: "calc(100% / 3)",
      textAlign: "center",
    },
  },
  quantitySell: {
    ...Commons.flexCenter,
    border: `1px solid ${colors.black}`,
    borderRadius: 18,
    padding: "6px 12px",
    width: "66px !important",
    marginRight: 18,
    backgroundColor: colors.white,
    "& div": {
      textAlign: "center",
    },
  },
  numberQuantitySell: {
    fontSize: 18,
    fontWeight: "800",
    textAlign: "center",
  },
  chevButton: {
    ...Commons.defaultButton,
    position: "relative",
    top: 2,
    flex: 1,
    "& svg": {
      fontSize: "16px !important",
      color: colors.black,
    },
  },
  opacity: {
    opacity: 0.25,
    cursor: "not-allowed",
  },
  rowArtists: {
    overflowX: "auto",
    whiteSpace: "nowrap",
    maxWidth: 694,
    width: "calc((100vw * (2/3)) - 104px)",
    [Commons.smallQuery]: {
      width: "100%",
    },
    "&.isMobile": {
      width: "100%",
    },
  },
  iconInfoSell: {
    fontSize: 16,
  },
  taxesTextSells: {
    textAlign: "center",
    margin: "18px 0",
    color: colors.black,
    lineHeight: "30px",
    fontSize: "15px",
    borderBottom: `1px solid ${colors.white}`,
    padding: "0 0 18px 0",
  },
  buySellButton: {
    ...Commons.defaultButton,
    textAlign: "center",
    padding: "12px 30px",
    fontWeight: "700",
    width: "fit-content",
    backgroundColor: colors.themeColor,
    borderRadius: 12,
    color: colors.white,
    margin: "12px auto 0 auto",
    display: "block",
  },
  blank: {
    height: 42,
    [Commons.smallQuery]: {
      height: 24,
    },
    "&.isMobile": {
      height: 24,
    },
  },
  contArtist: {
    margin: 6,
    display: "inline-block",
    cursor: "pointer",
    maxWidth: 150,
    overflow: "hidden",
    verticalAlign: "top",
    "&:hover div.overArtist": {
      display: "flex",
    },
  },
  imgArtist: {
    position: "relative",
    ...Commons.coverBackground,
    minWidth: 120,
    maxWidth: 120,
    minHeight: 120,
    maxHeight: 120,
    backgroundColor: colors.white,
    borderRadius: "50%",
    margin: "0 auto",
    marginBottom: 3,
    border: "3px solid black",
    overflow: "hidden",
  },
  overArtist: {
    ...Commons.flexCenter,
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(239, 239, 239, 0.75)",
    display: "none",
    transition: "0.3 seg",
    cursor: "pointer",
    "& svg": {
      color: colors.themeColor,
      fontSize: 42,
    },
  },
  nameArtist: {
    fontSize: 18,
    textAlign: "center",
    margin: 0,
    fontWeight: "700",
    whiteSpace: "break-spaces",
  },
  subArtist: {
    fontSize: 14,
    textAlign: "center",
    margin: 0,
    marginTop: -3,
    whiteSpace: "break-spaces",
  },
  contMap: {
    width: "75%",
    height: "auto",
    aspectRatio: "1 / 1",
    borderRadius: 12,
    backgroundColor: colors.white,
    cursor: "pointer",
    overflow: "hidden",
    boxShadow: colors.shadow,
    margin: "12px auto",
    "& img": {
      width: "100%",
      height: "100%",
    },
  },
  rowPrice: {
    ...Commons.flexRow,
    margin: "12px",
  },
  labelPrice: {
    backgroundColor: colors.black,
    padding: "6px 18px",
    borderRadius: "0 30px 30px 0",
    color: colors.white,
    fontSize: 18,
    fontWeight: "600",
    flex: 1,
  },
  amountPrice: {
    flex: 1,
    textAlign: "right",
    color: colors.black,
    fontSize: 18,
    lineHeight: "14px",
    "& span": {
      fontSize: 14,
      fontWeight: "700",
    },
  },
  anchorSellsFloat: {
    ...Commons.centerHorizontal,
    position: "fixed",
    animation: "$moveBottomInfinite 2s infinite",
    bottom: 12,
    cursor: "pointer",
    textDecoration: "none",
    [Commons.smallQuery]: {
      left: 18,
      transform: "none",
    },
  },
  labelSellsFloat: {
    position: "relative",
    backgroundColor: colors.black,
    borderRadius: 12,
    padding: "6px 12px 9px 12px",
    whiteSpace: "nowrap",
    "& span": {
      color: colors.white,
      textAlign: "center",
      fontWeight: "600",
      fontSize: 16,
    },
    "& div": {
      ...Commons.centerHorizontal,
      position: "absolute",
      width: 0,
      height: 0,
      borderLeft: `12px solid transparent`,
      borderRight: `12px solid transparent`,
      borderTop: `12px solid ${colors.black}`,
      bottom: -12,
    },
  },
  modalPreferences: {
    ...Commons.flexCenter,
    backgroundColor: "rgba(0 , 0, 0, 0.75)",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
  cardPreferences: {
    backgroundColor: colors.white,
    maxWidth: "calc(90% - 36px)",
    padding: 18,
    borderRadius: 12,
    maxHeight: "55vh",
    overflow: "auto",
  },
  titleCardPreferences: {
    textAlign: "center",
    fontSize: 24,
    margin: "12px 0",
  },
  rowPreferences: {
    ...Commons.flexRow,
    flexWrap: "wrap",
    gap: 24,
    justifyContent: "center",
    marginTop: 30,
  },
  buttonPrefence: {
    ...Commons.defaultButton,
    backgroundColor: colors.white,
    border: `2px solid ${colors.themeColor}`,
    color: colors.themeColor,
    fontWeight: "700",
    fontSize: 30,
    borderRadius: 12,
    padding: "12px 18px",
    "&:hover": {
      backgroundColor: colors.themeColor,
      color: colors.white,
    },
  },
  bgRed: {
    backgroundColor: colors.red,
  },
});

export default useStyles;
