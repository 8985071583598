import { makeStyles } from "@material-ui/styles";
import Commons from "../../../utils/styles";
import { colors } from "../../../styleguide";

const styles = makeStyles({
  title: {
    color: colors.mainxText,
    fontWeight: "500",
    fontSize: 18,
    margin: "12px auto",
    textAlign: "center",
    maxWidth: 900,
  },
  itemListUser: {
    ...Commons.flexRow,
    width: "calc(100% - 48px)",
    margin: "0px 24px 12px 24px",
    borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
    padding: "12px 0",
  },
  imgUser: {
    ...Commons.coverBackground,
    minWidth: 72,
    maxWidth: 72,
    minHeight: 72,
    maxHeight: 72,
    borderRadius: "50%",
    backgroundColor: colors.white,
    boxShadow: colors.shadow,
  },
  infoUser: {
    marginLeft: 18,
    width: "calc(100% - 90px)",
    "& svg": {
      cursor: "pointer",
    },
  },
  rowInfo: {
    ...Commons.flexRow,
  },
  contTitleUser: {
    flex: 1,
    marginRight: 12,
    maxWidth: "calc(100% - 30px)",
  },
  titleUser: {
    ...Commons.ellipsis,
    fontWeight: "600",
    fontSize: 18,
    margin: 0,
  },
  emailUser: {
    fontWeight: "300",
    fontSize: 15,
    margin: 0,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginBottom: 12,
  },
  iconBtnTicket: {
    fontSize: 24,
    color: colors.blue,
  },
  btnMore: {
    ...Commons.defaultButton,
    padding: "0 12px",
  },
  blank: {
    height: 60,
  },
  lblEmpty: {
    textAlign: "center",
    color: colors.black,
    fontSize: 18,
    width: "80%",
    margin: "0 auto",
    marginTop: 30,
  },
  rowTabs: {
    overflowX: "auto",
    whiteSpace: "nowrap",
    width: "fit-content",
    margin: "42px auto 30px auto",
    position: "relative",
    maxWidth: "95%",
    "& button": {
      ...Commons.defaultButton,
      color: colors.white,
      textAlign: "center",
      padding: "18px 24px",
      fontWeight: "600",
      fontSize: 16,
      backgroundColor: colors.themeColor,
      borderRadius: 30,
      margin: "0 12px",
    },
  },
  disabledTab: {
    backgroundColor: `${colors.themeColor}50 !important`,
    color: colors.themeColor,
  },
  createButton: {
    ...Commons.defaultButton,
    ...Commons.centerHorizontal,
    backgroundColor: colors.white,
    color: colors.themeColor,
    fontWeight: "700",
    padding: "12px 60px",
    borderRadius: 12,
    fontSize: 18,
    boxShadow: colors.shadow,
    marginBottom: 12,
    marginTop: 12,
  },
  gridUsers: {
    maxWidth: 780,
    margin: "0 auto",
  },
});

export default styles;
