import React from "react";
import {
  WhatsApp,
  Facebook,
  Twitter,
  Instagram,
  YouTube,
  Event,
  Store as StoreIcon,
  RemoveShoppingCart,
  VerifiedUser,
  Report,
  TrendingUp,
} from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import moment from "moment";

import BannerPlace from "../../assets/images/dummy/banner2.png";
import ItemProduct from "../../components/ItemProduct";
import Avatar from "../../assets/images/avatar.jpeg";
import SwitchBoth from "../../components/SwitchBoth";
import PartnerService from "../../services/partner";
import ItemEvent from "../../components/ItemEvent";
import pageStyles from "./styles/partnerStyles";
import { Store } from "../../hooks/main_store";
import Model from "../../hooks/Model";
import Layout from "../layout";

const Partner = ({ history }) => {
  const { t } = useTranslation();
  const styles = pageStyles();
  const { state } = React.useContext(Store);
  const socialIcons = {
    facebook: <Facebook />,
    twitter: <Twitter />,
    instagram: <Instagram />,
    youtube: <YouTube />,
  };
  const socialUrls = {
    facebook: "https://www.facebook.com/",
    twitter: "https://twitter.com/",
    instagram: "https://www.instagram.com/",
    youtube: "https://www.youtube.com/channel/",
  };

  const [filterEvents, setFilterEvents] = React.useState("active");
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [content2render, setContent2render] = React.useState([]);
  const [vc, setVc] = React.useState({
    name: state?.userInfo?.name || "",
    email: state?.userInfo?.email || "",
    imageProfile: state?.userInfo?.imageProfile || "",
    password: "",
    phone: state?.userInfo?.phone,
    description: "",
    logo: "",
    created: "",
    events: [],
    products: [],
    uid: "",
    social: {},
    verified: false,
    team: {},
  });
  const changeVc = (data) => setVc((ov) => ({ ...ov, ...data }));

  const changeTab = (indexTab) => {
    setSelectedTab(indexTab);
    setContent2render(indexTab === 0 ? vc.events : vc.products);
  };

  const copyLink = () => {
    let link2copy = String(window?.location);
    if (link2copy.indexOf("selectedTab=") > -1) {
      link2copy = `${link2copy.slice(
        0,
        link2copy.indexOf("selectedTab=")
      )}selectedTab=${selectedTab}`;
    } else if (link2copy.indexOf("?") < 0) {
      link2copy = `${link2copy}?selectedTab=${selectedTab}`;
    } else {
      link2copy = `${link2copy}&selectedTab=${selectedTab}`;
    }
    navigator.clipboard.writeText(link2copy);
    Model.updateAlerts({
      message: t("pages.partner.profile.linkCopied"),
      variant: "success",
    });
  };

  const go2Wp = () => {
    if (vc.phone) {
      window.open(
        "https://wa.me/" + vc?.phone?.replace(/[^0-9]/g, ""),
        "blank"
      );
    }
  };

  const getPartner = async (uidPartner, selectedTabNum = 0) => {
    try {
      Model.setData("loading", true);
      const infoPartner = await PartnerService.getPartner(uidPartner);
      changeVc(infoPartner?.basicInfo);
      changeVc({
        events: infoPartner?.events || [],
        products: infoPartner?.products || [],
        team: infoPartner?.team || {},
      });
      setContent2render(
        (selectedTabNum === 0 ? infoPartner?.events : infoPartner?.products) ||
          []
      );
      Model.setData("infoPartner", {
        ...infoPartner?.basicInfo,
        uid: uidPartner,
      });
      if (!state.fistLoad) {
        Model.setData("isWhiteLabel", !!infoPartner?.basicInfo?.isWhiteLabel);
      }
      Model.setData(
        "eventsPartnerPlugin",
        (infoPartner?.events || [])?.map?.((event) => event?.uid) || []
      );

      if (infoPartner?.basicInfo?.url) {
        window.history.replaceState(
          null,
          infoPartner?.basicInfo?.name || "Fravents",
          `/${infoPartner?.basicInfo?.url}?selectedTab=${selectedTabNum}`
        );
      }
    } catch (e) {
      Model.updateAlerts({
        message: `${t("pages.partner.profile.infoSelectedNo")}: [${e}]`,
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  const onSelectEvent = (item) => {
    Model.setData("eventSelected", item);
    history.push(
      `/events?event=${item.uid}${
        item?.isCollaborator ? `&ref=${vc.uid}` : ""
      }`,
      {
        event: item,
      }
    );
  };

  const onSelectProduct = (item) => {
    Model.setData("productSelected", item);
    history.push(`/detail-product?product=${item.uid}`, {
      product: item,
    });
  };

  const goLinkSocial = (type, url) => {
    if (type && url) {
      window.open(socialUrls[type] + url, "blank");
    }
  };

  const renderContent = () => {
    if (content2render.length < 1) {
      return (
        <div className={styles.contEmpty}>
          <p className={styles.emptyMsg}>
            {t("pages.partner.profile.noContentToShow")}
          </p>
          <RemoveShoppingCart />
        </div>
      );
    }
    if (selectedTab === 0) {
      return content2render
        .sort((a, b) => b.date - a.date)
        .filter((a) =>
          filterEvents === "active" ? a.date >= moment().format("x") : true
        )
        .map((item, index) => (
          <ItemEvent
            key={"item-list-event-" + index}
            item={item}
            onClick={onSelectEvent}
            customClasses={styles.itemEvent}
          />
        ));
    } else if (selectedTab === 1) {
      return content2render
        .sort((a, b) => b.created - a.created)
        .map((item, index) => (
          <ItemProduct
            key={"item-list-product-" + index}
            item={item}
            onClick={onSelectProduct}
            customClasses={styles.itemEvent}
          />
        ));
    }
  };

  const renderSocial = () => {
    return Object.keys(vc.social).map((keySocial) =>
      socialIcons[keySocial] ? (
        <button
          key={"item-btn-social-" + keySocial}
          className={styles.social}
          onClick={() => goLinkSocial(keySocial, vc.social[keySocial])}
        >
          {socialIcons[keySocial]}
        </button>
      ) : null
    );
  };

  const renderReportsButton = () => {
    if (
      state.uid &&
      ((state.uid === vc.uid && state.userInfo?.availableShop) ||
        vc.team?.reports?.indexOf?.(state.uid) > -1)
    ) {
      return (
        <button
          className={[styles.tab, styles.colorTheme].join(" ")}
          onClick={() =>
            history.push(
              state.uid === vc.uid
                ? "/admin-shop"
                : `/shop-reports?ref=${vc.uid}`
            )
          }
        >
          <TrendingUp />
          <span>{t("pages.partner.profile.manageSells")}</span>
        </button>
      );
    }
  };

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const uidPartner = urlParams.get("ref");
    const selectedTabNum = parseInt(urlParams.get("selectedTab") || 0);

    changeVc({ uid: uidPartner });
    changeTab(selectedTabNum);
    getPartner(uidPartner, selectedTabNum);
    Model.setData("isOnPartner", true);
    return () => {
      Model.setData("isOnPartner", false);
    };
    //eslint-disable-next-line
  }, []);

  return (
    <Layout hideFooterLinks blackHeader>
      <div
        className={styles.banner}
        style={{ backgroundImage: `url(${vc.banner || BannerPlace})` }}
      />
      <div className={styles.rowColumns}>
        <div className={styles.leftContainer}>
          <div className={styles.rowInfo}>
            <img
              alt="partner"
              className={styles.imageProfile}
              src={vc.imageProfile || Avatar}
            />
            <div className={styles.contInfo}>
              <h2 className={styles.name}>{vc.name}</h2>
              <div className={styles.status}>
                {vc.verified ? (
                  <VerifiedUser className={styles.verifiedIcon} />
                ) : (
                  <Report className={styles.noVerifiedIcon} />
                )}
                <span className={styles.labelStatus}>
                  &nbsp;
                  {vc.verified
                    ? t("pages.partner.profile.verified")
                    : t("pages.partner.profile.noVerified")}
                </span>
              </div>
            </div>
          </div>
          {vc.description ? (
            <p className={styles.description}>{vc.description}</p>
          ) : null}
          <div className={styles.rowActions}>
            {vc.uid ? (
              <button className={styles.share} onClick={copyLink}>
                {t("pages.partner.profile.share")}
              </button>
            ) : null}
            {vc.phone ? (
              <button className={styles.whatsapp} onClick={go2Wp}>
                <WhatsApp />
              </button>
            ) : null}
          </div>
          {Object.keys(vc.social).length > 0 ? (
            <div className={styles.rowActions}>{renderSocial()}</div>
          ) : null}
          <div className={styles.since}>
            {t("pages.partner.profile.memberSince")}{" "}
            {moment(vc.created).format("MMM DD, YYYY")}
          </div>
        </div>
        <div className={styles.rightContainer}>
          <div className={styles.rowTabs}>
            <button
              className={[
                styles.tab,
                selectedTab === 0 ? styles.selectedTab : "",
              ].join(" ")}
              onClick={() => changeTab(0)}
            >
              <Event />
              <span>{t("pages.partner.profile.events")}</span>
            </button>
            <button
              className={[
                styles.tab,
                selectedTab === 1 ? styles.selectedTab : "",
              ].join(" ")}
              onClick={() => changeTab(1)}
            >
              <StoreIcon />
              <span>{t("pages.partner.profile.shop")}</span>
            </button>

            {renderReportsButton()}
          </div>
          {selectedTab === 0 ? (
            <SwitchBoth
              onChange={(value) => setFilterEvents(value)}
              value={filterEvents}
              values={[
                { label: t("pages.partner.profile.all"), value: "all" },
                { label: t("pages.partner.profile.active"), value: "active" },
              ]}
              className={styles.switchContainer}
            />
          ) : null}
          <div className={styles.listItems}>{renderContent()}</div>
          {content2render.length > 0 && false ? (
            <button className={styles.loadMore}>
              {t("pages.partner.profile.loadMore")}
            </button>
          ) : null}
        </div>
      </div>
    </Layout>
  );
};

export default Partner;
