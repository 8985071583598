import React from "react";
import pageStyles from "./styles";

const TextImageBanner = ({
  title,
  description,
  color,
  onPress,
  Icon,
  className,
}) => {
  const styles = pageStyles();

  return (
    <button
      onClick={onPress}
      className={[styles.container, styles[`bg_${color}`], className].join(" ")}
    >
      {Icon ? <Icon className={styles.icon} /> : null}
      <div className={styles.infoContainer}>
        {title ? <h3 className={styles.title}>{title}</h3> : null}
        {description ? (
          <p className={styles.description}>{description}</p>
        ) : null}
      </div>
    </button>
  );
};

export default TextImageBanner;
