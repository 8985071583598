import React from "react";
import pageStyles from "./styles";
import * as AvatarImages from "../../assets/images/tutorial";
import { Store } from "../../hooks/main_store";
import Model from "../../hooks/Model";
import LocalStorage from "../../utils/localStorage";

const NotificationModal = () => {
  const { state } = React.useContext(Store);
  const styles = pageStyles();

  const onButton = (action) => {
    switch (action) {
      case "reload":
        return window.location.reload();
      case "close":
        return Model.setData("notification", {
          open: false,
          title: "",
          description: "",
          image: "",
          buttons: [],
        });
      case "history":
        if (state?.notification?.created) {
          LocalStorage(state.isIncognito).setItem(
            `notification_${state.notification.created}`,
            "closed"
          );
        }

        return Model.setData("notification", {
          open: false,
          title: "",
          description: "",
          image: "",
          buttons: [],
        });
      default:
        return null;
    }
  };

  const renderButtons = () =>
    state?.notification?.buttons?.map((button) => (
      <button key={button?.label} onClick={() => onButton(button?.action)}>
        {button?.label}
      </button>
    ));

  React.useEffect(() => {
    if (
      state?.systemSettings?.notification?.title &&
      state?.systemSettings?.notification?.description &&
      state?.systemSettings?.notification?.buttons &&
      state?.systemSettings?.notification?.created &&
      state?.systemSettings?.liveVersion &&
      state.version &&
      state.systemSettings.liveVersion === state.version
    ) {
      const isClosed = LocalStorage(state.isIncognito).getItem(
        `notification_${state.systemSettings.notification.created}`
      );
      if (isClosed !== "closed") {
        Model.setData("notification", {
          ...state?.systemSettings?.notification,
          open: true,
        });
      }
    }
    //eslint-disable-next-line
  }, [state?.systemSettings]);

  if (!state?.notification?.open) {
    return null; // Nothing
  }

  return (
    <div className={styles.container}>
      <div className={styles.column}>
        <img
          className={styles.image}
          src={AvatarImages?.[state?.notification?.image] || AvatarImages.hi}
          alt="Notification"
        />
        <div className={styles.card}>
          <p className={styles.titleCard}>{state?.notification?.title}</p>
          <p className={styles.descriptionCard}>
            {state?.notification?.description
              ?.split?.("\\n")
              .map((item, index) => (
                <React.Fragment key={index}>
                  {item}
                  <br />
                </React.Fragment>
              ))}
          </p>
          <div className={styles.rowButtonsCard}>{renderButtons()}</div>
        </div>
      </div>
    </div>
  );
};

export default NotificationModal;
