import React from "react";
import {
  CloudDownload,
  CloudUpload,
  CheckCircle,
  AttachMoney,
  ConfirmationNumber,
  AccessTime,
  Block,
  Add,
  SyncAlt,
  Edit,
  Delete,
  Visibility,
  Refresh,
  CropFree,
  Autorenew,
} from "@material-ui/icons";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  ArcElement,
  BarElement,
} from "chart.js";
import {
  Menu,
  MenuItem,
  ListItemText,
  Grid,
  Switch,
  Tooltip as TooltipMui,
  Modal,
  Drawer,
} from "@material-ui/core";
import { Line, Pie, Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment";

import AddTicketsList from "../../components/AddTicketsList";
import { renderShortPrice } from "../../utils/validations";
import DialogForm from "../../components/dialogForm";
import DetailTickets from "../tickets/detailTickets";
import EventsService from "../../services/events";
import AdminService from "../../services/admin";
import Loading from "../../components/loading";
import { Store } from "../../hooks/main_store";
import pageStyles from "./styles/salesStyles";
import DetailMember from "./detailMember";
import Model from "../../hooks/Model";
import Layout from "../layout";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  ArcElement,
  BarElement
);

const libColors = new Array(100).fill("color").map(() => {
  const randColor = () => Math.floor(Math.random() * 256);
  return `${randColor()}, ${randColor()}, ${randColor()}`;
});

const Sales = ({ history, location }) => {
  const { t } = useTranslation();
  const { state } = React.useContext(Store);
  const event = location?.state?.event || {};
  const valueYears = [2023, 2022];
  const yearLabels = [
    t("commons.january"),
    t("commons.february"),
    t("commons.march"),
    t("commons.april"),
    t("commons.may"),
    t("commons.june"),
    t("commons.july"),
    t("commons.august"),
    t("commons.september"),
    t("commons.october"),
    t("commons.november"),
    t("commons.december"),
  ];
  const initialValuesPromoCode = {
    textPromoCode: "",
    quantityPromoCode: "",
    userLimitPromoCode: "",
    datePromoCode: "",
    valuePromoCode: "",
    percentPromoCode: "",
  };
  const monthLabels = new Array(31).fill(1).map((_, index) => index + 1);

  const styles = pageStyles();

  const inputFileRef = React.useRef(null);
  const [anchorFilter, setAnchorFilter] = React.useState(null);
  const [anchorYear, setAnchorYear] = React.useState(null);
  const [showAddListTickets, setShowAddListTickets] = React.useState(false);
  const [showDrawer, setShowDrawer] = React.useState(false);
  const [sizeList, setSizeList] = React.useState(20);
  const [selectedMember, setSelectedMember] = React.useState({
    open: false,
  });
  const [vc, setVc] = React.useState({
    allTickets: [],
    historyDate: "month",
    tickets2render: [],
    textSearch: "",
    selectedFilter: "",
    logistics: [],
    promoters: [],
    logisticsStats: {},
    promotersStats: {},
    promoCodes: [],
    showPromptPromoCode: false,
    editPromoCode: false,
    historyPromoCode: [],
    indexDetailPromoCode: -1,
    showListPromoCode: true,
    selectedYear: valueYears[0],
    ...initialValuesPromoCode,
  });
  const changeVc = (dataVc) => setVc((ov) => ({ ...ov, ...dataVc }));

  const changeState = (name, event) => {
    event.persist();
    changeVc({
      [name]: event.target.value,
    });
  };

  const onKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      filterData();
    } else if (event.keyCode === 27) {
      changeVc({ textSearch: "" });
      changeVc({ tickets2render: vc.allTickets });
    }
  };

  const changePromoCodeValue = (data) => {
    const currentData = { ...data };
    const keyValue = Object.keys(data)[0];
    currentData[keyValue] = currentData[keyValue].replace(/ /g, "");

    switch (keyValue) {
      case "textPromoCode":
        currentData[keyValue] = currentData[keyValue].replace(
          /[^a-zA-Z0-9]/g,
          ""
        );
        break;
      case "quantityPromoCode":
        currentData[keyValue] = currentData[keyValue].replace(/[^0-9]/g, "");
        break;
      case "userLimitPromoCode":
        currentData[keyValue] = currentData[keyValue].replace(/[^0-9]/g, "");
        break;
      case "valuePromoCode":
        if (!currentData[keyValue]) {
          currentData[keyValue] = "";
        } else {
          currentData[keyValue] = String(
            parseInt(currentData[keyValue].replace(/[^0-9]/g, ""))
          );
        }
        break;
      case "percentPromoCode":
        if (!currentData[keyValue]) {
          currentData[keyValue] = "";
        } else {
          currentData[keyValue] = String(
            parseInt(currentData[keyValue].replace(/[^0-9]/g, ""))
          );
          currentData[keyValue] =
            parseInt(currentData[keyValue]) > 100
              ? 100
              : parseInt(currentData[keyValue]) <= 0
              ? 0
              : currentData[keyValue];
        }
        break;
      default:
        break;
    }

    changeVc(currentData);
  };

  const returnHistorySales = (data2render = []) => {
    const ticketsByDate = new Array(vc.historyDate === "year" ? 12 : 31).fill(
      0
    );
    (data2render?.length > 0 ? data2render : vc.allTickets)
      .filter((ticket) => ticket.status === "payed")
      .forEach((ticket) => {
        const numMonth = parseInt(moment(ticket.created, "x").format("M")) - 1;
        const numDay = parseInt(moment(ticket.created, "x").format("D")) - 1;
        const numYear = parseInt(moment(ticket.created, "x").format("YYYY"));

        if (vc.historyDate === "year" && numYear === vc.selectedYear) {
          ticketsByDate[numMonth] = (ticketsByDate[numMonth] || 0) + 1;
        } else if (vc.historyDate === "month") {
          const currentMonth = parseInt(moment().format("M")) - 1;
          if (currentMonth === numMonth) {
            ticketsByDate[numDay] = (ticketsByDate[numDay] || 0) + 1;
          }
        }
      });
    return ticketsByDate;
  };

  const returnDataTypes = (returnTotal = false) => {
    const labelsPrices = event?.prices?.map((price) => price.label) || [];
    labelsPrices.push("Cortesia");
    const dataSales = new Array(labelsPrices.length).fill(0);
    let totalAmout = 0;

    vc.allTickets
      .filter(
        (ticket) =>
          ticket.status === "payed" && ticket.statusTransfer !== "pending"
      )
      .forEach((ticket) => {
        ticket.tickets.forEach((infoTicket) => {
          const indexType = labelsPrices.indexOf(infoTicket.label);
          if (indexType > -1 && !infoTicket.transferedEmail) {
            totalAmout++;
            dataSales[indexType] = (dataSales[indexType] || 0) + 1;
          }
        });
      });

    if (returnTotal) {
      return totalAmout;
    }

    return {
      labels: labelsPrices,
      datasets: [
        {
          data: dataSales,
          backgroundColor: labelsPrices.map(
            (label, index) => `rgba(${libColors[index]}, 0.2)`
          ),
          borderColor: labelsPrices.map(
            (label, index) => `rgba(${libColors[index]}, 1)`
          ),
          borderWidth: 1,
        },
      ],
    };
  };

  const returnStatusSales = () => {
    const labelsStatus = [
      t("pages.admin.sales.completed"),
      t("pages.admin.sales.pending"),
      t("pages.admin.sales.rejected"),
      t("pages.admin.sales.transfered"),
      t("pages.admin.sales.scaned"),
      t("pages.admin.sales.resell"),
    ];
    const randColor = () => Math.floor(Math.random() * 256);
    const colors = labelsStatus.map(
      () => `${randColor()}, ${randColor()}, ${randColor()}`
    );
    const dataStatus = new Array(labelsStatus.length).fill(0);

    vc.allTickets.forEach((ticket) => {
      if (ticket.status === "payed" && ticket.refResaleTransaction) {
        dataStatus[5] = (dataStatus[5] || 0) + 1;
      } else if (
        ticket.status === "payed" &&
        ticket.idPreference === "transferred" &&
        ticket.statusTransfer === "done"
      ) {
        dataStatus[3] = (dataStatus[3] || 0) + 1;
      } else if (
        ticket.status === "payed" &&
        ticket.idPreference !== "transferred"
      ) {
        dataStatus[0] = (dataStatus[0] || 0) + 1;
      } else if (ticket.status === "pending") {
        dataStatus[1] = (dataStatus[1] || 0) + 1;
      } else if (ticket.status === "rejected") {
        dataStatus[2] = (dataStatus[2] || 0) + 1;
      }

      if (ticket?.tickets?.forEach) {
        ticket.tickets.forEach((infoEachTicket) => {
          if (infoEachTicket?.status === "validated") {
            dataStatus[4] = (dataStatus[4] || 0) + 1;
          }
        });
      }
    });

    return {
      labels: labelsStatus,
      datasets: [
        {
          data: dataStatus,
          backgroundColor: colors.map((color) => `rgba(${color}, 0.2)`),
          borderColor: colors.map((color) => `rgba(${color}, 1)`),
          borderWidth: 1,
        },
      ],
    };
  };

  const returnDataIncome = (returnTotal = false) => {
    const labelsPrices = event?.prices?.map((price) => price.label) || [];
    labelsPrices.push("Cortesia");
    const dataSales = new Array(labelsPrices.length).fill(0);
    let totalAmout = 0;

    vc.allTickets
      .filter((ticket) => ticket.status === "payed")
      .forEach((ticket) => {
        ticket.tickets.forEach((infoTicket) => {
          const indexType = labelsPrices.indexOf(infoTicket.label);
          if (
            indexType > -1 &&
            ticket.status === "payed" &&
            !infoTicket.transferedEmail &&
            ticket.statusTransfer !== "pending"
          ) {
            totalAmout += parseFloat(infoTicket?.price || 0);
            dataSales[indexType] =
              (dataSales[indexType] || 0) + parseFloat(infoTicket?.price || 0);
          }
        });
      });

    if (returnTotal) {
      return totalAmout;
    }

    return {
      labels: labelsPrices,
      datasets: [
        {
          data: dataSales,
          backgroundColor: labelsPrices.map(
            (label, index) => `rgba(${libColors[index]}, 0.2)`
          ),
          borderColor: labelsPrices.map(
            (label, index) => `rgba(${libColors[index]}, 1)`
          ),
          borderWidth: 1,
        },
      ],
    };
  };

  const renderLabelFilter = () => {
    switch (vc.selectedFilter) {
      case "all":
        return t("pages.admin.sales.filter");
      case "payed":
        return t("pages.admin.sales.comple");
      case "pending":
        return t("pages.admin.sales.pendi");
      case "rejected":
        return t("pages.admin.sales.rejec");
      default:
        return t("pages.admin.sales.filter");
    }
  };

  const changeYearRef = (value) => {
    setAnchorYear(null);
    changeVc({ selectedYear: value });
  };

  const filterData = (type) => {
    setAnchorFilter(null);
    if (!type) {
      // Press on search button
      if (vc.textSearch) {
        changeVc({
          tickets2render: vc.allTickets.filter((ticket) => {
            const flagName = String(ticket?.client?.name)
              .toLowerCase()
              .includes(vc.textSearch.toLowerCase());
            const flagLastName = String(ticket?.client?.lastName)
              .toLowerCase()
              .includes(vc.textSearch.toLowerCase());
            const flagNumId = String(ticket?.client?.numId)
              .toLowerCase()
              .includes(vc.textSearch.toLowerCase());
            const flagEmail = String(ticket?.client?.email)
              .toLowerCase()
              .includes(vc.textSearch.toLowerCase());
            return flagName || flagLastName || flagNumId || flagEmail;
          }),
        });
      } else {
        changeVc({ tickets2render: vc.allTickets });
      }
    } else {
      const status = type === "all" ? "" : type;
      changeVc({
        tickets2render: vc.allTickets.filter((ticket) => {
          const hasStatus = String(ticket?.status)
            .toLowerCase()
            .includes(status.toLowerCase());
          const noTransfer = ticket?.idPreference !== "transferred";

          return noTransfer ? hasStatus : type === "transferred";
        }),
        selectedFilter: status,
      });
    }
  };

  const renderListSales = (data2render = []) => {
    const finalData =
      data2render?.length > 0
        ? data2render.sort((a, b) => b.created - a.created)
        : vc.tickets2render
            .sort((a, b) => b.created - a.created)
            .slice(0, sizeList);

    if (finalData.length < 1) {
      return (
        <p className={styles.labelEmpty}>
          {t("pages.admin.sales.noMoreSells")}
        </p>
      );
    }
    return finalData.map((ticket, index) => (
      <div
        key={"item-sale-" + index}
        className={styles.itemSale}
        onClick={() => {
          Model.setData("ticketSelected", {
            uid: ticket.uid,
            hideQr: true,
            isAdmin: true,
            promoters: vc.promoters,
            logistics: vc.logistics,
            data: {
              ...ticket,
              // status: "payed",
            },
          });
          setShowDrawer(true);
          // history.push("/detailTickets");
        }}
      >
        <div className={styles.nameItemSale}>
          {ticket?.client?.name || "-"}
          <br />
          <span>
            {ticket?.client?.email || t("pages.admin.sales.withoutEmail")}
          </span>
        </div>
        <div className={styles.ticketsItemSale}>
          {ticket?.tickets?.length || 0} {t("pages.admin.sales.tickets")}
          <br /> <span>{ticket?.tickets?.[0]?.label}</span>
        </div>
        <div className={styles.dateItemSale}>
          <b>
            <span
              className={
                ticket.statusTransfer === "pending"
                  ? styles.yellowColor
                  : ticket.status === "payed"
                  ? styles.blueColor
                  : ticket.status === "refunded"
                  ? styles.orangeColor
                  : ticket.status === "pending"
                  ? styles.yellowColor
                  : styles.redColor
              }
            >
              {ticket.idPreference === "transferred"
                ? ticket.statusTransfer === "pending"
                  ? t("pages.admin.sales.onTransfer")
                  : t("pages.admin.sales.transferedS")
                : ticket.status === "payed"
                ? t("pages.admin.sales.payed")
                : ticket.status === "pending"
                ? t("pages.admin.sales.pendingS")
                : ticket.status === "refunded"
                ? t("pages.admin.sales.refunded")
                : t("pages.admin.sales.rejectedS")}
            </span>
          </b>
          <br />
          {moment(ticket.created, "x").format("YYYY-MM-DD HH:mm")}
        </div>
      </div>
    ));
  };

  const renderPromoters = () => {
    if (vc.promoters.length < 1) {
      return (
        <p className={styles.labelEmpty}>
          {t("pages.admin.sales.thereNoMembers")}
        </p>
      );
    }
    return vc.promoters.map((user, index) => (
      <div
        key={"item-promoter-" + index}
        className={styles.itemSale}
        onClick={() =>
          // history.push("/detailMember", { userSelected: user, event })
          setSelectedMember({
            state: { userSelected: user, event },
            open: true,
          })
        }
      >
        <div className={styles.nameItemSale}>
          {user?.name || "-"}
          <br />
          {/* <span>{user?.email || "Sin email"}</span> */}
        </div>
        <div className={styles.ticketsItemSale}>
          {vc.promotersStats?.[user?.uid]?.tickets || 0}{" "}
          {t("pages.admin.sales.tickets")}
          <br /> <span>{t("pages.admin.sales.referedUsers")}</span>
        </div>
        <div className={styles.dateItemSale}>
          $
          {String(vc.promotersStats?.[user?.uid]?.total || 0).replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ","
          )}
          <br /> <span>{t("pages.admin.sales.totalSell")}</span>
        </div>
      </div>
    ));
  };

  const renderQuickStats = (typeData = "sales") => {
    const cards =
      typeData === "sales"
        ? [
            {
              label: t("pages.admin.sales.totalIncomes"),
              icon: <AttachMoney />,
              value: renderShortPrice(returnDataIncome(true), true),
              longData: returnDataIncome(true),
            },
            {
              label: t("pages.admin.sales.generatedTickets"),
              icon: <ConfirmationNumber />,
              value: renderShortPrice(returnDataTypes(true), true),
              longData: returnDataTypes(true),
            },
            {
              label: t("pages.admin.sales.scanedCodes"),
              icon: <CropFree />,
              value: renderShortPrice(
                returnStatusSales()?.datasets?.[0]?.data?.[4],
                true
              ),
              longData: returnStatusSales()?.datasets?.[0]?.data?.[4],
            },
            {
              label: t("pages.admin.sales.transfered"),
              icon: <SyncAlt />,
              value: renderShortPrice(
                returnStatusSales()?.datasets?.[0]?.data?.[3],
                true
              ),
              longData: returnStatusSales()?.datasets?.[0]?.data?.[3],
            },
            {
              label: t("pages.admin.sales.resellTickets"),
              icon: <Autorenew />,
              value: renderShortPrice(
                returnStatusSales()?.datasets?.[0]?.data?.[5],
                true
              ),
              longData: returnStatusSales()?.datasets?.[0]?.data?.[5],
            },
          ]
        : [
            {
              label: t("pages.admin.sales.completeSells"),
              icon: <CheckCircle />,
              value: renderShortPrice(
                returnStatusSales()?.datasets?.[0]?.data?.[0],
                true
              ),
              longData: returnStatusSales()?.datasets?.[0]?.data?.[0],
            },
            {
              label: t("pages.admin.sales.pendingSells"),
              icon: <AccessTime />,
              value: renderShortPrice(
                returnStatusSales()?.datasets?.[0]?.data?.[1],
                true
              ),
              longData: returnStatusSales()?.datasets?.[0]?.data?.[1],
            },
            {
              label: t("pages.admin.sales.rejectedSells"),
              icon: <Block />,
              value: renderShortPrice(
                returnStatusSales()?.datasets?.[0]?.data?.[2],
                true
              ),
              longData: returnStatusSales()?.datasets?.[0]?.data?.[2],
            },
          ];

    return cards.map((info) => {
      const longdata = String(info.longData).replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      );

      return (
        <TooltipMui key={info.label} title={`${info.label}: ${longdata}`}>
          <div className={styles.cardQuickStat}>
            {info.icon}
            <p>{longdata}</p>
            {/* <p>{info.value}</p> */}
            <span>{info.label}</span>
          </div>
        </TooltipMui>
      );
    });
  };

  const onDeletePromoCode = async (textCode) => {
    try {
      Model.setData("loading", true);
      Model.setData("dialog", { open: false });

      await AdminService.deletePromoCode({
        eventUid: event.uid,
        textPromoCode: textCode,
      });

      Model.updateAlerts({
        message: t("pages.admin.sales.deletedCode"),
        variant: "success",
      });

      changeVc({
        ...initialValuesPromoCode,
        showPromptPromoCode: false,
        editPromoCode: false,
      });

      getPromoCodes();
    } catch (e) {
      Model.updateAlerts({
        message: t("pages.admin.sales.errorPromoCode") + String(e),
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  const showDialogDeletePromoCode = (textCode) => {
    Model.setData("dialog", {
      open: true,
      title: t("pages.admin.sales.deletePromoCode"),
      text: t("pages.admin.sales.areYourSureDeletePromoCode", { textCode }),
      txtLeft: t("commons.cancel"),
      fnLeft: () => Model.setData("dialog", { open: false }),
      txtRight: t("commons.yesConfirm"),
      fnRight: () => onDeletePromoCode(textCode),
    });
  };

  const onDetailPromoCode = async (promoCode, index) => {
    try {
      changeVc({
        indexDetailPromoCode: -1,
      });
      Model.setData("loading", true);
      if (!promoCode) {
        throw new Error(t("pages.admin.sales.noExistCode"));
      }
      const historyPromoCode = await AdminService.getHistoryPromoCode({
        eventUid: event.uid,
        textPromoCode: promoCode,
      });

      changeVc({
        historyPromoCode: historyPromoCode || [],
        indexDetailPromoCode: index,
      });
    } catch (e) {
      Model.updateAlerts({
        message: t("pages.admin.sales.errorPromoCode") + String(e),
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  const renderPromoCodes = () => {
    if (vc.promoCodes.length < 1) {
      return (
        <p className={styles.labelEmpty}>
          {t("pages.admin.sales.noCodesToShow")}
        </p>
      );
    }
    return vc.promoCodes.map((infoCode, index) => (
      <React.Fragment key={"item-promoCode-" + index}>
        <div
          className={[styles.itemSale, styles.itemPromoCode].join(" ")}
          onClick={() => changeVc({ indexDetailPromoCode: -1 })}
        >
          <div className={styles.nameItemSale}>
            {infoCode?.textPromoCode || "-"}
            <br />
            <span>
              {t("pages.admin.sales.discount")}:{" "}
              {!!infoCode?.valuePromoCode ? "$" : ""}
              {renderShortPrice(
                infoCode?.valuePromoCode || infoCode?.percentPromoCode
              )}{" "}
              {!!infoCode?.valuePromoCode ? "COP" : "%"}
            </span>
          </div>
          <div className={styles.ticketsItemSale}>
            {infoCode?.quantityPromoCode || 0} {t("pages.admin.sales.created")}
            <br />{" "}
            <span>
              {infoCode?.userLimitPromoCode || 0}{" "}
              {t("pages.admin.sales.buyUser")}
            </span>
          </div>
          <div className={styles.dateItemPromoCode}>
            {t("pages.admin.sales.expDate")}:
            <br />
            {moment(infoCode?.datePromoCode).format("ddd DD MMM YYYY, hh:mm a")}
            <br />
            {/* <span>Venta total</span> */}
          </div>
          <div className={styles.actionsItemSale}>
            <TooltipMui title={t("pages.admin.sales.viewDetails")}>
              <button
                onClick={() =>
                  onDetailPromoCode(infoCode?.textPromoCode, index)
                }
              >
                <Visibility />
              </button>
            </TooltipMui>
            <TooltipMui title={t("pages.admin.sales.edit")}>
              <button
                onClick={() => {
                  changeVc({
                    showPromptPromoCode: true,
                    textPromoCode: infoCode?.textPromoCode,
                    quantityPromoCode: infoCode?.quantityPromoCode,
                    userLimitPromoCode: infoCode?.userLimitPromoCode,
                    datePromoCode: infoCode?.datePromoCode,
                    valuePromoCode: infoCode?.valuePromoCode,
                    percentPromoCode: infoCode?.percentPromoCode,
                    editPromoCode: true,
                  });
                }}
              >
                <Edit />
              </button>
            </TooltipMui>
            <TooltipMui title={t("pages.admin.sales.delete")}>
              <button
                onClick={() =>
                  showDialogDeletePromoCode(infoCode?.textPromoCode)
                }
              >
                <Delete />
              </button>
            </TooltipMui>
          </div>
        </div>
        <div
          className={[
            styles.detailsContainerPromoCode,
            vc.indexDetailPromoCode === index ? styles.openDetails : "",
          ].join(" ")}
        >
          <div
            className={[
              styles.dataDetailsPromoCode,
              !vc.showListPromoCode ? styles.hideMobileContainer : "",
            ].join(" ")}
          >
            <button
              className={styles.changeViewDetailPromoCode}
              onClick={() => changeVc({ showListPromoCode: false })}
            >
              {t("pages.admin.sales.viewGraphs")}
            </button>
            <>
              <h5>{t("pages.admin.sales.data")}</h5>
              <div className="rowData">
                <p>
                  {t("pages.admin.sales.used")}:{" "}
                  {vc.historyPromoCode?.length || 0}
                </p>
                <p>
                  {t("pages.admin.sales.remaning")}:{" "}
                  {parseInt(infoCode?.quantityPromoCode) -
                    (vc.historyPromoCode?.length || 0)}
                </p>
              </div>
              <div className={styles.listDetailsPromoCode}>
                {renderListSales(vc.historyPromoCode)}
              </div>
            </>
          </div>
          <div
            className={[
              styles.graphDetailsPromoCode,
              vc.showListPromoCode ? styles.hideMobileContainer : "",
            ].join(" ")}
          >
            <button
              className={styles.changeViewDetailPromoCode}
              onClick={() => changeVc({ showListPromoCode: true })}
            >
              {t("pages.admin.sales.viewList")}
            </button>
            {vc.indexDetailPromoCode === index && (
              <Line
                className={styles.responsiveMobileContainer}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      display: false,
                    },
                    title: {
                      display: true,
                      text: t("pages.admin.sales.historyUses", {
                        date:
                          vc.historyDate === "year"
                            ? t("pages.admin.sales.year")
                            : t("pages.admin.sales.lastMonth"),
                      }),
                    },
                  },
                  scales: {
                    yAxis: { beginAtZero: true, ticks: { stepSize: 1 } },
                  },
                }}
                data={{
                  labels: vc.historyDate === "year" ? yearLabels : monthLabels,
                  datasets: [
                    {
                      data: returnHistorySales(vc.historyPromoCode),
                      borderColor: "rgb(255, 99, 132)",
                      backgroundColor: "rgba(255, 99, 132, 0.5)",
                      yAxisID: "yAxis",
                    },
                  ],
                }}
              />
            )}
          </div>
        </div>
      </React.Fragment>
    ));
  };

  const showPromoCodeForm = () => {
    changeVc({
      showPromptPromoCode: true,
      editPromoCode: false,
      ...initialValuesPromoCode,
    });
  };

  const onSendPromoCode = async () => {
    try {
      Model.setData("loading", true);
      if (
        !vc.textPromoCode ||
        !vc.quantityPromoCode ||
        !vc.userLimitPromoCode ||
        !vc.datePromoCode ||
        !(vc.valuePromoCode || vc.percentPromoCode)
      ) {
        throw new Error(t("pages.admin.sales.fillDataYet"));
      }
      await AdminService.createPromoCode({
        eventUid: event.uid,
        textPromoCode: vc.textPromoCode,
        quantityPromoCode: vc.quantityPromoCode,
        userLimitPromoCode: vc.userLimitPromoCode,
        datePromoCode: vc.datePromoCode,
        valuePromoCode: vc.valuePromoCode,
        percentPromoCode: vc.percentPromoCode,
        editPromoCode: vc.editPromoCode,
      });

      Model.updateAlerts({
        message: vc.editPromoCode
          ? t("pages.admin.sales.editedCode")
          : t("pages.admin.sales.createdCode"),
        variant: "success",
      });

      changeVc({
        ...initialValuesPromoCode,
        showPromptPromoCode: false,
        editPromoCode: false,
      });

      getPromoCodes();
    } catch (e) {
      Model.updateAlerts({
        message: t("pages.admin.sales.errorPromoCode") + String(e),
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  const returnInputsPromoCode = () => {
    const inputs = [
      {
        label: t("pages.admin.sales.text"),
        value: "textPromoCode",
        disabled: vc.editPromoCode,
      },
      { label: t("pages.admin.sales.quantity"), value: "quantityPromoCode" },
      {
        label: t("pages.admin.sales.limitUseByClient"),
        value: "userLimitPromoCode",
      },
      {
        label: t("pages.admin.sales.validUntil"),
        value: "datePromoCode",
        type: "datetime-local",
        disabled: false,
      },
      {
        label: t("pages.admin.sales.priceCop"),
        value: "valuePromoCode",
        disabled: !!vc.percentPromoCode && !vc.valuePromoCode,
      },
      {
        label: t("pages.admin.sales.percentage"),
        value: "percentPromoCode",
        disabled: !!vc.valuePromoCode && !vc.percentPromoCode,
      },
    ];

    return inputs;
  };

  const rageDateHandleChange = (event) => {
    changeVc({
      historyDate: event.target.checked ? "year" : "month",
    });
  };

  const renderLogistics = () => {
    if (vc.logistics.length < 1) {
      return (
        <p className={styles.labelEmpty}>
          {t("pages.admin.sales.thereNoMembers")}
        </p>
      );
    }
    return vc.logistics.map((user, index) => (
      <div
        key={"item-logistic-" + index}
        className={styles.itemSale}
        onClick={() =>
          // history.push("/detailMember", { userSelected: user, event })
          setSelectedMember({
            state: { userSelected: user, event },
            open: true,
          })
        }
      >
        <div className={styles.nameItemSale}>
          {user?.name || "-"}
          <br />
          {/* <span>{user?.email || "Sin email"}</span> */}
        </div>
        <div className={styles.ticketsItemSale}>
          {vc.logisticsStats?.[user?.uid]?.tickets || 0}{" "}
          {t("pages.admin.sales.tickets")}
          <br /> <span>{t("pages.admin.sales.scaneds")}</span>
        </div>
      </div>
    ));
  };

  const s2ab = (s) => {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };

  const returnDataExport = () => {
    let dataExport = [
      [
        t("pages.admin.sales.name"),
        t("pages.admin.sales.lastName"),
        t("pages.admin.sales.email"),
        t("pages.admin.sales.document"),
        t("pages.admin.sales.typeTicket"),
        t("pages.admin.sales.numBought"),
        t("pages.admin.sales.numValidated"),
        t("pages.admin.sales.sellDate"),
        t("pages.admin.sales.statusTransaction"),
        t("pages.admin.salesShop.promoCode"),
        t("pages.admin.sales.totalValueCop"),
      ],
    ];
    vc.allTickets.forEach((ticket) => {
      dataExport.push([
        ticket?.client?.name,
        ticket?.client?.lastName,
        ticket?.client?.email,
        ticket?.client?.numId,
        ticket?.tickets?.[0]?.label,
        ticket?.tickets?.length,
        ticket?.tickets?.filter?.((ticket) => ticket?.status === "validated")
          ?.length,
        moment(ticket?.created, "x").format("YYYY-MM-DD HH:mm"),
        ticket?.status,
        ticket?.promoCode,
        ticket?.tickets?.[0]?.price * ticket?.tickets?.length,
      ]);
    });
    return dataExport;
  };

  const exportData = () => {
    Model.setData("dialog", { open: false });
    const wb = XLSX.utils.book_new();
    const dateNow = moment().format("MM/DD/YYYY");
    const secondNow = moment().format("x");
    wb.Props = {
      Title: t("pages.admin.sales.fileNameExport", {
        name: event.name,
        dateNow,
      }),
      Subject: t("pages.admin.sales.sellFravent"),
      Author: state?.userInfo?.name,
      CreatedDate: new Date(),
    };
    wb.SheetNames.push(t("pages.admin.sales.sells"));
    wb.Sheets[t("pages.admin.sales.sells")] = XLSX.utils.aoa_to_sheet(
      returnDataExport()
    );
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    saveAs(
      new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
      `fravents_sales_${secondNow}.xlsx`
    );
  };

  const showAlertDownload = () => {
    Model.setData("dialog", {
      open: true,
      title: t("pages.admin.sales.alertDownload"),
      text: t("pages.admin.sales.toDownloadInfoAccept"),
      txtLeft: t("commons.cancel"),
      fnLeft: () => Model.setData("dialog", { open: false }),
      txtRight: t("commons.yesConfirm"),
      fnRight: exportData,
    });
  };

  const getPromoCodes = async (isLoading = true) => {
    try {
      Model.setData("loading", isLoading);
      const dataPromoCodes = await AdminService.getPromoCodes({
        eventUid: event.uid,
      });
      let dataArray = [];
      if (dataPromoCodes) {
        dataArray = Object.keys(dataPromoCodes).map((key) => ({
          ...dataPromoCodes[key],
          textPromoCode: key,
        }));
      }
      changeVc({ promoCodes: dataArray });
    } catch (e) {
      Model.updateAlerts({
        message: t("pages.admin.sales.errorGetPromoCode") + String(e),
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  const getMySales = async (callback) => {
    const isLoading = !state.salesEventPartner?.[event.uid];
    try {
      Model.setData("loading", isLoading);

      if (state.salesEventPartner?.[event.uid]) {
        changeVc(state.salesEventPartner?.[event.uid]);
      }

      const dataSales = await AdminService.getMySales(event.uid);
      // Promoters
      const responseContacts = await EventsService.getEventsContacts({
        uidEvent: event.uid,
      });
      // Logistics
      const responseLogistics = await EventsService.getEventsContacts({
        uidEvent: event.uid,
        type: "logistics",
      });
      const data2save = {
        allTickets: dataSales?.allTickets || [],
        tickets2render: dataSales?.allTickets || [],
        promoters: responseContacts || [],
        logistics: responseLogistics || [],
        logisticsStats: dataSales?.logisticsStats || {},
        promotersStats: dataSales?.promotersStats || {},
      };
      changeVc(data2save);
      Model.setData("salesEventPartner", {
        ...state.salesEventPartner,
        [event.uid]: data2save,
      });
    } catch (e) {
      Model.updateAlerts({
        message: t("pages.admin.sales.errorGetSells") + String(e),
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
      if (callback === "getPromoCodes") {
        getPromoCodes(isLoading);
      }
    }
  };

  React.useEffect(() => {
    if (event) {
      getMySales("getPromoCodes");
    } else {
      history.push("/");
      Model.updateAlerts({
        message: t("pages.admin.sales.invalidData"),
        variant: "error",
      });
    }
    return () => {};
    //eslint-disable-next-line
  }, []);

  return (
    <Layout title="ADMINISTRADOR">
      <div className={styles.rowGray}>
        <div className={styles.graphStatsContainer}>
          <div className={styles.row}>
            <p className={styles.title}>
              {t("pages.admin.sales.sellData")} <em>({event?.name})</em>
            </p>
            <button
              className={styles.downloadButton}
              onClick={showAlertDownload}
            >
              <CloudDownload />
              {t("pages.admin.sales.downloadData")}
              <div className={styles.sublabelButton}>
                {t("pages.admin.sales.maxMb")}
              </div>
            </button>
            <button
              className={[styles.uploadButton, styles.uploadButtonMobile].join(
                " "
              )}
              onClick={() => setShowAddListTickets((ov) => !ov)}
            >
              <CloudUpload />
              {t("pages.admin.sales.addTickets")}
              <div className={styles.sublabelButton}>
                {t("pages.admin.sales.maxUploadTickets")}
              </div>
            </button>
          </div>
          <div className={[styles.row, styles.rowQuickStats].join(" ")}>
            {renderQuickStats()}
          </div>
          <div className={styles.gridCards}>
            <div className={styles.cardGraph} style={{ gridArea: "pie" }}>
              <Pie
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    title: {
                      display: true,
                      text: t("pages.admin.sales.sellsByType"),
                    },
                    tooltip: {
                      callbacks: {
                        label: (tooltipItem) => {
                          return (
                            tooltipItem?.label +
                            ": $" +
                            tooltipItem?.formattedValue
                          );
                        },
                      },
                    },
                  },
                }}
                data={returnDataIncome()}
              />
            </div>

            <div className={styles.cardGraph} style={{ gridArea: "chart1" }}>
              <Bar
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      display: false,
                    },
                    title: {
                      display: true,
                      text: t("pages.admin.sales.quantityTicketGenerated"),
                    },
                  },
                }}
                data={returnDataTypes()}
              />
            </div>

            <div className={styles.cardGraph} style={{ gridArea: "line" }}>
              <Grid
                container
                className={[styles.row, styles.rowRangeDateSwitch].join(" ")}
              >
                <Grid item>{t("pages.admin.sales.lastMonth")}</Grid>
                <Grid item>
                  <Switch
                    checked={vc.historyDate === "year"}
                    onChange={rageDateHandleChange}
                    className={styles.switchContainer}
                  />
                </Grid>
                <Grid item>
                  <button
                    className={styles.yearButton}
                    onClick={(event) => {
                      setAnchorYear(event.currentTarget);
                    }}
                  >
                    {t("pages.admin.sales.year")} {vc.selectedYear}
                  </button>
                  <Menu
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    anchorEl={anchorYear}
                    open={Boolean(anchorYear)}
                    onClose={() => setAnchorYear(null)}
                  >
                    {valueYears.map((value) => (
                      <MenuItem
                        key={value}
                        onClick={() => changeYearRef(value)}
                      >
                        <ListItemText primary={value} />
                      </MenuItem>
                    ))}
                  </Menu>
                </Grid>
              </Grid>
              <Line
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      display: false,
                    },
                    title: {
                      display: true,
                      text: t("pages.admin.sales.historySells", {
                        historyDate:
                          vc.historyDate === "month"
                            ? yearLabels[parseInt(moment().format("M")) - 1]
                            : vc.selectedYear,
                      }),
                    },
                  },
                  scales: {
                    yAxis: {
                      beginAtZero: true,
                      ticks: { stepSize: 1 },
                    },
                  },
                }}
                data={{
                  labels: vc.historyDate === "year" ? yearLabels : monthLabels,
                  datasets: [
                    {
                      data: returnHistorySales(),
                      borderColor: "rgb(255, 99, 132)",
                      backgroundColor: "rgba(255, 99, 132, 0.5)",
                      yAxisID: "yAxis",
                    },
                  ],
                }}
              />
            </div>

            <div
              className={[styles.cardGraph, styles.noHeight].join(" ")}
              style={{ gridArea: "refered" }}
            >
              <p className={styles.titleCardGraph}>
                {t("pages.admin.sales.promoters")}
              </p>
              <div className={styles.contListCard}>{renderPromoters()}</div>
            </div>
            <div
              className={[styles.cardGraph, styles.noHeight].join(" ")}
              style={{ gridArea: "logistic" }}
            >
              <p className={styles.titleCardGraph}>
                {t("pages.admin.sales.logistics")}
              </p>
              <div className={styles.contListCard}>{renderLogistics()}</div>
            </div>
          </div>

          <div className={[styles.row, styles.rowPromoCodesTitle].join(" ")}>
            <p className={styles.title}>{t("pages.admin.sales.promoCodes")}</p>
            <button className={styles.uploadButton} onClick={showPromoCodeForm}>
              <Add />
              {t("pages.admin.sales.createNewPromoCode")}
            </button>
          </div>
          <div className={[styles.cardGraph, styles.cardPromos].join(" ")}>
            <div className={[styles.contListCard, styles.listPromos].join(" ")}>
              {renderPromoCodes()}
            </div>
          </div>
          <div className={styles.blank} />
        </div>
        <div className={styles.notificationsContainer}>
          <div className={styles.row}>
            <p className={styles.title}>{t("pages.admin.sales.records")}</p>
            {/* <Grid container className={styles.row}>
              <Grid item>Último mes</Grid>
              <Grid item>
                <Switch
                  checked={true}
                  onChange={rageDateHandleChange}
                  name="rangeDate"
                  className={styles.switchContainer}
                />
              </Grid>
              <Grid item>Año</Grid>
            </Grid> */}
          </div>

          <div className={[styles.cardGraph, styles.salesListHeight].join(" ")}>
            <div className={styles.row}>
              <input
                className={styles.inputsearch}
                placeholder={t("pages.admin.sales.searchBy")}
                value={vc.textSearch}
                onChange={(e) => changeState("textSearch", e)}
                onKeyDown={onKeyDown}
              />
              {vc.textSearch || vc.selectedFilter ? (
                <button
                  className={styles.filterButton}
                  onClick={() =>
                    changeVc({
                      textSearch: "",
                      tickets2render: vc.allTickets,
                      selectedFilter: "",
                    })
                  }
                >
                  X
                </button>
              ) : null}
              <button
                className={styles.filterButton}
                onClick={(event) => {
                  setAnchorFilter(event.currentTarget);
                }}
              >
                <span>{renderLabelFilter()}</span>
              </button>
              <Menu
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                anchorEl={anchorFilter}
                open={Boolean(anchorFilter)}
                onClose={() => setAnchorFilter(null)}
              >
                <MenuItem onClick={() => filterData("all")}>
                  <ListItemText primary={t("pages.admin.sales.alla")} />
                </MenuItem>
                <MenuItem onClick={() => filterData("payed")}>
                  <ListItemText primary={t("pages.admin.sales.completed")} />
                </MenuItem>
                <MenuItem onClick={() => filterData("pending")}>
                  <ListItemText primary={t("pages.admin.sales.pending")} />
                </MenuItem>
                <MenuItem onClick={() => filterData("rejected")}>
                  <ListItemText primary={t("pages.admin.sales.rejected")} />
                </MenuItem>
                <MenuItem onClick={() => filterData("transferred")}>
                  <ListItemText primary={t("pages.admin.sales.transfered")} />
                </MenuItem>
              </Menu>
              <button
                className={styles.searchrButton}
                onClick={() => filterData()}
              >
                {t("pages.admin.sales.search")}
              </button>
            </div>
            <div className={styles.contListCard}>
              {renderListSales()}
              {vc.tickets2render.length > 0 &&
              vc.tickets2render.length > sizeList ? (
                <button
                  className={styles.refreshButton}
                  onClick={() => setSizeList((ov) => ov + 20)}
                >
                  <Refresh /> &nbsp; {t("pages.admin.sales.loadMode")}
                </button>
              ) : null}
            </div>
          </div>
          <div className={[styles.row, styles.rowQuickStats].join(" ")}>
            {renderQuickStats("register")}
          </div>
        </div>
      </div>

      <Modal
        open={showAddListTickets}
        onClose={() => setShowAddListTickets(false)}
        className={styles.lessIndexModal}
      >
        <>
          <AddTicketsList
            inputFileRef={inputFileRef}
            onCancel={() => setShowAddListTickets(false)}
            onConfirm={getMySales}
            event={event}
          />
        </>
      </Modal>

      <DialogForm
        show={vc.showPromptPromoCode}
        title={t("pages.admin.sales.infoPromoCode")}
        text={t("pages.admin.sales.addDataPreferences")}
        subtext={t("pages.admin.sales.textMustBeUnique")}
        leftBtText={t("commons.cancel")}
        funcLeft={() =>
          changeVc({
            showPromptPromoCode: false,
            ...initialValuesPromoCode,
          })
        }
        rightBtText={t("commons.accept")}
        funcRight={onSendPromoCode}
        inputs={returnInputsPromoCode()}
        vc={vc}
        changeVc={changePromoCodeValue}
      />

      <Drawer
        anchor="bottom"
        open={showDrawer || !!selectedMember?.open}
        onClose={() => {
          setShowDrawer(false);
          setSelectedMember({ open: false });
        }}
        className={styles.cardDrawer}
      >
        {showDrawer ? (
          <DetailTickets
            history={{
              push: () => {
                // setShowDrawer(false);
              },
              goBack: () => setShowDrawer(false),
            }}
          />
        ) : null}
        {!!selectedMember?.open ? (
          <DetailMember
            history={{
              push: () => {
                // setSelectedMember({open: false})
              },
              goBack: () => setSelectedMember({ open: false }),
            }}
            location={selectedMember}
            hideLayout
          />
        ) : null}
        <Loading />
      </Drawer>
    </Layout>
  );
};

export default Sales;
