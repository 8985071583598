import React from "react";
import { useTranslation } from "react-i18next";

import LocalStorage from "../../utils/localStorage";
import { Store } from "../../hooks/main_store";
import * as Routes from "../../utils/routes";
import { returnCurrentLink } from "../../utils/validations";
import Model from "../../hooks/Model";
import pageStyles from "./styles";

const Footer = ({ hideFooterLinks, history }) => {
  const { t, i18n } = useTranslation();
  const styles = pageStyles();
  const { state } = React.useContext(Store);

  const anchors = [
    { label: t("components.footer.begin"), view: Routes.HOME },
    { label: t("components.footer.profile"), view: Routes.PROFILE },
    { label: t("components.footer.myShopping"), view: Routes.MY_TRANSACTIONS },
  ];

  if (state?.isWhiteLabel) {
    anchors.splice(0, 1);
  }

  const onChangeLanguage = () => {
    const currentLang = LocalStorage(false).getItem("defaultLanguage");
    const newLang = currentLang === "es" ? "en" : "es";
    i18n.changeLanguage(newLang, () => {
      LocalStorage(state.isIncognito).setItem("defaultLanguage", newLang);

      if (!state.uid) {
        Model.setData("defaultCurrency", newLang === "en" ? "USD" : "COP");
      }
    });
  };

  React.useEffect(() => {
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <div
        className={[styles.contFooter, hideFooterLinks ? styles.hide : ""].join(
          " "
        )}
      >
        <div className={styles.column}>
          <div className={styles.logo} onClick={() => history.push("/")}>
            <img
              alt="FRAVENTS"
              src={require("../../assets/images/Logo-Fravents-blanco.svg")}
              className={styles.imgLogo}
            />
          </div>
        </div>
        <div className={styles.column}>
          <p className={styles.titleColumn}>{t("components.footer.siteMap")}</p>
          <button
            className={styles.languageSwitcher}
            onClick={onChangeLanguage}
          >
            {i18n.language === "en"
              ? "🇨🇴  Versión Español"
              : "🇺🇸  English version (beta)"}
          </button>
          {anchors.map((anchor) => (
            <a
              className={styles.link}
              href={returnCurrentLink(anchor.view)}
              key={anchor.view}
            >
              {anchor.label}
            </a>
          ))}

          <a
            className={styles.link}
            href={returnCurrentLink(
              Routes.RENDER_LEGAL_DOC + "?document=privacy"
            )}
          >
            {t("components.footer.privacyPolicy")}
          </a>

          <a
            className={styles.link}
            href={returnCurrentLink(
              Routes.RENDER_LEGAL_DOC + "?document=terms"
            )}
          >
            {t("components.footer.termsConditions")}
          </a>
        </div>
        <div className={styles.column}>
          <p className={styles.titleColumn}>{t("components.footer.contact")}</p>
          <a className={styles.link} href={returnCurrentLink(Routes.FAQ)}>
            {t("components.footer.faq")}
          </a>
          <a
            className={styles.link}
            href={returnCurrentLink(Routes.CONTACT_PUBLISH)}
          >
            {t("components.footer.wantPublishEvent")}
          </a>
          <a
            className={styles.link}
            href={returnCurrentLink(Routes.PROGRAM_INFO)}
          >
            {t("components.footer.communityFraventsPartners")}
          </a>
          <a className={styles.link} href="mailto:help@fravents.com">
            help@fravents.com
          </a>
          <a
            className={styles.link}
            href="https://www.instagram.com/fravents/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Instagram
          </a>
          {/* <a
            className={styles.link}
            href="https://www.tiktok.com/@fravents"
            target="_blank"
            rel="noopener noreferrer"
          >
            TikTok
          </a> */}
          <a
            className={styles.link}
            href={"https://www.sic.gov.co/"}
            target="blank"
          >
            {t("components.footer.sicLink")}
          </a>
        </div>
      </div>
      {state?.isWhiteLabel ? null : (
        <div className={styles.copyright}>
          FRAVENTS © 2023 - v. {state.version} <br />
          🇨🇴 {t("components.footer.madeIn")}
        </div>
      )}
    </>
  );
};

export default Footer;
