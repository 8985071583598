import { makeStyles } from "@material-ui/core/styles";
import { colors } from "../../styleguide";
import Commons from "../../utils/styles";

const useStyles = makeStyles({
  contentBanner: {
    ...Commons.flexRow,
    backgroundColor: colors.black,
    height: 480,
    position: "relative",
    marginTop: -90,
    paddingTop: 90,
    [Commons.smallQuery]: {
      ...Commons.flexColumn,
      justifyContent: "space-around",
    },
  },
  imageBanner: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    objectFit: "cover",
  },
  filterBg: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    objectFit: "cover",
    backgroundColor: colors.black,
    opacity: 0.4,
  },
  infoBanner: {
    position: "relative",
    flex: 3,
    margin: "0 60px",
    [Commons.smallQuery]: {
      flex: "unset",
      margin: "0 18px",
      marginBottom: -60,
    },
  },
  titleBanner: {
    color: colors.themeColor,
    fontWeight: "700",
    fontSize: 48,
    margin: 0,
    textShadow: colors.textShadow,
    [Commons.smallQuery]: {
      fontSize: 30,
      textAlign: "center",
    },
  },
  descriptionBanner: {
    color: colors.white,
    fontSize: 24,
    margin: 0,
    marginTop: 6,
    [Commons.smallQuery]: {
      fontSize: 17,
      textAlign: "center",
    },
  },
  videoBannerContainer: {
    position: "relative",
    marginRight: 60,
    flex: 2,
    textAlign: "center",
    [Commons.smallQuery]: {
      marginRight: 0,
      flex: "unset",
      width: "100%",
    },
  },
  shadowBanner: {
    width: "100%",
    height: 30,
    background: "linear-gradient(180deg, gray 0%, white 100%)",
    position: "absolute",
    top: "100%",
  },
  videoBanner: {
    width: "100%",
    maxWidth: "40vw",
    borderRadius: 15,
    overflow: "hidden",
    boxShadow: colors.shadow,
    maxHeight: 420,
    backgroundColor: colors.gray50,
    [Commons.smallQuery]: {
      maxWidth: "60vw",
    },
  },
  toggleVideo: {
    ...Commons.flexCenter,
    ...Commons.centerHorizontal,
    maxWidth: "40vw",
    position: "absolute",
    height: "100%",
    width: "100%",
    zIndex: 1,
    top: 0,
    [Commons.smallQuery]: {
      maxWidth: "60vw",
    },
    "& svg": {
      color: colors.white,
      fontSize: 78,
    },
  },
});

export default useStyles;
