import React, { useEffect } from "react";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";

import Avatar from "../../assets/images/avatar.jpeg";
import { Store } from "../../hooks/main_store";
import Model from "../../hooks/Model";
import pageStyles from "./styles";

const LayoutListEvents = ({
  children,
  tabSelected,
  topBtns = [],
  bottomBtns = [],
}) => {
  const styles = pageStyles();
  const { state } = React.useContext(Store);
  const [openDrawer, setOpenDrawer] = React.useState(true);
  const drawerContainer = React.useRef();

  const renderTopBtns = () =>
    topBtns.map((infoBtn, index) => (
      <button
        key={infoBtn?.label}
        className={[
          styles.btnAction,
          tabSelected === index ? styles.btnActionSelected : "",
          openDrawer ? "" : styles.smallIcon,
        ].join(" ")}
        onClick={infoBtn?.callBack}
      >
        {infoBtn?.icon}
        <span className={openDrawer ? "" : styles.hideLabel}>
          {infoBtn?.label}
        </span>
      </button>
    ));

  const renderBottomBtns = () =>
    bottomBtns.map((infoBtn) => (
      <button
        key={infoBtn?.label}
        className={[
          styles.btnAction,
          openDrawer ? "" : styles.smallIcon,
          infoBtn?.highlighted ? styles.btnActionHighlighted : "",
        ].join(" ")}
        onClick={infoBtn?.callBack}
      >
        {infoBtn?.icon}
        {openDrawer ? <span>{infoBtn?.label}</span> : null}
      </button>
    ));

  const onToggleDrawer = () => {
    if (drawerContainer?.current?.style) {
      if (!openDrawer) {
        setOpenDrawer(true);
        localStorage.removeItem("@closeDrawer");
      } else {
        setOpenDrawer(false);
        localStorage.setItem("@closeDrawer", "true");
      }
    }
  };

  useEffect(() => {
    const closeDrawer = localStorage.getItem("@closeDrawer");

    if (closeDrawer === "true" && openDrawer) {
      onToggleDrawer();
    }

    Model.setData("withTabs", true);
    return () => {
      Model.setData("withTabs", false);
    };
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <div className={styles.container}>
        <div
          className={[
            styles.leftContainer,
            openDrawer ? "" : styles.hideContainer,
          ].join(" ")}
          ref={drawerContainer}
        >
          <div className={styles.contImgProfile}>
            <img
              src={state?.userInfo?.imageProfile || Avatar}
              alt="profile"
              className={styles.imageProfile}
            />
          </div>
          <div
            className={[
              styles.actionsContainer,
              openDrawer ? "" : styles.moreHeight,
            ].join(" ")}
          >
            {renderTopBtns()}
            <div className={styles.blankFlex}></div>
            {renderBottomBtns()}
          </div>
          <button
            className={styles.toggleContainerButton}
            onClick={onToggleDrawer}
          >
            {openDrawer ? <ChevronLeft /> : <ChevronRight />}
          </button>
        </div>
        <div
          className={[
            styles.rightContainer,
            openDrawer ? "" : styles.fullContainer,
          ].join(" ")}
        >
          {children}
        </div>
      </div>
    </>
  );
};

export default LayoutListEvents;
