import { makeStyles } from "@material-ui/styles";
import Commons from "../../../utils/styles";
import { colors } from "../../../styleguide";

const styles = makeStyles({
  rowGray: {
    ...Commons.flexRow,
    justifyContent: "center",
    backgroundColor: colors.grayLight,
    height: "calc(100vh - 89px)",
    [Commons.salesQuery]: {
      flexDirection: "column",
      height: "auto",
    },
  },
  columnGray: {
    padding: "30px 60px",
    backgroundColor: colors.grayLight,
    borderRadius: 12,
    minWidth: 1080,
    [Commons.smallQuery]: {
      padding: "30px 12px",
      minWidth: "unset",
    },
  },
  graphStatsContainer: {
    flex: 2,
    padding: "30px 60px",
    height: "calc(100% - 60px)",
    overflow: "auto",
    [Commons.salesQuery]: {
      padding: "30px 12px",
      height: "auto",
      overflow: "visible",
      width: "calc(100% - 24px)",
    },
  },
  notificationsContainer: {
    backgroundColor: colors.white,
    flex: 1,
    minWidth: 390,
    borderLeft: `1px solid ${colors.grayBlack}`,
    height: "calc(100% - 60px)",
    padding: "30px 18px",
    [Commons.salesQuery]: {
      minWidth: "auto",
      padding: "30px 12px",
      height: "auto",
      width: "calc(100% - 25px)",
      borderLeft: "none",
      borderTop: `1px solid ${colors.grayBlack}`,
    },
  },
  row: {
    ...Commons.flexRow,
    justifyContent: "center",
    width: "auto",
    [Commons.smallQuery]: {
      flexWrap: "wrap",
    },
  },
  title: {
    color: colors.mainxText,
    fontWeight: "700",
    fontSize: 30,
    margin: "0 0 12px 0",
    flex: 1,
    "& em": {
      fontSize: 14,
      fontStyle: "normal",
    },
    [Commons.smallQuery]: {
      fontSize: 18,
    },
  },
  visitPartner: {
    ...Commons.defaultButton,
    ...Commons.flexRow,
    color: colors.themeColor,
    fontSize: 16,
    fontWeight: "600",
  },
  logoPartner: {
    width: 30,
    height: 30,
    objectFit: "cover",
    borderRadius: "50%",
    overflow: "hidden",
    marginRight: 12,
  },
  switchContainer: {
    "& .MuiSwitch-colorSecondary.Mui-checked": {
      color: colors.themeColor,
    },
    "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
      backgroundColor: colors.themeColor,
    },
  },
  yearButton: {
    ...Commons.defaultButton,
    backgroundColor: colors.white,
    color: colors.themeColor,
    borderRadius: 6,
    boxShadow: colors.shadow,
    padding: "6px 18px",
    fontWeight: "bold",
  },
  rowQuickStats: {
    paddingTop: 36,
    gap: 24,
    justifyContent: "space-between",
    [Commons.smallQuery]: {
      paddingTop: 0,
    },
  },
  cardQuickStat: {
    flex: 1,
    borderRadius: 12,
    boxShadow: `0px 14px 18px ${colors.themeColor}30`,
    backgroundColor: colors.white,
    padding: "30px 18px",
    "& svg": {
      color: colors.gray50,
    },
    "& p": {
      margin: 0,
      paddingTop: 12,
      fontWeight: "600",
      fontSize: 18,
    },
    "& span": {
      color: colors.gray50,
      fontSize: 14,
    },
    [Commons.smallQuery]: {
      ...Commons.flexRow,
      justifyContent: "center",
      padding: "12px 6px",
      width: "fit-content",
      flex: "auto",
      "& p": {
        padding: "0 12px",
      },
    },
  },
  rowPromoCodesTitle: {
    paddingTop: 18,
  },
  rowRangeDateSwitch: {
    width: "fit-content !important",
    position: "absolute",
    right: 24,
    top: 4,
    [Commons.smallQuery]: {
      padding: "30px 0 18px 0",
    },
  },
  downloadButton: {
    ...Commons.defaultButton,
    ...Commons.flexRow,
    backgroundColor: colors.white,
    color: colors.themeColor,
    borderRadius: 6,
    boxShadow: colors.shadow,
    padding: "6px 18px",
    fontWeight: "bold",
    marginRight: 30,
    "& svg": {
      marginRight: 6,
    },
    [Commons.smallQuery]: {
      marginRight: 0,
    },
  },
  mobileMarginBotton: {
    [Commons.smallQuery]: {
      marginBottom: 36,
    },
  },
  uploadButton: {
    ...Commons.defaultButton,
    ...Commons.flexCenter,
    backgroundColor: colors.themeColor,
    color: colors.white,
    borderRadius: 6,
    boxShadow: colors.shadow,
    padding: "6px 18px",
    fontWeight: "bold",
    "& svg": {
      marginRight: 6,
    },
  },
  uploadButtonMobile: {
    [Commons.smallQuery]: {
      margin: "30px 0 36px 0",
      width: "100%",
      padding: "24px 18px",
    },
  },
  sublabelButton: {
    position: "absolute",
    bottom: -20,
    color: colors.themeColor,
    fontSize: 12,
    fontWeight: "500",
    textAlign: "center",
    width: "100%",
    left: 0,
    ...Commons.ellipsis,
  },
  blank: {
    height: 120,
    [Commons.salesQuery]: {
      height: 30,
    },
  },
  blankSmall: {
    height: 42,
  },
  gridCards: {
    padding: "42px 0 30px 0",
    position: "relative",
    display: "grid",
    gridTemplateAreas: `
    'pie chart1'
    'line line'
    'refered logistic'`,
    gap: 18,
    gridAutoColumns: "minmax(100px, 50vw)",
    [Commons.smallQuery]: {
      ...Commons.flexColumn,
    },
  },
  lessMarginTop: {
    marginTop: -30,
  },
  cardGraph: {
    backgroundColor: colors.white,
    borderRadius: 12,
    padding: 12,
    position: "relative",
    height: 330,
    maxHeight: 330,
    "& canvas": {
      width: "100% !important",
      height: "318px !important",
    },
    width: "calc(100% - 24px)",
  },
  cardPromos: {
    height: 390,
    maxHeight: 390,
  },
  noHeight: {
    height: "fit-content",
    [Commons.smallQuery]: {
      maxHeight: "none",
    },
  },
  salesListHeight: {
    height: "calc(100% - 276px)",
    maxHeight: "none",
    padding: "12px 0",
    width: "100%",
  },
  titleCardGraph: {
    textAlign: "center",
    fontWeight: 600,
    fontSize: 14,
    margin: 12,
  },
  bodyCardGraph: {
    textAlign: "center",
    fontWeight: 400,
    fontSize: 14,
    margin: "-6px 0 18px 0",
    opacity: 0.75,
  },
  contListCard: {
    border: `1px solid ${colors.grayBlack}`,
    width: "100%",
    borderRadius: 6,
    margin: "0 auto",
    overflow: "auto",
    height: "calc(100% - 48px)",
    [Commons.salesQuery]: {
      maxHeight: 360,
    },
  },
  move2rightButton: {
    ...Commons.defaultButton,
    ...Commons.flexCenter,
    ...Commons.centerVertical,
    position: "absolute",
    backgroundColor: colors.grayBlack,
    boxShadow: colors.shadow,
    minWidth: 48,
    minHeight: 48,
    borderRadius: "24px",
    color: colors.gray50,
    fontSize: 36,
    right: -32,
    zIndex: 1,
    transition: "all 0.3s",
    border: "2px solid transparent",
    "&.active": {
      backgroundColor: colors.themeColor,
      color: colors.white,
    },
    "&.active-rev": {
      color: colors.red,
      backgroundColor: colors.white,
      transform: "rotate(180deg)",
      borderColor: colors.red,
      "& .label": {
        transform: "rotate(180deg)",
      },
      [Commons.smallQuery]: {
        bootom: -90,
      },
    },
    "& .label": {
      display: "none",
      fontSize: 18,
      fontWeight: "600",
      margin: "0 24px",
    },
    "&:hover": {
      "& svg": {
        display: "none",
        [Commons.smallQuery]: {
          display: "block",
        },
      },
      "& .label": {
        display: "block",
      },
    },
    [Commons.smallQuery]: {
      ...Commons.centerHorizontal,
      bottom: -18,
      zIndex: 0,
      padding: "0 12px",
      top: "unset",
      "& .label": {
        display: "block",
        margin: "0 0 0 12px",
        fontSize: 15,
      },
    },
  },
  refreshButton: {
    ...Commons.defaultButton,
    ...Commons.flexRow,
    justifyContent: "center",
    textAlign: "center",
    width: "100%",
    color: colors.themeColor,
    padding: "12px 0",
    margin: "12px 0",
    fontWeight: "bold",
    fontSize: 18,
  },
  listPromos: {
    height: "100%",
  },
  itemSale: {
    ...Commons.flexRow,
    borderBottom: `1px solid ${colors.grayBlack}`,
    padding: "12px 18px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: colors.grayLight,
    },
  },
  orangeBg: {
    backgroundColor: `${colors.orange}25 !important`,
  },
  blueBg: {
    backgroundColor: `${colors.blue}25 !important`,
  },
  nameItemSale: {
    flex: 1,
    fontSize: 14,
    "& span": {
      fontSize: 12,
      opacity: 0.5,
    },
  },
  ticketsItemSale: {
    flex: 1,
    fontSize: 14,
    textAlign: "center",
    "& span": {
      fontSize: 12,
      opacity: 0.5,
    },
  },
  dateItemSale: {
    flex: 1,
    color: colors.gray50,
    fontSize: 12,
    textAlign: "right",
    "& span": {
      fontSize: 12,
      opacity: 0.5,
    },
  },
  viewItemSale: {
    ...Commons.defaultButton,
    color: colors.themeColor,
    fontWeight: "700",
    fontSize: 14,
    padding: "0 6px",
    marginLeft: 12,
  },
  selectItemSale: {
    ...Commons.defaultButton,
    ...Commons.flexCenter,
    border: `2px solid ${colors.themeColor}`,
    width: 18,
    height: 18,
    borderRadius: "50%",
    marginLeft: 12,
    "&.selected": {
      backgroundColor: colors.themeColor,
    },
  },
  dateItemPromoCode: {
    flex: 1,
    color: colors.gray50,
    fontSize: 12,
    textAlign: "center",
    "& span": {
      fontSize: 12,
      opacity: 0.5,
    },
  },
  itemPromoCode: {
    cursor: "auto",
    minWidth: 438,
  },
  actionsItemSale: {
    "& button": {
      ...Commons.defaultButton,
      marginLeft: 12,
    },
  },
  inputsearch: {
    flex: 1,
    outline: "none",
    border: "0px solid black",
    borderColor: colors.grayLight,
    marginRight: 12,
    marginBottom: 18,
    color: colors.themeColor,
    backgroundColor: colors.white,
    boxShadow: colors.shadow,
    borderRadius: 6,
    padding: "6px 18px",
    [Commons.smallQuery]: {
      width: "100%",
      marginRight: 0,
      flex: "auto",
    },
  },
  filterButton: {
    ...Commons.defaultButton,
    marginBottom: 18,
    padding: "6px 12px",
    marginLeft: 12,
    color: colors.themeColor,
    backgroundColor: colors.white,
    boxShadow: colors.shadow,
    borderRadius: 6,
    [Commons.smallQuery]: {
      marginLeft: 0,
      flex: 1,
    },
  },
  searchrButton: {
    ...Commons.defaultButton,
    marginBottom: 18,
    marginLeft: 12,
    padding: "6px 12px",
    color: colors.themeColor,
    backgroundColor: colors.white,
    boxShadow: colors.shadow,
    borderRadius: 6,
    [Commons.smallQuery]: {
      flex: 1,
    },
  },
  labelEmpty: {
    textAlign: "center",
  },
  blueColor: {
    color: colors.blue,
  },
  yellowColor: {
    color: colors.yellow,
  },
  redColor: {
    color: colors.red,
  },
  greenColor: {
    color: colors.primary,
  },
  orangeColor: {
    color: colors.orange,
  },
  detailsContainerPromoCode: {
    ...Commons.flexRow,
    alignItems: "flex-start",
    backgroundColor: `${colors.grayLight}75`,
    borderBottom: `1px solid ${colors.grayBlack}`,
    padding: 12,
    transition: "all 0.2s",
    height: 0,
    overflow: "hidden",
    position: "absolute",
    zIndex: -1,
    // [Commons.smallQuery]: {
    //   flexDirection: 'column'
    // },
  },
  dataDetailsPromoCode: {
    flex: 2,
    borderRight: `1px solid ${colors.grayBlack}`,
    "& h5": {
      textAlign: "center",
      fontWeight: "bold",
      fontSize: 18,
      margin: "0 0 6px 0",
    },
    "& .rowData": {
      ...Commons.flexRow,
    },
    "& p": {
      margin: 0,
      textAlign: "center",
      flex: 1,
    },
    [Commons.smallQuery]: {
      width: 676,
      maxWidth: "none",
    },
  },
  changeViewDetailPromoCode: {
    ...Commons.defaultButton,
    backgroundColor: colors.themeColor,
    color: colors.white,
    fontWeight: "bold",
    textAlign: "center",
    borderRadius: 6,
    padding: "6px 12px",
    margin: "0 auto 12px auto",
    display: "none",
    [Commons.smallQuery]: {
      display: "block",
    },
  },
  hideMobileContainer: {
    display: "block !important",
    [Commons.smallQuery]: {
      display: "none !important",
    },
  },
  listDetailsPromoCode: {
    overflow: "auto",
    maxHeight: 228,
    borderTop: `1px solid ${colors.grayBlack}`,
    marginTop: 12,
  },
  graphDetailsPromoCode: {
    flex: 3,

    [Commons.smallQuery]: {
      width: 664,
    },
  },
  openDetails: {
    height: 330,
    zIndex: 0,
    position: "relative",

    [Commons.smallQuery]: {
      flexDirection: "column",
      width: 664,
      maxWidth: "none",
    },
  },
  responsiveMobileContainer: {
    [Commons.smallQuery]: {
      maxHeight: 284,
    },
  },
  cardDrawer: {
    "& div.MuiPaper-root.MuiDrawer-paper": {
      borderRadius: "60px 60px 0 0",
      overflow: "auto",
      maxHeight: "85vh",
    },
  },
  lessIndexModal: {
    zIndex: "2 !important",
  },
});

export default styles;
