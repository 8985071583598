import React from "react";

import { currencyFormat } from "../../utils/validations";
import pageStyles from "./styles";

const ItemProduct = ({ item = {}, onClick = () => {}, customClasses = "" }) => {
  const styles = pageStyles();

  return (
    <div
      className={[styles.itemListProduct, customClasses].join(" ")}
      onClick={() => onClick(item)}
    >
      <div
        style={{ backgroundImage: `url(${item.images[0]})` }}
        className={styles.image}
      />
      <p className={styles.name}>{item.name}</p>
      <p className={styles.price}>{currencyFormat(item.price, "COP", 0)}</p>
    </div>
  );
};

export default ItemProduct;
