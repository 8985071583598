import React from "react";
import { LocalShipping, Done, Cancel } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import moment from "moment";
import _ from "lodash";

import { currencyFormat /*, isBookmarked*/ } from "../../utils/validations";
import NoImage from "../../assets/images/noimage.jpeg";
import pageStyles from "./styles/detailProductStyles";
import Avatar from "../../assets/images/avatar.jpeg";
import { Store } from "../../hooks/main_store";
import ShopService from "../../services/shop";
import AuthService from "../../services/auth";
import SelectQuantity from "./selectQuantity";
import * as Routes from "../../utils/routes";
import Model from "../../hooks/Model";
import Layout from "../layout";

const DetailProduct = ({ history, isPreview = false, previewData }) => {
  const { t, i18n } = useTranslation();
  const styles = pageStyles();
  const { state } = React.useContext(Store);
  const productInfo = previewData || state.productSelected;

  const [selectedImg, setSelectedImg] = React.useState(0);
  const [openQuantity, setOpenQuantity] = React.useState(false);

  const returnMobileStyles = (currentStyle) =>
    [currentStyle, isPreview ? "isMobile" : ""].join(" ");

  const visitStore = () => {
    if (!isPreview) {
      history.push(`partnerProfile?ref=${productInfo?.partner}&selectedTab=1`);
    }
  };

  const renderImages = () => {
    if (productInfo?.images?.length > 0) {
      return (
        <div className={returnMobileStyles(styles.imagesScroll)}>
          {productInfo?.images.map((image, index) => (
            <div
              key={image}
              className={returnMobileStyles(styles.containerPreviewImg)}
              onClick={() => setSelectedImg(index)}
            >
              <img src={image} alt="product" />
            </div>
          ))}
        </div>
      );
    }
  };

  const getProduct = async (uid) => {
    try {
      Model.setData("loading", true);
      const infoProduct = await ShopService.getProduct(uid);
      Model.setData("productSelected", {
        ...infoProduct.product,
        partnerInfo: infoProduct.basicInfo,
      });
      Model.setData("infoPartner", {
        ...infoProduct.basicInfo,
        uid: infoProduct.product.partner,
        logo: state.infoPartner?.logo,
      });
      if (
        state?.infoPartner?.uid &&
        state?.infoPartner?.logo &&
        !state.fistLoad
      ) {
        Model.setData("isWhiteLabel", !!infoProduct?.basicInfo?.isWhiteLabel);
      }
    } catch (e) {
      console.log(t("pages.shop.detailProduct.errorGetProduct"), e);
      if (!productInfo?.name) {
        history.push("/");
      }
    } finally {
      Model.setData("loading", false);
    }
  };

  // const onWishlist = async () => {
  //   try {
  //     const data = productInfo?.uid;
  //     let currentData = state.wishProducts || [];
  //     let increase = 0;
  //     const indexSearch = _.findIndex(state.wishProducts, { uid: data });

  //     if (isPreview) {
  //       return null;
  //     }

  //     if (!state.uid) {
  //       return Model.setData("openAuth", true);
  //     }
  //     if (indexSearch > -1) {
  //       currentData.splice(indexSearch, 1);
  //       increase = -1;
  //     } else {
  //       currentData.push({
  //         uid: data,
  //         created: moment().format("x"),
  //       });
  //       increase = 1;
  //     }

  //     Model.setData("wishProducts", currentData);

  //     await ShopService.updateWishProducts(state.uid, data, increase);

  //     Model.updateAlerts({
  //       message: increase > 0 ? t("pages.shop.detailProduct.productSaved") : t("pages.shop.detailProduct.productDeleted"),
  //       variant: "success",
  //     });
  //   } catch (e) {
  //     console.log(t("pages.shop.detailProduct.errorUpdate"), e);
  //   } finally {
  //     Model.setData("loading", false);
  //   }
  // };

  const updateCart = (typeSelected, quantity) => {
    if (!state.uid) {
      return Model.setData("openAuth", true);
    }

    if (typeSelected === null) {
      return Model.updateAlerts({
        message: t("pages.shop.detailProduct.completeDataTry"),
        variant: "error",
      });
    }

    let newDataCart = [...state.shoppingCart];
    const indexBeforeProduct = newDataCart.findIndex(
      (product) =>
        product.type === productInfo.prices[typeSelected].label &&
        product.uid === productInfo.uid
    );

    if (indexBeforeProduct > -1) {
      newDataCart[indexBeforeProduct].quantity =
        parseInt(newDataCart[indexBeforeProduct].quantity) + parseInt(quantity);
    } else {
      newDataCart.push({
        type: productInfo.prices[typeSelected].label,
        quantity,
        image: productInfo.images[0],
        partner: productInfo.partner,
        name: productInfo.name,
        created: moment().format("x"),
        price: productInfo.prices[typeSelected].price,
        uid: productInfo.uid,
      });
    }

    if (newDataCart[0]?.partner === productInfo.partner) {
      Model.setData("shoppingCart", newDataCart);
      setOpenQuantity(false);

      Model.updateAlerts({
        message: t("pages.shop.detailProduct.infoUpdated"),
        variant: "success",
      });

      AuthService.updateMe(state.uid, { shoppingCart: newDataCart }, true)
        .catch((e) => {
          Model.updateAlerts({
            message: `Error: ${_.get(e, "message", t("commons.unknowError"))}`,
            variant: "error",
          });
        })
        .finally(() => {
          Model.setData("loading", false);
        });
    } else {
      Model.updateAlerts({
        message: t("pages.shop.detailProduct.noAddProduct"),
        variant: "warning",
      });
    }
  };

  const goToPay = (typeSelected, quantity) => {
    if (!state.uid) {
      return Model.setData("openAuth", true);
    }

    if (typeSelected === null) {
      return Model.updateAlerts({
        message: t("pages.shop.detailProduct.completeDataTry"),
        variant: "error",
      });
    }

    updateCart(typeSelected, quantity);
    history.push(Routes.SHOPPING_CART);
  };

  const renderContent = () => (
    <div className={returnMobileStyles(styles.container)}>
      {renderImages()}
      <div className={returnMobileStyles(styles.separatorLeft)} />
      <div
        className={returnMobileStyles(styles.selectedImage)}
        style={{
          backgroundImage: `url(${
            productInfo?.images?.[selectedImg] || NoImage
          })`,
        }}
      ></div>
      <div className={returnMobileStyles(styles.separatorRight)} />
      <div className={returnMobileStyles(styles.infoContainer)}>
        <div
          className={returnMobileStyles(styles.partnerRow)}
          onClick={visitStore}
        >
          <img
            alt="partner"
            src={productInfo?.partnerInfo?.imageProfile || Avatar}
            className={styles.imgPartner}
          />
          <h5 className={styles.nameProvider}>
            {productInfo?.partnerInfo?.name}
          </h5>
          <div className={styles.visitButton}>
            {t("pages.shop.detailProduct.viewShop")}
          </div>
        </div>
        <h3 className={returnMobileStyles(styles.nameProduct)}>
          {productInfo?.name}
        </h3>
        <div className={styles.priceRow}>
          <div className={styles.priceProduct}>
            <span>{currencyFormat(productInfo?.price, "COP", 0)}</span>
          </div>
          <div className={styles.stockProduct}>
            <span>
              {productInfo?.prices
                ?.map?.((type) => type?.stock || 0)
                ?.reduce?.((total, stock) => total + stock, 0) || 0}{" "}
              {t("pages.shop.detailProduct.available")}
              {productInfo?.stock === 1
                ? ""
                : i18n.language === "en"
                ? ""
                : "s"}
            </span>
          </div>
        </div>
        <div className={returnMobileStyles(styles.topBlurScroll)} />
        <p className={styles.descriptionProduct}>
          <br />
          {productInfo?.description}
          <br />
          <br />{" "}
        </p>
        <div className={returnMobileStyles(styles.bottomBlurScroll)} />
        <div className={styles.shipmentRow}>
          {productInfo?.includeShipment ? <Done /> : <Cancel />}
          <LocalShipping />
          <span>
            {productInfo?.includeShipment
              ? t("pages.shop.detailProduct.priceSendIncluded")
              : t("pages.shop.detailProduct.noSendIncluded")}
          </span>
        </div>
        <button
          className={returnMobileStyles(styles.addProductButton)}
          onClick={() => setOpenQuantity(true)}
        >
          {t("pages.shop.detailProduct.startBuy")}
        </button>
        {/* <button
          className={returnMobileStyles(styles.saveWishlistButton)}
          onClick={onWishlist}
        >
          {isBookmarked(productInfo?.uid, state.wishProducts)
            ? t("pages.shop.detailProduct.delete")
            : t("pages.shop.detailProduct.save")}
          de Favoritos
        </button> */}
      </div>
    </div>
  );

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const product = urlParams.get("product");

    if (!isPreview) {
      if (!product && state?.productSelected?.uid && !isPreview) {
        return (window.location.href += `?product=${productInfo?.uid}`);
      } else if (productInfo) {
        if (!productInfo?.partnerInfo) {
          getProduct(productInfo?.uid);
        }
      } else if (product) {
        getProduct(product);
      } else {
        history.push("/");
      }
    }
    //eslint-disable-next-line
  }, [productInfo, state.uid, state.userInfo]);

  React.useEffect(() => {
    if (!isPreview) {
      Model.setData("opacity", 0.7);
      Model.setData("loading", false);
    }
    //eslint-disable-next-line
  }, []);

  return isPreview ? (
    renderContent()
  ) : (
    <Layout blackHeader>
      {renderContent()}
      {isPreview ? null : (
        <SelectQuantity
          onClose={() => setOpenQuantity(false)}
          onSave={updateCart}
          onDone={goToPay}
          data={productInfo}
          show={openQuantity}
        />
      )}
    </Layout>
  );
};

export default DetailProduct;
