import { makeStyles } from "@material-ui/styles";
import Commons from "../../../utils/styles";
import { colors } from "../../../styleguide";

const styles = makeStyles({
  containerError: {
    ...Commons.flexCenter,
    flexDirection: "column",
    width: "100%",
    height: "100%",
    backgroundColor: colors.red,
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 2,
  },
  iconError: {
    color: colors.white,
    fontSize: 72,
  },
  descriptionError: {
    color: colors.white,
    textAlign: "center",
    fontSize: 18,
    width: "90%",
    maxWidth: 300,
  },
  container: {
    width: "calc(100% - 24px)",
    marginLeft: 12,
  },
  contEmpty: {
    ...Commons.flexColumn,
    width: "100%",
    "& svg": {
      fontSize: 96,
      color: colors.gray50,
    },
  },
  emptyMsg: {
    textAlign: "center",
    fontSize: 18,
    color: colors.gray50,
    margin: 0,
    padding: "24px 0 36px 0",
  },
  loadMore: {
    ...Commons.defaultButton,
    textAlign: "center",
    backgroundColor: colors.themeColor,
    color: colors.white,
    fontSize: 18,
    padding: "12px 0",
    borderRadius: 18,
    width: "90%",
    maxWidth: 240,
    margin: "0 auto 48px auto",
    display: "block",
  },
  listItems: {
    ...Commons.flexRow,
    flexWrap: "wrap",
    gap: 18,
    marginTop: 18,
    marginBottom: 48,
    [Commons.smallQuery]: {
      flexDirection: "column",
    },
  },
  itemEvent: {
    flex: 1,
    minWidth: 220,
    margin: 0,
  },
});

export default styles;
