import React from "react";
import { Cancel, CheckCircle } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { QrReader } from "react-qr-reader";

import AdminService from "../../services/admin";
import { Store } from "../../hooks/main_store";
import Model from "../../hooks/Model";
import pageStyles from "./styles";

const QrScanModal = () => {
  const { t } = useTranslation();
  const styles = pageStyles();
  const { state } = React.useContext(Store);
  const [vc, setVc] = React.useState({
    errorCamera: "",
    canScan: "",
    typeOfAlert: null,
    infoTicketVerified: null,
    messageError: "",
  });

  const changeVc = (value) => setVc((ov) => ({ ...ov, ...value }));

  const onScanQr = async (code) => {
    try {
      Model.setData("loading", true);
      const infoTicketVerified = await AdminService.validateQrTicket(
        code + "-0"
      );
      changeVc({ sales2show: [] });
      Model.updateAlerts({
        message: t("components.qrScanModal.validateCodeCompleted"),
        variant: "success",
      });
      changeVc({ errorCamera: false, typeOfAlert: "done", infoTicketVerified });
      // setTimeout(() => {
      //   changeVc({ typeOfAlert: null });
      // }, 3000);
    } catch (e) {
      changeVc({ typeOfAlert: "error", messageError: String(e) });
      Model.updateAlerts({
        message: t("components.qrScanModal.errorValidateInfo") + String(e),
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
      setTimeout(() => {
        changeVc({ canScan: "" /*, typeOfAlert: null*/ });
      }, 2000);
    }
  };

  React.useEffect(() => {
    // onScanQr('1646778871073-BeawYm-1646521321765-BjPeBl')
    if (vc.errorCamera) {
      Model.updateAlerts({
        message: t("components.qrScanModal.noAccessCamera"),
        variant: "error",
      });
    }
    return () => {};
    //eslint-disable-next-line
  }, [vc.errorCamera]);

  React.useEffect(() => {
    if (vc.canScan && !vc.typeOfAlert) {
      onScanQr(vc.canScan);
    }
    return () => {};
    //eslint-disable-next-line
  }, [vc.canScan]);

  if (state.showQrScanModal) {
    return (
      <>
        <div className={styles.modalQr}>
          <button
            className={styles.closeButton}
            onClick={() => {
              changeVc({ errorCamera: false });
              Model.setData("showQrScanModal", false);
            }}
          >
            <Cancel />
          </button>
          <p className={styles.textQr}>
            {vc.errorCamera
              ? t("components.qrScanModal.errorAccessCamera", {
                  errorCamera: vc.errorCamera,
                })
              : t("components.qrScanModal.scanCodeInSquare")}
          </p>
          {vc.errorCamera ? null : (
            <QrReader
              constraints={{ facingMode: "environment" }}
              onResult={(result, error) => {
                if (result?.text) {
                  if (!state?.loading) {
                    changeVc({ canScan: result?.text });
                    // onScanQr(result?.text)
                  } else {
                    setTimeout(() => {
                      Model.setData("loading", false);
                    }, 2000);
                  }
                }
                if (
                  error &&
                  String(error).toLocaleLowerCase().indexOf("denied") > -1 &&
                  !vc.errorCamera
                ) {
                  changeVc({ errorCamera: String(error) });
                }
              }}
              onScan={() => {
                Model.setData("loading", true);
              }}
              videoContainerStyle={{}}
              videoStyle={{}}
              containerStyle={{}}
              ViewFinder={() => <div className={styles.viewFinder}></div>}
            />
          )}
          <button
            className={styles.buttonModalAnswer}
            onClick={() => {
              changeVc({ errorCamera: false });
              Model.setData("showQrScanModal", false);
            }}
          >
            {t("components.qrScanModal.close")}
          </button>
        </div>
        {vc.typeOfAlert ? (
          <div
            className={[
              styles.modalAnswer,
              vc.typeOfAlert === "error" ? styles.modalRed : "",
            ].join(" ")}
          >
            {vc.typeOfAlert === "error" ? <Cancel /> : <CheckCircle />}
            <p className={styles.titleModalAnswer}>
              {t("components.qrScanModal.transactionInfo")}
            </p>
            <p className={styles.descriptionModalAnswer}>
              {vc.typeOfAlert === "error" ? (
                t("components.qrScanModal.codeInvalid", {
                  error: vc.messageError || "404",
                })
              ) : (
                <>
                  <b>
                    {vc.infoTicketVerified?.sellInfo?.products
                      ? t("components.qrScanModal.products")
                      : t("components.qrScanModal.ticketType")}{" "}
                  </b>
                  {vc.infoTicketVerified?.sellInfo?.products
                    ? vc.infoTicketVerified?.sellInfo?.products.map(
                        (product) =>
                          `\n${product.name} ... ${product.type} ... (${product.quantity} und)`
                      )
                    : vc.infoTicketVerified?.ticket}
                  <br />
                  <br />
                  {vc.infoTicketVerified?.sellInfo?.products ? null : (
                    <>
                      <b>{t("commons.quantity")}: </b>1<br />
                    </>
                  )}
                  <b>{t("commons.name")}: </b>
                  {vc.infoTicketVerified?.name}{" "}
                  {vc.infoTicketVerified?.lastName}
                  <br />
                  {vc.infoTicketVerified?.numI ? (
                    <>
                      <b>{t("commons.numId.")}: </b>
                      {vc.infoTicketVerified?.numId}
                      <br />
                    </>
                  ) : null}
                  <b>{t("commons.email")}: </b>
                  {vc.infoTicketVerified?.email}
                </>
              )}
            </p>
            <button
              className={styles.buttonModalAnswer}
              onClick={() => changeVc({ canScan: "", typeOfAlert: null })}
            >
              {t("commons.ready")}
            </button>
          </div>
        ) : null}
      </>
    );
  }

  return null;
};

export default QrScanModal;
