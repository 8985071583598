import { makeStyles } from "@material-ui/core/styles";
import { colors } from "../../styleguide";
import Commons from "../../utils/styles";

const useStyles = makeStyles({
  container: {
    ...Commons.flexCenter,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 11,
  },
  card: {
    backgroundColor: colors.white,
    borderRadius: 18,
    boxShadow: colors.shadow,
    border: `6px solid ${colors.themeColor}`,
    width: "calc(90% - 48px)",
    maxWidth: 480,
    padding: 24,
  },
  title: {
    textAlign: "center",
    color: colors.black,
    fontSize: 18,
    fontWeight: 600,
    margin: "0 0 30px 0",
  },
  label: {
    color: colors.black,
    fontSize: 16,
    fontWeight: 700,
    margin: "12px 0 6px 0",
    borderBottom: `2px solid ${colors.grayBlack}`,
    paddingBottom: 6,
  },
  rowOptions: {
    ...Commons.flexRow,
    justifyContent: "space-around",
    margin: "18px 0 30px 0",
  },
  optionButton: {
    ...Commons.defaultButton,
    textAlign: "center",
    padding: "6px 12px",
    borderRadius: 6,
    border: `2px solid ${colors.grayBlack}`,
    color: colors.black,
    fontSize: 14,
    transition: "all 0.2s",
    "&:hover": {
      backgroundColor: colors.themeColor,
      color: colors.white,
      borderColor: colors.themeColor,
    },
  },
  selectedOption: {
    backgroundColor: colors.themeColor,
    color: colors.white,
    borderColor: colors.themeColor,
  },
  submit: {
    ...Commons.defaultButton,
    boxShadow: colors.shadow,
    backgroundColor: colors.themeColor,
    color: colors.white,
    fontSize: 16,
    fontWeight: 600,
    borderRadius: 6,
    marginTop: 12,
    width: "100%",
    padding: "8px 0",
    transition: "all 0.2s",
  },
  disabled: {
    backgroundColor: colors.gray50,
    opacity: 0.5,
  },
});

export default useStyles;
