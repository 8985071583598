import _ from "lodash";

import { storageService, firestoreService, databaseService } from "./firebase";
import Model from "../hooks/Model";
import api from "./api";

const getProducts = async (uid) => {
  try {
    const response = await api.post("/shop/getProducts", {
      uidPartner: uid,
    });
    if (response.ok && response.status === 200) {
      Model.setData(
        "productsPartner",
        response?.data?.products?.sort?.((a, b) => b.date - a.date) || []
      );
      return response?.data?.products || [];
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw _.get(e, "message", e);
  }
};

const getProduct = async (productUid) => {
  try {
    const response = await api.post("/shop/getProduct", { productUid });
    if (response.ok && response.status === 200) {
      return {
        ...response.data,
        uid: productUid,
      };
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw e;
  }
};

const changeStatusProduct = async (productUid) => {
  try {
    const response = await api.post("/shop/changeStatusProduct", {
      productUid,
    });
    if (response.ok && response.status === 200) {
      return {
        ...response.data,
        uid: productUid,
      };
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw e;
  }
};

const createProduct = async (data) => {
  try {
    const response = await api.post("/shop/createProduct", data);
    if (response.ok && response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw e;
  }
};

const updateProduct = async (data) => {
  try {
    const response = await api.post("/shop/updateProduct", data);
    if (response.ok && response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw e;
  }
};

const updateProductImages = async (
  uid,
  uidProduct,
  images = [],
  pendingPhotos = []
) => {
  try {
    let arrayImages = [];

    if (pendingPhotos?.length > 0) {
      const allPromises = images.map(
        (image, index) =>
          new Promise(async (resolve) => {
            const indexPreview = pendingPhotos.findIndex(
              (item) => item.preview === image
            );
            if (indexPreview > -1) {
              await storageService.uploadImage(
                `products/${uid}/${uidProduct}/imageProduct_${index}.png`,
                pendingPhotos[indexPreview].data
              );

              resolve(
                await storageService.getUrlImage(
                  `products/${uid}/${uidProduct}/imageProduct_${index}.png`
                )
              );
            }

            resolve(image);
          })
      );

      arrayImages = await Promise.all(allPromises);
    } else {
      arrayImages = images;
    }

    return [arrayImages];
  } catch (e) {
    throw _.get(e, "message", e);
  }
};

const updateWishProducts = async (uid, data, increase = 0) => {
  try {
    const newWishList = await firestoreService.updateWishProducts(uid, data);
    await api.post("/shop/updateWishProducts", {
      uidProduct: data,
      data: increase,
    });
    Model.setData("wishProducts", newWishList || []);
    Model.updateUserInfo({ wishProducts: newWishList || [] });
  } catch (e) {
    throw _.get(e, "message", e);
  }
};

const createPromoCode = async (body) => {
  try {
    const response = await api.post("/shop/createPromoCode", body);
    if (response.ok && response.status === 200) {
      return response.data || "";
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw e;
  }
};

const getPromoCodes = async (body) => {
  try {
    const response = await api.post("/shop/getPromoCodes", body);
    if (response.ok && response.status === 200) {
      return response.data || "";
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw e;
  }
};

const deletePromoCode = async (body) => {
  try {
    const response = await api.post("/shop/deletePromoCode", body);
    if (response.ok && response.status === 200) {
      return response.data || "";
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw e;
  }
};

const getHistoryPromoCode = async (body) => {
  try {
    const response = await api.post("/shop/getHistoryPromoCode", body);
    if (response.ok && response.status === 200) {
      return response.data || "";
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw e;
  }
};

const confirmPromoCode = async (body) => {
  try {
    const response = await api.post("/shop/confirmPromoCode", body);
    if (response.ok && response.status === 200) {
      return response.data || "";
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw e;
  }
};

const confirmSells = async (body) => {
  try {
    const response = await api.post("/shop/confirmSells", body);
    if (response.ok && response.status === 200) {
      return response.data || "";
    } else {
      if (response.data?.errorProducts) {
        // eslint-disable-next-line no-throw-literal
        throw { errorProducts: response.data };
      } else {
        throw new Error(response.data);
      }
    }
  } catch (e) {
    throw e;
  }
};

const getMyProducts = async (body) => {
  try {
    const response = await api.post("/shop/getMyProducts", body);
    if (response.ok && response.status === 200) {
      return response.data || [];
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw e;
  }
};

const getMySells = async (body) => {
  try {
    const response = await api.post("/shop/getMySells", body);
    if (response.ok && response.status === 200) {
      return response.data || {};
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw e;
  }
};

const onChangeSellStatus = async (data) => {
  try {
    const response = await api.post("/shop/onChangeSellStatus", data);
    if (response.ok && response.status === 200) {
      return response.data || [];
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw e;
  }
};

const onChatSell = (uid, callback) => {
  return databaseService.onDatabase(`chatrooms/${uid}`, callback);
};

const sendMessage = (uid, data) => {
  return databaseService.setChatroomDatabase(`chatrooms/${uid}`, data);
};

const getMyTeam = async () => {
  try {
    const response = await api.get("/shop/getMyTeam");
    if (response.ok && response.status === 200) {
      return response.data || {};
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw e;
  }
};

const addUserMyTeam = async (payload) => {
  try {
    const response = await api.post("/shop/addUserMyTeam", payload);
    if (response.ok && response.status === 200) {
      return response.data || {};
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw e;
  }
};

const deleteUserMyTeam = async (payload) => {
  try {
    const response = await api.post("/shop/deleteUserMyTeam", payload);
    if (response.ok && response.status === 200) {
      return response.data || {};
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw e;
  }
};

const sendMessageExternal = async (uid, data) => {
  try {
    const response = await api.post("/shop/sendMessageExternal", {
      sell: uid,
      data,
    });
    if (response.ok && response.status === 200) {
      return response.data || {};
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw e;
  }
};

export default {
  getProducts,
  getProduct,
  changeStatusProduct,
  createProduct,
  updateProduct,
  updateProductImages,
  updateWishProducts,
  createPromoCode,
  getPromoCodes,
  deletePromoCode,
  getHistoryPromoCode,
  confirmPromoCode,
  confirmSells,
  getMyProducts,
  getMySells,
  onChangeSellStatus,
  onChatSell,
  sendMessage,
  getMyTeam,
  addUserMyTeam,
  deleteUserMyTeam,
  sendMessageExternal,
};
