import React from "react";
import {
  WatchLater,
  LocationOn,
  Visibility,
  VerifiedUser,
  Report,
  People,
} from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import moment from "moment";

import NoImage from "../../assets/images/noimage.jpeg";
import Avatar from "../../assets/images/avatar.jpeg";
import { Store } from "../../hooks/main_store";
import ItemTypeEvent from "../TypesEvent/item";
import pageStyles from "./styles";

const BannerEvent = ({
  eventInfo = {},
  changeVc = () => {},
  isMobile = false,
  history,
  BarActions = React.Fragment,
}) => {
  const { t } = useTranslation();
  const { state } = React.useContext(Store);

  const styles = pageStyles();

  const returnWithMobileStyles = (normalStyles) =>
    [normalStyles, isMobile ? "isMobile" : ""].join(" ");

  const goToProfile = () =>
    history?.push?.(
      `/${state.isOnPluginPartner ? "pluginPartner" : "partnerProfile"}?ref=${
        eventInfo?.partner
      }`
    );

  return (
    <div className={returnWithMobileStyles(styles.contBanner)}>
      <div className={returnWithMobileStyles(styles.bgContBanner)}>
        <img
          src={require("../../assets/images/dummy/banner3.png")}
          alt="Banner events"
          className={styles.imageBanner}
        />
        <div className={styles.shadowBanner} />
      </div>
      <div className={returnWithMobileStyles(styles.cardContBanner)}>
        <div
          className={returnWithMobileStyles(styles.contImageCardBanner)}
          onClick={() => changeVc({ showPoster: true })}
        >
          <img
            src={eventInfo.imageBanner || NoImage}
            alt="Banner events"
            className={styles.imageCardBanner}
          />
          <div className={styles.contImageEyeBanner}>
            <Visibility />
          </div>
        </div>
        <div className={returnWithMobileStyles(styles.contInfoBanner)}>
          <h2 className={returnWithMobileStyles(styles.title)}>
            {eventInfo.name}
          </h2>
          <p className={returnWithMobileStyles(styles.subtitle)}>
            {t("components.bannerEvent.readInfoBeforeBuy")}
          </p>
          <div className={returnWithMobileStyles(styles.rowInfoBanner)}>
            <People
              className={returnWithMobileStyles(styles.iconRowInfoBanner)}
            />
            <p className={returnWithMobileStyles(styles.textRowInfoBanner)}>
              {t("components.bannerEvent.eventIsFor")}{" "}
              {eventInfo?.isOlder18
                ? t("components.bannerEvent.forOld18")
                : t("components.bannerEvent.forAllAges")}
            </p>
          </div>
          <div className={returnWithMobileStyles(styles.rowInfoBanner)}>
            <WatchLater
              className={returnWithMobileStyles(styles.iconRowInfoBanner)}
            />
            <p className={returnWithMobileStyles(styles.textRowInfoBanner)}>
              {eventInfo.dateEnd ? t("components.bannerEvent.startDate") : ""}
              {eventInfo.date
                ? moment(eventInfo.date, "x").format("hh:00a | DD MMM, YYYY")
                : "-"}

              {eventInfo.dateEnd ? (
                <>
                  <br />
                  {t("components.bannerEvent.endDate")}
                  {moment(eventInfo.dateEnd, "x").format(
                    "hh:00a | DD MMM, YYYY"
                  )}
                </>
              ) : (
                ""
              )}
            </p>
          </div>

          <div className={returnWithMobileStyles(styles.rowInfoBanner)}>
            <LocationOn
              className={returnWithMobileStyles(styles.iconRowInfoBanner)}
            />
            <p className={returnWithMobileStyles(styles.textRowInfoBanner)}>
              {eventInfo?.hideAddress
                ? t("components.bannerEvent.secretLocation")
                : eventInfo.location}
            </p>
          </div>
          {eventInfo?.organizer ? (
            <div className={returnWithMobileStyles(styles.rowInfoBanner)}>
              <div
                className={styles.imgOrganizer}
                style={{
                  backgroundImage: `url(${
                    eventInfo?.organizer?.imageProfile || Avatar
                  })`,
                }}
                onClick={goToProfile}
              />
              <div className={styles.dataOrganizer} onClick={goToProfile}>
                <p className={styles.nameOrganizer}>
                  {eventInfo?.organizer?.name}
                </p>
                <p className={styles.subOrganizer}>
                  {t("components.bannerEvent.organizer")}
                </p>
                <p className={styles.visitOrganizer}>
                  {t("components.bannerEvent.visit")}
                </p>
              </div>
              <div
                className={returnWithMobileStyles(
                  styles.statusContainerOrganizer
                )}
              >
                {eventInfo?.organizer?.verified ? (
                  <VerifiedUser className={styles.verifiedOrganizer} />
                ) : (
                  <Report className={styles.noVerifiedOrganizer} />
                )}
                <p
                  className={returnWithMobileStyles(
                    styles.textVerifiedOrganizer
                  )}
                >
                  {eventInfo?.organizer?.verified
                    ? t("components.bannerEvent.organizerVerified")
                    : t("components.bannerEvent.organizerNoVerified")}
                </p>
              </div>
            </div>
          ) : null}

          {state.loading || eventInfo?.isCancelled ? (
            eventInfo?.isCancelled ? (
              <div className={styles.rowInfoCancelled}>
                <div className={styles.labelInfoCancelled}>
                  {t("components.bannerEvent.cancelledEvent")}
                </div>
                <div className={styles.infoCancelled}>
                  {t("components.bannerEvent.messageCancelled")}{" "}
                  <span onClick={() => history.push("/myTransactions")}>
                    {t("components.bannerEvent.myShopping")}
                  </span>
                </div>
              </div>
            ) : null
          ) : (
            <BarActions />
          )}
        </div>
        <ItemTypeEvent
          defaultValue={eventInfo.type}
          className={styles.typeTag}
          isTag
        />
      </div>
    </div>
  );
};

export default BannerEvent;
