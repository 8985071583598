import React from "react";
import { RemoveCircle, AddCircle, CloseRounded } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import { currencyFormat } from "../../utils/validations";
import NoImage from "../../assets/images/noimage.jpeg";
import { Store } from "../../hooks/main_store";
import AuthService from "../../services/auth";
import * as Routes from "../../utils/routes";
import Model from "../../hooks/Model";
import pageStyles from "./styles";

const ShoppingCartDialog = ({ open, onClose, history }) => {
  const { t } = useTranslation();
  const styles = pageStyles();
  const { state } = React.useContext(Store);
  const [callback, setCallback] = React.useState(null);

  const updateCart = (cart = []) => {
    Model.setData("shoppingCart", cart);

    clearTimeout(callback);
    setCallback(
      setTimeout(() => {
        AuthService.updateMe(state.uid, { shoppingCart: cart }, true)
          .catch((e) => {
            Model.updateAlerts({
              message: `Error: ${_.get(
                e,
                "message",
                t("commons.unknowError")
              )}`,
              variant: "error",
            });
          })
          .finally(() => {
            Model.setData("loading", false);
          });
      }, 6000)
    );
  };

  const clearCart = () => {
    Model.setData("dialog", { open: false });
    updateCart([]);
  };

  const onEmptyCart = () => {
    Model.setData("dialog", {
      open: true,
      title: t("components.shoppingCart.deleteAllProducts"),
      text: t("components.shoppingCart.areAgreeDelete"),
      txtLeft: t("commons.no"),
      fnLeft: () => Model.setData("dialog", { open: false }),
      txtRight: t("commons.yes"),
      fnRight: clearCart,
    });
  };

  const updateItem = (index, quantity) => {
    let currentCart = [...state.shoppingCart];
    const newQuantity =
      parseInt(currentCart[index].quantity) + parseInt(quantity);

    if (newQuantity < 1) {
      currentCart.splice(index, 1);
    } else {
      currentCart[index].quantity = parseInt(newQuantity);
    }

    updateCart(currentCart);
  };

  return (
    <div
      className={[styles.cartContainer, open ? styles.showCart : ""].join(" ")}
    >
      <p className={styles.titleCart}>
        {t("components.shoppingCart.shoppingCart")}
      </p>
      {state.shoppingCart?.length > 0 ? (
        <button className={styles.deleteCart} onClick={onEmptyCart}>
          {t("components.shoppingCart.deleteAll")}
        </button>
      ) : null}
      <button className={styles.closeCart} onClick={onClose}>
        <CloseRounded />
      </button>
      <div className={styles.scrollContainerCart}>
        {state.shoppingCart?.length > 0 ? (
          state.shoppingCart.map((item, index) => (
            <div className={styles.rowItemCart} key={item.created}>
              <img
                className={styles.imageItemCart}
                src={item.image || NoImage}
                alt="Item Cart"
                onClick={() =>
                  history.push(`/detail-product?product=${item.uid}`)
                }
              />
              <div className={styles.rowInfoItemCart}>
                <p className={styles.nameItemCart}>{item.name}</p>
                <p className={styles.typeItemCart}>{item.type}</p>
              </div>
              <button
                className={styles.actionItemCart}
                onClick={() => updateItem(index, -1)}
              >
                <RemoveCircle />
              </button>
              <span className={styles.quantityItemCart}>{item.quantity}</span>
              <button
                className={styles.actionItemCart}
                onClick={() => updateItem(index, 1)}
              >
                <AddCircle />
              </button>
              <p className={styles.totalItemCart}>
                {currencyFormat(
                  parseFloat(item.price) * parseInt(item.quantity),
                  "COP",
                  0
                )}
              </p>
            </div>
          ))
        ) : (
          <p className={styles.emptyLabelCart}>
            {t("components.shoppingCart.noYetAddAnyProduct")}
          </p>
        )}
      </div>
      <div className={styles.rowSubtotalCart}>
        <p className={styles.labelSubtotalCart}>Sub-total:</p>
        <p className={styles.valueSubtotalCart}>
          {state.shoppingCart?.length > 0
            ? currencyFormat(
                state.shoppingCart
                  .map(
                    (item) => parseFloat(item.price) * parseInt(item.quantity)
                  )
                  .reduce(
                    (total, item) => parseFloat(total) + parseFloat(item)
                  ),
                "COP",
                0
              )
            : "$0"}
        </p>
      </div>
      <button
        className={styles.submitButtonCart}
        onClick={() => {
          if (onClose) {
            onClose();
          }
          history.push(Routes.SHOPPING_CART);
        }}
      >
        {t("components.shoppingCart.goTopay")}
      </button>
    </div>
  );
};

export default ShoppingCartDialog;
