import { makeStyles } from "@material-ui/styles";
import Commons from "../../../utils/styles";
import { colors } from "../../../styleguide";

const styles = makeStyles({
  container: {
    ...Commons.flexCenter,
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "100%",
    height: "100%",
    backgroundColor: colors.white,
    overflowY: "auto",
  },
  row: {
    ...Commons.flexRow,
  },
  rowTop: {
    ...Commons.flexRow,
    marginTop: 24,
    width: "90%",
  },
  backBtn: {
    ...Commons.flexCenter,
    ...Commons.defaultButton,
    boxShadow: colors.shadow,
    width: 30,
    height: 30,
    borderRadius: 15,
    backgroundColor: colors.white,
  },
  title: {
    flex: 1,
    marginLeft: 18,
    fontWeight: 600,
    fontSize: 16,
  },
  rowAdditionalInfo: {
    ...Commons.flexRow,
    maxWidth: 560,
    marginTop: 30,
    width: "calc(100% - 60px)",
    [Commons.smallQuery]: {
      flexWrap: "wrap",
    },
  },
  importanteText: {
    textAlign: "center",
    width: "calc(100% - 60px)",
    maxWidth: 990,
    marginTop: 30,
    color: colors.themeColor,
    fontSize: 24,
    [Commons.smallQuery]: {
      fontSize: 18,
    },
  },
  containterPromoter: {
    ...Commons.flexRow,
    backgroundColor: colors.grayLight,
    boxShadow: colors.shadow,
    borderRadius: 18,
    padding: 12,
    flex: 1,
    marginRight: 12,
    cursor: "pointer",
  },
  containerRefunded: {
    border: `3px solid ${colors.themeColor}`,
    padding: 12,
    textAlign: "center",
    borderRadius: 12,
    marginTop: 30,
    width: "calc(100% - 60px)",
    maxWidth: 990,
  },
  titleRefun: {
    color: colors.themeColor,
    textAlign: "center",
    fontSize: 24,
    fontWeight: "700",
    margin: 0,
    marginBottom: 12,
  },
  dataRefund: {
    fontSize: 15,
    margin: 0,
  },
  imagePromoter: {
    minWidth: 48,
    minHeight: 48,
    maxWidth: 48,
    maxHeight: 48,
    borderRadius: "50%",
    backgroundColor: colors.white,
  },
  infoPromoter: {
    flex: 1,
    marginLeft: 6,
  },
  iconLabelPromoter: {
    marginRight: 12,
    fontSize: 24,
    color: colors.themeColor,
  },
  labelPromoter: {
    fontWeight: 600,
    fontSize: 14,
    margin: 0,
  },
  dataPromoter: {
    ...Commons.ellipsis,
    margin: 0,
    fontSize: 16,
  },
  listPromoters: {
    backgroundColor: colors.white,
    border: `1px solid ${colors.themeColor}`,
    borderRadius: 6,
    flex: 1,
    overflow: "auto",
    maxHeight: 120,
  },
  containerNewPromoter: {
    ...Commons.flexRow,
    borderBottom: `1px solid ${colors.grayBlack}`,
    padding: 12,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: colors.grayLight,
    },
  },
  imageNewPromoter: {
    minWidth: 42,
    minHeight: 42,
    maxWidth: 42,
    maxHeight: 42,
    borderRadius: "50%",
    backgroundColor: colors.grayLight,
  },
  nameNewPromoter: {
    ...Commons.ellipsis,
    marginLeft: 6,
    flex: 1,
    fontSize: 15,
    fontWeight: 600,
  },
  emptyPromoters: {
    textAlign: "center",
    fontSize: 14,
    cursor: "pointer",
  },
  containerPayment: {
    flex: 1,
    [Commons.smallQuery]: {
      width: "100%",
      flex: "unset",
      marginTop: 18,
    },
  },
  labelPayment: {
    fontWeight: 600,
    fontSize: 14,
    margin: 0,
  },
  dataPayment: {
    margin: 0,
    color: colors.themeColor,
    fontWeight: 700,
    fontSize: 16,
    lineHeight: "18px",
    cursor: "pointer",
  },
  rowCards: {
    ...Commons.flexRow,
    flexWrap: "wrap",
    width: "100%",
    justifyContent: "center",
    alignItems: "flex-start",
    marginTop: 30,
  },
  contCard: {
    backgroundColor: colors.grayLight,
    width: "calc(50% - 60px)",
    borderRadius: 12,
    boxShadow: colors.shadow,
    padding: "12px 30px",
    margin: "12px 30px 24px 30px",
    maxWidth: 500,
    [Commons.smallQuery]: {
      width: "calc(100% - 60px)",
    },
  },
  redeemButton: {
    ...Commons.defaultButton,
    backgroundColor: colors.themeColor,
    textAlign: "center",
    color: colors.white,
    fontWeight: "bold",
    fontSize: 24,
    padding: "24px 0",
    width: "100%",
    borderRadius: 12,
  },
  secretLocation: {
    textAlign: "center",
    backgroundColor: colors.themeColor,
    marginBottom: 12,
    padding: "12px 0",
    borderRadius: "12px 12px 0 0",
    width: "calc(100% + 60px)",
    marginLeft: -30,
    color: colors.white,
    fontSize: 16,
    marginTop: -12,
    "& span": {
      fontSize: 24,
      fontWeight: "bold",
    },
  },
  nameTicket: {
    textAlign: "center",
    fontSize: 24,
    fontWeight: 600,
    margin: "0px 0px 12px 0px",
  },
  contQr: {
    margin: "0 auto",
    backgroundColor: colors.white,
    padding: 6,
    width: "fit-content",
    marginBottom: 18,
  },
  flexCol: {
    ...Commons.flexColumn,
    flex: 1,
    alignItems: "flex-start",
  },
  flexColR: {
    ...Commons.flexColumn,
    flex: 1,
    alignItems: "flex-end",
  },
  smallLbl: {
    fontWeight: 600,
    fontSize: 12,
    opacity: 0.75,
    margin: 0,
  },
  smallLblRight: {
    fontWeight: 600,
    fontSize: 12,
    opacity: 0.75,
    margin: 0,
    textAlign: "right",
  },
  mediumLbl: {
    fontWeight: 600,
    fontSize: 16,
    opacity: 0.75,
    margin: 0,
  },
  smallLblValue: {
    fontWeight: 700,
    fontSize: 15,
    marginTop: 6,
  },
  colorGreen: {
    color: colors.primary,
  },
  colorPink: {
    color: colors.pink,
  },
  dashLine: {
    width: "calc(100% + 60px)",
    borderBottom: "2px dashed rgba(0, 0, 0, 0.25)",
    position: "relative",
    left: -30,
    margin: "30px 0px",
  },
  lblCard: {
    fontWeight: 700,
    fontSize: 15,
    margin: 0,
    marginBottom: 6,
  },
  lblCancelled: {
    backgroundColor: colors.themeColor,
    padding: "6px 18px",
    borderRadius: 12,
    textAlign: "center",
    width: "fit-content",
    marginBottom: 12,
    fontSize: 12,
    fontWeight: "700",
  },
  infoIcon: {
    fontSize: 14,
  },
  infoTxt: {
    fontSize: 12,
    marginLeft: 6,
    flex: 1,
    opacity: 0.5,
  },
  smallLblCard: {
    fontWeight: 600,
    fontSize: 12,
    margin: 0,
    marginBottom: 6,
    marginTop: 18,
  },
  contTerms: {
    width: "100%",
    position: "relative",
    marginBottom: 18,
  },
  terms: {
    fontSize: 12,
    margin: 0,
    opacity: 0.5,
    textAlign: "justify",
    lineHeight: "12px",
  },
  viewMoreBtn: {
    ...Commons.defaultButton,
    position: "absolute",
    bottom: 0,
    right: 0,
    backgroundColor: colors.grayLight,
    color: colors.themeColor,
    fontSize: 11,
    padding: "0px 6px",
  },
  transferButton: {
    ...Commons.defaultButton,
    backgroundColor: colors.themeColor,
    color: colors.white,
    textAlign: "center",
    fontWeight: "600",
    fontSize: 16,
    width: "calc(100% + 60px)",
    marginTop: 13,
    padding: "18px 0",
    marginBottom: -12,
    borderRadius: "0 0 12px 12px",
    marginLeft: -30,
  },
  noTransferButton: {
    backgroundColor: colors.gray50,
    cursor: "no-drop",
  },
  blank: {
    minHeight: 270,
  },
  red: {
    color: colors.red,
  },
});

export default styles;
