import React from "react";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import NavigationEvent from "../../components/NavigationEvent";
import LocalStorage from "../../utils/localStorage";
import { Store } from "../../hooks/main_store";
import pageStyles from "./styles/layoutStyles";
import AuthService from "../../services/auth";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import * as Routes from "../../utils/routes";
import Model from "../../hooks/Model";

const Layout = ({
  history,
  children,
  hideFooter,
  hideFooterLinks,
  location,
  onScrollDash,
  blackHeader,
}) => {
  const { t } = useTranslation();
  const styles = pageStyles();
  const { state } = React.useContext(Store);
  const contentDashRef = React.useRef(null);

  const signOut = () => {
    const tokenPlugin = LocalStorage(state.isIncognito).getItem(
      "token_plugin_partner"
    );
    let isOnPluginPartner = false;

    try {
      isOnPluginPartner = window.self !== window.top;
    } catch (e) {
      isOnPluginPartner = true;
    }

    AuthService.signOut(() => {
      Model.setData("isOnPluginPartner", isOnPluginPartner);
      LocalStorage(state.isIncognito).setItem(
        "token_plugin_partner",
        tokenPlugin
      );
    }).finally(() => {});
  };

  const returnStepLabelPlugin = () => {
    const pathname = window.location.pathname;

    switch (pathname) {
      case Routes.HOME:
        return t("pages.layout.selectEvent");
      case Routes.EVENTS:
        return t("pages.layout.reviewBuy");
      case Routes.PLUGIN_PARTNER:
        return t("pages.layout.selectEvent");
      case Routes.MY_TRANSACTIONS:
        return t("pages.layout.hereTickets");
      default:
        return "Fravents";
    }
  };

  React.useEffect(() => {
    if (state.moveLayoutScroll && contentDashRef?.current?.scrollTo) {
      contentDashRef.current.scrollTo(0, 0);
      Model.setData("moveLayoutScroll", "");
    }
    // eslint-disable-next-line
  }, [state.moveLayoutScroll]);

  React.useEffect(() => {
    if (contentDashRef?.current?.addEventListener) {
      contentDashRef.current.addEventListener("scroll", onScrollDash);
    }
    return () => {
      if (contentDashRef?.current?.removeEventListener) {
        // eslint-disable-next-line
        contentDashRef.current.removeEventListener("scroll", onScrollDash);
      }
    };
    // eslint-disable-next-line
  }, [contentDashRef]);

  React.useEffect(() => {
    if (!state.isWhiteLabel) {
      Model.setData("infoPartner", {
        ...(state.infoPartner || {}),
        logo: null,
      });
    }
    //eslint-disable-next-line
  }, []);

  return (
    <div
      className={[
        styles.container,
        state.isOnPluginPartner ? styles.pluginLayout : "",
      ].join(" ")}
    >
      <div className={styles.contAll}>
        <Header history={history} />
        {state.isOnPluginPartner ? (
          <div className={styles.selectEventLabel}>
            {returnStepLabelPlugin()}{" "}
            {state?.userInfo?.email ? (
              <div
                onClick={signOut}
                className={[styles.floatLogoContainer, styles.btLogout].join(
                  " "
                )}
              >
                <span>
                  {t("pages.layout.signOut")} (
                  {state.userInfo.email.slice(0, 4)}...
                  {state.userInfo.email.slice(state.userInfo.email.length - 6)})
                </span>
              </div>
            ) : null}
            {state.isWhiteLabel ? null : (
              <div
                onClick={() => window.open("https://fravents.com", "blank")}
                className={[
                  styles.floatLogoContainer,
                  state?.userInfo?.email ? styles.floatLeftBottom : "",
                ].join(" ")}
              >
                <span>by</span>
                <img
                  alt="FRAVENTS"
                  src={require("../../assets/images/Logo-Fravents-blanco.svg")}
                />
              </div>
            )}
          </div>
        ) : (
          <NavigationEvent history={history} location={location} />
        )}

        <div
          ref={contentDashRef}
          className={[
            styles.contentDash,
            state.showNavigationEvent ? styles.hasNavigationEvent : "",
          ].join(" ")}
        >
          <div
            className={[
              styles.contentChildren,
              hideFooterLinks ? styles.childrenNoFooter : "",
            ].join(" ")}
          >
            {blackHeader ? <div className={styles.blackHeader} /> : null}
            {children}
          </div>
          {hideFooter ? null : (
            <Footer hideFooterLinks={hideFooterLinks} history={history} />
          )}
          {state.withTabs ? <div className={styles.blankTabs} /> : null}
        </div>
      </div>
    </div>
  );
};

export default withRouter(Layout);
