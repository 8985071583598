import { makeStyles } from "@material-ui/styles";
import Commons from "../../../utils/styles";
import { colors } from "../../../styleguide";

const styles = makeStyles({
  banners: {
    minWidth: 900,
    [Commons.smallQuery]: {
      minWidth: "unset",
    },
  },
  switchContainer: {
    ...Commons.flexRow,
    width: "100%",
    justifyContent: "left",
    [Commons.smallQuery]: {
      justifyContent: "center",
      marginTop: -24,
      marginBottom: -12,
    },
  },
  tableContainer: {
    minWidth: 900,
    width: "100%",
    [Commons.smallQuery]: {
      display: "none",
    },
  },
  rowHeadTable: {
    "& th": {
      borderBottom: `1px solid ${colors.themeColor}`,
      textAlign: "left",
      color: colors.black,
      fontWeight: "700",
      padding: "12px 0",
      fontSize: 18,
    },
  },
  rowBodyTable: {
    "& td": {
      padding: "12px 0",
      color: colors.black,
      borderBottom: `1px solid ${colors.grayBlack}`,
    },
    "& td:first-child": {
      textAlign: "center",
    },
  },
  inputNumber: {
    width: "120px !important",
  },
  viewMoreButton: {
    ...Commons.defaultButton,
    color: colors.white,
    fontWeight: "700",
    textAlign: "center",
    padding: "6px",
    borderRadius: 12,
    width: "100%",
    backgroundColor: colors.themeColor,
  },
  listTickets: {
    display: "none",
    [Commons.smallQuery]: {
      display: "block",
    },
  },
  titleList: {
    color: colors.themeColor,
    fontWeight: "700",
    fontSize: 24,
    margin: 0,
    marginBottom: 12,
  },
  emptyList: {
    textAlign: "center",
    padding: "18px 0",
    fontSize: 18,
    color: colors.black,
  },
  containerEventList: {
    margin: "18px 0",
    borderRadius: 12,
    overflow: "hidden",
    backgroundColor: colors.grayLight,
  },
  topInfoEventList: {
    ...Commons.flexRow,
    backgroundColor: colors.themeColor,
    justifyContent: "space-between",
    padding: "6px 12px 6px 108px",
  },
  textTopEventList: {
    color: colors.white,
    fontSize: 15,
    fontWeight: "700",
  },
  bodyInfoEventList: {
    ...Commons.flexRow,
  },
  contImgEventList: {
    backgroundColor: colors.white,
    borderRadius: 12,
    padding: 6,
    width: 72,
    height: 72,
    margin: "-24px 0 6px 6px",
  },
  imgEventList: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: 12,
  },
  nameEventList: {
    color: colors.black,
    fontSize: 18,
    margin: "12px 18px",
    flex: 1,
    maxHeight: 46,
    overflow: "hidden",
  },
  containerPagination: {
    ...Commons.flexRow,
    padding: "18px 0",
    justifyContent: "flex-end",
    [Commons.smallQuery]: {
      justifyContent: "flex-start",
    },
  },
});

export default styles;
