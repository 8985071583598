import { makeStyles } from "@material-ui/styles";
import Commons from "../../utils/styles";
import { colors } from "../../styleguide";

const styles = makeStyles({
  container: {
    ...Commons.flexRow,
    justifyContent: "center",
    width: "fit-content !important",
    [Commons.smallQuery]: {
      flexWrap: "wrap",
      padding: "30px 0 18px 0",
    },
  },
  switchContainer: {
    "& .MuiSwitch-colorSecondary.Mui-checked": {
      color: colors.themeColor,
    },
    "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
      backgroundColor: colors.themeColor,
    },
  },
});

export default styles;
