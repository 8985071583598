import { makeStyles } from "@material-ui/styles";
import Commons from "../../../utils/styles";
import { colors } from "../../../styleguide";

const styles = makeStyles({
  contQr: {
    margin: "30px auto 0 auto",
    backgroundColor: colors.white,
    padding: 6,
    width: "fit-content",
    boxShadow: colors.shadow,
    borderRadius: 6,
    display: "none",
    [Commons.smallQuery]: {
      display: "block",
    },
  },
  viewQrButton: {
    ...Commons.defaultButton,
    ...Commons.flexRow,
    margin: "30px auto 0 auto",
    textAlign: "center",
    color: colors.themeColor,
    fontSize: 18,
    fontWeight: "600",
    display: "none",
    "& svg": {
      marginRight: 6,
    },
    [Commons.smallQuery]: {
      display: "flex",
    },
  },
  container: {
    ...Commons.flexRow,
    ...Commons.centerHorizontal,
    backgroundColor: colors.white,
    borderRadius: 12,
    overflow: "hidden",
    boxShadow: colors.shadow,
    maxWidth: 1200,
    width: "90%",
    margin: "30px 0",
    alignItems: "normal",
    minHeight: 720,
    maxHeight: 720,
    [Commons.smallQuery]: {
      flexDirection: "column",
      maxHeight: "unset",
    },
  },
  leftContainer: {
    ...Commons.flexColumn,
    flex: 3,
    [Commons.smallQuery]: {
      maxHeight: "75vh",
    },
  },
  rightContainer: {
    flex: 2,
    backgroundColor: colors.grayLight,
    borderLeft: `1px solid ${colors.gray50}`,
    overflow: "auto",
    [Commons.smallQuery]: {
      borderLeft: "none",
      borderTop: `1px solid ${colors.gray50}`,
    },
  },
  partnerRow: {
    ...Commons.flexRow,
    backgroundColor: colors.grayLight,
    padding: "12px 24px",
    width: "calc(100% - 48px)",
    boxShadow: colors.shadow,
  },
  imgPartner: {
    width: 30,
    height: 30,
    borderRadius: "50%",
    objectFit: "cover",
    backgroundColor: colors.white,
    border: `1px solid ${colors.grayBlack}`,
  },
  namePartner: {
    flex: 1,
    margin: 0,
    marginLeft: 12,
    fontWeight: "600",
    fontSize: 16,
    color: colors.black,
  },
  visitPartner: {
    ...Commons.defaultButton,
    color: colors.themeColor,
    fontWeight: "600",
    fontSize: 14,
  },
  listMessages: {
    flex: 1,
    width: "calc(100% - 48px)",
    maxHeight: "100%",
    overflow: "auto",
    padding: "0 24px",
    [Commons.smallQuery]: {
      display: "none",
      "&.showMobile": {
        display: "block",
      },
    },
  },
  showListMessages: {
    ...Commons.defaultButton,
    display: "none",
    width: "100%",
    backgroundColor: colors.themeColor,
    color: colors.white,
    textAlign: "center",
    fontSize: 15,
    fontWeight: "700",
    flex: 1,
    textTransform: "uppercase",
    [Commons.smallQuery]: {
      display: "block",
      "&.showMobile": {
        display: "none",
      },
    },
  },
  inputRow: {
    ...Commons.flexRow,
    borderTop: `1px solid ${colors.gray50}`,
    padding: "12px 24px",
    width: "calc(100% - 48px)",
  },
  inputMessage: {
    ...Commons.defaultInput,
    flex: 1,
    border: `1px solid ${colors.grayBlack}`,
    borderRadius: 6,
    padding: 6,
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: 16,
    resize: "none",
  },
  sendMessage: {
    ...Commons.defaultButton,
    ...Commons.flexCenter,
    backgroundColor: colors.themeColor,
    color: colors.white,
    width: 42,
    height: 42,
    borderRadius: "50%",
    marginLeft: 12,
    "& svg": {
      fontSize: 24,
    },
  },
  sellInfoRow: {
    ...Commons.flexRow,
    width: "calc(100% - 48px)",
    padding: "12px 24px",
  },
  sellInfo: {
    flex: 1,
  },
  statusSell: {
    color: colors.themeColor,
    fontWeight: "600",
    fontSize: 16,
    margin: 0,
  },
  updatedSell: {
    color: colors.black,
    fontSize: 14,
    margin: 0,
  },
  contactSell: {
    ...Commons.flexRow,
    ...Commons.defaultButton,
    marginLeft: 12,
    padding: "6px 12px",
    color: colors.themeColor,
    fontSize: 16,
    "& svg": {
      fontSize: 18,
      marginRight: 6,
    },
  },
  additionalInfo: {
    color: colors.black,
    fontSize: 14,
    margin: "6px 24px",
    maxHeight: 48,
    overflow: "auto",
  },
  contQrDesktop: {
    margin: "12px auto",
    backgroundColor: colors.white,
    padding: 6,
    width: "fit-content",
    boxShadow: colors.shadow,
    borderRadius: 6,
    display: "block",
    [Commons.smallQuery]: {
      display: "none",
    },
  },
  viewQrButtonDesktop: {
    ...Commons.defaultButton,
    ...Commons.flexRow,
    margin: "12px auto",
    textAlign: "center",
    color: colors.themeColor,
    fontSize: 15,
    fontWeight: "600",
    display: "flex",
    "& svg": {
      marginRight: 6,
    },
    [Commons.smallQuery]: {
      display: "none",
    },
  },
  labelSection: {
    margin: "12px 24px",
    fontWeight: "700",
    fontSize: 16,
    color: colors.black,
  },
  itemProduct: {
    ...Commons.flexRow,
    padding: "12px 24px",
    width: "calc(100% - 48px)",
    borderBottom: `1px solid ${colors.grayBlack}`,
  },
  imageProduct: {
    width: 60,
    height: 60,
    objectFit: "contain",
  },
  infoProduct: {
    flex: 1,
    marginLeft: 12,
  },
  dataProduct: {
    margin: 0,
    fontSize: 14,
    color: colors.black,
    "& b": {
      fontSize: 16,
    },
  },
  viewProduct: {
    ...Commons.defaultButton,
    color: colors.themeColor,
    fontWeight: "600",
    textAlign: "center",
    fontSize: 14,
  },
  emptyMessage: {
    textAlign: "center",
    fontSize: 16,
    color: colors.gray50,
    margin: "60px 24px",
  },
  bubbleConversation: {
    borderRadius: 12,
    backgroundColor: colors.grayLight,
    padding: 12,
    boxShadow: colors.shadow,
    margin: "18px 0",
    border: `2px dashed ${colors.themeColor}`,
    "&.seller": {
      maxWidth: "calc(75% - 24px)",
      border: "none",
      [Commons.smallQuery]: {
        maxWidth: "calc(90% - 24px)",
      },
    },
    "&.buyer": {
      maxWidth: "calc(75% - 24px)",
      marginLeft: "25%",
      border: "none",
      [Commons.smallQuery]: {
        maxWidth: "calc(90% - 24px)",
        marginLeft: "10%",
      },
    },
  },
  headerBubble: {
    margin: 0,
    color: colors.black,
    fontWeight: "600",
    fontSize: 14,
  },
  bodyBubble: {
    margin: "6px 0",
    color: colors.black,
    fontSize: 15,
  },
  footerBubble: {
    margin: 0,
    color: colors.black,
    fontSize: 13,
    textAlign: "right",
  },
});

export default styles;
