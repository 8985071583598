import { makeStyles } from '@material-ui/styles';
import Commons from '../../../utils/styles';
import { colors } from '../../../styleguide';

const styles = makeStyles({
  container: {
    ...Commons.flexCenter,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
    backgroundColor: colors.white,
    overflowY: 'auto'
  },
  iconTop: {
    ...Commons.flexCenter,
    backgroundColor: colors.themeColor,
    width: 42,
    height: 42,
    borderRadius: 21,
    color: colors.white,
    marginTop: 24
  },
  title: {
    fontWeight: 600,
    fontSize: 20,
    textAlign: 'center',
    margin: '12px 24px'
  },
  contCard: {
    backgroundColor: colors.grayLight,
    width: 'calc(90% - 60px)',
    borderRadius: 12,
    boxShadow: colors.shadow,
    padding: 30,
    margin: '12px 0px 24px 0px',
    maxWidth: 500
  },
  row: {
    ...Commons.flexRow,
  },
  flexCol: {
    ...Commons.flexColumn,
    flex: 1,
    alignItems: 'flex-start'
  },
  flexColR: {
    ...Commons.flexColumn,
    flex: 1,
    alignItems: 'flex-end'
  },
  smallLbl: {
    fontWeight: 600,
    fontSize: 12,
    opacity: 0.75,
    margin: 0
  },
  smallLblValue: {
    fontWeight: 700,
    fontSize: 15,
    marginTop: 6
  },
  lblCard: {
    fontWeight: 700,
    fontSize: 14,
    margin: 0,
    marginTop: 18
  },
  rowTickets: {
    ...Commons.flexRow,
    justifyContent: 'space-between',
    borderRadius: 6,
    border: `1px solid ${colors.grayBlack}`,
    backgroundColor: '#F1F0F0',
    padding: '6px 12px',
    marginTop: 6,
    marginBottom: 18
  },
  ticketIcon: {
    color: colors.themeColor,
    fontSize: 18
  },
  lblTicket: {
    fontWeight: 700,
    fontSize: 13,
  },
  titleEvent: {
    fontWeight: 700,
    fontSize: 15,
    margin: 0,
    marginBottom: 12
  },
  infoIcon: {
    fontSize: 14
  },
  infoTxt: {
    fontSize: 12,
    marginLeft: 6,
    flex: 1,
    opacity: 0.5
  },
  detailsBtn: {
    ...Commons.defaultButton,
    backgroundColor: '#B2B1B2',
    width: '90%',
    borderRadius: 12,
    padding: 12,
    margin: '6px 0px',
    maxWidth: 560,
    color: colors.white,
    fontWeight: 600,
  },
  confirmBtn: {
    backgroundColor: colors.themeColor
  }
});

export default styles;