import React from "react";
import { detectIncognito } from "detectincognitojs";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { returnSessionStorage as SessionStorage } from "../../utils/localStorage";
import pageStyles from "./styles/myProductsStyles";
import { Store } from "../../hooks/main_store";
import ShopService from "../../services/shop";
import Model from "../../hooks/Model";

const MyProducts = ({ history, getEvents }) => {
  const { t } = useTranslation();
  const styles = pageStyles();
  const { state } = React.useContext(Store);
  const [transactions, setTransactions] = React.useState([]);

  const onSelectProduct = (item) => {
    Model.setData("sellSelected", item);
    history.push("/sellDetail", { sellSelected: item });
  };

  const renderStatus = (status = "") => {
    const labelStatus = {
      pending: t("pages.shop.myProducts.payInProcess"),
      payed: t("pages.shop.myProducts.sendInProcess"),
      rejected: t("pages.shop.myProducts.payRejected"),
      approved: t("pages.shop.myProducts.sent"),
      finished: t("pages.shop.myProducts.delireved"),
    };

    return labelStatus[status];
  };

  const renderProducts = () => {
    if (transactions.length < 1) {
      return (
        <div className={styles.lblEmpty}>
          {t("pages.shop.myProducts.noHaveAnyProduct")}
        </div>
      );
    }
    return transactions.map((item, index) => (
      <div
        key={"item-list-product-" + index}
        className={styles.itemProductList}
      >
        <div className={styles.headerItemProduct}>
          <p className={styles.dateItemProduct}>
            {moment(item.created, "x").format("D MMMM, YYYY hh:mma")}
          </p>
          <button
            className={styles.ctaItemProduct}
            onClick={() => onSelectProduct(item)}
          >
            {t("pages.shop.myProducts.viewSell")}
          </button>
        </div>
        <div className={styles.bodyItemProduct}>
          <div className={styles.listItemProduct}>
            {item.products.map((product) => (
              <div
                key={"item-transaction-product-" + product.created}
                className={styles.rowItemProduct}
              >
                <img
                  src={product.image}
                  alt={product.name}
                  className={styles.imageItemProduct}
                />
                <div className={styles.infoItemProduct}>
                  <p className={styles.nameItemProduct}>{product.name}</p>
                  <p className={styles.typeItemProduct}>{product.type}</p>
                </div>
                <p className={styles.quantityItemProduct}>
                  {t("pages.shop.myProducts.quantity")}:{" "}
                  <b>{product.quantity}</b>
                </p>
              </div>
            ))}
          </div>
          <div className={styles.moreInfoItemProduct}>
            <p className={styles.statusItemProduct}>
              {renderStatus(item.status)}
            </p>
            <p className={styles.updatedItemProduct}>
              {t("pages.shop.myProducts.updatedAt")}:
              <br />
              {moment(item?.updated || item?.created, "x").format(
                "D MMMM, YYYY hh:mma"
              )}
            </p>
          </div>
        </div>
      </div>
    ));
  };

  const getMyProducts = async () => {
    try {
      Model.setData("loading", !(state?.transactionsHistory?.length > 0));
      const { isPrivate } = await detectIncognito();
      const myTransactions = (await ShopService.getMyProducts())?.sort(
        (a, b) => b.created - a.created
      );
      Model.setData("transactionsHistory", myTransactions);
      setTransactions(myTransactions);
      SessionStorage(isPrivate).setItem(
        "transactionsHistory",
        JSON.stringify(myTransactions)
      );
    } catch (e) {
      console.log(t("pages.shop.myProducts.errorGetSells"), e);
    } finally {
      Model.setData("loading", false);
      getEvents();
    }
  };

  React.useEffect(() => {
    Model.setData("opacity", 0.7);

    if (state?.transactionsHistory?.length > 0) {
      setTransactions(state.transactionsHistory);
    }

    getMyProducts();
    //eslint-disable-next-line
  }, []);

  return (
    <div className={styles.paddingList}>
      <p className={styles.title}>{t("pages.shop.myProducts.productBought")}</p>
      <div className={styles.listOfProducts}>{renderProducts()}</div>
      <div className={styles.blank} />
    </div>
  );
};

export default MyProducts;
