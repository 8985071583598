import React from "react";
import { Close, Visibility, VisibilityOff } from "@material-ui/icons";
import { Modal, Fade, Backdrop } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { showAuthError, returnCurrentLink } from "../utils/validations";
import useStyles from "./styles/authDialogStyles";
import { Store } from "../hooks/main_store";
import AuthService from "../services/auth";
import * as Routes from "../utils/routes";
import DialogForm from "./dialogForm";
import Model from "../hooks/Model";
import _ from "lodash";

const AuthDialog = ({ open = false, onClose = () => {} }) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const { state } = React.useContext(Store);
  const [vc, setVc] = React.useState({
    showPassword: false,
    email: "",
    password: "",
    showRegister: false,
    showPrompt: false,
  });
  const changeVc = (data) => setVc((ov) => ({ ...ov, ...data }));

  const changeInput = (name) => (event) => {
    event.persist();
    setVc((oldValues) => ({
      ...oldValues,
      [name]: event.target.value,
    }));
  };

  const login = async () => {
    try {
      Model.setData("opacity", 0.7);
      Model.setData("loading", true);
      if (!vc.email || !vc.password) {
        throw new Error(t("components.authDialog.mustFillAllFields"));
      } else {
        const emailLower = vc.email.toLowerCase().replace(/ /g, "");
        await AuthService.emailFirebaseLogin(emailLower, vc.password);
      }
      onClose();
      Model.setData("loading", false);
    } catch (e) {
      Model.setData("loading", false);
      Model.updateAlerts({
        message: showAuthError(_.get(e, "message", t("commons.unknowError"))),
        variant: "error",
      });
    }
  };

  const register = async () => {
    try {
      Model.setData("opacity", 0.7);
      Model.setData("loading", true);
      if (!vc.email || !vc.password) {
        throw new Error(t("components.authDialog.mustFillAllFields"));
      } else {
        const emailLower = vc.email.toLowerCase().replace(/ /g, "");
        await AuthService.emailFirebaseRegister(emailLower, vc.password);
        await AuthService.sendEmailVerification();
      }
      onClose();
      Model.setData("loading", false);
      Model.updateAlerts({
        message: t("components.authDialog.reviewYourInbox"),
        variant: "warning",
      });
    } catch (e) {
      Model.setData("loading", false);
      Model.updateAlerts({
        message: showAuthError(_.get(e, "message", t("commons.unknowError"))),
        variant: "error",
      });
    }
  };

  const onSubmit = async () => {
    if (vc.showRegister) {
      register();
    } else {
      login();
    }
  };

  const onKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      onSubmit();
    }
  };

  const changeForm = () => {
    setVc((ov) => ({
      ...ov,
      showRegister: !ov.showRegister,
      email: "",
      password: "",
      showPassword: false,
    }));
  };

  const onSendFormRecover = async () => {
    try {
      Model.setData("opacity", 0.7);
      Model.setData("loading", true);
      if (!vc.email) {
        throw new Error(t("components.authDialog.mustFillAllFields"));
      } else {
        const emailLower = vc.email.toLowerCase().replace(/ /g, "");
        await AuthService.sendPasswordResetEmail(emailLower);
      }
      changeVc({ showPrompt: false, email: "" });
      Model.setData("loading", false);
      Model.updateAlerts({
        message: t("components.authDialog.reviewInboxInstructions"),
        variant: "success",
      });
    } catch (e) {
      Model.setData("loading", false);
      Model.updateAlerts({
        message: showAuthError(e),
        variant: "error",
      });
    }
  };

  React.useEffect(() => {
    setVc({
      showPassword: false,
      email: "",
      password: "",
      showRegister: false,
    });
  }, [open]);

  React.useEffect(() => {
    if (state.uid) {
      onClose();
    }
    //eslint-disable-next-line
  }, [state.uid]);

  return (
    <Modal
      className={styles.contModal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}>
        <div className={styles.contForm}>
          <div className={styles.rowTitle}>
            <p className={styles.title}>
              {vc.showRegister
                ? t("components.authDialog.register")
                : t("components.authDialog.logIn")}
            </p>
            <button className={styles.closeBtn} onClick={onClose}>
              <Close />
            </button>
          </div>
          <div className={styles.contInputs}>
            <input
              className={styles.input}
              placeholder={t("commons.email")}
              type="email"
              onChange={changeInput("email")}
              value={vc.email}
              maxLength={320}
              autoFocus
              onKeyDown={onKeyDown}
            />
            <div className={styles.rowPass}>
              <input
                className={[styles.input, styles.inputPass].join(" ")}
                placeholder={t("commons.password")}
                type={vc.showPassword ? "text" : "password"}
                onChange={changeInput("password")}
                value={vc.password}
                maxLength={50}
                onKeyDown={onKeyDown}
              />
              <button
                className={styles.eyeBtn}
                onClick={() =>
                  setVc((ov) => ({ ...ov, showPassword: !ov.showPassword }))
                }
              >
                {vc.showPassword ? <VisibilityOff /> : <Visibility />}
              </button>
            </div>
            {vc.showRegister ? (
              <div className={styles.termsLbl}>
                {t("components.authDialog.onClickAccept")}{" "}
                <a
                  href={returnCurrentLink(
                    Routes.RENDER_LEGAL_DOC + "?document=terms"
                  )}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("components.authDialog.useConditions")}
                </a>
                , {t("components.authDialog.the")}{" "}
                <a
                  href={returnCurrentLink(
                    Routes.RENDER_LEGAL_DOC + "?document=privacy"
                  )}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {t("components.authDialog.privacyPolicy")}
                </a>
                , {t("components.authDialog.receiveNotifications")}
              </div>
            ) : (
              <button
                className={styles.noBgBtn}
                onClick={() => changeVc({ showPrompt: true })}
              >
                {t("components.authDialog.forgotPassword")}
              </button>
            )}
            <button className={styles.fullBtn} onClick={onSubmit}>
              {vc.showRegister
                ? t("components.authDialog.createAccount")
                : t("components.authDialog.enterAccount")}
            </button>
          </div>
          <div className={styles.footerModal}>
            <button className={styles.noBgBtn} onClick={() => changeForm()}>
              {vc.showRegister
                ? t("components.authDialog.alreadyHaveAccount")
                : t("components.authDialog.createNewAccount")}
            </button>
          </div>
          <DialogForm
            show={vc.showPrompt}
            title={t("components.authDialog.recoverPassword")}
            text={t("components.authDialog.enterEmailSendLink")}
            leftBtText={t("commons.cancel")}
            funcLeft={() => changeVc({ showPrompt: false, email: "" })}
            rightBtText={t("commons.accept")}
            funcRight={onSendFormRecover}
            inputs={[
              { label: t("commons.email"), value: "email", type: "email" },
            ]}
            vc={vc}
            changeVc={changeVc}
          />
        </div>
      </Fade>
    </Modal>
  );
};

export default AuthDialog;
