import React from "react";
import { Check, Cancel, AccessTime } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

import pageStyles from "./styles/finishpaymentStyles";
import EventsService from "../../services/events";
import Model from "../../hooks/Model";

const Finishpayment = ({ history }) => {
  const { t, i18n } = useTranslation();
  const styles = pageStyles();
  const [vc, setVc] = React.useState({
    statusLbl: "",
    messageStatus: "",
    external_reference: "",
  });
  const changeVc = (data) => setVc((ov) => ({ ...ov, ...data }));

  const firstLoad = async () => {
    try {
      Model.setData("loading", true);
      const urlParams = new URLSearchParams(window.location.search);
      const payment_id = urlParams.get("payment_id");
      const external_reference = urlParams.get("external_reference");
      if (payment_id) {
        const response = await EventsService.getPaymentStatus({
          id: payment_id,
          topic: "payment",
          external_reference,
        });
        if (response?.status === "approved") {
          changeVc({
            statusLbl:
              t("pages.events.finishpayment.finishedTransaction") +
              external_reference,
            messageStatus: t("pages.events.finishpayment.thanksBuy"),
            external_reference,
          });
        } else if (
          response?.status === "pending" ||
          response?.status === "authorized" ||
          response?.status === "in_process"
        ) {
          changeVc({
            statusLbl:
              t("pages.events.finishpayment.pendingTransaction") +
              external_reference,
            messageStatus: t("pages.events.finishpayment.weAreProcessing"),
          });
          setTimeout(firstLoad, 10000); // Vefiry payment again
        } else {
          changeVc({
            statusLbl:
              t("pages.events.finishpayment.rejectedTransaction") +
              external_reference,
            messageStatus: t("pages.events.finishpayment.sorryNoComplete"),
          });
        }
      } else {
        Model.updateAlerts({
          message: t("pages.events.finishpayment.noFoundPayment"),
          variant: "error",
        });
        history.push("/");
      }
    } catch (e) {
      console.log(t("pages.events.finishpayment.paymentError"), String(e));
    } finally {
      Model.setData("loading", false);
    }
  };

  React.useEffect(() => {
    firstLoad();
    return () => {};
    //eslint-disable-next-line
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.iconTop}>
        {vc.external_reference ? (
          <Check />
        ) : vc.statusLbl.includes(
            i18n.language === "en" ? "pending" : "pendiente"
          ) ? (
          <AccessTime />
        ) : (
          <Cancel />
        )}
      </div>
      <p className={styles.title}>{vc.statusLbl}</p>
      <div className={styles.contCard}>{vc.messageStatus}</div>
      <button
        className={[styles.detailsBtn, styles.confirmBtn].join(" ")}
        onClick={() => history.push("/")}
      >
        {t("pages.events.finishpayment.returnFravents")}
      </button>
    </div>
  );
};

export default Finishpayment;
