import { makeStyles } from "@material-ui/core/styles";
import { colors } from "../../styleguide";
import Commons from "../../utils/styles";

const useStyles = makeStyles({
  container: {
    ...Commons.flexCenter,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 11,
  },
  column: {
    ...Commons.flexCenter,
    flexDirection: "column",
    animation: "$scaleAnimation 1s ease-out",
  },
  image: {
    width: "80%",
    maxWidth: 240,
    position: "relative",
    zIndex: 1,
    marginBottom: -72,
    marginTop: -60,
    animation: "$zoom 3s infinite",
  },
  card: {
    zIndex: 0,
    position: "relative",
    backgroundColor: colors.white,
    borderRadius: 18,
    boxShadow: colors.shadow,
    border: `6px solid ${colors.themeColor}`,
    width: "90%",
    maxWidth: 480,
    paddingTop: 72,
  },
  titleCard: {
    textAlign: "center",
    color: colors.black,
    fontWeight: "bold",
    fontSize: 20,
    margin: "0 30px 6px 30px",
  },
  descriptionCard: {
    textAlign: "center",
    color: colors.black,
    margin: "0 30px 30px 30px",
    fontSize: 16,
  },
  rowButtonsCard: {
    ...Commons.flexRow,
    borderTop: `1px solid ${colors.themeColor}`,
    "& button": {
      ...Commons.defaultButton,
      flex: 1,
      textAlign: "center",
      color: colors.black,
      padding: "18px 12px",
      fontSize: 16,
      fontWeight: "600",
      borderLeft: `1px solid ${colors.themeColor}`,
      "&:first-child": {
        borderLeft: "none",
      },
    },
  },
  "@keyframes zoom": {
    "0%": {
      transform: "scale(1)",
    },
    "25%": {
      transform: "scale(1.05)",
    },
    "75%": {
      transform: "scale(0.95)",
    },
    "100%": {
      transform: "scale(1)",
    },
  },
  "@keyframes scaleAnimation": {
    "0%": {
      transform: "scale(0)",
    },
    "30%": {
      transform: "scale(1.1)",
    },
    "40%": {
      transform: "scale(0.9)",
    },
    "50%": {
      transform: "scale(1.1)",
    },
    "60%": {
      transform: "scale(0.9)",
    },
    "70%": {
      transform: "scale(1.05)",
    },
    "100%": {
      transform: "scale(1)",
    },
  },
});

export default useStyles;
