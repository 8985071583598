import React, { Component } from "react";
import { detectIncognito } from "detectincognitojs";

import InitialSettingsModal from "./components/InitialSettingsModal";
import NotificationModal from "./components/NotificationModal";
import { Store, StoreProvider } from "./hooks/main_store";
import QrScanModal from "./components/QrScanModal";
import Tutorial from "./components/Tutorial";
import Loading from "./components/loading";
import Dialog from "./components/dialog";
import Alert from "./components/alert";
import UrlsComponent from "./urls";
import Model from "./hooks/Model";

// Function to init dispatch model classes
const InitHooksClasses = () => {
  const { dispatch } = React.useContext(Store);
  // Init dispatch for model or models
  React.useEffect(() => {
    Model.set_dispatch(dispatch);
    return () => {};
  });
  return <React.Fragment />;
};

class Container extends Component {
  constructor() {
    super();
    this.state = {
      isIncognito: false,
    };
  }

  componentDidMount() {
    detectIncognito()
      .then((result) => {
        this.setState({ isIncognito: result.isPrivate });
      })
      .catch(() => this.setState({ isIncognito: true }));
  }

  render() {
    return (
      <>
        <StoreProvider isIncognito={this.state.isIncognito}>
          <InitHooksClasses />
          <UrlsComponent />
          <QrScanModal />
          <Tutorial />
          <Loading />
          <Alert />
          <Dialog />
          <NotificationModal />
          <InitialSettingsModal />
        </StoreProvider>
      </>
    );
  }
}

export default Container;
