import { makeStyles } from "@material-ui/styles";
import Commons from "../../../utils/styles";
import { colors } from "../../../styleguide";

const styles = makeStyles({
  container: {
    ...Commons.flexRow,
    alignItems: "flex-start",
    [Commons.smallQuery]: {
      flexDirection: "column",
    },
  },
  row: {
    ...Commons.flexRow,
  },
  leftColumn: {
    flex: 2,
    backgroundColor: colors.white,
    padding: "60px 48px",
    overflow: "auto",
    maxHeight: 660,
    [Commons.smallQuery]: {
      padding: "30px 12px",
      width: "calc(100% - 24px)",
      maxHeight: "unset",
      overflow: "visible",
    },
  },
  rightColumn: {
    flex: 1,
    backgroundColor: colors.grayBlack,
    padding: "60px 48px",
    [Commons.smallQuery]: {
      padding: "30px 12px",
      width: "calc(100% - 24px)",
    },
  },
  rowHeader: {
    ...Commons.flexRow,
    borderBottom: `1px solid ${colors.gray50}`,
    paddingBottom: 24,
  },
  titleHeader: {
    fontWeight: 700,
    fontSize: 24,
    color: colors.black,
    margin: 0,
    flex: 1,
  },
  dataHeader: {
    fontWeight: 700,
    fontSize: 24,
    color: colors.black,
  },
  listTable: {
    width: "100%",
    marginTop: 12,
  },
  headerListTable: {
    "& th": {
      padding: "12px 0",
      color: colors.gray50,
      textAlign: "left",
      fontSize: 16,
    },
  },
  rowSubtotal: {
    ...Commons.flexRow,
    borderTop: `1px solid ${colors.gray50}`,
    padding: "24px 0",
    marginTop: 24,
  },
  titleSubtotal: {
    fontWeight: 700,
    fontSize: 18,
    color: colors.black,
    margin: 0,
    flex: 1,
  },
  dataSubtotal: {
    fontWeight: 700,
    fontSize: 18,
    color: colors.black,
  },
  labelRight: {
    fontWeight: 600,
    fontSize: 18,
    color: colors.black,
    margin: "0 0 12px 0",
  },
  descriptionLabelRight: {
    padding: 12,
    backgroundColor: `${colors.themeColor}25`,
    fontSize: 14,
  },
  input: {
    width: "calc(100% - 24px)",
    marginBottom: 18,
    backgroundColor: colors.white,
    padding: "6px 12px",
    color: colors.black,
    fontSize: 16,
    border: "none",
    outline: "none",
    fontFamily: "'Source Sans Pro', sans-serif",
    resize: "none",
  },
  applyButton: {
    ...Commons.defaultButton,
    backgroundColor: colors.white,
    border: `1px solid ${colors.themeColor}`,
    padding: "6px 18px",
    color: colors.themeColor,
    fontSize: 16,
    marginBottom: 18,
  },
  submitButton: {
    ...Commons.defaultButton,
    width: "100%",
    backgroundColor: colors.themeColor,
    color: colors.white,
    fontWeight: 600,
    fontSize: 16,
    textAlign: "center",
    padding: "12px 0",
  },
  rowInfoItem: {
    ...Commons.flexRow,
    marginBottom: 30,
    [Commons.smallQuery]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  imageItem: {
    width: 90,
    height: 90,
    objectFit: "contain",
    cursor: "pointer",
  },
  nameItem: {
    color: colors.black,
    fontSize: 18,
    fontWeight: 600,
    margin: "0 0 0 12px",
    [Commons.smallQuery]: {
      fontSize: 15,
    },
  },
  priceItem: {
    color: colors.black,
    fontSize: 18,
    fontWeight: 600,
  },
  actionItemCart: {
    ...Commons.defaultButton,
    color: colors.themeColor,
  },
  quantityItemCart: {
    color: colors.black,
    fontSize: 18,
    margin: "0 6px",
    top: -5,
    position: "relative",
  },
  hideMobile: {
    [Commons.smallQuery]: {
      display: "none",
    },
  },
});

export default styles;
