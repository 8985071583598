import { makeStyles } from "@material-ui/styles";
import Commons from "../../../utils/styles";
import { colors } from "../../../styleguide";

const styles = makeStyles({
  container: {
    ...Commons.flexRow,
    margin: "30px auto",
    maxWidth: 900,
    width: "calc(90% - 60px)",
    backgroundColor: colors.white,
    boxShadow: colors.shadow,
    borderRadius: 30,
    padding: 30,
  },
  imgProfile: {
    minWidth: 120,
    maxWidth: 120,
    maxHeight: 120,
    minHeight: 120,
    objectFit: "cover",
    overflow: "hidden",
    border: "1px solid black",
    borderRadius: "50%",
    backgroundColor: colors.white,
  },
  contInfo: {
    flex: 1,
    marginLeft: 18,
    "& p": {
      margin: "6px 0",
      fontSize: 18,
    },
  },
  containerActivity: {
    flexDirection: "column",
  },
  titleActivity: {
    fontWeight: "bold",
    textAlign: "center",
    fontSize: 18,
    margin: 0,
  },
  textActivity: {
    textAlign: "center",
    fontSize: 16,
    margin: "18px 0 24px 0",
  },
  labelEmpty: {
    textAlign: "center",
  },
  listActivity: {
    width: "100%",
    borderRadius: 12,
    border: "1px solid black",
    position: "relative",
    overflow: "scroll",
    maxHeight: 300,
  },
  itemSale: {
    ...Commons.flexRow,
    borderBottom: "1px solid gray",
    padding: "12px 18px",
    cursor: "pointer",
    width: "calc(100% - 36px)",
    position: "relative",
    "&:hover": {
      backgroundColor: colors.grayLight,
    },
  },
  nameItemSale: {
    flex: 1,
    fontSize: 14,
    "& span": {
      fontSize: 12,
      opacity: 0.5,
    },
  },
  ticketsItemSale: {
    flex: 1,
    textAlign: "center",
    fontSize: 14,
    opacity: 0.5,
    fontWeight: "bold",
  },
  dateItemSale: {
    flex: 1,
    color: colors.gray50,
    fontSize: 12,
    textAlign: "right",
    "& span": {
      fontSize: 12,
      opacity: 0.5,
    },
  },
});

export default styles;
