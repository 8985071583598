import React from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import { languages, currencies } from "../../utils/constants";
import LocalStorage from "../../utils/localStorage";
import { Store } from "../../hooks/main_store";
import AuthService from "../../services/auth";
import Model from "../../hooks/Model";
import pageStyles from "./styles";

const InitialSettingsModal = () => {
  const { t, i18n } = useTranslation();
  const { state } = React.useContext(Store);
  const styles = pageStyles();

  const [selectedLanguage, setSelectedLanguage] = React.useState(null);
  const [selectedCurrency, setSelectedCurrency] = React.useState(null);

  const renderLanguages = () =>
    languages.map((language) => (
      <button
        key={language.value}
        className={[
          styles.optionButton,
          selectedLanguage === language.value ? styles.selectedOption : "",
        ].join(" ")}
        onClick={() => setSelectedLanguage(language.value)}
      >
        {language.label}
      </button>
    ));

  const renderCurrencies = () =>
    currencies.map((currency) => (
      <button
        key={currency.value}
        className={[
          styles.optionButton,
          selectedCurrency === currency.value ? styles.selectedOption : "",
        ].join(" ")}
        onClick={() => setSelectedCurrency(currency.value)}
      >
        {currency.label}
      </button>
    ));

  const onSubmit = () => {
    if (selectedCurrency && selectedLanguage && !state.loading) {
      AuthService.updateMe(state.uid, {
        defaultLanguage: selectedLanguage,
        defaultCurrency: selectedCurrency,
      })
        .then(() => {
          i18n.changeLanguage(selectedLanguage, () => {
            LocalStorage(state.isIncognito).setItem(
              "defaultLanguage",
              selectedLanguage
            );
          });
          Model.setData("defaultLanguage", selectedLanguage);
          Model.setData("defaultCurrency", selectedCurrency);
          Model.updateAlerts({
            message: t("components.initialSettingsModal.infoUpdated"),
            variant: "success",
          });
        })
        .catch((e) => {
          Model.updateAlerts({
            message: `Error: ${_.get(e, "message", t("commons.unknowError"))}`,
            variant: "error",
          });
        });
    } else {
      Model.updateAlerts({
        message: t("components.initialSettingsModal.selectAll"),
        variant: "warning",
      });
    }
  };

  if (
    state.defaultLanguage ||
    state.defaultCurrency ||
    !state.uid ||
    state.loading ||
    state.superLoading
  ) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <p className={styles.title}>
          {t("components.initialSettingsModal.beforeContinue")}
        </p>
        <p className={styles.label}>
          {t("components.initialSettingsModal.language")}
        </p>
        <div className={styles.rowOptions}>{renderLanguages()}</div>
        <p className={styles.label}>
          {t("components.initialSettingsModal.currency")}
        </p>
        <div className={styles.rowOptions}>{renderCurrencies()}</div>
        <button
          onClick={onSubmit}
          className={[
            styles.submit,
            selectedCurrency && selectedLanguage ? "" : styles.disabled,
          ].join(" ")}
        >
          {selectedCurrency && selectedLanguage
            ? t("components.initialSettingsModal.continue")
            : "..."}
        </button>
      </div>
    </div>
  );
};

export default InitialSettingsModal;
