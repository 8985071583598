import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useTranslation } from "react-i18next";
import useStyles from "./styles/loadingStyles";
import { Store } from "../hooks/main_store";

const LoadingComponent = () => {
  const { t } = useTranslation();
  const { state } = React.useContext(Store);
  const styles = useStyles();
  // Handle the loading state
  if (state.loading || state.superLoading) {
    return (
      <div
        className={styles.contLoader}
        style={{
          opacity: state.opacity,
        }}
      >
        {state.isOnPluginPartner && !state.isWhiteLabel ? (
          <>
            <img
              alt="fravents"
              src={require("../assets/images/logo.png")}
              className={styles.logo}
            />
            <p className={styles.poweredLabel}>
              {t("components.loading.poweredBy")}
            </p>
          </>
        ) : (
          <CircularProgress size={60} />
        )}
      </div>
    );
  } else if (state.errorLoad) {
    return <div>{t("components.loading.sorryThereProblem")}</div>;
  } else {
    return null;
  }
};

export default LoadingComponent;
