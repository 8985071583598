import _ from "lodash";
import moment from "moment";
import Model from "../hooks/Model";
import AuthService from "../services/auth";
import PartnerService from "../services/partner";
import i18next from "../languages/i18n";
import LocalStorage from "./localStorage";

const commonPlatformTax = 0.08;
const commonMpTax = [0.0329, 952];

const verifyEmail = (text) => {
  const regex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
  return regex.test(text);
};

const verifyUrl = (text) => {
  const regex = /^(https):\/\/[^ "]+$/;
  return regex.test(text);
};

const upperFirst = (text) => {
  if (text) {
    const firstLetter = text[0].toUpperCase();
    const restWord = text.slice(1).toLowerCase();
    return firstLetter.concat(restWord);
  } else {
    return null;
  }
};

const renderPricePoster = (prices = [], currency = "") => {
  const labelPrice = currency === "USD" ? "usdPrice" : "price";
  let minPrice = 0;
  prices.forEach((item) => {
    if (item?.[labelPrice] > 0 && minPrice === 0 && item.stock > 0) {
      minPrice = item?.[labelPrice];
    } else if (
      item?.[labelPrice] > 0 &&
      item?.[labelPrice] < minPrice &&
      item.stock > 0
    ) {
      minPrice = item?.[labelPrice];
    }
  });
  if (minPrice <= 0 && prices.length > 0) {
    prices.forEach((item) => {
      if (item?.[labelPrice] > 0 && minPrice === 0) {
        minPrice = item?.[labelPrice];
      } else if (item?.[labelPrice] > 0 && item?.[labelPrice] < minPrice) {
        minPrice = item?.[labelPrice];
      }
    });
  }

  if (minPrice <= 0) {
    return i18next.t("validations.free");
  } else if (minPrice <= 999) {
    return `$${minPrice} ${currency}`;
  } else if (Math.floor(minPrice / 1000) <= 999) {
    return `$${Math.floor(minPrice / 1000)}k ${currency}`;
  } else {
    return `$${Math.floor(minPrice / 1000000)}M ${currency}`;
  }
};

const renderInterested = (number) => {
  if (parseInt(number) < 10) {
    return number;
  }
  let numberString = String(number)[0];
  for (let i = 0; i < String(number).length - 1; i++) {
    numberString = numberString + "0";
  }
  return parseInt(numberString).toLocaleString("es-CO") + "+";
};

const showAuthError = (e) => {
  let msg;
  if (e.indexOf("auth/invalid-email") > -1) {
    msg = i18next.t("validations.invalidEmail");
  } else if (e.indexOf("auth/user-not-found") > -1) {
    msg = i18next.t("validations.userNotFound");
  } else if (e.indexOf("auth/wrong-password") > -1) {
    msg = i18next.t("validations.wrongPassword");
  } else if (e.indexOf("auth/weak-password") > -1) {
    msg = i18next.t("validations.weakPassword");
  } else if (e.indexOf("auth/email-already-in-use") > -1) {
    msg = i18next.t("validations./emailAlreadyUse");
  } else if (e.indexOf("auth/user-mismatch") > -1) {
    msg = i18next.t("validations.userMismatch");
  } else {
    msg = e;
  }
  return String(msg);
};

const isBookmarked = (uid, array) => {
  return _.find(array, { uid });
};

const currencyFormat = (value = 0, currency = "COP", fractions = 0) => {
  const suggestFractions = fractions;
  // parseInt(
  //   String(value).split(".")?.[1]?.length > 0 ? 2 : fractions
  // );
  const formatter = new Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: String(currency),
    minimumFractionDigits: suggestFractions,
    maximumFractionDigits: suggestFractions,
  });
  return formatter.format(parseFloat(value));
};

const sizeImage = (file, limit = 5 * 1042 * 1024, callback = () => {}) => {
  if (file.size <= limit) {
    callback();
  } else {
    Model.updateAlerts({
      message: i18next.t("validations.imageTooBigger", {
        size: Math.floor(limit / (1024 * 1024)),
      }),
      variant: "error",
    });
  }
};

const renderShortPrice = (price, noFree) => {
  if (price <= 0 && !noFree) {
    return i18next.t("validations.free");
  } else if (price <= 999) {
    return price;
  } else if (Math.floor(price / 1000) <= 999) {
    return `${Math.floor(price / 1000)}k`;
  } else {
    return `${Math.floor(price / 1000000)}M`;
  }
};

const renderEllipsis = (text = "", maxChars = 0) => {
  const isOver = text.length > maxChars;
  return text.slice(0, maxChars - 1) + (isOver ? " ..." : "");
};

const moveItemsArray = (array, old_index, new_index) => {
  const arr = [...array];
  if (new_index >= arr.length) {
    return arr;
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
};

const returnTax = (priceSell = 0, returnOnly = "", currency = "COP") => {
  let platformTax = parseFloat(priceSell) * commonPlatformTax;
  let providerTax = parseFloat(priceSell) * commonMpTax[0] + commonMpTax[1];

  if (currency === "USD") {
    platformTax = parseFloat(priceSell) * 0.03;
    providerTax = parseFloat(priceSell) * 0.02;
  }

  if (isNaN(platformTax) || isNaN(providerTax)) {
    return 0;
  }

  if (returnOnly === "platform") {
    return platformTax;
  } else if (returnOnly === "provider") {
    return providerTax;
  }

  return platformTax + providerTax;
};

const setCookie = (cname, cvalue) => {
  document.cookie = cname + "=" + cvalue + ";";
};

const getCookie = (cname) => {
  const nameCookie = cname + "=";
  const cookiesArray = document.cookie.split(";");
  let returnValue = "";

  cookiesArray.forEach((cookie) => {
    if (cookie.indexOf(nameCookie) > -1) {
      returnValue = cookie.split("=")[1];
    }
  });

  return returnValue;
};

const removeChild = (childTarget) => {
  if (childTarget?.parentNode?.removeChild) {
    return childTarget?.parentNode?.removeChild?.(childTarget);
  }
};

const linkMp = async () => {
  try {
    const randId = moment().format("x");

    Model.setData("dialog", { open: false });
    Model.setData("loading", true);

    if (window?.location?.host?.includes?.("test.")) {
      Model.updateAlerts({
        message: `Error: ${i18next.t("validations.errorTestSite")}`,
        variant: "error",
      });
      throw new Error(i18next.t("validations.errorTestSite"));
    }

    await AuthService.setStateOAuth({ stateOAuth: randId });
    window
      .open(
        `https://auth.mercadopago.com/authorization?client_id=7143367300090982&response_type=code&platform_id=mp&state=${randId}&redirect_uri=https://www.fravents.com/confirmPartner`,
        "_blank"
      )
      .focus();
  } catch (e) {
    console.log("Error link: ", String(e));
  } finally {
    Model.setData("loading", false);
  }
};

const verifyIsNumber = (text) => {
  const regex = /[0-9]/g;
  return regex.test(text);
};

const linkNequi = async ({ client_id, client_secret, xApiKey, uid }) => {
  try {
    Model.setData("dialog", { open: false });
    if (window?.location?.host?.includes?.("test.")) {
      Model.updateAlerts({
        message: `Error: ${i18next.t("validations.errorTestSite")}`,
        variant: "error",
      });
      throw new Error(i18next.t("validations.errorTestSite"));
    }
    Model.setData("loading", true);
    await PartnerService.acceptLinkNequiPartner({
      client_id,
      client_secret,
      xApiKey,
    });
    await AuthService.getMe(uid, true);
    Model.updateAlerts({
      message: i18next.t("validations.linkedAccountSuccess"),
      variant: "success",
    });
  } catch (e) {
    Model.updateAlerts({
      message: `${i18next.t("validations.errorLinkNequi")}: ${e}`,
      variant: "error",
    });
  } finally {
    Model.setData("loading", false);
  }
};

const totalSellReducer = (intProducts = []) => {
  const products =
    typeof intProducts === "object" && intProducts.length > 0
      ? intProducts
      : [];
  const reducePrices = products.map(
    (product) =>
      parseFloat(product?.price || 0) * parseInt(product?.quantity || 0)
  );

  return reducePrices.length > 0
    ? reducePrices.reduce((total, current) => total + current)
    : 0;
};

const totalQuantityReducer = (intProducts = []) => {
  const products =
    typeof intProducts === "object" && intProducts.length > 0
      ? intProducts
      : [];
  const reduceQuantity = products.map((product) =>
    parseInt(product?.quantity || 0)
  );

  return reduceQuantity.length > 0
    ? reduceQuantity.reduce((total, current) => total + current)
    : 0;
};

const returnLabelSellStatus = (status) => {
  switch (status) {
    case "payed":
      return i18next.t("validations.payed");
    case "pending":
      return i18next.t("validations.pending");
    case "approved":
      return i18next.t("validations.sent");
    case "finished":
      return i18next.t("validations.delivered");
    default:
      return i18next.t("validations.rejected");
  }
};

const linkBitcoin = async ({ walletAddress, uid }) => {
  try {
    Model.setData("dialog", { open: false });
    if (window?.location?.host?.includes?.("test.")) {
      Model.updateAlerts({
        message: `Error: ${i18next.t("validations.errorTestSite")}`,
        variant: "error",
      });
      throw new Error(i18next.t("validations.errorTestSite"));
    }
    Model.setData("loading", true);
    await PartnerService.acceptLinkBitcoinPartner({
      walletAddress,
    });
    await AuthService.getMe(uid, true);
    Model.updateAlerts({
      message: i18next.t("validations.linkedAccountSuccess"),
      variant: "success",
    });
  } catch (e) {
    Model.updateAlerts({
      message: `${i18next.t("validations.errorLinkBitcoin")}: ${e}`,
      variant: "error",
    });
  } finally {
    Model.setData("loading", false);
  }
};

const currentLang = () => {
  const myLang = LocalStorage(false).getItem("defaultLanguage");

  return myLang || "es";

  // return (myLang || navigator?.languages?.[0] || navigator?.language)
  //   .toLowerCase()
  //   .indexOf("en") === 0
  //   ? "en"
  //   : "es";
};

const returnCurrentLink = (route) =>
  `http${process.env.NODE_ENV === "production" ? "s" : ""}://${
    window?.location?.host
  }${route}`;

export {
  verifyEmail,
  verifyUrl,
  upperFirst,
  renderPricePoster,
  renderInterested,
  showAuthError,
  isBookmarked,
  currencyFormat,
  sizeImage,
  renderShortPrice,
  renderEllipsis,
  moveItemsArray,
  returnTax,
  setCookie,
  getCookie,
  removeChild,
  linkMp,
  verifyIsNumber,
  linkNequi,
  totalSellReducer,
  totalQuantityReducer,
  returnLabelSellStatus,
  linkBitcoin,
  currentLang,
  returnCurrentLink,
};
