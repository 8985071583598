import { makeStyles } from "@material-ui/styles";
import Commons from "../../../utils/styles";
import { colors } from "../../../styleguide";

const styles = makeStyles({
  title: {
    color: colors.mainxText,
    fontWeight: "700",
    fontSize: 36,
    margin: 12,
    textAlign: "center",
    marginTop: 36,
    marginBottom: 24,
  },
  descriptionLabel: {
    textAlign: "center",
    margin: 0,
    marginBottom: 30,
    "& span": {
      fontWeight: "bold",
      cursor: "pointer",
      margin: "0 12px",
      "&:hover": {
        textDecoration: "underline",
      },
    },
  },
  contLogo: {
    ...Commons.flexCenter,
    minWidth: 120,
    maxWidth: 120,
    minHeight: 120,
    maxHeight: 120,
    borderRadius: "50%",
    backgroundColor: colors.black,
    margin: "0 auto",
    border: "3px solid white",
    boxShadow: colors.shadow,
    zIndex: 1,
    marginBottom: -24,
  },
  logo: {
    minWidth: "80%",
    maxWidth: "80%",
    minHeight: "80%",
    maxHeight: "80%",
    borderRadius: "50%",
    objectFit: "contain",
  },
  description: {
    backgroundColor: colors.black,
    width: "calc(90% - 60px)",
    boxShadow: colors.shadow,
    padding: 30,
    color: colors.white,
    margin: "0 auto",
    borderRadius: 30,
    border: "3px solid white",
    fontSize: 18,
    textAlign: "justify",
    maxWidth: 600,
    "& a": {
      color: colors.themeColor,
    },
  },
  blank: {
    height: 60,
  },
  cardVideo: {
    ...Commons.flexRow,
    width: "90%",
    maxWidth: 1140,
    margin: "0 auto",
    overflow: "hidden",
    borderRadius: 18,
    backgroundColor: colors.themeColor,
    height: 420,
    [Commons.smallQuery]: {
      height: "auto",
      flexDirection: "column",
    },
    "& video": {
      width: "70%",
      height: "100%",
      backgroundColor: colors.black,
      [Commons.smallQuery]: {
        width: "100%",
        height: 240,
      },
    },
  },
  chapters: {
    ...Commons.flexColumn,
    width: "30%",
    borderRight: `2px solid ${colors.white}`,
    height: "100%",
    [Commons.smallQuery]: {
      flexDirection: "column",
      width: "100%",
      height: "auto",
    },
  },
  optionChapter: {
    ...Commons.flexCenter,
    flex: 1,
    width: "100%",
    color: colors.white,
    fontWeight: "bold",
    fontSize: 20,
    borderBottom: `1px solid ${colors.white}`,
    cursor: "pointer",
    padding: "12px 0",
  },
});

export default styles;
