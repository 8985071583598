import { makeStyles } from "@material-ui/styles";
import Commons from "../../utils/styles";
import { colors } from "../../styleguide";

const styles = makeStyles({
  contHeader: {
    ...Commons.flexCenter,
    zIndex: 2,
    width: "100%",
    boxShadow: colors.shadow,
    color: colors.white,
    paddingTop: 12,
    position: "fixed",
  },
  background: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 0,
    top: 0,
    left: 0,
  },
  contMenuMobile: {
    ...Commons.flexCenter,
    ...Commons.defaultButton,
    width: 36,
    height: 60,
    color: colors.white,
    display: "none",
    paddingRight: 24,
    marginLeft: 10,
    [Commons.smallQuery]: {
      display: "flex",
    },
  },
  contTitle: {
    color: colors.white,
    fontSize: 18,
    textAlign: "left",
    fontWeight: 600,
    paddingLeft: 30,
    paddingRight: 30,
    cursor: "pointer",
    position: "relative",
    [Commons.smallQuery]: {
      flex: 1,
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  contLogoTitle: {
    height: 72,
    width: "auto",
    objectFit: "contain",
    position: "relative",
    top: 2,
    [Commons.smallQuery]: {
      height: 60,
    },
  },
  logoFloat: {
    [Commons.smallQuery]: {
      position: "absolute",
      top: 72,
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      borderRadius: "0 0 12px 12px",
      left: 0,
      "& img": {
        [Commons.smallQuery]: {
          height: "38px",
        },
      },
    },
  },
  header: {
    flex: 1,
    height: "calc(100% - 24px)",
    overflow: "auto",
    padding: 12,
    fontSize: 13,
    color: colors.gray50,
    whiteSpace: "pre-wrap",
  },
  contLogoParnter: {
    ...Commons.flexRow,
    flex: 1,
    justifyContent: "space-around",
    [Commons.smallQuery]: {
      justifyContent: "flex-start",
    },
  },
  alignToleft: {
    justifyContent: "flex-start",
    flex: "unset",
    [Commons.smallQuery]: {
      flex: 1,
    },
  },
  logoPartner: {
    height: 72,
    aspectRatio: "4 / 1",
    objectFit: "contain",
    backgroundColor: "transparent",
    [Commons.smallQuery]: {
      height: 60,
      aspectRatio: "3 / 1",
    },
  },
  rowInHeader: {
    ...Commons.flexRow,
  },
  hideMobile: {
    [Commons.smallQuery]: {
      display: "none !important",
    },
  },
  onlyMobile: {
    display: "none !important",
    [Commons.smallQuery]: {
      display: "flex !important",
    },
  },
  cart: {
    ...Commons.flexCenter,
    ...Commons.defaultButton,
    position: "relative",
    marginRight: 12,
    color: colors.white,
    "& svg": {
      fontSize: 30,
    },
    [Commons.smallQuery]: {
      marginRight: 0,
    },
  },
  badgeCart: {
    ...Commons.centerHorizontal,
    position: "absolute",
    top: -18,
    padding: "3px 6px",
    borderRadius: 30,
    fontSize: 12,
    color: colors.white,
    backgroundColor: colors.themeColor,
    fontWeight: 700,
  },
  userInfo: {
    ...Commons.flexCenter,
    ...Commons.defaultButton,
    marginRight: 42,
    [Commons.smallQuery]: {
      marginRight: 0,
    },
  },
  btLogin: {
    backgroundColor: colors.themeColor,
    color: colors.white,
    fontWeight: "600",
    fontSize: 18,
    textAlign: "center",
    padding: "6px 12px",
    borderRadius: 12,
    [Commons.smallQuery]: {
      fontSize: 14,
    },
  },
  contImgProfile: {
    ...Commons.flexCenter,
    position: "relative",
    width: 34,
    height: 34,
    border: "2px solid white",
    borderRadius: "50%",
  },
  imageProfile: {
    width: "100%",
    height: "100%",
    borderRadius: "50%",
    backgroundColor: colors.white,
    overflow: "hidden",
    objectFit: "cover",
  },
  contIconError: {
    ...Commons.flexCenter,
    position: "absolute",
    width: 26,
    height: 26,
    backgroundColor: colors.white,
    borderRadius: "50%",
    bottom: -6,
    right: -6,
  },
  iconError: {
    color: colors.red,
    fontSize: 24,
  },
  drawerContainer: {
    "& .MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorRight": {
      borderRadius: "10px 0 0 10px",
    },
  },
  closeMenuBtn: {
    ...Commons.defaultButton,
    ...Commons.flexCenter,
    backgroundColor: colors.themeColor,
    padding: "42px 24px 24px 24px",
    fontSize: 16,
    color: colors.black,
    "& span": {
      flex: 1,
      textAlign: "right",
      color: colors.white,
      fontWeight: "600",
      fontSize: 18,
      marginRight: 24,
    },
  },
  contAnchors: {
    width: "75vw",
    maxWidth: 300,
    flex: 1,
    padding: 12,
  },
  contAnchorsMenu: {
    ...Commons.flexRow,
    flex: 1,
    justifyContent: "space-around",
    [Commons.smallQuery]: {
      justifyContent: "center",
      display: "none",
    },
  },
  anchorBtn: {
    ...Commons.defaultButton,
    ...Commons.flexRow,
    fontWeight: 600,
    fontSize: 18,
    color: colors.white,
    padding: "6px",
    height: 77,
    [Commons.smallQuery]: {
      justifyContent: "flex-end",
      height: "auto",
      padding: "18px 12px 18px 12px",
      color: colors.black,
      backgroundColor: colors.white,
      width: "100%",
    },
    "&:hover": {
      textDecoration: "underline",
      [Commons.smallQuery]: {
        borderBottom: `2px solid ${colors.grayBlack}`,
        backgroundColor: colors.grayBlack,
        color: colors.black,
      },
    },
    "& svg": {
      fontSize: 20,
      color: colors.themeColor,
      marginLeft: 18,
      display: "none",
      [Commons.smallQuery]: {
        display: "block",
      },
    },
  },
  footerMenu: {
    ...Commons.flexCenter,
    backgroundColor: colors.grayLight,
    padding: "12px 0px",
    fontSize: 14,
  },
  barSelected: {
    height: 9,
    width: "100%",
    backgroundColor: colors.themeColor,
    borderRadius: "0px 0px 50px 50px",
    position: "absolute",
    bottom: -9,
    left: 0,
    [Commons.smallQuery]: {
      display: "none",
    },
  },
  textTheme: {
    color: colors.themeColor,
    [Commons.smallQuery]: {
      color: colors.black,
    },
  },
  anchorWithChild: {
    [Commons.smallQuery]: {
      display: "none",
    },
  },
  childAnchorBtn: {
    display: "none",
    [Commons.smallQuery]: {
      display: "flex",
    },
  },
  verifyHereAlert: {
    zIndex: "2 !important",
    "& div": {
      backgroundColor: `${colors.red} !important`,
      margin: "6px 0",
    },
  },
});

export default styles;
