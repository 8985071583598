import React from "react";

const MainPage = (props) => {
  React.useEffect(() => {
    props.history.push("/dashboard");
    //eslint-disable-next-line
  }, []);

  return <div />;
};

export default MainPage;
