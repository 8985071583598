const platformTax = ["12%", "8%"];

const texts = {
  translation: {
    commons: {
      unknowError: "Unknown error",
      email: "Email",
      password: "Password",
      cancel: "Cancel",
      accept: "Accept",
      all: "All",
      format: "Format",
      yesConfirm: "Yes, I confirm",
      agree: "OK",
      confirm: "Confirm",
      imAgree: "I agree",
      artists: "Artists",
      description: "Description",
      promoters: "Promoters",
      price: "Price",
      availables: "Available",
      quantity: "Amount",
      buy: "Buy",
      name: "Name",
      lastName: "Last name",
      numId: "Identification No.",
      ready: "Ready",
      no: "No",
      yes: "Yeah",
      phone: "Phone",
      cellphone: "cell phone",
      download: "Discharge",
      free: "Free",
      date: "Date",
      january: "January",
      february: "February",
      march: "March",
      april: "April",
      may: "May",
      june: "June",
      july: "July",
      august: "August",
      september: "September",
      october: "October",
      november: "November",
      december: "December",
      continue: "Continue",
      noSupportVideos: "Your browser does not support videos",
      ok: "OK",
    },
    components: {
      addTicketsList: {
        name: "Name",
        lastName: "Last name",
        numId: "Document",
        email: "Mail",
        typeTicket: "Ticket Type",
        quantity: "Amount",
        alertUploadInfo: "Alert loading information",
        rememberUploadInfo:
          "Remember that when you upload sales information, the process of generating tickets and sending QRs for each user will be executed. If you want to reverse the process, you must do it manually. (REMEMBER: The limit per file is 100 tickets, if it is exceeded, the rest will be omitted. Avoid duplicating records). You can download the excel format to order your purchases.",
        errorUploadList: "Failed to upload list: ",
        confirmBuys: "Confirm purchases",
        rememberConfirmBuys:
          "Remember that you cannot reverse this process when adding new purchases to the list, do you agree?",
        firstUploadFile: "First upload an excel file",
        cancelProcess: "Cancel process",
        confirmCancelProcess:
          "Do you agree to delete the added purchases and not upload them permanently? This action cannot be reversed.",
        thereSomeErrors:
          "There are {{countErrors}} log{{multiple}} with errors, check your file again.",
        noFoundValidTicket: "No valid entry found",
        errorUploadExcel: "Error to upload Excel: ",
        invalidFile: "invalid file",
        noAddSellsYet: "No new purchases have been added",
        addTickets: "Add tickets",
        useOurFormat:
          "Use our excel format to upload your purchases (from 1 to 100 tickets per file), if there are any doubts the file comes with instructions.",
        uploadExcel: "Upload excel",
        maxPerOne: "Maximum 100 tickets each",
        pendingConfirm: "Pending to be confirmed",
        thisIsPreview:
          "This is a preview of the tickets that you have uploaded but have not been confirmed. When you want to finish, don't forget to confirm with the button at the top right of the table.",
      },
      authDialog: {
        mustFillAllFields: "You must fill in all the data.",
        reviewYourInbox:
          "Check your email and follow the instructions to verify it and start making purchases.",
        reviewInboxInstructions:
          "Check your email and follow the instructions.",
        register: "Record",
        logIn: "Log in",
        onClickAccept: "By clicking &quot;Create Account&quot; I agree to the",
        useConditions: "Terms of use",
        the: "the",
        privacyPolicy: "Privacy Policy",
        receiveNotifications: "receive news and promotions.",
        forgotPassword: "Did you forget your password?",
        createAccount: "Create Account",
        enterAccount: "Login",
        alreadyHaveAccount: "Do you already have an account? enter here",
        createNewAccount: "Create a new account",
        recoverPassword: "Recover password",
        enterEmailSendLink:
          "Enter your previously registered email on our platform to send you a link with the respective instructions.",
      },
      bannerEvent: {
        readInfoBeforeBuy:
          "Please read the following information carefully before purchasing your tickets.",
        eventIsFor: "The event is for",
        forOld18: "over 18 years",
        forAllAges: "for all ages",
        startDate: "Start: ",
        endDate: "End: ",
        secretLocation: "Secret location",
        organizerVerified: "Verified organizer for the trust of our users.",
        organizerNoVerified:
          "Unverified organizer, make the purchase if you trust it is authentic.",
        organizer: "Organizer",
        visit: "Visit",
        cancelledEvent: "CANCELED EVENT",
        messageCancelled:
          "For reasons external to the platform, the organizer has canceled this presentation. If you bought tickets check the information in",
        myShopping: '"My Shopping".',
      },
      bannerHome: {
        easy: "easy",
        innovative: "innovative",
        affordable: "affordable",
        secure: "safe",
        friendly: "friendly",
        weOffer: "We offer the way",
        wayManageEvents: "to manage your events",
        andCentralize: "And we centralize entertainment information",
        free: "FREE!",
        addEvent: "Create my event",
        viewEvents: "See events",
      },
      contentEvent: {
        viewWebsite: "See website",
        cannotBook:
          "The resale reservation can no longer be made because it is not available.",
        multiplePayments: "Multiple payments",
        pendingMultiplePayments:
          "We notify you that you must make the payment in {{length}} different transactions because each ticket must be purchased separately. REMEMBER: Every time you finish a transaction, you must close the payment box and select the next payment you have pending. Anything you can contact technical support at help@fravents.com",
        transationNoAvailable: "This transaction is no longer available",
        confirmSelection: "Confirm selection",
        confirmPayResell:
          "{{name}} Do you want to purchase {{quantity}} tickets{{multiples}} for resale? You will be redirected to the payment platform and you will have a maximum of 10 minutes to make the purchase or your reservation will be deleted.",
        completeDataTry: "Fill all inputs and try again",
        enteredEmailBadFormat: "The email entered has an incorrect format",
        mustSelectOffer: "You must select an offer to continue",
        beforeContinue: "Before continuing...",
        agreeBuyResell:
          "Do you agree to buy the following tickets for resale? Remember that refunds are not accepted, for more information read our [[Terms and Conditions]]. {{textSells}} Total purchase with service included: {{totalSells}}",
        yet: "Still",
        havePendingTransactions: "you have pending transactions",
        retakeProcessFinish:
          "to finish, resume your process and finish your purchases 🤓",
        resum: "Resume",
        anyUserPublish: "No user has made any resale publication 😥",
        alreadyFollowEvent: "You already follow this event",
        youCanCancelSuscription:
          ", you can unsubscribe to stop receiving notifications.",
        comeOnFollow: "Give it follow ",
        thisEventReceiveNotifications:
          "to this event and you will receive notifications when there are new offers.",
        termsConditionsEvent: "Event Terms and Conditions",
        conditionEventFravents:
          "Fravents is only responsible for issues related to technical support in the purchase and sale of tickets. Fravents is not responsible for the execution or anything related to this event.",
        ticketPrices: "Ticket prices",
        map: "Map",
        sales: "🔥 Offers 🔥",
        ticketsOnListMembersResell:
          "The tickets in this list are from Fravents' own users, therefore we create a safe and fast space for ticket resale. Refunds are not accepted. For more information read the Terms and Conditions.",
        resellOffers: "Resale of tickets offered by users",
        taxPlatform: "+ platform service fee",
        stopFollowOffers: "Stop following offers",
        followOffers: "Follow offers",
        moreOffers: "More events from the organizer",
        completeSellData: "Complete the purchase data",
        toContinueFillData:
          "To continue with the purchase of {{quantity}} entrada{{multiples}} for resale, please enter the following information and when confirming your order will be reserved.",
        sendDataAcceptTerms:
          "By submitting the data, you accept the [[Terms and Conditions]] of the purchase. To provide the best security and confidence to our users, payments are made through the Mercado Pago platform, therefore, FRAVENTS does not store or manipulate any sensitive information on user payment methods.",
        sellsInSale: "🔥 Resale Offers 🔥",
        selectTransaction: "Select the transaction you want to make",
      },
      features: {
        participants: "🎟 Participant 🎟",
        participantsDescription:
          "You are interested in attending an event on our platform and purchasing your tickets.",
        viewEvents: "See events",
        reseller: "🤑 Reseller 🤑",
        resellerDescription:
          "Acquire tickets on our platform and sell them at a different price in the safest and instant way.",
        goSell: "Go sell",
        promoter: "🚀 Promoter 🚀",
        promoterDescription:
          "Search for events on our platform to promote them on your social networks and generate credits.",
        promoteNow: "Promote now",
        eventOrganizer: "😎 Organizer 😎",
        eventOrganizerDescription:
          "You produce events and want to acquire services for the sale and management of online ticketing.",
        moreInfo: "More information",
        viewVideoTutorial: "Watch video tutorial",
      },
      footer: {
        begin: "Home",
        profile: "Profile",
        myShopping: "My shopping",
        siteMap: "Site Map",
        privacyPolicy: "Privacy Policy",
        termsConditions: "Terms and Conditions",
        organizersNotice: "Organizers Notice",
        buyersNotice: "Buyers Notice",
        tcResale: "T&C Resale",
        tcMarketplace: "T&C Marketplace",
        dataAuthorization: "Data authorization",
        sicLink: "Go to the SIC",
        contact: "Contact",
        faq: "Frequent questions",
        madeIn: "Made in Colombia",
        wantPublishEvent: "I want to publish an event",
        communityFraventsPartners: "Community of Fravents partners",
      },
      header: {
        titleWantSell: "Do you want to sell your products on Fravents?",
        descriptionWantSell:
          "Before continuing, your account must go through a verification process to continue with the process. This step is necessary to trust that you are going to sell real products and avoid scams to our users. Send us an email to help@fravents.com with as much relevant information about your brand and our team will help you through the process.",
        logIn: "Login",
        events: "Events",
        myShopping: "My shopping",
        admin: "Administrator",
        myEvents: "My events",
        shop: "My store",
        scanQr: "QR scanner",
        hi: "Hello",
        publishEvent: "Post my event",
        profile: "Profile",
        verifyHere: "Verify here",
      },
      incognitoAlert: {
        forLiveExp: "To live the whole experience",
        noUseIncognito: "don't use incognito browsing",
      },
      initialSettingsModal: {
        infoUpdated: "Updated information",
        selectAll: "Select all preferences",
        beforeContinue: "Before continuing, select your preferences",
        spanish: "Spanish 🇨🇴",
        english: "English 🇺🇸 (beta)",
        language: "Language",
        currency: "Currency",
        continue: "Continue",
      },
      itemEvent: {
        alreadyFollow: "Now you follow this event",
        noFollow: "You no longer follow this event",
        errorRefresh: "Failed to update: ",
      },
      loading: {
        poweredBy: "Powered by Fravents",
        sorryThereProblem: "Sorry, there was a problem loading the page.",
      },
      navigationEvent: {
        team: "Team",
        sells: "Sales",
        validate: "Validate",
        edit: "Edit",
        delete: "Delete",
        deleteEvent: "Delete event",
        doYouAgreeDeleteEvent:
          "Do you agree to permanently delete the event {{event}}?",
        eventSuccessDelete: "The event has been successfully deleted.",
        youCanNotDeleteEvent:
          "You cannot delete an event with tickets created. Contact us if you want to delete it permanently.",
        errorDeleteEvent: "There was an error deleting the event: ",
      },
      qrScanModal: {
        validateCodeCompleted: "QR code validation completed",
        errorValidateInfo: "Error validating information: ",
        noAccessCamera:
          "There is no access to your camera, check the permissions and try again or with another browser.",
        errorAccessCamera:
          "Error accessing your camera, check permissions or try another browser. ({{errorCamera}})",
        scanCodeInSquare:
          "Place the QR code inside the box, if you have problems we recommend you to be in a lighted place, check the camera permissions or use a different browser.",
        close: "Close",
        transactionInfo: "Transaction Information",
        codeInvalid:
          "This QR code is invalid, please verify the information. [{{error}}]",
        products: "Products:",
        ticketType: "Ticket type:",
      },
      shoppingCart: {
        deleteAllProducts: "Delete all products",
        areAgreeDelete:
          "Do you agree to remove all items from your Shopping Cart?",
        shoppingCart: "Shopping cart",
        deleteAll: "Delete all",
        noYetAddAnyProduct: "You haven't added any products yet.",
        goTopay: "GO TO PAY",
      },
      statsCounter: {
        generatedTickets: "Generated tickets",
        registeredUsers: "Registered users",
        totalSells: "Total sales",
      },
      templateEventSelectorModal: {
        freeList: "Lists (Free)",
        freeListDescription:
          "Any user with access to the event can register at no cost. Ideal for entrance control of bars, free events, restaurants, ...",
        sellByStages: "Sale in stages",
        sellByStagesDescription:
          "You define different ticket prices and they will be enabled each time the previous stage runs out. Designed for events that have dynamic prices based on your demand.",
        withoutBlockers: "No restrictions",
        withoutBlockersDescription:
          "All ticket prices will be enabled at the same time for users with access to the purchase. For events with fixed prices and available from its publication.",
        selectFormat: "Select the format of your event",
        indentifyFeatures:
          "Identify the characteristics of each one and choose the one that best suits your objectives.",
        continue: "Continue",
        lessSelectOption: "Need to select an option",
      },
      tutorial: {
        cookiesPolicy: "Cookies policy",
        ourPlatformUseCookies:
          "Our web platform uses Cookies to ensure that our users have the best experience during their session. When you use our platform you accept our policy",
        readMore: "Read more",
        welcome: "Welcome to Fravents!",
        wecolmeMessage:
          "Hello I am<bold> D.</bold> and I'll be here to help you. Here you can find events of your interest, relevant information about our platform and how to be part of it<bold> Do you want to publish your own events?</bold> Is<bold> free</bold> for all",
        lookAllEvents: "See the available events",
        findEventsFinder:
          "Find the event you want to attend in our featured selection or through our search engine",
        ticketsOnHands: "Your tickets at hand!",
        ticketsOnHandsDescription:
          "Here you can see the complete list of your tickets, the events you have saved in your history and the product purchases. Remember that<bold> you can sell</bold> either<bold> transfer tickets</bold> instantly to any contact",
        thereAreTickets: "Here are your tickets",
        thereAreTicketsDescription:
          "Each ticket has a unique and dynamic QR that you must present on the day of the event. There is also the relevant information of the event in each ticket, you can sell or transfer each one to another contact",
        hereBeginAll: "It all starts here",
        hereBeginAllDescription:
          "Use our event creator and enter all the necessary information and publish it on<bold> 4 easy steps</bold> . You also have a previewer to review how your event is turning out",
        haveAllPower: "You have the power!",
        haveAllPowerDescription:
          "We have worked hard so that the platform can be managed by you, like the<bold> creation</bold> of events, statistics of<bold> sales</bold>, The team of<bold> promoters</bold> and of<bold> Logistics</bold> , among other functionalities",
        timeValidateTickets: "Time to validate tickets",
        timeValidateTicketsDescription:
          "We offer two ways to do it. The first is to scan the QR codes with your device, we give you peace of mind that our system will not allow reused, counterfeit or invented codes, rest assured 😉. And the second is through a search engine to validate entries manually, one by one.",
        buildTeam: "Build your team",
        buildTeamDescription:
          "Here you can give permissions to<bold> collaborators, promoters and logistics</bold> so they can promote your event or validate tickets, respectively. Just remember that they must already be registered to add them",
        dataOnHand: "Statistics at hand!",
        dataOnHandDescription:
          "We centralize the most relevant data of your event to follow up whenever and wherever you want, such as sales status, income, number of tickets generated, among other features. remember that you can<bold> add tickets manually</bold> , you can also download data in an Excel and<bold> create promo codes</bold>",
        perzonalizeProfile: "Personalize your profile",
        perzonalizeProfileDescription:
          "Complete as much information as possible so that your profile is the most complete and you can make a good impression on users. You can too<bold> customize theme color</bold> , participate in our<bold> Referral program</bold> and set the data as",
        wantBePartner: "We want you to be a Partner",
        wantBePartnerDescription:
          "We are pleased that you want to be part of our community and use our services, just read this content and clarify your doubts before starting. Remember that we are here to help you, write us anything",
        thisEventWaits: "This event awaits you",
        thisEventWaitsDescription:
          "You can see all the information that the organizer prepared about your event, you just have to decide if you want to go and which ticket to buy. The means of payment vary depending on the country but in general they are<bold> Market payment, debit, cash and credit</bold>",
        ourStepStep: "Our step by step",
        ourStepStepDescription:
          "In this content you can find a more dynamic, complete and detailed tutorial to use our platform as a partner. Anything you can write to us",
        finishPayment: "Here ends the purchase",
        finishPaymentDescription:
          "Thank you for using our services, if you have any concerns with your purchase it may take a few minutes to be reflected in your profile or you can write to us to give you personalized support",
        bePartCommunity: "Be part of the community",
        bePartCommunityDescription:
          "We appreciate your interest in being part of our community and linking your Mercadopago account, when everything is complete you can sell immediately on our platform and receive your earnings instantly",
        contactInfo: "Your contact information",
        contactInfoDescription:
          "Review the information of referrals or scanned tickets of your contact so that you can give a more timely follow-up of their activity",
        partnerInfo: "Information from our Partner",
        partnerInfoDescription:
          "Welcome to our partner's page, where you expose your relevant contact information, centralize your events and products that you offer to the public, and you can share your information",
        faqs: "Frequent questions",
        faqsDescription:
          "Find the answers to your questions about how to use our platform, solve a case or understand our processes",
        newPlugin: "New website plugin!",
        newPluginDescription:
          "Integrate our plugin on your website so you can sell from there",
        buyProducts: "Purchase of products",
        buyProductsDescription:
          "Pay for the products of each seller to claim at their point of sale or receive a shipment",
        createProduct: "Create product",
        createProductDescription:
          "Enter all the information of your product to then be sent for review and, subsequently, for publication",
        shoppingCart: "Shopping cart",
        shoppingCartDescription:
          "Review the products you want to buy, enter discount codes and proceed to payment in a few clicks.",
        sellInfo: "Purchase information",
        sellInfoDescription:
          "In this space you can review the details of the purchase and be able to review messages and notifications about the delivery status or answer questions.",
        superViseSells: "Monitor sales",
        superViseSellsDescription:
          "Being part of the team of store supervisors, you have access to the reports and manage the purchases of each client (change shipping and delivery statuses, and answer messages)",
        superViseSellsAdminDescription:
          "Hello Super Admin, here you can manage your store team, the available products, you have access to reports and manage each customer's purchases (change shipping and delivery statuses, and respond to messages).",
        partnerProgram: "Partner program",
        partnerProgramDescription:
          "Look at the proposal we have for you to generate income with us. Write us if you want us to tell you more.",
        legalDoc: "Legal documents",
        legalDocDescription:
          "Here you have the requested legal document, we always want to maintain transparency with our users. If you have any questions, write to us!",
        infoOurPartner: "Information from our Partner",
        infoOurPartnerDescription:
          "Welcome to our partner's page, where you expose your relevant contact information, centralize your events and products that you offer to the public, and you can share your information",
        eventsOurPartner: "Events of our Partner",
        eventsOurPartnerDescription:
          "Welcome to our partner's list of events, where you can directly purchase your tickets in a few simple steps",
        tutorialNoFound: "Tutorial not found",
        tutorialNoFoundDescription:
          "We're sorry, we couldn't find any help information for this page.",
        helpYou: "helps u",
      },
    },
    constants: {
      titlePublishEvent: "Post event",
      bodyPublishEvent:
        "You can create all the events you want, without limit of attendees and manage them from your device, without intermediaries.",
      titleCreateEvent: "Create tickets",
      bodyCreateEvent:
        "If you sell your tickets outside the platform, you can manually add purchases and generate tickets for your users automatically.",
      titleScanQr: "Scan QR",
      bodyScanQr:
        "Our tickets have a unique and dynamic QR, which can be scanned from any device on our platform, without complications or additional apps.",
      titleManagePromoters: "Manage promoters",
      bodyManagePromoters:
        "Add users to your team so they have a unique referral link for each event so you can see the sales records for each one.",
      titleTeamLogistics: "Logistics team",
      bodyTeamLogistics:
        "Delegate permissions to the users on your team who will help you in the process of scanning and validating tickets.",
      titleAnalytics: "Analytics",
      bodyAnalytics:
        "Each event has a sales dashboard where we centralize all the information with graphs and relevant data at your fingertips.",
      titleWebsite: "Web page",
      bodyWebsite:
        "When you create your profile, you are assigned your own profile page with your information, social networks and virtual store with a personalized url.",
      titleEarnNow: "Instant earnings",
      bodyEarnNow: `If you want to sell tickets you must link your Mercadopago account and you will receive your money from each purchase instantly (Average commission: ${platformTax[0]}).`,
    },
    pages: {
      admin: {
        confirmPartner: {
          messageConfirmPartner:
            "Registration successfully completed. Thank you for linking your account, in a few minutes your payment gateway will be configured on our platform. Any questions or concerns write to help@fravents.com",
          errorConfirmPartner: "The required action could not be performed.",
          returnHome: "Return to main page",
        },
        contactPublish: {
          createTypeEvent:
            "Create the type of event you want, this platform is for everyone.",
          manageDifferentEventTypes:
            "It handles different types of events: Public, Hidden and Private.",
          enterPlatformAdmin:
            "Access the Administrator platform to manage your events.",
          linkMercadoPagoAccount:
            "Link your Mercado Pago account to sell your tickets and receive the money through our platform.*",
          seeAnalyticPlatform:
            "View all the information on your sales and tickets on the analytics platform.",
          managePromotersTeam:
            "Manage your team of promoters so that they can promote your event in the best way and you can monitor their sales.",
          delegateLogictisTeam:
            "Delegate your Logistics team so they can scan QR codes and verify tickets on the day of your event.",
          keepInfoCentralized: "Keep your information centralized and secure.",
          continueWork:
            "... And we continue working to offer more and more functionalities!",
          includeAllPlan: "Includes everything in the Free Plan.",
          clientAssumeTax:
            "You can configure that this value is assumed by the customer at the time of purchase.",
          withoutSuscription:
            "No subscriptions or terms of permanence, zero strange charges or fine print.",
          linkMPaccountEarn:
            "Link your Mercadopago account and receive your earnings instantly.",
          taxIncludeAll: `The ${platformTax[0]} rate includes the Mercadopago and Fravents commission, the rest is yours.`,
          offerPaymentMethods:
            "We offer methods of credit, debit, bank transfers and Mercadopago accounts.",
          sellTicketsWithouLimit:
            "Sell your tickets without limit of capacity or price. *",
          youHaveOwnWebShop:
            "You have your own virtual store with your web page of your profile and products.",
          sellAnyProduct:
            "You can sell any type of product of your brand such as merchandising and others. *",
          step: "Passed",
          createAccount: "Create your account",
          simpleSteps:
            "In a few simple steps add your data, verify your account and you will be able to enjoy all our features.",
          setUpEvent: "Configure your event",
          addNeededInfo:
            "Add the necessary information to create and publish your event so that you can start managing it.",
          manageTeam: "Manage your team",
          addPromotersLogistics:
            "You can add the promoters and logistics authorized to manage event tickets with our security by means of a QR code.",
          supervise: "Supervises",
          ourPlatformAnalytics:
            "With our analytics platform you will be able to monitor the status of your event and tickets, seeing all the information secured in our database.",
          enjoyRepeat: "Enjoy and repeat these steps!",
          weWantBest:
            "We want everyone who uses our platform to be able to get the most out of the event experience with our help. You are free to create the events you want as long as they meet our",
          termsConditions: "Terms and Conditions",
          errorSaveParnet: "Error saving partner: ",
          beforeContinue: "Before continuing...",
          mustAcceptTerms:
            "You must accept our [[Terms and Conditions]] to confirm that you will make good use of the platform and its information, otherwise your account will be suspended and legal measures could be taken. FRAVENTS is not responsible for how you manage your event and frees us from any conflict of its realization, we only want to provide a service tool to optimize certain processes. Do you accept our Terms and Conditions?",
          noLater: "No, better later",
          yesAccept: "Yes I accept",
          promoterCode: "Referral promoter code",
          promoterCodeInfo:
            "If your process has been guided by one of our authorized promoters, please enter the referral code that was shared with you and your benefits will be activated. If you don't have any one, skip this field.",
          viewAdminAccount: "See my administrator account",
          createParnertAccount: "Create my partner account",
          mustVerifyEmail:
            "You must verify your email, go to your profile here.",
          logInContinue: "Sign in to continue",
          publishEvent: "Post my event",
          hiThanks:
            "Hello! Thank you for your interest in using our services. We want to offer the best platform for event management and within reach of anyone. So, here are the benefits of being a part of our community and what we offer without any weird or overpriced costs. Any concern or community, you can write to us at",
          withBriefMessage:
            "with a brief message and contact information so that an agent from our team can contact you.",
          bestRegards: "Cordially, FRAVENTS Team.",
          lookFuntionalities:
            "Take a look at some of the most relevant features",
          manageTickets: "Manage tickets as you wish",
          manual: "Manual",
          ifSellOut:
            "If you sell outside of Fravents, we offer a manual ticket creation service where you only have to upload an Excel file with the data of each one so that the same platform generates them and sends them to each user.",
          refer: "Referred",
          allMemberHaveLink:
            "Every member of your team of Event Promoters will have a referral link with which you can track the sales generated by each promoter. If your event is private, they can only purchase tickets through a referral link.",
          automatic: "Automatic",
          noWorryAboutProcess:
            "Do not worry about the entire process of buying and assigning tickets, it is all automated so that you ignore the process and only focus on the metrics that we offer you.",
          stepByStepAccount: "Step by step to create your event",
          ourPlans: "Our plans",
          freePlan: "Plan free",
          ourCommunityIsUnique:
            "Our community is one, which is why we believe that there should be no plans that limit the scope of our project. Therefore, we offer our plan:",
          zeroFree: "$0 = FREE",
          isYours: "Now is yours!",
          payedplan: "Paid plan",
          forInterested:
            "For those interested in making sales through our platform, we offer instant payments with an average commission percentage of:",
          aproxTax: `${platformTax[0]} approx.*`,
          contactUs: "Contact us",
          ifSellInOurPlatform: `* If it is sold through our payment platform, a discount of approximately ${platformTax[0]} per transaction will be made to cover tax expenses, payment gateway and operational charges (These expenses may vary depending on the payment method chosen by users).`,
        },
        detailMember: {
          emptyRegisters: "No records to display",
          withoutEmail: "No email",
          refer: "Referred",
          scaned: "Scanning",
          errorGetInfo: "Error getting information: ",
          history: "Record",
          refers: "Referrals",
          scaneds: "Scanned",
        },
        eventForm: {
          personalData: "General data",
          data: "Data",
          contentAndArtists: "Content and artists",
          content: "Content",
          pricesAndTc: "Prices and T&C",
          prices: "Prices",
          configuration: "Settings",
          mustLetOneCoin: "You must leave at least one coin enabled",
          enterAllData: "Enter all the required data (*) to continue",
          coordsHasFormat: "Coordinates are in the wrong format",
          priceMustMajor: "The price must be greater than $1000 COP",
          addPriceUsd: "Add the price in USD",
          nameMustBeDif: "The name must be different from the others",
          enterAllDataPrice: "Enter all the price data",
          linkMustHaveFormat:
            "The link must have the format https (Ex: https://fravents.com)",
          enterTitle: "Enter Title",
          toEmpty: "Empty",
          soldOut: "Sold out",
          availableQuantity: "Quantity Available",
          available: "available",
          availablePrice: "Price available",
          nextToSell: "Soon for sale",
          categoryName: "Category name",
          copPrice: "Price $ COP",
          usdPrice: "Price $USD",
          quantity: "Amount",
          inYourProfileLink: "In your profile you can link your accounts",
          linkAdded: "ADDED LINK",
          addNewArtist: "Add new artist (Photo, Name and Link)",
          urlPhoto: "Photo url",
          successCreateEvent: "Event created successfully",
          errorCreateEvent: "Error creating event",
          successSaveEvent: "Event saved successfully",
          errorSaveEvent: "Failed to save event",
          saveEvent: "Save event",
          areYouSureSaveEvent:
            "Do you agree that you have completed all the information to save the event?",
          eventName: "Name of the event",
          whatIsEventName: "What is your event called?",
          poster: "Poster/Banner",
          maxFileSize: "The maximum size allowed to upload each image is 5Mb",
          dateHourStart: "Start Date and Time",
          dateHourEnd: "End Date and Time",
          select: "Choose",
          address: "Address",
          hideAddress: "Hide address",
          isFor18olld: "Is it for adults?",
          eventType: "Event type",
          description: "Description",
          moreAboutEvent: "Tell more about your event...",
          descriptionImage: "Image in the description",
          termsConditionsEvent: "Event Terms and Conditions",
          textAddedTerms:
            "In the end, it will always go 'Fravents is only responsible for issues related to technical support in the purchase and sale of tickets. Fravents is not responsible for the execution or anything related to this event.'",
          noEditPrices:
            "Prices cannot be edited for customer safety, you can only edit the number of tickets available for sale. If you want to change any of them, please write to us through our service channels.",
          moneySendToYourAccounts:
            "The money from the purchases will be sent directly to your linked Mercado Pago account",
          toSellMustLinkAccount:
            "To charge through our platform you must link your Mercado Pago account to receive the money in your account.",
          acceptCop: "Accept payments in pesos ($ COP)?",
          acceptUsd: "Do you accept payments in dollars ($ USD)?",
          addNewPrice: "Add new price (Name, Price and Quantity)",
          changeDefaultCurrency: "(Change default currency)",
          sellStagesOrAll:
            "Is the sale in stages or will all prices be available at the same time?",
          ifIsStages:
            "If it works in stages, it means that the system will automatically only enable the first price on the list that has quantity available for sale, if it does not, it will take the second on the list and so on. The system will show you what price will be available.",
          allAvailable: "All available",
          byStages: "By stages",
          pricesHaveTax:
            "Do the prices include the service fee or is it assumed by the clients?",
          yesIncluded: "Yes, it's already included",
          noClientPay: "No, the client assumes it",
          graphicResources:
            "Graphic Resources - It would be great if you added our logo to your posts to inform users that we are your ticket ally. REMEMBER NOT TO ALTER THE LOGO TO AVOID LEGAL PROBLEMS ACCORDING TO OUR TERMS AND CONDITIONS.",
          availablePromoters: "Enable promoters",
          wantPromotersVisible:
            "Do you want promoters to be visible at the event?",
          isPrivate:
            "It is private? They can only access through a referral link",
          yesPrivate: "Yes, private",
          noPublic: "No, public",
          wantPermanentVisible:
            "Do you want it to be permanently visible? It can be hidden if you want",
          yesVisible: "Yes, visible",
          noHide: "No, hide",
          addFbPixel: "Add Facebook pixel identifier?",
          fbPixel: "Facebook pixel token",
          loadTestData: "Load test data",
          preview: "Preview",
          before: "Before",
          save: "Save",
          next: "Next",
          activatePayments: "Do you want to enable online payments?",
        },
        dashboard: {
          newPlugin: "New plugin!",
          integrateItInWebsite:
            "Integrate it into your website to sell from there",
          setUpAccount: "Set up account",
          linkAccounts: "Link Mercado Pago, customize your page and profile",
          team: "Team",
          sales: "Sales",
          validate: "Validate",
          details: "Detail",
          edit: "Edit",
          noHaveEvents: "We don't have any events that you created,",
          createNewOne: "Create a new one here",
          remaningTickets: "Remaining tickets",
          errorGetEvents: "Error getting events: ",
          availables: "Available",
          hidden: "Hidden",
          finished: "Finished",
          createEvent: "Create event",
          events: "Events",
          options: "Options",
        },
        productForm: {
          generalData: "General data",
          data: "Data",
          pricesTc: "Prices and T",
          prices: "Prices",
          configuration: "Setting",
          enterAllData: "Enter all the required data (*) to continue",
          priceMajorCop: "The price must be greater than $1000 COP",
          nameMustBeDifferent: "The name must be different from the others",
          enterAllPriceData: "Enter all the price data",
          available: "available",
          empty: "Empty",
          soldOut: "Sold out",
          sizeColor: "Size, Color, ...",
          priceCop: "Price $ COP",
          availableQuantity: "Quantity Available",
          successCreateProduct: "Product created successfully",
          errorCreateProduct: "Error to create product",
          successSaveProduct: "Product saved successfully",
          errorSaveProduct: "Error to save product",
          saveProduct: "Save product",
          areYouSureSaveProduct:
            "Do you agree that you have completed all the information to save the product?",
          maxFileSize: "The maximum size allowed to upload each image is 5Mb",
          whatIsProductName: "What is your product called?",
          images: "Images",
          description: "Description",
          tellMoreAbout: "Tell more about your product...",
          termsConditions: "Terms and conditions of purchase",
          textAddedTerms:
            "In the end, it will always go 'Fravents is only responsible for issues related to technical support in the payment gateway of the product. Fravents is not responsible for delivery management or any issue related to this product.'",
          moneySendToYourAccounts:
            "The money from the purchases will be sent directly to your linked Mercado Pago account",
          addNewPrice: "Add new price (Reference, Price and Quantity)",
          graphicResources:
            "Graphic resources - It would be great if you added our logo in your publications to inform users that we are your sales ally. REMEMBER NOT TO ALTER THE LOGO TO AVOID LEGAL PROBLEMS ACCORDING TO OUR TERMS AND CONDITIONS.",
          sellAvailable: "Is it enabled for sale?",
          sendsIncluded: "Is shipping included?",
          addFbPixel: "Add Facebook pixel identifier?",
          fbPixel: "Facebook pixel token",
          loadTestData: "Load test data",
          preview: "Preview",
          before: "Before",
          save: "Save",
          next: "Next",
        },
        salesShop: {
          forSend: "To send",
          pending: "Pending",
          rejected: "Rejected",
          payed: "Paid",
          sent: "Sent",
          delivered: "Delivered",
          filter: "Filter",
          comple: "Comple.",
          pendi: "Pending",
          sen: "Sent",
          deliv: "Delivered",
          rejec: "Rejected.",
          noMoreSells: "No purchases to display",
          withoutEmail: "No email",
          product: "product",
          errorUpdateSellStatus: "Error to update sales status: ",
          confirmDelivery: "Confirm delivery and finalize order",
          areYourSureChangeStatus:
            "Do you agree to change the {{length}} sale{{multiple}} status to &quot;Delivered&quot;? IT CANNOT BE CHANGED AFTER CONFIRMING. This action will send a notification to the buyer. Remember to keep your customers up to date through the chat of each sale.",
          confirmReturnDelivery: "Confirm return shipments",
          areYourSureChangeReturnStatus:
            "Do you agree to change the {{length}} sale{{multiple}} status to &quot;Waiting&quot;? This action will send a notification to the buyer. Remember to keep your customers up to date through the chat of each sale.",
          confirmSend: "Confirm shipments",
          areYourSureChangeSendStatus:
            "Do you agree to change the {{length}}} sale{{multiple}} status to &quot;Sent&quot;? This action will send a notification to the buyer. Remember to keep your customers up to date through the chat of each sale.",
          thereNoItems: "There are no items to display",
          bought: "bought",
          totalSells: "Total sale",
          detail: "Detail",
          totalIncomes: "Total income",
          selledProducts: "Sold products",
          sellsWaiting: "Purchases on hold",
          senta: "Sent",
          finishedDelivery: "Finished deliveries",
          completeSells: "Completed purchases",
          pendingSells: "Pending purchases",
          rejectedSells: "Rejected purchases",
          deletedCode: "Removed code",
          errorPromoCode: "Promo code error: ",
          deletePromoCode: "Remove promo code",
          areYourSureDeletePromoCode:
            "Do you agree to permanently delete the promotional code {{textCode}} and all its data? This action cannot be reversed",
          noExistCode: "The code does not exist, please try again",
          noCodesToShow: "There are no codes to display",
          discount: "Discount",
          created: "created",
          buyUser: "per user",
          expDate: "Valid until",
          viewDetails: "See details",
          edit: "Edit",
          delete: "Eliminate",
          viewGraphs: "See graph",
          data: "Data",
          used: "used",
          remaning: "Remaining",
          viewList: "See list",
          historyUses: "Usage history ({{date}})",
          year: "Year",
          fillDataYet: "Information is still missing, please try again",
          editedCode: "Code edited",
          createdCode: "Code created",
          text: "Text",
          quantity: "Amount",
          limitUseByClient: "Usage limit per customer",
          validUntil: "Valid until",
          priceCop: "Value $ (COP)",
          percentage: "Percentage (%)",
          name: "Name",
          email: "Mail",
          phone: "Phone",
          productM: "Product",
          type: "Type",
          sellDate: "Purchase date",
          status: "State",
          promoCode: "Promotional code",
          totalValueCop: "Total value (COP)",
          fileNameExport:
            "FRAVENTS Store Consolidated Product Sales - {{dateNow}}",
          sellFraventsShop: "Sales Store FRAVENTS",
          sells: "Sales",
          alertDownload: "Data download alert",
          toDownloadInfoAccept:
            "By downloading the information of registered users in purchases, you are responsible for their use and destination.",
          errorGetPromoCode: "Error to get promo codes: ",
          errorGetSells: "Error to get sales: ",
          manageSellsSends: "Manage purchases and shipments",
          onWaiting: "On hold",
          reviewAndSelect:
            "Review and select the sales that have payment approved and are pending to manage and send.",
          return: "Go back",
          send: "Send",
          sendUpdatesConfirm:
            "Send shipment updates and confirm when it is delivered. You can check the completed sales below in the Records section.",
          delivery: "Deliver",
          sellDataOf: "Sales data from",
          eCommerce: "e-commerce",
          yourEcommerce: " your e-commerce",
          viewShop: "See shop",
          downloadData: "Download data",
          maxMb: "Maximum 5Mb each",
          lastMonth: "Last month",
          historySells: "Sales History ({{historyDate}})",
          promoCodes: "Promotional codes",
          createNewPromoCode: "Create new code",
          records: "Records",
          searchBy: "Search by name, email or phone",
          alla: "All",
          search: "Search",
          loadMode: "Load more",
          infoPromoCode: "Promotional code information",
          addDataPreferences:
            "Add data and usage preferences. Remember that after it is created, you can only edit its validity date and the limit of use.\n\nREMEMBER: These codes will only work for payments in Colombian pesos (COP).",
          textMustBeUnique:
            "The text must be unique and you must fill in the value or the promotion percentage",
        },
        salesTeam: {
          teamAccessViewStats:
            "Personnel who have access to the dashboard of your e-commerce sales. They also have access to view discount code reports and order management to change shipping statuses and answer customer messages.",
          addSupervisor: "Add supervisor",
          supervisorSell: "Sales Supervisor",
          rememberAddSupervisor:
            "Remember that when you add it, it is assigned at once and you get access to the dashboard",
          supervisor: "SUPERVISOR",
          thisTeamQrAccess:
            "These personnel only have access to the QR reader to be used at physical points or at home, and validate the delivery of the products.",
          addValidator: "Add validator",
          validator: "Validator",
          eachUserCanScan:
            "Each member will have access to the Scan QR Code functionality to validate each purchase.",
          validatorM: "VALIDATOR",
          userAdded: "User added",
          userDeleted: "User Deleted",
          confirmAction: "Confirm action",
          wantDeleteUser:
            "Do you want to remove {{name}} user {{email}} from your team?",
          thereNoUser: "There is no user in this list",
          viewStats: "Monitor sales",
          validateQr: "Validate QR",
          wantAddUser: "Want to add {{name}} email {{email}} to your team?",
          userAlreadyBe: "The user is already part of the team.",
          enterValidEmail: "Enter a valid email",
          errorGetTeam: "Error getting team: ",
          viewDetails: "See details",
          delete: "Eliminate",
          add: "Add",
          pleaseEnterCompleteEmail:
            "Please enter the full email of the person you want to add, remember that they must already be registered on the FRAVENTS platform.",
        },
        sales: {
          completed: "Completed",
          pending: "Pending",
          rejected: "Rejected",
          transfered: "Transfers",
          scaned: "Scanned",
          resell: "Resold",
          filter: "Filter",
          comple: "Comple.",
          pendi: "Pending",
          rejec: "Rejected.",
          noMoreSells: "No purchases to display",
          withoutEmail: "No email",
          tickets: "tickets",
          onTransfer: "In transfer",
          transferedS: "Transferred",
          payed: "Paid",
          pendingS: "Pending",
          rejectedS: "Rejected",
          refunded: "Refunded",
          thereNoMembers: "No members to display",
          referedUsers: "Referrals",
          totalSell: "Total sale",
          totalIncomes: "Total income",
          generatedTickets: "Generated tickets",
          scanedCodes: "Scanned codes",
          resellTickets: "Resold tickets",
          completeSells: "Completed purchases",
          pendingSells: "Pending purchases",
          rejectedSells: "Rejected purchases",
          deletedCode: "Removed code",
          errorPromoCode: "Promo code error: ",
          deletePromoCode: "Remove promo code",
          areYourSureDeletePromoCode:
            "Do you agree to permanently delete the promotional code {{textCode}} and all its data? This action cannot be reversed",
          noExistCode: "The code does not exist, please try again",
          noCodesToShow: "There are no codes to display",
          discount: "Discount",
          created: "created",
          byUser: "per user",
          expDate: "Valid until",
          viewDetails: "See details",
          edit: "Edit",
          delete: "Eliminate",
          viewGraphs: "See graph",
          data: "Data",
          used: "Used",
          remaning: "Remaining",
          viewList: "See list",
          historyUses: "Usage history ({{date}})",
          year: "Year",
          fillDataYet: "Information is still missing, please try again",
          editedCode: "Edited code",
          createdCode: "Created code",
          text: "Text",
          quantity: "Amount",
          limitUseByClient: "Usage limit per customer",
          validUntil: "Valid until",
          priceCop: "Value $ (COP)",
          percentage: "Percentage (%)",
          scaneds: "Scanned",
          name: "Name",
          lastName: "Last name",
          email: "Mail",
          document: "Document",
          typeTicket: "Ticket Type",
          sellDate: "Purchase date",
          statusTransaction: "Transaction status",
          promoCode: "Promotional code",
          totalValueCop: "Total value (COP)",
          fileNameExport: "FRAVENTS {{name}} Sales - {{dateNow}}",
          sellFravent: "FRAVENTS SALES",
          sells: "Sales",
          alertDownload: "Data download alert",
          toDownloadInfoAccept:
            "By downloading the information of registered users in purchases, you are responsible for their use and destination.",
          errorGetPromoCode: "Error to get promo codes: ",
          errorGetSells: "Error to get sales: ",
          invalidData: "Invalid data",
          sellData: "Sales data",
          downloadData: "Download data",
          maxMb: "Maximum 5Mb each",
          addTickets: "Add tickets",
          maxUploadTickets: "Max 100 tickets per file",
          sellsByType: "Sales by type (COP)",
          quantityTicketGenerated: "Amount of tickets generated by type",
          lastMonth: "Last month",
          historySells: "Sales History ({{historyDate}})",
          promoters: "Promoters",
          logistics: "Logistics",
          promoCodes: "Promotional codes",
          createNewPromoCode: "Create new code",
          records: "Records",
          searchBy: "Search by name, email or document",
          alla: "All",
          search: "Search",
          loadMode: "Load more",
          infoPromoCode: "Promotional code information",
          addDataPreferences:
            "Add data and usage preferences. Remember that after it is created, you can only edit its validity date and the limit of use.",
          textMustBeUnique:
            "The text must be unique and you must fill in the value or the promotion percentage",
        },
        shop: {
          newPlugin: "New plugin!",
          integrateItInWebsite:
            "Integrate it into your website to sell from there",
          setUpAccount: "Set up account",
          linkAccounts: "Link Mercado Pago, customize your page and profile",
          successChange: "Successful change",
          errorChangeProductState: "Error to change product status: ",
          changeProductState: "Change product status",
          areSureChangeShowProduct:
            "Do you agree to {{isAvailable}}enable product {{name}}? Remember that when {{isAvailable}} is enabled, users {{isNoYet}} will be able to buy it.",
          labelAvailable: "{{preLabel}}enable",
          validate: "Validate",
          edit: "Edit",
          detail: "Detail",
          unavailable: "Disable",
          available: "Enable",
          weNoHaveAnyProduct: "We don't have any products that you created",
          remaningItems: "Remaining items",
          dateCreate: "Creation date",
          errorGetProducts: "Error getting products: ",
          products: "Products",
          options: "Options",
          sells: "Sales",
          team: "Team",
          createNewOne: "Create new",
        },
        team: {
          teamAccessLink:
            "Personnel who have access to the reference link of the event.",
          addPromoter: "Add promoter",
          promoter: "Promoter",
          rememberAddPromoter:
            "Remember that when you add it, it is assigned at once and you get a referral link for possible purchases",
          promotersM: "PROMOTERS",
          thisTeamQrAccess:
            "These personnel only have access to the QR reader on the day of the event to register tickets.",
          addLogistic: "Add logistics",
          logistic: "Logistics",
          eachUserCanScan:
            "Each logistics member will have access to the Scan QR Code functionality on the day of the event and validate each ticket.",
          logisticM: "LOGISTICS",
          yourColaboratorsHelp:
            "Your allies will help you expand the reach of your event to new users.",
          addColaborator: "Add collaborator",
          colaborator: "Collaborator",
          whenAddColaborator:
            "When you add collaborators, you enable the sale of your event in the profile of each one. You can manage access whenever you want.",
          colaborators: "COLLABORATORS",
          userAdded: "User added",
          userDeleted: "User Deleted",
          confirmAction: "Confirm action",
          wantDeleteUser:
            "Do you want to remove {{name}} user {{email}} from your team?",
          linkCopied: "Link copied correctly",
          thereNoUser: "There is no user in this list",
          promoters: "Promoters",
          colaboratorsM: "Collaborators",
          confirmAddUser: "confirm addition",
          wantAddUser: "Want to add {{name}} email {{email}} to your team?",
          userAlreadyBe: "The user is already part of the team.",
          enterValidEmail: "Enter a valid email",
          errorGetTeam: "Error getting team: ",
          invalidData: "Invalid data",
          viewDetails: "See details",
          copyLink: "Copy link",
          delete: "Eliminate",
          add: "Add",
          pleaseEnterCompleteEmail:
            "Please enter the full email of the person you want to add, remember that they must already be registered on the FRAVENTS platform.",
        },
        validateTicket: {
          noFoundSell: "No purchase was found with that data",
          enterAtLeast: "Enter at least 3 characters",
          available: "available",
          ticketValidated: "Ticket validated, ",
          errorValidateTicket: "Error validating information: ",
          validateTicket: "Validate ticket",
          wantValidateTickets:
            "Do you want to validate 1 purchase ticket for {{name}}? (Has {{quantity}}) REMEMBER that this action cannot be reversed.",
          yesValidate: "Yes, validate",
          enterAnyData: "Enter some information and click on 'Search'",
          thereNoItems: "There are no items to display",
          idNoRegistered: "Unregistered document",
          transfered: "Transferred",
          validate: "Validate",
          errorGetInfo: "Error getting information: ",
          youCanValidate:
            "You can validate tickets through the search engine with the buyer's data (by name, email or document), or through the QR code scanner.\n\n<bold> ATTENTION:</bold> Remember that when validating each ticket, the action cannot be reversed.",
          buyerData: "Customer's information",
          search: "Search",
          scanQrCode: "Scan QR code",
        },
        videoTutorial: {
          introduction: "Introduction",
          enter: "Enter",
          dashboard: "Dashboard",
          createEvent: "Create event",
          manageTeam: "Manage your team",
          viewAnalytics: "See analytics",
          validateTickets: "Validate inputs",
          final: "Final",
          welcome: "Welcome @, watch our tutorial!",
          hiHereIsVideo:
            "Hello! Next we leave our video tutorial to explain each of the functionalities that we offer to our partners. Any concern or community, you can write to us at",
          withBriefMessage:
            "with a brief message and contact information so that an agent from our team can contact you.",
          bestRegards: "Cordially, FRAVENTS Team.",
          videoTutorial: "Video-tutorial",
          youCanSkip:
            "You can jump between the different parts of the video depending on the section of your interest:",
        },
      },
      auth: {
        page404: {
          weDidntFindPage: "We have not found the page you are looking for",
          getMeOut: "Get me out of here",
        },
      },
      dashboard: {
        bookmarks: {
          noHaveEvents: "We don't have any events you've saved",
          favorites: "Favorites",
        },
        faq: {
          noFindAny:
            "We did not find any solution to your case, try to use other keywords",
          errorGetQuestions: "Error getting questions: ",
          faqs: "Frequently Asked Questions (FAQs) 🤓",
          searchKeyWords:
            "Search by keywords the answer to your concern. If our answers fail to solve your problem, write us an email and our support team will reply to you as soon as possible.",
          keyWords: "Keywords of your search",
          search: "Search",
        },
        index: {
          viewMoreInfo: "See more information",
          thereNoEvents: "No {{pastEvents}} events to display",
          before: "previous",
          closed: "nearby",
          errorGetEvents: "Error getting events: ",
          whatYouCanDo: "What can you do here?",
          titleNews: "We invest in the revolution 🔥",
          descriptionNews:
            "In 2024 we will integrate payments and rewards with the $FROIN token of our current blockchain project",
          forViewmore: "More info",
          clickHere: "click here",
          weOfferFeatures:
            "We offer different functionalities for you to be part of our community,<bold> FREE and everything at your fingertips</bold>",
          featured: "Featured",
          eventOnFire: "Our selective group of on-fire events",
          meetOutBenefits:
            "Learn about our benefits, features and prices here to<bold> create your event</bold> .",
          soon: "Upcoming Events",
          noKeepWithoutPlan:
            "Do not stay without a plan, go for one of these events",
          viewAll: "See all",
          doItEasy: "Do it in the most optimal and simple way possible",
          createYourEvent: "Create your event here",
          befored: "Powered Events",
          thanksEvents: "Thanks to the events that have trusted us",
        },
      },
      events: {
        allEvents: {
          weDontHaveEvents: "We don't have any events to display",
          enterAtLeast: "Enter at least 3 characters of the name",
          errorGetEvents: "Error getting events: ",
          eventsOnFire: "On-fire events",
          theseAndMoreEvents:
            "These and more events so you don't run out of plans. Use the search engine in the next section to filter the content and see the results in the list",
          whatSearch: "What are you looking for?",
          search: "Search",
        },
        finishpayment: {
          finishedTransaction: "Completed transaction ID: ",
          thanksBuy:
            "Thank you for shopping with FRAVENTS. You can now check your transactions in the My purchases list. If you do not receive the purchase confirmation email or have any inconvenience, you can write to us at help@fravents.com",
          pendingTransaction: "Pending Transaction ID: ",
          weAreProcessing:
            "We are still processing the transaction and waiting for a response, this page will reload every 10 seconds to check again. If you have any problems, you can write to us at help@fravents.com.",
          rejectedTransaction: "Transaction rejected or with ID problems: ",
          sorryNoComplete:
            "We are sorry that your payment could not be completed. If you have problems, do not hesitate to write to us at help@fravents.com.",
          noFoundPayment: "We didn't find any payments, please try again.",
          paymentError: "Payment error: ",
          returnFravents: "Return to Fravents",
        },
        indexEvent: {
          nowFollow: "Now you follow this event",
          unFollow: "You no longer follow this event",
          errorUpdate: "Failed to update: ",
          sellRegistered: "Registered purchase",
          inSomeMinutesEmail:
            "The purchase has been registered successfully and in a few minutes an email should arrive at {{email}} with the respective instructions. If there is any news, please communicate it to the Event Organizer.",
          gotIt: "Understood",
          noAvailableBooking:
            "The reservation can no longer be made because it is not available. [Error: {{error}}]",
          errorData: "Wrong data",
          beforeContinue: "Before continuing...",
          logInAccount:
            "For your purchase you must log in to Fravents or create an account, it's easy! Remember that you must verify your email, if you have problems please write to help@fravents.com",
          mustVerifyEmail:
            "For your purchase you must {{label}} verify your email for security. You can verify your email in Your Profile.",
          createAnd: "create and",
          goToProfile: "Go to my profile",
          promoCode: "Promotional code",
          youHaveCode:
            "Do you have any discount codes for this event? It's not mandatory.",
          completeData: "Fill in the details and try again",
          confirmSelection: "Confirm selection",
          youWantBuyTickets:
            "{{name}} Do you want {{action}} {{quantity}} ticket{{multiple}} {{ticketName}}? {{description}}.",
          register: "to register",
          purchase: "acquire",
          dataWillSave:
            "The data will be saved and an email will be sent to the user {{email}} with the respective instructions. THIS ACTION CANNOT BE REVERSED",
          youWillBeRedirect:
            "You will be redirected to the payment platform and you will have a maximum of 10 minutes to make the purchase or your reservation will be deleted",
          confirmDiscount: "Discount confirmation",
          infoPromoCodeEntered:
            "The promotional code you entered [{{name}}] gives you access to a discount of {{discount}} on the value of the tickets without tax. Do you want to continue?",
          yesPay: "Yes, go pay",
          errorPromoCode: "Promo code error: ",
          emailHasBadFormat: "The email entered has an incorrect format",
          buyTickets: "Buy tickets",
          partnerNoCanSell:
            "The organizer does not have the purchase enabled through Fravents, therefore, you must buy it directly with the authorized promoters through Whatsapp. When the purchase is registered, you should receive confirmation of the tickets that you can use on our platform.",
          goToWhatsapp: "Go to whatsapp",
          errorGetContacts: "Error getting contacts: ",
          errorGetEvents: "Error getting event: ",
          errorGetOffers: "Error to get offers: ",
          errorGetStats: "Error getting statistics: ",
          linkCopied: "Link copied correctly",
          blockRefLink:
            "The organizer does not have the Promoters feature enabled",
          linkReadyRef: "Link copied and now to reference!",
          shareLink:
            "Share this link on your social networks and each time a user buys through your link, the following amount will be credited to your credit balance:{{amount}} REMEMBER: The value is approximate because it varies depending on the means of payment of the user. Refer to the Terms and Conditions anything.",
          refAndEarn: "Refer and Earn!",
          eventFinished: "Event ended",
          getTicketsByLink:
            "To purchase tickets you must receive a referral link, write to an event promoter to request one.",
          registerFree: "Sign up free",
          toBuyVerifyEmail: "To buy you must verify your email here",
          youAreMember:
            "You are part of the team, these are the actions you can perform:",
          boughtTickets: "Tickets purchased through your referral link:",
          copyLink: "Copy referral link",
          linkRefBlocked: "Referral link blocked",
          addTicket: "Add purchase",
          validateTicket: "Validate ticket",
          completeBuyData: "Complete the purchase data",
          toContinueWithBuy:
            "To continue with the purchase of {{quantity}} entrada{{multiple}} {{type}}, please enter the following information and when confirming your order will be reserved.",
          confirmBuyRef:
            "Confirm that your buyer has already made the payment and that the data is correct. This action cannot be reversed and remains available for possible review by the Organizer.",
          toSendDataAcceptTC:
            "By submitting the data you confirm that you have read and accepted the [[Buyers Notice]], you also accept the terms and conditions of the event. To provide the best security and confidence to our users, payments are made through the {{provider}} platform, therefore, FRAVENTS does not store or manipulate any sensitive information on user payment methods.",
        },
        selectQuantity: {
          soldOut: "Sold out",
          soon: "Soon",
          available: "available",
          firstSelect: "First select a ticket type",
          noMoreQuantity: "You cannot exceed the amount available",
          alertMaxQuantity: "The maximum amount per purchase is 10 tickets",
          changeCurrency: "Change the coins",
          selectOtherCurrency:
            "The method you want to select uses another currency ({{newCurrency}}) different from your preferences ({{defaultCurrency}}), do you want to continue?",
          typeTicket: "Ticket type",
          taxBuy: "+ platform service fee {{value}}",
          taxIncluded: "These prices already include the service fee",
          quantity: "Amount",
          totalPrice: "Total price",
          withLocalPayment: "With<bold> local methods</bold> you pay:",
          withCryptosPayment: "With<bold> cryptocurrencies</bold> you pay:",
          errorpartnerAccounts:
            "Error: Problem with the organizer's payment accounts, please report the case.",
          continuePayment: "Continue to payment",
          remainData: "Missing purchase data",
        },
      },
      layout: {
        selectEvent: "Select the event of your choice",
        reviewBuy: "Check and buy your tickets",
        hereTickets: "Here are your tickets",
        signOut: "Sign off",
      },
      partner: {
        profile: {
          linkCopied: "Link copied correctly",
          infoSelectedNo: "The selected information does not exist",
          noContentToShow: "There is no content to display",
          manageSells: "Manage sales",
          verified: "Verified",
          noVerified: "Not verified",
          share: "Share",
          memberSince: "Member since",
          events: "Events",
          shop: "Store",
          all: "All",
          active: "Assets",
          loadMore: "Load more",
        },
        infoPlugin: {
          pluginAvailable: "Plugin enabled successfully",
          pluginUnavailable: "Plugin disabled successfully",
          linkCopied: "Link copied correctly",
          onPlugin: "Enable plugin",
          areYourSureGoodUse:
            "Do you agree to make good use of our code and will you assume responsibility for its integration?",
          offPlugin: "Disable plugin",
          areYourSureBlock:
            "Do you agree to block the use of your code? Remember that you can generate another code again but it will always be different to provide better security.",
          titleNewPlugin: "New plugin for your website 🤓",
          bodyNewPlugin:
            "Here you can enable your plugin so that you can copy and paste the code snippet into your website and start selling your Fravents content directly on your page without your customers having to leave your page. Check the video tutorial for more information.",
          instructions: "Instructions",
          youWillFindOptions:
            "Below you will find the options to enable, or not, your plugin. When you want to integrate it into your website, remember not to modify our code for its correct use. Just copy the following HTML code and paste it into the desired section in your source code.",
          copyCode: "Copy code",
          rememberEmail:
            "Remember to have the support of a web developer for this integration, since it is required to modify the source code of your website. If you want support, our technical team will be happy to help you if necessary, just write an email to",
        },
        plugin: {
          infoSelectedNo: "The selected information does not exist",
          noContentToShow: "There is no content to display",
          thisPluginIsUnavailable:
            "This plugin is disabled, to solve any problem contact technical support.",
          loadMore: "Load more",
        },
        programInfo: {
          communityFraventsPartners: "Anyone can be a Partner 🤑",
          descriptionPartners:
            'Before continuing, please watch the video that we have prepared for you in the introduction to our innovative business model that we want to share with you. After viewing it, the "Continue" option will be enabled to see the next steps.',
          continue: "Continue",
          messageContinue:
            "Thank you for watching our video, now read in detail the following document that will expand the information previously shown.",
          noContinue: "Oops there's something wrong...",
          messageNoContinue:
            "You have not yet completed watching the video, you cannot continue until you see at least 30 seconds of the presentation.",
          showForm: "Show form",
          cannotContinue: "You cannot continue yet",
          mustVerifyEmail:
            "To continue you must verify your email for security. You can verify your email in Your Profile.",
          completeDataBefore: "Complete the contact details",
          completeDataMessage:
            "We are glad that you are interested in continuing with the process, for this we need a couple of pieces of information and the information will arrive to your email in a short time.",
          subCompleteDataMessage:
            "By sending the data you confirm that you have read and accepted the [[Data processing authorization]] to continue with the process.",
          formSent:
            "Form submitted successfully, you will receive a response soon.",
          thanksForSendTitle: "Registration completed",
          thanksForSendText:
            "You are already on our guest list for our informative video conference to chat with our team about the next steps, we will be accompanying you throughout the process. Good luck and see you soon.",
          selectOneProgram: "Promoter Partner Program 🤑",
          descriptionIntro:
            "We have designed the following programs so that you can choose the path you want to be part of our partner community. Read the description of each one carefully and continue with their respective steps, if you have questions you can contact us through our contact channels. attention.",
          referenceEarn: "Refer and Earn",
          referenceEarnDesription:
            "If all you want is to share your referral code and see how your earnings grow, this program is for you.",
          referenceEarnRate: "10% of commission",
          commissionPercentageCalculated:
            "The commission percentage is calculated on Fravents' net earnings for each referred customer.",
          startReferencingInstantly:
            "You can start referencing instantly without selection processes.",
          eachCustomerRefer10:
            "For each customer you refer, you will receive 10% commission on that customer's sales for a period of 6 months, counting from the first sale made.",
          eachReferralReceives:
            "Each referral receives a preferential rate of 5% (normally it is 8%) on the service on the Fravents platform, not including the fixed costs of payment gateways.",
          salesAgent: "Sales Agent",
          salesAgentDescription:
            "For people who want to be part of our team of Promoters, receive a higher percentage of profits for longer and a benefits package, this program is designed for you.",
          salesAgentRate: "50% of commission",
          simpleWQuickSelection:
            "You must go through a simple and quick selection process, our team will always be with you.",
          eachCustomerRefer50:
            "For each customer you refer, you will receive 50% commission on that customer's sales for a period of 2 months (with an extension option *), counting from the first sale made.",
          accessPrivateCommunity:
            "Access to our private community of Promoter Partners",
          receiveBenefits:
            "You'll receive benefits like event discounts, free access to Fravents events, merchandise, and more!",
          youhavePossibility:
            "You have the possibility of negotiating the preferential rate for your referrals (between 2% to 8%) on the service on the Fravents platform, without including the fixed costs of payment gateways.",
          extendCondition:
            "* If after 2 months from the first sale of the referenced customer and another customer is not obtained, the percentage of 50% of the currently referenced customer will decrease by 5% each month until a new referenced customer is obtained and makes sales .",
          continuePromoter: "Continue the process",
          activateCode: "Activate my code",
          viewMyCode: "View my code",
          viewMoreBene: "See more benefits",
          beforeContinue: "Before continuing...",
          mustAcceptTerms: "Do you accept our [[Terms and Conditions]]?",
          noLater: "No, better later",
          yesAccept: "Yes I accept",
        },
      },
      profile: {
        index: {
          return: "Return",
          logOut: "Sign off",
          wantlogOut: "Do you want to log out of your account?",
          infoUpdated: "Updated information",
          referProgram: "Referral program",
          beforeContinue: "Before continuing...",
          areSureAcceptTerms:
            "Do you agree to accept our [[Terms and Conditions]] of our referral program? REMEMBER: If you violate our terms and conditions, your user will be blocked and you will not be able to join the program again.",
          mustVerifyEmail:
            "You must verify your email for security. You can send your verification email in Your Profile.",
          noOtherDay: "Not another day",
          goToMyProfile: "Go to my profile",
          agree: "OK",
          deletePhoto: "Delete picture",
          wantDeletePhoto: "Do you want to permanently delete this photo?",
          whatIsCompleteName: "What is your full name?",
          whereSendEmail: "Where do we send the emails?",
          newPassword: "New Password",
          typeHere: "Write it here",
          thanksVerifyEmail: "Thanks for checking your email!",
          ifNoVerifyEmail:
            "If you do not verify your email you will not be able to buy tickets for the events. To send a verification email, click the button below.",
          verified: "(Verified)",
          noVerified: "(Unverified)",
          logo: "Logo",
          banner: "Banner",
          description: "Description",
          tellMoreAbout: "Tell us a bit about yourself ...",
          customUrl: "Custom url",
          contactUsToGet: "Contact us to get it",
          onlyId: "Just your ID",
          emailSent: "Verification email sent",
          errorEmailSent: "Failed to send mail",
          badEmailFormat: "The format of the email entered is incorrect.",
          passwordMustHave: "Password must contain 6 or more characters.",
          pleaseFillAll: "Please enter all the data",
          ifYouWantUnlink: "If you want to unlink your account, contact us",
          linkNequi: "Link Nequi",
          beforeContinueLinkNewAccount:
            "Before continuing, you must verify your account to continue with the process. This step is necessary to trust that you are going to sell real products and avoid scams to our users. Send us an email to help@fravents.com with as much relevant information about your brand and our team will help you through the process. By continuing, you accept the terms and conditions of the platform",
          rememberNequi:
            "Remember that by continuing with the process you accept the terms and conditions of the platform by linking your Nequi account in our system. Do not forget that your data will be encrypted for your greater security.",
          linkBitcoinCash: "Link USDT wallet",
          rememberBitcoinCash:
            "Remember that by continuing with the process you accept the terms and conditions of the platform by linking your USDT wallet in our system. Do not forget that your data will be encrypted for your greater security.",
          linkMercadoPago: "Link Mercadopago",
          beforeContinueLinkMercadoPago:
            "Before continuing, you must verify your account to continue with the process. This step is necessary to trust that you are going to sell real products and avoid scams to our users. Send us an email to help@fravents.com with as much relevant information about your brand and our team will help you through the process.",
          maxFileSize: "The maximum size allowed to upload each image is 5Mb",
          hereYourName: "[Here goes your name]",
          hereYourEmail: "[Here goes your email]",
          yourInfo: "Your information",
          refers: "Referral Program",
          partnerData: "Partner Data",
          settings: "Settings",
          activatedBenefits: "Activated benefits",
          preferentialCommissionRate: "Preferential Commission Rate",
          promoterDiscountDescription: `This commission isn't including payment providers. Fravents normally charges ${platformTax[1]} of the total purchase value.`,
          linkAccounts: "Link accounts",
          personalInfo: "Personal information",
          verifyEmail: "Verify email",
          saveNewData: "Save new data",
          yourDataIsUpdated: "Your data is up to date",
          youHaveInAccount: "You have available in your account:",
          untilToday: "And to this day you have referenced",
          confirmed: "confirmed",
          howCanRefer: "How can I reference?",
          inEachEvent:
            "In each available event you will find the notice of<bold> Refer and Earn!</bold> And if you click on it, your referral link for that event will be automatically copied, you just have to share it in your networks and if someone buys through that link, your credits will be assigned to you.<break /><break /> Thank you for being part of our program 🤝. Remember that you can use this money to buy on our platform or you can write us to",
          toRequest:
            "to request the sending of your money to the available means of payment.",
          referEventsEarn: "Refer events and earn credits",
          weWantYouEarn:
            "We want you to have the opportunity to generate income and that is why with our referral program you can earn credits for each purchase completed through the event link that you refer. That easy! You just have to accept our",
          termsConditions: "Terms and Conditions",
          andAvailableFunction:
            "and the function will be enabled in the available events.",
          acceptTC: "Accept T&C and start",
          iconVerified: "✅ Verified",
          iconNoVerified: "❌ Not verified",
          weGiveYouPage:
            "We give you the possibility to have your own personalized page with your segmented content. Enter the following data to complete it and after saving the changes you can click on &quot;View my page&quot; to view the changes made.",
          viewPage: "See my page",
          savePartnerData: "Save Partner data",
          allInfoAboutLinkAccounts:
            "We offer our community the following options to link their accounts and receive money from each sale made on our platform. Remember, if you have any questions or inconveniences, write an email to help@fravents.com",
          remeberWhatFroin:
            "Link your account and <bold>take advantage of the benefits</bold> that only <bold>FROIN</bold> offers you, paying easily, safely and that rewards you.",
          remeberWhatMercadoPago:
            "Remember that Mercado Pago<bold> only accept national payment methods (Colombia)</bold> . For international payments you must make them externally and then register the purchase manually.",
          alreadyLink: "You already linked ",
          linkYour: "Link ",
          yourAccount: "your account ",
          typeHereYour: "Write here your",
          allUsersPayCryptos:
            "All users<bold> they will be able to pay with their cryptocurrency of choice</bold> and you<bold> the value will arrive in USDT</bold> using the same-day market conversion rate.",
          walletAddressLinked: "Linked wallet address",
          walletAddress: "Wallet address",
          typeHereAddress: "Write here your address",
          currency: "Currency",
          language: "Language",
          themeColor: "Theme color",
          enterCurrentPassword: "Enter your current password",
          toUpdateWeNeedPassword:
            "In order to continue updating information, we need you to enter your current password to verify that you are the owner of the account.",
          byTickets: "Tickets",
          byClients: "Clients",
          lastUpdated: "Last update was on ",
          sureEnterCode:
            "You must make sure that your referral enters your code at the time they are going to accept their <bold>Administrator</bold> role. If your client did not enter it, please write us an email to validate it.<break /><break /> Thank you for being part of our program 🤝. Remember that you can use this money to buy on our platform or you can write us to",
          thisIsYourCode: "This is your unique referral code:",
          yourReferralsReceive:
            "Your referrals receive a preferential rate of:",
        },
      },
      shop: {
        detailProduct: {
          errorGetProduct: "Error to get product: ",
          productSaved: "Saved product",
          productDeleted: "Removed from saves",
          errorUpdate: "Failed to update: ",
          completeDataTry: "Fill all inputs and try again",
          infoUpdated: "Updated information",
          noAddProduct:
            "You cannot add products from a different merchant, first complete the purchase of your selected products.",
          viewShop: "See shop",
          available: "available",
          priceSendIncluded: "Shipping price included in certain areas",
          noSendIncluded:
            "Does not include shipping, must be arranged through chat",
          startBuy: "Start purchase",
          delete: "Delete ",
          save: "Save ",
        },
        myProducts: {
          payInProcess: "Payment in process",
          sendInProcess: "In process of shipment",
          payRejected: "Payment declined",
          sent: "Sent",
          delireved: "Delivered",
          noHaveAnyProduct:
            "We do not have any products that you have purchased",
          viewSell: "See purchase",
          quantity: "Amount",
          updatedAt: "Updated on",
          errorGetSells: "Error to get your product purchases: ",
          productBought: "Purchased products",
        },
        selectQuantity: {
          soldOut: "Exhausted",
          soon: "Soon",
          available: "available",
          firstSelect: "First select a type",
          noCanPassQuantity: "You cannot exceed the amount available",
          quantityMax: "The maximum quantity per purchase is 10 items.",
          selectTypeQuantity: "Select type and quantity",
          plusTax: "+ platform service fee",
          alreadyIncludedTax: "These prices already include the service fee",
          quantity: "Amount",
          totalPrice: "Total price",
          addCart: "Add to cart",
          continuePay: "Continue to payment",
        },
        sellDetail: {
          buyer: "Buyer",
          seller: "Seller",
          thereNoMessages:
            "There are no messages to display. Remember that this space is to send notifications of the shipping process and so that any concerns can be resolved.",
          enterMinText: "Enter a text of at least 5 characters",
          maxTextLength: "The text must not exceed 250 characters",
          errorSendMessage: "Failed to send message",
          errorLoadData: "Error loading information",
          viewQrCode: "View QR code",
          visit: "Visit",
          viewConfiguration: "view conversation",
          writeTo: "Write to",
          client: "customer",
          sellerM: "seller",
          updatedAt: "Updated on",
          support: "Support",
          products: "Products",
          type: "Type",
          price: "Price",
          eachOne: "each one",
          quantity: "Amount",
          view: "See",
          detail: "detail",
        },
        shoppingCart: {
          noAddItemsYet: "You have not yet added items to your purchases",
          mustEnterCode: "You must enter a code",
          firstAddItem: "First you must add products",
          errorValidateCode: "Error to validate promotion code: ",
          beforeContinue: "Before continuing...",
          forYourBuy:
            "For your purchase you must log in to Fravents or create an account, it's easy! Remember that you must verify your email, if you have problems please write to help@fravents.com",
          mustVerifyEmail:
            "For your purchase you must verify your email for security. You can send your verification email in Your Profile.",
          continue: "Continue",
          goToProfile: "Go to my profile",
          bookingNoAvailable:
            "The reservation can no longer be made because it is not available.",
          errorData: "Wrong data",
          someProductsNoHave:
            "Some products no longer have the quantity you want available, they have been removed from your Shopping Cart",
          confirmSell: "Confirm purchase",
          wantGetItems:
            "Do you want to purchase {{totalItems}} Item{{multiple}}? You will be redirected to the payment platform and you will have a maximum of 10 minutes to make the purchase or your reservation will be deleted. If you continue you confirm that you have read and accepted the [[Marketplace Terms and Conditions]].",
          remainInfoYet: "You still need information to complete",
          shoppingCart: "Shopping cart",
          thisSectionNoGuarant:
            "This selection still does not guarantee that we reserve the products for you. You must continue to the next step to secure your products and finish the payment process.",
          product: "Product",
          quantity: "Amount",
          price: "Price",
          subTotal: "Subtotal",
          sellSummary: "Purchase Summary",
          additionalData: "ADDITIONAL DATA",
          pleaseAddData:
            "Please enter the necessary information about your shipping address, instructions or additional requests to communicate it to the seller. You will have a private chat where you can continue the conversation.",
          directionCity:
            "Address, city and additional data for the seller to manage your purchase",
          promoCode: "DISCOUNT CODE",
          enterYourCode: "Enter your code",
          discountApplied: "discount applied",
          apply: "Apply",
          taxes: "Taxes and services",
          totalCost: "TOTAL COST",
          finishPay: "FINISH AND PAY",
        },
      },
      tickets: {
        detailTickets: {
          yourTicketIsSelling: "Your ticket is now on sale.",
          errorSellTicket: "Failed to sell ticket",
          receivedTicket:
            "Ticket received. You already own it and you can use it",
          beforeContinue: "Before continuing...",
          rememberTransferTicket:
            "Remember that this process cannot be reversed, at the moment the ticket transfer is accepted, the previous user will no longer own it and will not be able to recover it.",
          transferCanceled:
            "Canceled transfer. You already have your ticket available",
          rememberCancelTransferTicket:
            "Remember that this process cannot be reversed, as soon as the ticket transfer is cancelled, the new user will no longer own it and will not be able to recover it.",
          completeDataTry: "Fill all inputs and try again",
          emailHasBadFormat: "The email entered has an incorrect format",
          confirmationTransfer: "Transfer Confirmation",
          areSureTransfer:
            "Do you agree to transfer the tickets to the user {{email}}? You can cancel the transfer before the new user accepts it.",
          rememberBeforeTransfer:
            "Remember that this process cannot be reversed, as soon as the ticket is sent to the new user and he accepts, you will no longer own it and you will not be able to recover it. If you have any problems, contact the organizer of the event.",
          sellCanceled: "Your sale has been cancelled.",
          errorToCancelSell: "Error to cancel sale",
          rememberCancelSell:
            "Remember that this process cannot be reversed, as soon as the ticket sale is canceled it will return to your property and will no longer be offered to users.",
          completeValueSell: "Full resale price value",
          badCopPrice:
            "The value entered is invalid, only numbers greater than $1000 COP are accepted.",
          badUsdPrice:
            "The value entered is invalid, only numbers greater than $0 USD are accepted.",
          resellConfirmation: "Resale confirmation",
          areSureSellTicket:
            "Do you agree to sell this ticket at {{labelPrice}}? You can cancel the offer before a user buys it.",
          enterPriceSell: "Enter the price at which you want to sell",
          mustLinkAccount: "You must link your account",
          toSellLinkAccounts:
            "To sell your tickets through Fravents you must link your Mercado Pago account to instantly receive your money from the sale.",
          link: "Link",
          everySellAccept:
            "Every time you sell your tickets through our platform, you accept our [[Terms and Conditions]] for the process of selling and delivering your money.",
          mustVerifyEmail:
            "You must verify your email for security. You can send your verification email in Your Profile.",
          goToProfile: "Go to my profile",
          sellUpdated: "Updated Purchase",
          waitRead: "Wait! ... Read this before continuing",
          rememberThisStep:
            "Remember that this step cannot be reversed, at the moment a promoter is assigned it cannot be edited later. Do you agree to assign developer {{name}} to this purchase?",
          priceInCop: "Price in pesos ($ COP)",
          priceInUsd: "Price in dollars ($ USD)",
          referedPromotor: "referred promoter",
          noPromotorAdd: "No promoters, add here",
          addPromoter: "Assign a promoter",
          sellSummary: "Purchase Summary",
          eventFinished: "The purchased event has already ended.",
          payed: "Paid",
          payPending: "The payment is still being processed.",
          payRejected: "The payment was declined",
          payRefunded: "The payment was refunded",
          sellDate: "Date of purchase",
          totalPrice: "Total price",
          canceled: "CANCELED",
          eventTerms: "Event Terms and Conditions",
          fraventsTerms:
            "Fravents is only responsible for issues related to technical support in the purchase and sale of tickets. Fravents is not responsible for the execution or anything related to this event.",
          viewMore: "See more",
          thisTicketMaxTransfer:
            "This ticket has already exceeded 10 transfers allowed.",
          cancelTransfer: "Cancel transfer to",
          noCortesiaTransfer: "Cortesia tickets cannot be transferred",
          transferTicket: "Transfer ticket",
          publishResell: "Sell and post resale",
          cancelResell: "Cancel sale",
          priceResell: "Sale price",
          redeemTicket: "Redeem ticket",
          location: "Location",
          ticketStatus: "Ticket Status",
          noUsed: "NOT USED",
          used: "REDEEMED",
          scannedDate: "Date scanned",
          whoScanned: "Who scan",
          errorGetSell: "Error to get sale: ",
          errorGetEvent: "Error getting event: ",
          rememberNoScreenshot:
            "Remember<bold> DO NOT MAKE SCREENSHOT OR PRINT</bold> because they are dynamic and must be presented from your device on the day of the event. If when your QR code is scanned it says invalid, reload this screen in case there are cache problems.",
          refundPayment: "Purchase refunded",
          date: "Date",
          idTransaction: "Transaction ID",
          youCanUseRefundId: `You can use your <bold>transaction ID</bold> to track it with Mercado Pago in its platform's customer support<break />Remember that you can check the Mercado Pago return times on this page depending on the payment method:<break /><break /><anchor target="blank" href="https://www.mercadopago.com.co/ayuda/devolver-dinero-mi-compra_1601"> https://www.mercadopago.com. co/ayuda/return-money-my-purchase_1601</anchor><break /><break />Now you must wait for your money to be reflected in your account, any problem with your refund please write to the event organizer.`,
          resellTickets: "Ticket resale",
          completeDataNewUser: "Complete the data of the new user",
          enterWantPriceResell:
            "Enter the value at which you want to sell this ticket to the community. This offer will be published in the event and we will send a notification to interested users to promote your publication.",
          remberNoResellBefore:
            "Remember that this process can only be done once, the new owner will not be able to transfer this ticket, so we avoid massive resale.",
        },
        myTickets: {
          selectPriceUnit:
            "Select the price per unit and the quantity you want to sell",
          mustLinkAccount: "You must link your account",
          toSellMustLinkAccount: `To sell your tickets through Fravents you must link your Mercado Pago account to instantly receive your money from the sale. REMEMBER: Fravents charges a commission of approx. ${platformTax[0]}.`,
          link: "Link",
          beforeContinue: "Before continuing...",
          everySellAccept:
            "Every time you sell your tickets through our platform, you accept our [[Terms and Conditions]] for the process of selling and delivering your money.",
          mustVerifyEmail:
            "You must verify your email for security. You can send your verification email in Your Profile.",
          goToProfile: "go to my profile",
          ticketsOnSell: "Your tickets are now on sale.",
          errorToSell: "Error to sell tickets",
          mustAddPrice:
            "You must add at least one price and it must be greater than $1,000 COP, and the amount to sell must be greater than zero.",
          someSellsNoShow:
            "Some sales are not shown because the price must be greater than $1,000 COP and the amount to be sold greater than zero.",
          confirmSelect: "Confirm selection",
          areSureSellTicket:
            "Do you agree to sell the following tickets? Remember that you can cancel it at any time in the detail of each ticket.",
          mustLoginPc:
            "You must enter from a computer to sell multiple tickets, otherwise you can only sell from the detail of each purchase.",
          cancelSell: "Cancel sale",
          areSureCancelSell:
            "Do you agree to cancel the process of selling your tickets? Remember that you will lose the entered data.",
          confirmSell: "Confirm and sell",
          sellYourTickets: "Sell your tickets",
          setPrice: "Set the price and receive the money when it is sold",
          noForMobile: "Not available for mobile devices",
          refAndEarn: "Refer and Earn!",
          shareLink: "Share the link of each event and earn for each purchase",
          noHaveAnyEvent: "We don't have any events that you have purchased",
          pending: "Pending",
          refunded: "Refunded",
          confirm: "Confirmed",
          viewDetails: "See detail",
          weNoHaveEvent: "We don't have any events that you have purchased",
          quantity: "Amount",
          all: "All",
          next: "Upcoming",
          event: "Event",
          buyDate: "Date of purchase",
          state: "State",
          unitPrice: "Unit price",
          quantitySell: "Amount to sell",
          qrCode: "QR code",
          myTickets: "My Tickets",
          errorToGetEvents: "Error getting events: ",
          errorGetTickets: "Error getting tickets: ",
          tickets: "Tickets",
          products: "Products",
          favorites: "Favorites",
          support: "Support",
        },
      },
    },
    validations: {
      invalidEmail: "The email address is incorrectly formatted.",
      userNotFound: "There is no user with the email entered.",
      wrongPassword:
        "The password is invalid or the user does not have a password.",
      weakPassword: "Password must be 6 characters or more.",
      emailAlreadyUse:
        "The email address is already in use by another account.",
      userMismatch: "The data entered does not correspond to the user.",
      imageTooBigger: "Image is larger than {{size}}Mb",
      free: "Free",
      errorTestSite: "This site is test",
      linkedAccountSuccess: "Account linked successfully",
      errorLinkNequi: "Error linking Nequi",
      errorLinkBitcoin: "Error linking USDT wallet wallet",
      payed: "Paid",
      pending: "Pending",
      sent: "Sent",
      delivered: "Delivered",
      rejected: "Rejected",
    },
    services: {
      auth: {
        hiWelcome: "Hello welcome @!",
        weKnowYouFirst:
          "We know it's your first time on the platform. Before continuing, and that you can use all our features, you must accept the Terms of Use and the Privacy Policy of our site that you can see in our document. Select one of the following options:",
        goToDocument: "Go to document",
        yesConfirm: "Yes I accept",
        thanksWelcome:
          "Thank you, Welcome to FRAVENTS! You can now use our site.",
        errorGetInfo: "Error getting information",
        errorSignOut: "Failed to log out",
      },
      events: {
        errorGetEvents: "Error getting the events",
        errorAddEvent: "Error adding event",
        withoutQuestions: "No questions",
        withoutEventTypes: "No event types",
      },
      firebase: {
        infoDidntBeFound: "Information not found",
        errorListenerChat: "Chat listener error: ",
      },
    },
    system: {
      beforeTest:
        "Authentication Before continuing, please enter the code assigned to you",
      titleOldVersion: "Outdated version",
      errorTestCode: "Wrong code",
      bodyOldVersion:
        "You need to refresh the page to get the latest updates and have the best Fravents experience. [Current Version: {{version}} - Update: {{liveVersion}}]",
      reload: "Reload",
      errorGetSettings: "Error getting settings: ",
    },
  },
};

export default texts;
