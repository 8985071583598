import { makeStyles } from "@material-ui/core/styles";
import { colors } from "../../styleguide";

const useStyles = makeStyles({
  "@keyframes slideInFromLeft": {
    "0%": {
      right: -2000,
    },
    "100%": {
      right: 12,
    },
  },
  contAlerts: {
    position: "fixed",
    bottom: 30,
    right: 12,
    zIndex: 1301, // To overlay modals of material ui
    "@media (max-width: 780px)": {
      right: 0,
      bottom: 6,
    },
  },
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: colors.blue,
    padding: 15,
    position: "relative",
    right: 24,
    animation: "$slideInFromLeft 0.2s ease-out",
    transition: "all 0.2s",
    marginBottom: 12,
    boxShadow: colors.shadow,
    "@media (max-width: 780px)": {
      right: 0,
    },
  },
  iconAlert: {
    color: colors.white,
    fontSize: 22,
    marginRight: 12,
  },
  text: {
    margin: 0,
    color: colors.white,
    fontWeight: "500",
    fontSize: 18,
    flex: 1,
    marginRight: 12,
  },
  btClose: {
    cursor: "pointer",
    color: colors.white,
    fontSize: 20,
  },
  success: {
    backgroundColor: colors.primary,
  },
  warning: {
    backgroundColor: colors.orange,
  },
  error: {
    backgroundColor: colors.red,
  },
});

export default useStyles;
