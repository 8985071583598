import { makeStyles } from "@material-ui/core/styles";
import { colors } from "../../styleguide";
import Commons from "../../utils/styles";

const useStyles = makeStyles({
  containerAddTickets: {
    backgroundColor: colors.grayLight,
    padding: "30px 30px 24px 30px",
    overflowX: "auto",
    marginTop: 89,
    height: "calc(100% - 150px)",
    transition: "all 0.3s",
    animation: "$zoom 0.5s",
  },
  rowCard: {
    ...Commons.flexRow,
    backgroundColor: colors.white,
    borderRadius: 18,
    padding: 18,
    margin: "2px 0 ",
    minWidth: 900,
  },
  cloumnCard: {
    backgroundColor: colors.white,
    borderRadius: 18,
    padding: 18,
    margin: "2px 0 ",
    minWidth: 900,
  },
  columnInfoCard: {
    flex: 1,
  },
  titleInfoCard: {
    margin: 0,
    color: colors.black,
    fontSize: 24,
  },
  descriptionIfoCard: {
    margin: 0,
    fontSize: 18,
    marginTop: 6,
  },
  uploadButton: {
    ...Commons.defaultButton,
    ...Commons.flexRow,
    backgroundColor: colors.themeColor,
    color: colors.white,
    marginLeft: 12,
    borderRadius: 6,
    boxShadow: colors.shadow,
    padding: "6px 18px",
    fontWeight: "bold",
    "& svg": {
      marginRight: 6,
    },
  },
  sublabelButton: {
    position: "absolute",
    bottom: -20,
    color: colors.themeColor,
    fontSize: 12,
    fontWeight: "500",
    textAlign: "center",
    width: "100%",
    left: 0,
    ...Commons.ellipsis,
  },
  tableTickets: {
    width: "100%",
    margin: "30px 0",
  },
  headTableTickets: {
    "& td": {
      borderBottom: `1px solid ${colors.black}`,
      fontWeight: "600",
      fontSize: 18,
      padding: "12px 0",
    },
  },
  bodyTableTickets: {
    "& td": {
      borderBottom: `1px solid ${colors.grayBlack}`,
      fontSize: 15,
      padding: "12px 0",
    },
  },
  deleteBtn: {
    ...Commons.defaultButton,
    color: colors.red,
    fontSize: 15,
    padding: "6px 12px",
  },
  rowBottomTable: {
    ...Commons.flexRow,
    justifyContent: "flex-end",
    padding: "12px 0",
  },
  cancelBtn: {
    ...Commons.defaultButton,
    color: colors.red,
    fontSize: 15,
    marginRight: 24,
    padding: "6px 12px",
  },
  confirmBtn: {
    ...Commons.defaultButton,
    color: colors.white,
    fontSize: 15,
    marginRight: 12,
    padding: "6px 12px",
    backgroundColor: colors.primary,
    borderRadius: 6,
  },
  emptyMessage: {
    textAlign: "center",
    magin: 0,
    padding: "24px 0",
    fontSize: 18,
  },
  inputExcel: {
    position: "absolute",
    zIndex: -1,
    opacity: 0,
  },
  "@keyframes zoom": {
    "0%": {
      transform: "scale(0)",
    },
    "75%": {
      transform: "scale(1.1)",
    },
    "100%": {
      transform: "scale(1)",
    },
  },
});

export default useStyles;
