import React from "react";
import { useTranslation } from "react-i18next";
import { Bookmark } from "@material-ui/icons";
import moment from "moment";
import _ from "lodash";

import { renderEllipsis, isBookmarked } from "../../utils/validations";
import { renderPricePoster } from "../../utils/validations";
import { Store } from "../../hooks/main_store";
import ItemTypeEvent from "../TypesEvent/item";
import AuthService from "../../services/auth";
import Model from "../../hooks/Model";
import pageStyles from "./styles";

const ItemEvent = ({
  item = {},
  onClick = () => {},
  callback = () => {},
  customClasses = "",
}) => {
  const { t } = useTranslation();
  const styles = pageStyles();
  const { state } = React.useContext(Store);

  const onBook = async (data) => {
    try {
      let currentData = state.bookmarks || [];
      let increase = 0;
      const indexSearch = _.findIndex(state.bookmarks, { uid: data });

      Model.setData("loading", true);

      if (!state.uid) {
        return Model.setData("openAuth", true);
      }
      if (indexSearch > -1) {
        currentData.splice(indexSearch, 1);
        increase = -1;
      } else {
        currentData.push({
          uid: data,
          created: moment().format("x"),
        });
        increase = 1;
      }

      await AuthService.updateBookmarks(state.uid, data, increase);

      if (callback) {
        await callback();
      }

      Model.setData("bookmarks", currentData);
      Model.updateAlerts({
        message:
          increase > 0
            ? t("components.itemEvent.alreadyFollow")
            : t("components.itemEvent.noFollow"),
        variant: "success",
      });
    } catch (e) {
      console.log(t("components.itemEvent.errorRefresh"), e);
    } finally {
      Model.setData("loading", false);
    }
  };

  React.useEffect(() => {
    //eslint-disable-next-line
  }, []);
  return (
    <div className={[styles.itemListEvent, customClasses].join(" ")}>
      <div
        className={styles.imgLEvent}
        style={{ backgroundImage: `url(${item.imageBanner})` }}
        onClick={() => onClick(item)}
      >
        <div className={styles.titleEvent}>{item.name}</div>
        <div className={styles.badgePrice}>
          {renderPricePoster(item.prices, item.acceptUsd ? "USD" : "COP")}
        </div>
      </div>
      <div className={styles.rowInfo}>
        <div className={styles.infoEvent} onClick={() => onClick(item)}>
          <p className={styles.dateEvent}>
            {renderEllipsis(
              `${moment(item.date, "x").format("DD MMM, YYYY - ha")} | ${
                item.location
              }`,
              50
            )}
          </p>
          <p className={styles.description}>
            {renderEllipsis(item.description, 85)}
          </p>
        </div>
        <div className={styles.btBook} onClick={() => onBook(item.uid)}>
          <Bookmark
            className={[
              styles.iconBook,
              isBookmarked(item.uid, state.bookmarks) ? styles.yellow : "",
            ].join(" ")}
          />
        </div>
      </div>
      <ItemTypeEvent
        defaultValue={item.type}
        className={styles.typeTag}
        isTag
      />
    </div>
  );
};

export default ItemEvent;
