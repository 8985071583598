import { makeStyles } from "@material-ui/styles";
import Commons from "../../../utils/styles";
import { colors } from "../../../styleguide";

const styles = makeStyles({
  container: {
    ...Commons.flexRow,
    maxWidth: 1500,
    margin: "30px auto",
    width: "90%",
    padding: 30,
    height: "75vh",
    overflow: "hidden",
    "&.isMobile": {
      padding: 12,
      flexDirection: "column",
      height: "auto",
    },
    [Commons.smallQuery]: {
      padding: 12,
      flexDirection: "column",
      height: "auto",
    },
  },
  imagesScroll: {
    width: 100,
    overflow: "auto",
    maxHeight: "100%",
    textAlign: "center",
    "&.isMobile": {
      width: "100%",
      height: 72,
      whiteSpace: "nowrap",
      padding: "6px 0",
    },
    [Commons.smallQuery]: {
      width: "100%",
      height: 72,
      whiteSpace: "nowrap",
      padding: "6px 0",
    },
  },
  separatorLeft: {
    height: 300,
    width: 1,
    backgroundColor: colors.grayBlack,
    marginLeft: 60,
    "&.isMobile": {
      height: 1,
      width: "80%",
      marginLeft: 0,
      marginTop: 30,
    },
    [Commons.smallQuery]: {
      height: 1,
      width: "80%",
      marginLeft: 0,
      marginTop: 30,
    },
  },
  separatorRight: {
    height: 300,
    width: 1,
    backgroundColor: colors.grayBlack,
    marginRight: 60,
    "&.isMobile": {
      height: 1,
      width: "80%",
      margin: "30px 0",
      marginRight: 0,
    },
    [Commons.smallQuery]: {
      height: 1,
      width: "80%",
      margin: "30px 0",
      marginRight: 0,
    },
  },
  containerPreviewImg: {
    width: "100%",
    height: 120,
    overflow: "hidden",
    marginBottom: 30,
    boxShadow: colors.shadow,
    cursor: "pointer",
    borderRadius: 6,
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      transition: "all 2s",
    },
    "&:hover img": {
      transform: "scale(2)",
    },
    "&.isMobile": {
      display: "inline-block",
      height: "100%",
      marginBottom: 0,
      marginRight: 18,
      width: 60,
    },
    [Commons.smallQuery]: {
      display: "inline-block",
      height: "100%",
      marginBottom: 0,
      marginRight: 18,
      width: 60,
    },
  },
  selectedImage: {
    ...Commons.coverBackground,
    backgroundSize: "contain",
    height: "100%",
    flex: 1,
    margin: "0 90px",
    borderRadius: 6,
    "&.isMobile": {
      margin: "30px 0 0 0",
      height: 300,
      width: "100%",
      flex: "unset",
    },
    [Commons.smallQuery]: {
      margin: "30px 0 0 0",
      height: 300,
      width: "100%",
      flex: "unset",
    },
  },
  infoContainer: {
    ...Commons.flexColumn,
    alignItems: "flex-start",
    height: "100%",
    flex: 1,
    "&.isMobile": {
      height: "auto",
      flex: "unset",
      display: "block",
      width: "100%",
    },
    [Commons.smallQuery]: {
      height: "auto",
      flex: "unset",
      display: "block",
      width: "100%",
    },
  },
  partnerRow: {
    ...Commons.flexRow,
    margin: "0 0 12px 0",
    width: "100%",
    cursor: "pointer",
    "&.isMobile": {
      margin: "24px 0 30px 0",
    },
    [Commons.smallQuery]: {
      margin: "24px 0 30px 0",
    },
  },
  imgPartner: {
    width: 30,
    height: 30,
    borderRadius: "50%",
    border: `2px solid ${colors.themeColor}`,
    marginRight: 12,
    objectFit: "cover",
  },
  nameProvider: {
    margin: 0,
    fontWeight: "500",
    fontSize: 16,
    textTransform: "uppercase",
    letterSpacing: "3px",
    flex: 1,
  },
  visitButton: {
    marginLeft: 12,
    backgroundColor: colors.themeColor,
    color: colors.white,
    textAlign: "center",
    padding: "6px 18px",
    borderRadius: 30,
    fontSize: 15,
  },
  nameProduct: {
    margin: "0 0 18px 0",
    fontWeight: "600",
    textTransform: "uppercase",
    fontSize: 36,
    letterSpacing: "2px",
    "&.isMobile": {
      fontSize: 24,
    },
    [Commons.smallQuery]: {
      fontSize: 24,
    },
  },
  priceRow: {
    ...Commons.flexRow,
    width: "100%",
    justifyContent: "flex-start",
  },
  priceProduct: {
    fontWeight: "600",
    fontSize: 24,
  },
  stockProduct: {
    fontWeight: "600",
    fontSize: 18,
    marginLeft: 18,
    paddingLeft: 18,
    borderLeft: `2px solid ${colors.black}`,
    color: colors.themeColor,
  },
  topBlurScroll: {
    height: 24,
    backgroundColor: colors.white,
    width: "100%",
    opacity: 0.75,
    position: "relative",
    top: 24,
    zIndex: 1,
    "&.isMobile": {
      display: "none",
    },
    [Commons.smallQuery]: {
      display: "none",
    },
  },
  bottomBlurScroll: {
    height: 24,
    backgroundColor: colors.white,
    width: "100%",
    opacity: 0.75,
    position: "relative",
    bottom: 24,
    zIndex: 1,
    "&.isMobile": {
      display: "none",
    },
    [Commons.smallQuery]: {
      display: "none",
    },
  },
  descriptionProduct: {
    color: colors.black,
    fontSize: 17,
    margin: 0,
    opacity: 0.75,
    lineHeight: "24px",
    flex: 1,
    overflow: "auto",
    zIndex: 0,
    position: "relative",
  },
  shipmentRow: {
    ...Commons.flexRow,
    width: "100%",
    justifyContent: "flex-start",
    color: colors.gray50,
    gap: 12,
    fontWeight: "600",
    margin: "12px 0",
  },
  addProductButton: {
    ...Commons.defaultButton,
    backgroundColor: colors.themeColor,
    color: colors.white,
    textAlign: "center",
    padding: "12px 0",
    fontSize: 17,
    borderRadius: 12,
    boxShadow: colors.shadow,
    margin: "30px 0 12px 0",
    width: "100%",
    display: "block",
    maxWidth: 420,
    "&.isMobile": {
      maxWidth: "unset",
    },
    [Commons.smallQuery]: {
      maxWidth: "unset",
    },
  },
  saveWishlistButton: {
    ...Commons.defaultButton,
    backgroundColor: colors.white,
    color: colors.themeColor,
    border: `1px solid ${colors.themeColor}`,
    textAlign: "center",
    padding: "12px 0",
    fontSize: 17,
    borderRadius: 12,
    margin: "12px 0",
    width: "100%",
    display: "block",
    maxWidth: 420,
    "&.isMobile": {
      maxWidth: "unset",
    },
    [Commons.smallQuery]: {
      maxWidth: "unset",
    },
  },
});

export default styles;
