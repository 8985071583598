import React from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import ItemEvent from "../../components/ItemEvent";
import pageStyles from "./styles/dashboardStyles";
import { Store } from "../../hooks/main_store";
import Model from "../../hooks/Model";

const Bookmarks = ({ history, getEvents }) => {
  const { t } = useTranslation();
  const styles = pageStyles();
  const { state } = React.useContext(Store);

  const returnEvents = () => {
    return state.events
      .filter((event) => _.findIndex(state.bookmarks, { uid: event.uid }) > -1)
      .filter((item) =>
        state?.isOnPluginPartner || state?.isWhiteLabel
          ? state?.eventsPartnerPlugin?.indexOf?.(item?.uid) > -1 || false
          : true
      );
  };

  const onSelectEvent = (item) => {
    Model.setData("eventSelected", item);
    history.push("/events");
  };

  const renderEvents = () => {
    if (returnEvents().length < 1) {
      return (
        <div className={styles.lblEmpty}>
          {t("pages.dashboard.bookmarks.noHaveEvents")}
        </div>
      );
    }
    return returnEvents().map((item, index) => (
      <ItemEvent
        key={"item-list-event-" + index}
        item={item}
        onClick={onSelectEvent}
        callback={getEvents}
      />
    ));
  };

  return (
    <div className={styles.paddingList}>
      <p className={styles.title}>{t("pages.dashboard.bookmarks.favorites")}</p>
      <div className={styles.listOfBookmarks}>{renderEvents()}</div>
      <div className={styles.blank} />
    </div>
  );
};

export default Bookmarks;
