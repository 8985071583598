import { makeStyles } from "@material-ui/core/styles";
import { colors } from "../../styleguide";
import Commons from "../../utils/styles";

const useStyles = makeStyles({
  itemListEvent: {
    display: "inline-block",
    width: 400,
    margin: "0px 18px 18px 0px",
    boxShadow: colors.shadow,
    borderRadius: 18,
    padding: 12,
    backgroundColor: colors.black,
    position: "relative",
    [Commons.smallQuery]: {
      width: "75vw",
    },
  },
  typeTag: {
    position: "absolute",
    top: 16,
    left: 12,
    "& span": {
      display: "none",
      color: colors.white,
    },
    "&:hover span": {
      display: "block",
    },
  },
  imgLEvent: {
    ...Commons.coverBackground,
    minWidth: "100%",
    width: "100%",
    height: 240,
    borderRadius: 12,
    backgroundColor: colors.black,
    cursor: "pointer",
    position: "relative",
  },
  rowInfo: {
    ...Commons.flexRow,
    width: "100%",
  },
  titleEvent: {
    ...Commons.ellipsis,
    fontWeight: "700",
    fontSize: 20,
    margin: 0,
    position: "absolute",
    bottom: 0,
    left: 0,
    backgroundColor: "rgba(47, 47, 47, 0.7)",
    width: "calc(100% - 24px)",
    color: colors.white,
    padding: "6px 12px",
  },
  badgePrice: {
    backgroundColor: colors.themeColor,
    color: colors.black,
    padding: "6px 12px",
    fontSize: 12,
    fontWeight: "600",
    borderRadius: 12,
    position: "absolute",
    top: 6,
    right: 6,
  },
  infoEvent: {
    flex: 1,
    margin: "0 12px",
    whiteSpace: "normal",
  },
  dateEvent: {
    color: colors.themeColor,
    fontSize: 15,
    margin: "9px 0",
    overflow: "hidden",
    height: 20,
  },
  description: {
    fontSize: 15,
    color: colors.white,
    margin: 0,
    height: 40,
    overflow: "hidden",
  },
  btBook: {
    ...Commons.flexRow,
    backgroundColor: colors.white,
    minWidth: 30,
    width: 30,
    height: 30,
    borderRadius: "50%",
    justifyContent: "center",
    cursor: "pointer",
  },
  iconBook: {
    fontSize: 12,
    color: colors.black,
  },
  yellow: {
    color: colors.yellow,
  },
});

export default useStyles;
