import { Document, Page, pdfjs } from "react-pdf";
import React from "react";

import PrivacyPolicyFile from "../../assets/documents/Politica_de_privacidad.pdf";
import TermsConditionsFile from "../../assets/documents/Terminos_y_condiciones.pdf";
import OrganizersNoticeFile from "../../assets/documents/Aviso_organizadores.pdf";
import BuyersNoticeFile from "../../assets/documents/Aviso_compradores.pdf";
import TcResaleFile from "../../assets/documents/TYC_Reventa.pdf";
import TcMarketplaceFile from "../../assets/documents/TYC_Marketplace.pdf";
import DataAuthorizationFile from "../../assets/documents/Autorizacion_Data.pdf";
import TcPromoterPartners from "../../assets/documents/TYC_Socios_Promotores.pdf";
import pageStyles from "./styles/renderLegalDocStyles";
import Model from "../../hooks/Model";
import Layout from "../layout";

const documents = {
  privacy: PrivacyPolicyFile,
  terms: TermsConditionsFile,
  organizer: OrganizersNoticeFile,
  buyers: BuyersNoticeFile,
  resale: TcResaleFile,
  marketplace: TcMarketplaceFile,
  data: DataAuthorizationFile,
  promoter: TcPromoterPartners,
};

const RenderLegalDoc = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const nameDocument = urlParams.get("document") || "terms";
  // const { t } = useTranslation();
  const styles = pageStyles();
  const [numPages, setNumPages] = React.useState(0);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    Model.setData("loading", false);
  };

  React.useEffect(() => {
    Model.setData("superLoading", false);
    Model.setData("loading", true);
    Model.setData("opacity", 0.7);

    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);

  return (
    <Layout blackHeader>
      <Document
        file={documents[nameDocument] || documents.terms}
        onLoadSuccess={onDocumentLoadSuccess}
        className={styles.container}
      >
        {new Array(numPages).fill("page_").map((value, index) => (
          <Page pageNumber={index + 1} key={value + index} />
        ))}
        <div
          className={styles.cover}
          style={{ maxHeight: numPages * 1035, height: `${numPages * 129}vw` }}
        />
      </Document>
    </Layout>
  );
};

export default RenderLegalDoc;
