import { makeStyles } from "@material-ui/styles";
// import Commons from "../../../utils/styles";
import { colors } from "../../../styleguide";

const styles = makeStyles({
  container: {
    position: "relative",
    backgroundColor: colors.black,
    width: "100%",
    overflow: "hidden",
    "& canvas": {
      width: "100vw !important",
      height: "129vw !important",
      maxWidth: 800,
      maxHeight: 1035,
      margin: "0 auto",
    },
    "& .react-pdf__Page__annotations.annotationLayer": {
      display: "none",
    },
  },
  cover: {
    position: "absolute",
    width: "100%",
    backgroundColor: colors.white,
    top: 0,
    left: 0,
    opacity: 0,
  },
});

export default styles;
