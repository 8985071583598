import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";

const DialogFormComponent = ({
  show,
  title,
  text,
  subtext = "",
  leftBtText,
  rightBtText,
  funcLeft,
  funcRight,
  inputs,
  vc,
  changeVc,
  subtextLink,
  textLink,
}) => {
  const onChangeText = (event) => {
    if (event.persist) {
      event.persist();
    }
    if (event.target) {
      changeVc({ [event.target.name]: event.target.value });
    }
  };

  const renderLinkDescription = () => {
    const startIndex = text.indexOf("[[");
    const endIndex = text.indexOf("]]");
    const linkText = text.slice(startIndex + 2, endIndex);
    const leftText = text.slice(0, startIndex);
    const rightText = text.slice(endIndex + 2);

    if (startIndex > -1) {
      return (
        <span>
          {leftText}
          <a href={textLink} target="blank">
            {linkText}
          </a>
          {rightText}
        </span>
      );
    }

    return text;
  };

  const renderLink = () => {
    const startIndex = subtext.indexOf("[[");
    const endIndex = subtext.indexOf("]]");
    const linkText = subtext.slice(startIndex + 2, endIndex);
    const leftText = subtext.slice(0, startIndex);
    const rightText = subtext.slice(endIndex + 2);

    if (startIndex > -1) {
      return (
        <span>
          {leftText}
          <a href={subtextLink} target="blank">
            {linkText}
          </a>
          {rightText}
        </span>
      );
    }

    return subtext;
  };

  return (
    <Dialog open={show} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{renderLinkDescription()}</DialogContentText>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          {inputs.map(
            (item, index) =>
              item && (
                <TextField
                  key={"input-modal-" + index}
                  margin="dense"
                  label={item.label}
                  value={vc[item.value]}
                  style={{
                    width: `calc(${inputs.length > 1 ? "50%" : "100%"} - 12px)`,
                    margin: 6,
                  }}
                  name={item.value}
                  onChange={onChangeText}
                  type={item.type || "text"}
                  autoComplete="off"
                  placeholder={item.placeholder || ""}
                  disabled={item.disabled}
                  autoFocus={index === 0}
                />
              )
          )}
        </div>
        <DialogContentText style={{ fontSize: 12, marginTop: 12 }}>
          {renderLink()}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={funcLeft} color="primary">
          {leftBtText}
        </Button>
        <Button onClick={funcRight} color="primary">
          {rightBtText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DialogFormComponent.defaultProps = {
  show: false,
  title: "",
  text: "",
  leftBtText: "",
  rightBtText: "",
  funcLeft: () => {},
  funcRight: () => {},
  inputs: [],
  vc: {},
  changeVc: () => {},
};

DialogFormComponent.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  text: PropTypes.string,
  leftBtText: PropTypes.string,
  rightBtText: PropTypes.string,
  funcLeft: PropTypes.func,
  funcRight: PropTypes.func,
  inputs: PropTypes.array,
  vc: PropTypes.object,
  changeVc: PropTypes.func,
};

export default DialogFormComponent;
