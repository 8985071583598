import React from "react";
import { useTranslation } from "react-i18next";

import VideoBanner from "../../components/VideoBanner";
import PartnerService from "../../services/partner";
import pageStyles from "./styles/infoPluginStyles";
import { Store } from "../../hooks/main_store";
import Model from "../../hooks/Model";
import Layout from "../layout";

const InfoPlugin = () => {
  const { t } = useTranslation();
  const styles = pageStyles();
  const { state } = React.useContext(Store);

  const [apiToken, setApiToken] = React.useState("");

  const getPluginToken = async () => {
    try {
      Model.setData("dialog", { open: false });
      Model.setData("loading", true);

      const response = await PartnerService.getPluginToken();

      setApiToken(response);
    } catch (e) {
      Model.updateAlerts({
        message: `Error: ${
          e?.message || String(e) || t("commons.unknowError")
        }`,
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  const availablePlugin = async () => {
    try {
      Model.setData("dialog", { open: false });
      Model.setData("loading", true);

      const response = await PartnerService.availablePluginToken();

      setApiToken(response);
      Model.updateAlerts({
        message: t("pages.partner.infoPlugin.pluginAvailable"),
        variant: "success",
      });
    } catch (e) {
      Model.updateAlerts({
        message: `Error: ${
          e?.message || String(e) || t("commons.unknowError")
        }`,
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  const disablePlugin = async () => {
    try {
      Model.setData("dialog", { open: false });
      Model.setData("loading", true);

      await PartnerService.disablePluginToken();

      setApiToken("");
      Model.updateAlerts({
        message: t("pages.partner.infoPlugin.pluginUnavailable"),
        variant: "success",
      });
    } catch (e) {
      Model.updateAlerts({
        message: `Error: ${
          e?.message || String(e) || t("commons.unknowError")
        }`,
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  const copyCode = () => {
    navigator.clipboard.writeText(
      `<iframe src="http${process.env.NODE_ENV === "production" ? "s" : ""}://${
        window?.location?.host
      }/pluginPartner?ref=${apiToken}" style="height:100%;width:100%;border: none;border-radius: 12px;" title="Fravents Plugin"></iframe>`
    );
    Model.updateAlerts({
      message: t("pages.partner.infoPlugin.linkCopied"),
      variant: "success",
    });
  };

  const onAvailable = () => {
    Model.setData("dialog", {
      open: true,
      title: t("pages.partner.infoPlugin.onPlugin"),
      text: t("pages.partner.infoPlugin.areYourSureGoodUse"),
      txtLeft: t("commons.no"),
      fnLeft: () => Model.setData("dialog", { open: false }),
      txtRight: t("commons.yes"),
      fnRight: availablePlugin,
    });
  };

  const onDisable = () => {
    Model.setData("dialog", {
      open: true,
      title: t("pages.partner.infoPlugin.offPlugin"),
      text: t("pages.partner.infoPlugin.areYourSureBlock"),
      txtLeft: t("commons.no"),
      fnLeft: () => Model.setData("dialog", { open: false }),
      txtRight: t("commons.yes"),
      fnRight: disablePlugin,
    });
  };

  React.useEffect(() => {
    if (state.uid) {
      getPluginToken();
    }
    return () => {
      Model.setData("opacity", 0.7);
    };
    //eslint-disable-next-line
  }, [state.uid]);

  return (
    <Layout blackHeader>
      <VideoBanner
        title={t("pages.partner.infoPlugin.titleNewPlugin")}
        description={t("pages.partner.infoPlugin.bodyNewPlugin")}
        image={require("../../assets/images/dummy/banner3.png")}
        video={require("../../assets/videos/pluginTutorial.mp4")}
      />
      <div className={styles.container}>
        <p className={styles.title}>
          {t("pages.partner.infoPlugin.instructions")}:
        </p>
        <p className={styles.description}>
          {t("pages.partner.infoPlugin.youWillFindOptions")}
        </p>
        <div className={styles.codeContainer}>
          <p className={styles.code}>
            {`<iframe src="http${
              process.env.NODE_ENV === "production" ? "s" : ""
            }://${
              window?.location?.host
            }/pluginPartner?ref=${apiToken}" style="height:100%;width:100%;border: none;border-radius: 12px;" title="Fravents Plugin"></iframe>`}
          </p>

          <div
            className={[
              styles.floatContainer,
              apiToken ? styles.bgOpacity : "",
            ].join(" ")}
          >
            <button
              className={styles.floatButton}
              onClick={apiToken ? copyCode : onAvailable}
            >
              {apiToken
                ? t("pages.partner.infoPlugin.copyCode")
                : t("pages.partner.infoPlugin.onPlugin")}
            </button>
          </div>
        </div>
        {apiToken ? (
          <button className={styles.disableButton} onClick={onDisable}>
            {t("pages.partner.infoPlugin.offPlugin")}
          </button>
        ) : null}
        <p className={styles.description}>
          {t("pages.partner.infoPlugin.rememberEmail")}{" "}
          <a className={styles.link} href="mailto:help@fravents.com">
            help@fravents.com
          </a>
        </p>
      </div>
    </Layout>
  );
};

export default InfoPlugin;
