import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { currentLang } from "../utils/validations";
import EsTexts from "./es";
import EnTexts from "./en";

i18n.use(initReactI18next).init({
  fallbackLng: "es",
  lng: currentLang(),
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: EnTexts,
    es: EsTexts,
  },
});

export default i18n;
