import React from "react";
import {
  People,
  ConfirmationNumber,
  Edit,
  MoreVert,
  AddCircle,
  PlayCircleFilled,
  VisibilityOff,
  Folder,
  Visibility,
  CropFree,
  Extension,
  Settings,
} from "@material-ui/icons";
import { Menu, MenuItem, ListItemIcon, ListItemText } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { useTranslation } from "react-i18next";
import moment from "moment";

import TemplateEventSelectorModal from "../../components/TemplateEventSelectorModal";
import LayoutListEvents from "../../components/LayoutListEvents";
import { returnCurrentLink } from "../../utils/validations";
import ActionsBanner from "../../components/ActionsBanner";
import EventsService from "../../services/events";
import { Store } from "../../hooks/main_store";
import Model from "../../hooks/Model";
import Layout from "../layout";

import pageStyles from "./styles/adminStyles";

const Admin = ({ history }) => {
  const { t } = useTranslation();
  const banners = [
    {
      title: t("pages.admin.dashboard.newPlugin"),
      description: t("pages.admin.dashboard.integrateItInWebsite"),
      color: "purple",
      onPress: () => history.push("/infoPlugin"),
      Icon: (props = {}) => <Extension {...props} />,
    },
    {
      title: t("pages.admin.dashboard.setUpAccount"),
      description: t("pages.admin.dashboard.linkAccounts"),
      color: "orange",
      onPress: () => history.push("/profile?tab=2"),
      Icon: (props = {}) => <Settings {...props} />,
    },
  ];
  const styles = pageStyles();
  const { state } = React.useContext(Store);
  const [showTemplateModal, setShowTemplateModal] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedEvent, setSelectedEvent] = React.useState(null);
  const [vc, setVc] = React.useState({
    tabSelected: 0,
    currentPage: 1,
    totalPages: 1,
  });

  const changeVc = (data) => setVc((ov) => ({ ...ov, ...data }));

  const handlePageChange = (e, value) => {
    changeVc({ currentPage: value });
  };

  const onSelectEvent = (eventSelected, route) => {
    Model.setData("eventSelected", eventSelected);
    Model.setData("eventsCache", {
      ...state.eventsCache,
      [eventSelected?.uid]: eventSelected,
    });

    if (route === "/events") {
      window.open(
        returnCurrentLink(`/events?event=${eventSelected.uid}`),
        "blank"
      );
    } else {
      history.push(route, { event: eventSelected });
    }
  };

  const actions = [
    {
      label: t("pages.admin.dashboard.team"),
      icon: <People />,
      callBack: (item) => onSelectEvent(item, "/team"),
    },
    {
      label: t("pages.admin.dashboard.sales"),
      icon: <ConfirmationNumber />,
      callBack: (item) => onSelectEvent(item, "/sales"),
    },
    {
      label: t("pages.admin.dashboard.validate"),
      icon: <CropFree />,
      callBack: (item) => onSelectEvent(item, "/validate-ticket"),
    },
    {
      label: t("pages.admin.dashboard.details"),
      icon: <Visibility />,
      callBack: (item) => onSelectEvent(item, "/events"),
    },
    {
      label: t("pages.admin.dashboard.edit"),
      icon: <Edit />,
      callBack: (item) => {
        Model.setData("eventSelected", item);
        history.push("eventForm", { draftEvent: item });
      },
    },
  ];

  const returnTickets = (tickets = []) => {
    let count = 0;
    if (tickets.forEach) {
      tickets.forEach((item) => (count += item?.stock || 0));
    }
    return count;
  };

  const handleMoreVert = (event, info) => {
    setAnchorEl(event.currentTarget);
    setSelectedEvent(info);
  };

  const returnEventsData = () => {
    const dateToday = moment().subtract(1, "day").format("x");
    const filteredEvents = state.eventsPartner
      .filter((item) =>
        vc.tabSelected === 0
          ? item?.status === "published" && dateToday < item?.date
          : vc.tabSelected === 1
          ? item?.status === "draft" && dateToday < item?.date
          : dateToday > item?.date
      )
      .sort((a, b) =>
        vc.tabSelected === 2 ? b.date - a.date : a.date - b.date
      );

    return filteredEvents;
  };

  const returnActions = (isCancelled) => {
    const filteredActions = actions.filter((item) => {
      if (vc.tabSelected === 0) {
        if (item?.label === t("pages.admin.dashboard.edit") && isCancelled) {
          return false;
        }
        return true;
      } else if (vc.tabSelected === 1) {
        return true;
      }

      return item?.label !== t("pages.admin.dashboard.edit");
    });

    return filteredActions;
  };

  const renderEvents = () => {
    const filteredEvents = returnEventsData();
    if (filteredEvents.length < 1) {
      return (
        <tr>
          <td colSpan={2}>
            <div className={styles.lblEmpty}>
              {t("pages.admin.dashboard.noHaveEvents")}{" "}
              <span
                className={styles.linkInLbl}
                onClick={() => {
                  setShowTemplateModal(true);
                }}
              >
                {t("pages.admin.dashboard.createNewOne")}
              </span>
            </div>
          </td>
        </tr>
      );
    }
    return filteredEvents
      .slice((vc.currentPage - 1) * 10, vc.currentPage * 10 - 1)
      .map((item, index) => (
        <tr key={"item-list-event-" + index}>
          <td>
            <div className={styles.itemListEvent}>
              <img
                className={styles.imgLEvent}
                src={item?.imageBanner}
                alt="event"
              />
              <div className={styles.infoLEvent}>
                <div className={styles.rowInfo}>
                  <div className={styles.contTitleEvent}>
                    <p className={styles.titleEvent}>{item?.name}</p>
                    <p
                      className={styles.dateEvent}
                      style={
                        item?.status === "pending" ? { fontWeight: 500 } : {}
                      }
                    >
                      {t("pages.admin.dashboard.remaningTickets")}:{" "}
                      {returnTickets(item?.prices || [])}
                    </p>
                  </div>
                </div>
                <div className={styles.rowInfo}>
                  <p className={styles.sublblEvent}>
                    {t("commons.date")}:{" "}
                    {moment(item?.date, "x").format("ddd DD MMM, YYYY")}
                  </p>
                </div>
              </div>
            </div>
          </td>
          <td>
            <div className={styles.rowInfo}>
              <div
                className={styles.btTicketMobile}
                onClick={(e) => handleMoreVert(e, item)}
              >
                <MoreVert className={styles.iconBtnTicket} />
              </div>
              {returnActions(item?.isCancelled).map((action) => (
                <div
                  key={action.label}
                  className={styles.btTicket}
                  onClick={() => action.callBack(item)}
                >
                  {action.icon}
                  <span>{action.label}</span>
                </div>
              ))}
            </div>
          </td>
        </tr>
      ));
  };

  const getEvents = async () => {
    try {
      Model.setData("loading", state.eventsPartner?.length < 1);
      await EventsService.getEventsPartner(state.uid);
    } catch (e) {
      console.log(t("pages.admin.dashboard.errorGetEvents"), e);
    } finally {
      Model.setData("loading", false);
    }
  };

  React.useEffect(() => {
    changeVc({
      totalPages: Math.ceil((returnEventsData()?.length || 0) / 10) || 1,
      currentPage: 1,
    });
    return () => {};
    //eslint-disable-next-line
  }, [state.eventsPartner, vc.tabSelected]);

  React.useEffect(() => {
    getEvents();
    return () => {};
    //eslint-disable-next-line
  }, []);

  return (
    <Layout blackHeader>
      <LayoutListEvents
        tabSelected={vc.tabSelected}
        topBtns={[
          {
            label: t("pages.admin.dashboard.availables"),
            callBack: () => changeVc({ tabSelected: 0 }),
            icon: <PlayCircleFilled />,
          },
          {
            label: t("pages.admin.dashboard.hidden"),
            callBack: () => changeVc({ tabSelected: 1 }),
            icon: <VisibilityOff />,
          },
          {
            label: t("pages.admin.dashboard.finished"),
            callBack: () => changeVc({ tabSelected: 2 }),
            icon: <Folder />,
          },
        ]}
        bottomBtns={[
          {
            label: t("pages.admin.dashboard.createEvent"),
            icon: <AddCircle />,
            callBack: () => {
              setShowTemplateModal(true);
            },
            highlighted: true,
          },
        ]}
      >
        <ActionsBanner banners={banners} className={styles.banners} />

        <table
          cellPadding={0}
          cellSpacing={0}
          className={styles.tableContainer}
        >
          <thead className={styles.rowHeadTable}>
            <tr>
              <th>{t("pages.admin.dashboard.events")}</th>
              <th>{t("pages.admin.dashboard.options")}</th>
            </tr>
          </thead>
          <tbody className={styles.rowBodyTable}>{renderEvents()}</tbody>
        </table>
        <div className={styles.containerPagination}>
          <Pagination
            count={vc.totalPages}
            page={vc.currentPage}
            onChange={handlePageChange}
          />
        </div>
        <Menu
          elevation={3}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => {
            setAnchorEl(null);
            setSelectedEvent(null);
          }}
        >
          {actions.map((action) => (
            <MenuItem
              key={action.label}
              onClick={() => action.callBack(selectedEvent)}
            >
              <ListItemIcon>{action.icon}</ListItemIcon>
              <ListItemText primary={action.label} />
            </MenuItem>
          ))}
        </Menu>
        <div className={styles.blank} />
      </LayoutListEvents>

      <TemplateEventSelectorModal
        open={showTemplateModal}
        onClose={() => setShowTemplateModal(false)}
        onSubmit={(value) => {
          Model.setData("selectedEventTemplate", value);
          Model.setData("eventSelected", null);
          history.push("/eventForm");
        }}
      />
    </Layout>
  );
};

export default Admin;
