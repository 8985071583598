import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Link } from "@material-ui/core";

import { block, done } from "../../assets/images/tutorial";
import useStyles from "./styles/confirmPartnerStyles";
import AdminService from "../../services/admin";
import Model from "../../hooks/Model";

const ConfirmPartner = ({ history }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const { t } = useTranslation();
  // Data to link new partner
  const codeUrl = urlParams.get("code");
  const stateUrl = urlParams.get("state");

  const [vc, setVc] = React.useState({
    message: "",
    isDone: false,
  });
  const styles = useStyles();

  const firstLoad = async () => {
    try {
      if (codeUrl && stateUrl) {
        await AdminService.verifyOAuthMP({
          state: stateUrl,
          code: codeUrl,
        });
        setVc((ov) => ({
          ...ov,
          message: t("pages.admin.confirmPartner.messageConfirmPartner"),
          isDone: true,
        }));
      } else {
        throw new Error(t("pages.admin.confirmPartner.errorConfirmPartner"));
      }
    } catch (e) {
      setVc((ov) => ({ ...ov, message: String(e) }));
    } finally {
      Model.setData("loading", false);
    }
  };

  React.useEffect(() => {
    Model.setData("loading", true);
    firstLoad();
    //eslint-disable-next-line
  }, []);

  return (
    <Box className={styles.container}>
      <img
        alt="Error 404"
        className={styles.img}
        src={vc.isDone ? done : block}
      />
      <p className={styles.text}>
        {vc.message}
        <br />
        <br />
      </p>
      <Link
        href={
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000/profile"
            : "https://www.fravents.com/profile"
        }
        className={styles.button}
      >
        {t("pages.admin.confirmPartner.returnHome")}
      </Link>
    </Box>
  );
};

export default ConfirmPartner;
