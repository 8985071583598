import React from "react";
import { removeChild } from "../../utils/validations";

const MercadoPagoScript = ({ onEndLoad = () => {} }) => {
  React.useLayoutEffect(() => {
    const allScriptTags = document.getElementsByTagName("script");
    const allIframeTags = document.getElementsByTagName("iframe");
    const scripTag = document.createElement("script");
    let flag2createMp = true;

    for (let i = 0; i < allScriptTags.length; i++) {
      if (allScriptTags[i].src.includes("mercadopago")) {
        flag2createMp = false;
      }
    }

    if (flag2createMp) {
      scripTag.src = "https://sdk.mercadopago.com/js/v2";
      scripTag.addEventListener("load", onEndLoad);
      document.body.appendChild(scripTag);
    } else {
      setTimeout(() => {
        onEndLoad();
      }, 1000);
    }

    return () => {
      removeChild(scripTag);

      for (let i = 0; i < allScriptTags.length; i++) {
        if (allScriptTags[i].src.includes("mlstatic")) {
          removeChild(allScriptTags[i]);
        }
        if (allScriptTags[i].innerHTML.includes("mercadolibre")) {
          removeChild(allScriptTags[i]);
        }
      }

      for (let i = 0; i < allIframeTags.length; i++) {
        if (allIframeTags[i].src.includes("mercadolibre")) {
          removeChild(allIframeTags[i]);
        }
      }
    };
    //eslint-disable-next-line
  }, []);

  return null;
};

export default MercadoPagoScript;
