import { makeStyles } from "@material-ui/styles";
import Commons from "../../utils/styles";
import { colors } from "../../styleguide";

const styles = makeStyles({
  rowTypes: {
    overflowX: "auto",
    whiteSpace: "nowrap",
    maxWidth: 1200,
    width: "90%",
    margin: "-24px auto 24px auto",
    padding: "12px 0",
  },
  itemType: {
    ...Commons.defaultButton,
    marginRight: 12,
    minWidth: 72,
    maxWidth: 72,
    opacity: 0.5,
    transition: "all 0.25s",
    "&:hover": {
      opacity: 1,
    },
  },
  containerItemType: {
    ...Commons.flexColumn,
    "&:hover div.scale": {
      transform: "scale(1.1)",
    },
  },
  iconItemType: {
    ...Commons.flexCenter,
    backgroundColor: colors.black,
    minWidth: 42,
    maxWidth: 42,
    maxHeight: 42,
    minHeight: 42,
    borderRadius: "50%",
    border: `3px solid ${colors.themeColor}`,
    marginBottom: 6,
    transition: "all 0.25s",
    "& svg": {
      color: colors.white,
      fontSize: 24,
    },
  },
  labelItemType: {
    textAlign: "center",
    fontSize: 14,
    width: "100%",
    whiteSpace: "pre-wrap",
    color: colors.black,
  },
  fullOpacity: {
    opacity: 1,
  },
});

export default styles;
