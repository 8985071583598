import React from "react";
import { Help, Send, CropFree } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import QRCode from "qrcode.react";
import moment from "moment";

import { returnLabelSellStatus, currencyFormat } from "../../utils/validations";
import NoImage from "../../assets/images/noimage.jpeg";
import Avatar from "../../assets/images/avatar.jpeg";
import PartnerService from "../../services/partner";
import pageStyles from "./styles/sellDetailStyles";
import { Store } from "../../hooks/main_store";
import ShopService from "../../services/shop";
import * as Routes from "../../utils/routes";
import Model from "../../hooks/Model";
import Layout from "../layout";

const DetailProduct = ({ history }) => {
  const { t } = useTranslation();
  const { state } = React.useContext(Store);
  const isAdminView = history?.location?.state?.isAdminView;
  const sellSelected =
    history?.location?.state?.sellSelected || state?.sellSelected;

  const styles = pageStyles();
  const messagesScrollRef = React.useRef(null);
  const [partner, setPartner] = React.useState({});
  const [messages, setMessages] = React.useState([]);
  const [message, setMessage] = React.useState("");
  const [showConversation, setShowConversation] = React.useState(false);
  const [showQr, setShowQr] = React.useState(false);

  const moveScroll = () => {
    if (
      messagesScrollRef?.current?.scrollTop !==
      messagesScrollRef.current?.scrollHeight
    ) {
      setTimeout(() => {
        messagesScrollRef.current.scrollTop =
          messagesScrollRef.current.scrollHeight;
      }, 500);
    }
  };

  const renderMessages = () => {
    const namelabels = {
      buyer: sellSelected?.client?.name || t("pages.shop.sellDetail.buyer"),
      seller: partner?.name || t("pages.shop.sellDetail.seller"),
    };

    if (messages.length > 0) {
      return messages.map((message) => (
        <div
          key={message.created}
          className={[
            styles.bubbleConversation,
            isAdminView && message.role !== "admin"
              ? message.role === "buyer"
                ? "seller"
                : "buyer"
              : message.role,
          ].join(" ")}
        >
          <p className={styles.headerBubble}>
            {namelabels[message.role] || "Admin"}
          </p>
          <p className={styles.bodyBubble}>{message?.message || "-"}</p>
          <p className={styles.footerBubble}>
            {moment(message.created, "x").format("hh:mma - D MMM YYYY")}
          </p>
        </div>
      ));
    }

    return (
      <p className={styles.emptyMessage}>
        {t("pages.shop.sellDetail.thereNoMessages")}
      </p>
    );
  };

  const getChat = (data) => {
    if (data?.data?.length > 0) {
      setMessages(data.data);
      moveScroll();
    }
  };

  const sendMessage = async () => {
    try {
      if (!message || message?.length < 5) {
        return Model.updateAlerts({
          message: t("pages.shop.sellDetail.enterMinText"),
          variant: "warning",
        });
      }
      if (message?.length > 250) {
        return Model.updateAlerts({
          message: t("pages.shop.sellDetail.maxTextLength"),
          variant: "warning",
        });
      }

      setMessage("");
      // if (sellSelected?.partner === state?.uid) {
      //   await ShopService.sendMessage(sellSelected?.uid, {
      //     created: moment().format("x"),
      //     author: state?.uid,
      //     role: isAdminView ? "seller" : "buyer",
      //     message,
      //   });
      // } else {
      await ShopService.sendMessageExternal(sellSelected?.uid, {
        created: moment().format("x"),
        author: state?.uid,
        role: isAdminView ? "seller" : "buyer",
        message,
      });
      // }
    } catch (e) {
      Model.updateAlerts({
        message: `${t("pages.shop.sellDetail.errorSendMessage")}: [${e}]`,
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  const getPartner = async () => {
    try {
      Model.setData("loading", true);
      const infoPartner = await PartnerService.getPartner(
        sellSelected?.partner
      );
      setPartner({
        ...(infoPartner?.basicInfo || {}),
        uid: sellSelected?.partner,
      });
    } catch (e) {
      Model.updateAlerts({
        message: `${t("pages.shop.sellDetail.errorLoadData")}: [${e}]`,
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  React.useEffect(() => {
    let listener = null;
    if (!!sellSelected) {
      Model.setData("opacity", 0.7);
      getPartner();

      listener = ShopService.onChatSell(sellSelected?.uid, getChat);
    } else {
      history.push(Routes.MY_TRANSACTIONS);
    }

    return () => {
      if (listener) {
        listener();
      }
    };
    //eslint-disable-next-line
  }, []);

  return (
    <Layout blackHeader>
      {showQr ? (
        <div className={styles.contQr} onClick={() => setShowQr(false)}>
          <QRCode
            level="H"
            value={`${sellSelected?.consulted}-${sellSelected?.uid.slice(
              0,
              6
            )}-${sellSelected?.created}-${sellSelected?.user.slice(0, 6)}-${0}`}
            size={180}
          />
        </div>
      ) : (
        <button className={styles.viewQrButton} onClick={() => setShowQr(true)}>
          <CropFree />
          <span>{t("pages.shop.sellDetail.viewQrCode")}</span>
        </button>
      )}

      <div className={styles.container}>
        <div className={styles.leftContainer}>
          <div className={styles.partnerRow}>
            <img
              src={partner?.imageProfile || Avatar}
              className={styles.imgPartner}
              alt="partner"
            />
            <p className={styles.namePartner}>{partner?.name}</p>
            {partner?.uid ? (
              <button
                className={styles.visitPartner}
                onClick={() =>
                  history.push(`${Routes.PARTNER_PROFILE}?ref=${partner.uid}`)
                }
              >
                {t("pages.shop.sellDetail.visit")}
              </button>
            ) : null}
          </div>
          <div
            className={[
              styles.listMessages,
              showConversation ? "showMobile" : "",
            ].join(" ")}
            ref={messagesScrollRef}
          >
            {renderMessages()}
          </div>
          <button
            className={[
              styles.showListMessages,
              showConversation ? "showMobile" : "",
            ].join(" ")}
            onClick={() => setShowConversation(true)}
          >
            {t("pages.shop.sellDetail.")}
          </button>
          <div className={styles.inputRow}>
            <textarea
              placeholder={`${t("pages.shop.sellDetail.writeTo")} ${
                isAdminView
                  ? t("pages.shop.sellDetail.client")
                  : t("pages.shop.sellDetail.sellerM")
              }`}
              className={styles.inputMessage}
              rows={2}
              value={message}
              onChange={(event) => {
                event.persist();
                setMessage(event.target.value);
              }}
              maxLength={250}
            ></textarea>
            <button className={styles.sendMessage} onClick={sendMessage}>
              <Send />
            </button>
          </div>
        </div>
        <div className={styles.rightContainer}>
          <div className={styles.sellInfoRow}>
            <div className={styles.sellInfo}>
              <p className={styles.statusSell}>
                {returnLabelSellStatus(sellSelected?.status)}
              </p>
              <p className={styles.updatedSell}>
                {t("pages.shop.sellDetail.updatedAt")}{" "}
                {moment(
                  sellSelected?.updated || sellSelected?.created,
                  "x"
                ).format("D MMMM YYYY, hh:mma")}
              </p>
            </div>
            <button
              className={styles.contactSell}
              onClick={() => window.open("mailto:help@fravents.com")}
            >
              <Help /> <span>{t("pages.shop.sellDetail.support")}</span>
            </button>
          </div>
          <p className={styles.additionalInfo}>{sellSelected.additionalInfo}</p>
          {showQr ? (
            <div
              className={styles.contQrDesktop}
              onClick={() => setShowQr(false)}
            >
              <QRCode
                level="H"
                value={`${sellSelected?.consulted}-${sellSelected?.uid.slice(
                  0,
                  6
                )}-${sellSelected?.created}-${sellSelected?.user.slice(0, 6)}`}
                size={180}
              />
            </div>
          ) : isAdminView ? null : (
            <button
              className={styles.viewQrButtonDesktop}
              onClick={() => setShowQr(true)}
            >
              <CropFree />
              <span>{t("pages.shop.sellDetail.viewQrCode")}</span>
            </button>
          )}
          <p className={styles.labelSection}>
            {t("pages.shop.sellDetail.products")}
          </p>
          {sellSelected?.products?.map?.((product) => (
            <div key={product?.created} className={styles.itemProduct}>
              <img
                src={product?.image || NoImage}
                className={styles.imageProduct}
                alt="product"
              />
              <div className={styles.infoProduct}>
                <p className={styles.dataProduct}>{product?.name}</p>
                <p className={styles.dataProduct}>
                  {t("pages.shop.sellDetail.type")}: <b>{product?.type}</b>
                </p>
                <p className={styles.dataProduct}>
                  {t("pages.shop.sellDetail.price")}:{" "}
                  <b>
                    {currencyFormat(product?.price)}{" "}
                    {t("pages.shop.sellDetail.eachOne")}
                  </b>
                </p>
                <p className={styles.dataProduct}>
                  {t("pages.shop.sellDetail.quantity")}:{" "}
                  <b>{product?.quantity}</b>
                </p>
              </div>
              <button
                className={styles.viewProduct}
                onClick={() =>
                  history.push(
                    `${Routes.DETAIL_PRODUCT}?product=${product.uid}`
                  )
                }
              >
                {t("pages.shop.sellDetail.view")}
                <br />
                {t("pages.shop.sellDetail.detail")}
              </button>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default DetailProduct;
