import { makeStyles } from "@material-ui/styles";
import Commons from "../../../utils/styles";
import { colors } from "../../../styleguide";

const styles = makeStyles({
  responsiveContent: {
    ...Commons.centerHorizontal,
    width: "100%",
    maxWidth: 1024,
    paddingTop: 24,
  },
  contCarousel: {
    margin: "0 auto",
    marginBottom: 48,
    "& .control-next.control-arrow:before": {
      borderLeft: "8px solid black !important",
    },
    "& .control-prev.control-arrow:before": {
      borderRight: "8px solid black !important",
    },
    "& .control-arrow.control-next": {
      backgroundColor: "transparent !important",
    },
    "& .control-arrow.control-prev": {
      backgroundColor: "transparent !important",
    },
    [Commons.smallQuery]: {
      marginBottom: 24,
    },
  },
  title: {
    color: colors.themeColor,
    fontWeight: "700",
    fontSize: 36,
    margin: 0,
    paddingTop: 24,
    [Commons.smallQuery]: {
      fontSize: 30,
      margin: "0 12px",
    },
  },
  subtitle: {
    fontSize: 18,
    color: colors.mainxText,
    margin: 0,
    paddingBottom: 24,
    [Commons.smallQuery]: {
      fontSize: 16,
      margin: "0 12px",
    },
  },
  itemCardEvent: {
    ...Commons.coverBackground,
    ...Commons.flexColumn,
    justifyContent: "center",
    backgroundPosition: "top center",
    height: 360,
    width: "calc(100% - 60px)",
    borderRadius: 6,
    overflow: "visible",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    cursor: "pointer",
    boxShadow: colors.shadow,
    position: "relative",
    margin: "6px 30px",
    [Commons.smallQuery]: {
      height: 240,
    },
  },
  itemCardEventImage: {
    ...Commons.coverBackground,
    ...Commons.flexRow,
    backgroundPosition: "top center",
    height: 360,
    width: "calc(100% - 60px)",
    borderRadius: 6,
    overflow: "visible",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    cursor: "pointer",
    boxShadow: colors.shadow,
    position: "relative",
    margin: "6px 30px",
    [Commons.smallQuery]: {
      height: 240,
    },
  },
  bgDropItemCardEvent: {
    position: "absolute",
    left: 0,
    top: 0,
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: 0,
    width: "100%",
    height: "100%",
    borderRadius: 6,
  },
  imageItemCardEvent: {
    ...Commons.coverBackground,
    backgroundPosition: "top center",
    position: "relative",
    height: 300,
    aspectRatio: "1 / 1.3",
    borderRadius: 12,
    marginLeft: 24,
    marginRight: 24,
    border: `4px solid ${colors.themeColor}`,
    zIndex: 1,
    [Commons.smallQuery]: {
      height: 180,
      marginLeft: 6,
      marginRight: 6,
    },
  },
  badgePrice: {
    position: "absolute",
    backgroundColor: colors.themeColor,
    color: colors.white,
    fontSize: 18,
    fontWeight: 600,
    padding: "3px 6px",
    borderRadius: 6,
    boxShadow: colors.shadow,
    top: 12,
    right: -9,
  },
  containerInfoItemCardEvent: {
    flex: 1,
    marginRight: 24,
    padding: 30,
    position: "relative",
    zIndex: 1,
    [Commons.smallQuery]: {
      marginRight: 6,
      padding: 6,
    },
  },
  rowHeaderItemCardEvent: {
    ...Commons.flexRow,
    justifyContent: "flex-end",
    "& div": {
      backgroundColor: colors.themeColor,
      color: colors.white,
      padding: "6px 12px",
      fontSize: 12,
      borderRadius: "6px 6px 0 0",
    },
    [Commons.smallQuery]: {
      fontSize: 10,
    },
  },
  borderInfoItemCardEvent: {
    border: `4px solid ${colors.themeColor}`,
    backgroundColor: colors.white,
    boxShadow: colors.shadow,
    borderRadius: "6px 0 0 0",
    padding: "18px 30px",
    maxHeight: 210,
    overflow: "hidden",
    [Commons.smallQuery]: {
      borderRadius: 0,
      padding: 6,
      maxHeight: 120,
    },
  },
  viewMoreItemCardEvent: {
    backgroundColor: colors.themeColor,
    color: colors.white,
    fontWeight: "700",
    textAlign: "center",
    borderRadius: "0 0 6px 6px",
    padding: "6px 0 12px 0",
    fontSize: 20,
    [Commons.smallQuery]: {
      fontSize: 14,
    },
  },
  nameItemCardEvent: {
    color: colors.themeColor,
    fontSize: 30,
    fontWeight: "700",
    textAlign: "center",
    margin: "0 0 12px 0",
    [Commons.smallQuery]: {
      margin: 0,
      fontSize: 16,
    },
  },
  descriptionItemCardEvent: {
    fontSize: 16,
    color: colors.black,
    [Commons.smallQuery]: {
      display: "none",
    },
  },
  itemCategory: {
    width: 54,
    borderRadius: 6,
    margin: "0px 12px",
    minWidth: 54,
    cursor: "pointer",
  },
  imgCategory: {
    minWidth: 54,
    maxWidth: 54,
    minHeight: 54,
    maxHeight: 54,
    borderRadius: "50%",
    backgroundColor: colors.themeColor,
    boxShadow: colors.shadow,
  },
  lblCategory: {
    textAlign: "center",
    fontWeight: "600",
    fontSize: 12,
    margin: "12px 0px",
  },
  blank: {
    height: 60,
  },
  lblEmpty: {
    textAlign: "center",
    color: colors.black,
    fontSize: 18,
    width: "80%",
    margin: "30px auto",
  },
  containerCarousel: {
    marginBottom: 30,
    position: "relative",
  },
  listOfEvents: {
    overflowY: "hidden",
    overflowX: "auto",
    whiteSpace: "nowrap",
    [Commons.smallQuery]: {
      paddingLeft: 12,
    },
  },
  leftShadowCarousel: {
    height: "100%",
    width: 12,
    backgroundImage: `linear-gradient(to left, rgba(255,255,255,0), ${colors.white})`,
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: 1,
  },
  rightShadowCarousel: {
    height: "100%",
    width: 12,
    backgroundImage: `linear-gradient(to right, rgba(255,255,255,0), ${colors.white})`,
    position: "absolute",
    right: 0,
    top: 0,
    zIndex: 1,
  },
  spaceShadowCarousel: {
    width: 12,
    display: "inline-block",
  },
  listOfBookmarks: {
    ...Commons.flexRow,
    flexWrap: "wrap",
    margin: "30px 0",
    [Commons.smallQuery]: {
      margin: "30px 12px 30px 24px",
    },
  },
  paddingList: {
    marginTop: -24,
    [Commons.smallQuery]: {
      marginTop: -12,
    },
  },
  ctaList: {
    display: "inline-block",
    width: 220,
    margin: 0,
    backgroundColor: `rgba(0, 0, 0, 0.1)`,
    borderRadius: 12,
    boxShadow: colors.shadow,
    cursor: "pointer",
    whiteSpace: "normal",
    textAlign: "center",
    padding: 12,
    marginRight: 18,
  },
  txtCtaBanner: {
    color: colors.black,
    fontSize: 24,
    fontWeight: "500",
    maxWidth: 420,
    margin: "30px 0",
    textAlign: "center",
    [Commons.smallQuery]: {
      fontSize: 20,
      margin: "12px 18px",
    },
  },
  txtCtaList: {
    color: colors.black,
    fontSize: 24,
    letterSpacing: "-1px",
    fontWeight: "bold",
    margin: "30px 0",
    textAlign: "center",
  },
  btnCtaList: {
    color: colors.white,
    backgroundColor: colors.themeColor,
    padding: 12,
    fontSize: 16,
    borderRadius: 12,
    textAlign: "center",
    [Commons.smallQuery]: {
      marginRight: 12,
    },
  },
  rowInfo: {
    ...Commons.flexRow,
  },
  labelInfo: {
    flex: 1,
    marginRight: 18,
  },
  viewAllBtn: {
    ...Commons.defaultButton,
    color: colors.white,
    backgroundColor: colors.themeColor,
    padding: "9px 18px",
    boxShadow: colors.shadow,
    borderRadius: 12,
    fontWeight: "600",
    fontSize: 16,
    textDecoration: "none",
    [Commons.smallQuery]: {
      marginRight: 12,
      fontSize: 14,
    },
  },
  bannerNewsContainer: {
    ...Commons.flexRow,
    color: colors.white,
    textDecoration: "none",
    backgroundColor: colors.black,
    boxShadow: colors.shadow,
    margin: "30px auto 0 auto",
    maxWidth: 1024,
    borderRadius: 18,
    padding: "24px 30px",
    boxSizing: "border-box",
    border: `6px solid ${colors.themeColor}`,
    [Commons.smallQuery]: {
      padding: 12,
      margin: "30px 12px 0 12px",
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  imageNewsContainer: {
    height: 60,
    marginRight: 24,
    [Commons.smallQuery]: {
      marginRight: 0,
      marginBottom: 12,
      height: 48,
    },
  },
  titleNewsContainer: {
    fontSize: 30,
    margin: 0,
    [Commons.smallQuery]: {
      fontSize: 24,
    },
  },
  descriptionNewsContainer: {
    fontSize: 18,
    margin: "6px 0",
    "& span": {
      color: colors.themeColor,
    },
  },
});

export default styles;
