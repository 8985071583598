import { makeStyles } from "@material-ui/styles";
import Commons from "../../../utils/styles";

const styles = makeStyles({
  container: {
    width: "100%",
    overflowX: "auto",
    [Commons.smallQuery]: {
      overflowX: "hidden",
    },
  },
});

export default styles;
