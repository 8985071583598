import { makeStyles } from "@material-ui/core/styles";
import { colors } from "../../styleguide";
import Commons from "../../utils/styles";

const useStyles = makeStyles({
  container: {
    ...Commons.flexCenter,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 1,
  },
  backdrop: {
    position: "absolute",
    zIndex: 0,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "transparent",
  },
  card: {
    backgroundColor: colors.white,
    borderRadius: 18,
    boxShadow: colors.shadow,
    border: `6px solid ${colors.themeColor}`,
    width: "calc(90% - 72px)",
    maxWidth: 720,
    padding: 30,
    maxHeight: "calc(75vh - 72px)",
    overflow: "auto",
    position: "relative",
    zIndex: 1,
  },
  title: {
    margin: 0,
    color: colors.black,
    fontSize: 24,
    fontWeight: "700",
  },
  description: {
    margin: 0,
    color: colors.black,
    fontSize: 16,
    fontWeight: "500",
  },
  rowOptions: {
    ...Commons.flexRow,
    marginTop: 42,
    gap: 18,
    [Commons.smallQuery]: {
      flexDirection: "column",
    },
  },
  option: {
    ...Commons.flexColumn,
    flex: 1,
    backgroundColor: colors.white,
    boxShadow: colors.shadow,
    borderRadius: 12,
    minHeight: 252,
    cursor: "pointer",
    padding: 12,
    border: `2px solid ${colors.white}`,
    position: "relative",
    "&:hover": {
      borderColor: colors.themeColor,
      "& svg": {
        color: colors.themeColor,
      },
    },
    "& svg": {
      fontSize: 48,
      color: colors.gray50,
    },
    [Commons.smallQuery]: {
      minHeight: "unset",
      width: "calc(100% - 28px)",
      "& svg": {
        fontSize: 36,
        position: "absolute",
        top: 6,
        left: 6,
      },
    },
  },
  selectedOption: {
    borderColor: colors.themeColor,
    "& svg": {
      color: colors.themeColor,
    },
  },
  labelOption: {
    textAlign: "center",
    color: colors.black,
    fontWeight: "700",
    fontSize: 18,
    margin: "18px 0 12px 0",
    [Commons.smallQuery]: {
      margin: "0 0 12px 0",
    },
  },
  descriptionOption: {
    color: colors.black,
    fontWeight: "500",
    margin: 0,
    whiteSpace: "pre-wrap",
    fontSize: 15,
  },
  submit: {
    ...Commons.defaultButton,
    backgroundColor: colors.themeColor,
    color: colors.white,
    textAlign: "center",
    fontWeight: "600",
    borderRadius: 12,
    boxShadow: colors.shadow,
    padding: "12px 0",
    width: "100%",
    marginTop: 24,
  },
  disabledSubmit: {
    backgroundColor: colors.gray50,
    cursor: "no-drop",
  },
});

export default useStyles;
