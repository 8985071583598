import React from "react";
import pageStyles from "./styles";
import { Grid, Switch } from "@material-ui/core";

const SwitchBoth = ({
  value = "",
  values = new Array(2).fill({ value: "", label: "" }),
  className = "",
  onChange = () => {},
}) => {
  const styles = pageStyles();

  const rageDateHandleChange = (event) => {
    const newValue = event.target.checked
      ? values?.[1]?.value
      : values?.[0]?.value;
    onChange(newValue);
  };

  return (
    <Grid container className={[styles.container, className].join(" ")}>
      <Grid item>{values?.[0]?.label}</Grid>
      <Grid item>
        <Switch
          checked={value === values?.[1]?.value}
          onChange={rageDateHandleChange}
          className={styles.switchContainer}
        />
      </Grid>
      <Grid item>{values?.[1]?.label}</Grid>
    </Grid>
  );
};

export default SwitchBoth;
