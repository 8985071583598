import React from "react";
import { AddCircle, RemoveCircle } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import { currencyFormat, returnCurrentLink } from "../../utils/validations";
import { commonPlatformTax, commonMpTax } from "../../utils/constants";
import MercadoPagoScript from "../../components/MercadoPagoScript";
import NoImage from "../../assets/images/noimage.jpeg";
import pageStyles from "./styles/shoppingCartStyles";
import { Store } from "../../hooks/main_store";
import ShopService from "../../services/shop";
import AuthService from "../../services/auth";
import { keyMp } from "../../utils/constants";
import * as Routes from "../../utils/routes";
import Model from "../../hooks/Model";
import Layout from "../layout";

const ShoppinCart = ({ history }) => {
  const { t } = useTranslation();
  const styles = pageStyles();
  const { state } = React.useContext(Store);
  const totalItems =
    state.shoppingCart?.length > 0
      ? state.shoppingCart
          ?.map((item) => item.quantity)
          .reduce((total, item) => parseInt(total) + parseInt(item)) || 0
      : 0;
  const totalPrice =
    state.shoppingCart?.length > 0
      ? state.shoppingCart
          ?.map((item) => parseFloat(item.price) * parseInt(item.quantity))
          .reduce((total, item) => parseInt(total) + parseFloat(item)) || 0
      : 0;
  const [callback, setCallback] = React.useState(null);
  const [nameUser, setNameUser] = React.useState(state?.userInfo?.name || "");
  const [phoneUser, setPhoneUser] = React.useState(
    state?.userInfo?.phone || ""
  );
  const [textPromoCode, setTextPromoCode] = React.useState("");
  const [additionalInfo, setAdditionalInfo] = React.useState("");
  const [valuePromoCode, setValuePromoCode] = React.useState(null);
  const [mercadoPago, setMercadoPago] = React.useState(null);

  const changeInput = (event, callback) => {
    event.persist();
    callback(event.target.value);
  };

  const updateCart = (cart = []) => {
    Model.setData("shoppingCart", cart);

    clearTimeout(callback);
    setCallback(
      setTimeout(() => {
        AuthService.updateMe(state.uid, { shoppingCart: cart }, true)
          .catch((e) => {
            Model.updateAlerts({
              message: `Error: ${_.get(
                e,
                "message",
                t("commons.unknowError")
              )}`,
              variant: "error",
            });
          })
          .finally(() => {
            Model.setData("loading", false);
          });
      }, 6000)
    );
  };

  const updateItem = (index, quantity) => {
    let currentCart = [...state.shoppingCart];
    const newQuantity =
      parseInt(currentCart[index].quantity) + parseInt(quantity);

    if (newQuantity < 1) {
      currentCart.splice(index, 1);
    } else {
      currentCart[index].quantity = parseInt(newQuantity);
    }

    updateCart(currentCart);
  };

  const deleteEmptyProducts = (infoProductsError = []) => {
    let currentCart = [...state.shoppingCart];
    const index = currentCart.findIndex(
      (product) =>
        infoProductsError.map((item) => item.type).indexOf(product.type) > -1
    );

    currentCart.splice(index, 1);
    updateCart(currentCart);
  };

  const renderItems = () => {
    if (state.shoppingCart.length > 0) {
      return state.shoppingCart.map((item, index) => (
        <tr key={item.created}>
          <td className={styles.rowInfoItem}>
            <img
              src={item.image || NoImage}
              alt={item.name}
              className={styles.imageItem}
              onClick={() =>
                history.push(`/detail-product?product=${item.uid}`)
              }
            />
            <p className={styles.nameItem}>{item.name}</p>
          </td>
          <td>
            <button
              className={styles.actionItemCart}
              onClick={() => updateItem(index, -1)}
            >
              <RemoveCircle />
            </button>
            <span className={styles.quantityItemCart}>{item.quantity}</span>
            <button
              className={styles.actionItemCart}
              onClick={() => updateItem(index, 1)}
            >
              <AddCircle />
            </button>
          </td>
          <td className={styles.priceItem}>
            {currencyFormat(item.price, "COP", 0)}
          </td>
          <td className={[styles.priceItem, styles.hideMobile].join(" ")}>
            {currencyFormat(
              parseFloat(item.price) * parseInt(item.quantity),
              "COP",
              0
            )}
          </td>
        </tr>
      ));
    }

    return (
      <tr>
        <td>{t("pages.shop.shoppingCart.noAddItemsYet")}</td>
      </tr>
    );
  };

  const validateCode = async () => {
    try {
      if (!textPromoCode) {
        return Model.updateAlerts({
          message: t("pages.shop.shoppingCart.mustEnterCode"),
          variant: "error",
        });
      }

      if (!state.shoppingCart.length) {
        return Model.updateAlerts({
          message: t("pages.shop.shoppingCart.firstAddItem"),
          variant: "error",
        });
      }

      Model.setData("loading", true);
      const dataPromoCode = await ShopService.confirmPromoCode({
        partner: state.shoppingCart?.[0]?.partner,
        promoCode: textPromoCode,
      });

      setValuePromoCode(dataPromoCode);
    } catch (e) {
      Model.updateAlerts({
        message: t("pages.shop.shoppingCart.errorValidateCode") + String(e),
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  const returnDiscount = () =>
    parseFloat(
      valuePromoCode?.percentPromoCode
        ? (parseFloat(totalPrice) *
            parseFloat(valuePromoCode?.percentPromoCode)) /
            100
        : valuePromoCode?.valuePromoCode
    );

  const returnTax = () => {
    const price = parseFloat(totalPrice) - (returnDiscount() || 0);
    const platformTax = price * commonPlatformTax;
    const providerTax = price * commonMpTax[0] + commonMpTax[1];

    return platformTax + providerTax;
  };

  const goToPay = async () => {
    try {
      if (!state?.emailVerified) {
        return Model.setData("dialog", {
          open: true,
          title: t("pages.shop.shoppingCart.beforeContinue"),
          text: state.isOnPluginPartner
            ? t("pages.shop.shoppingCart.forYourBuy")
            : t("pages.shop.shoppingCart.mustVerifyEmail"),
          txtLeft: t("commons.cancel"),
          fnLeft: () => {
            Model.setData("dialog", { open: false });
          },
          txtRight: state.isOnPluginPartner
            ? t("pages.shop.shoppingCart.continue")
            : t("pages.shop.shoppingCart.goToProfile"),
          fnRight: () => {
            Model.setData("dialog", { open: false });
            if (state.isOnPluginPartner) {
              Model.setData("openAuth", true);
            } else {
              history.push("/profile");
            }
          },
        });
      }

      Model.setData("dialog", { open: false });
      Model.setData("loading", true);

      const isTest =
        process.env.NODE_ENV === "development" ||
        window?.location?.host?.includes?.("test.")
          ? "yes"
          : null;

      const data = {
        isTest,
        name: nameUser,
        phone: phoneUser,
        promoCode: valuePromoCode?.textPromoCode,
        products: state.shoppingCart,
        additionalInfo: additionalInfo,
      };

      const response = await ShopService.confirmSells(data);
      if (!response.idPreference || !mercadoPago) {
        throw new Error(
          `${t("pages.shop.shoppingCart.bookingNoAvailable")} [Error: ${
            response || t("pages.shop.shoppingCart.errorData")
          }]`
        );
      } else {
        // Go to Pay
        setTimeout(() => {
          updateCart([]);
          mercadoPago.checkout({
            preference: {
              id: response.idPreference,
            },
            autoOpen: true,
          });
        }, 1000);
      }
    } catch (e) {
      let message = e?.message || String(e);

      if (e?.errorProducts) {
        deleteEmptyProducts(e.errorProducts);
        Model.updateAlerts({
          message: t("pages.shop.shoppingCart.someProductsNoHave"),
          variant: "error",
        });
      } else {
        Model.updateAlerts({
          message: message,
          variant: "error",
        });
      }
    } finally {
      Model.setData("loading", false);
      Model.setData("dialog", { open: false, isReferred: false });
    }
  };

  const showConfirmDialog = () => {
    if (nameUser && phoneUser && parseInt(totalItems) > 0 && additionalInfo) {
      Model.setData("dialog", {
        open: true,
        title: t("pages.shop.shoppingCart.confirmSell"),
        text: t("pages.shop.shoppingCart.wantGetItems", {
          totalItems: totalItems,
          multiple: totalItems > 1 ? "s" : "",
        }),
        txtLeft: t("commons.cancel"),
        fnLeft: () => Model.setData("dialog", { open: false }),
        txtRight: t("commons.yesConfirm"),
        fnRight: goToPay,
        link: returnCurrentLink(
          Routes.RENDER_LEGAL_DOC + "?document=marketplace"
        ),
      });
    } else {
      Model.updateAlerts({
        message: t("pages.shop.shoppingCart.remainInfoYet"),
        variant: "warning",
      });
    }
  };

  React.useEffect(() => {
    Model.setData("opacity", 0.7);
    Model.setData("loading", false);
    //eslint-disable-next-line
  }, []);

  return (
    <Layout blackHeader>
      <MercadoPagoScript
        onEndLoad={() => {
          setMercadoPago(
            new window.MercadoPago(keyMp, {
              locale: "es-CO",
            })
          );
        }}
      />

      <div className={styles.container}>
        <div className={styles.leftColumn}>
          <div className={styles.rowHeader}>
            <h3 className={styles.titleHeader}>
              {t("pages.shop.shoppingCart.shoppingCart")}
            </h3>
            <span className={styles.dataHeader}>
              {totalItems} Item{totalItems === 1 ? "" : "s"}
            </span>
          </div>
          {state.shoppingCart.length > 0 ? (
            <p className={styles.descriptionLabelRight}>
              {t("pages.shop.shoppingCart.thisSectionNoGuarant")}
            </p>
          ) : null}
          <table className={styles.listTable}>
            <thead>
              <tr className={styles.headerListTable}>
                <th width="40%">{t("pages.shop.shoppingCart.product")}</th>
                <th>{t("pages.shop.shoppingCart.quantity")}</th>
                <th>{t("pages.shop.shoppingCart.price")}</th>
                <th className={styles.hideMobile}>
                  {t("pages.shop.shoppingCart.subTotal")}
                </th>
              </tr>
            </thead>
            <tbody>{renderItems()}</tbody>
          </table>
        </div>
        <div className={styles.rightColumn}>
          <h3 className={styles.titleHeader}>
            {t("pages.shop.shoppingCart.sellSummary")}
          </h3>
          <div className={styles.rowSubtotal}>
            <p className={styles.titleSubtotal}>
              {totalItems} Item{totalItems === 1 ? "" : "s"}
            </p>
            <span className={styles.dataSubtotal}>
              {currencyFormat(totalPrice, "COP", 0)}
            </span>
          </div>
          <p className={styles.labelRight}>
            {t("pages.shop.shoppingCart.additionalData")}
          </p>
          <p className={styles.descriptionLabelRight}>
            {t("pages.shop.shoppingCart.pleaseAddData")}
          </p>
          <textarea
            className={styles.input}
            placeholder={t("pages.shop.shoppingCart.directionCity")}
            rows={5}
            onChange={(e) => changeInput(e, setAdditionalInfo)}
            value={additionalInfo}
          />
          <input
            className={styles.input}
            placeholder={t("commons.name")}
            onChange={(e) => changeInput(e, setNameUser)}
            value={nameUser}
          />
          <input
            className={styles.input}
            placeholder={t("commons.cellphone")}
            onChange={(e) => changeInput(e, setPhoneUser)}
            value={phoneUser}
          />
          <p className={styles.labelRight}>
            {t("pages.shop.shoppingCart.promoCode")}
          </p>
          <input
            className={styles.input}
            placeholder={t("pages.shop.shoppingCart.enterYourCode")}
            onChange={(e) => changeInput(e, setTextPromoCode)}
            value={textPromoCode}
            disabled={!!valuePromoCode}
          />
          {valuePromoCode ? (
            <div className={styles.rowSubtotal}>
              <p className={styles.titleSubtotal}>
                {t("pages.shop.shoppingCart.discountApplied")}:
              </p>
              <span className={styles.dataSubtotal}>
                {currencyFormat(returnDiscount(), "COP", 0)}
              </span>
            </div>
          ) : (
            <button className={styles.applyButton} onClick={validateCode}>
              {t("pages.shop.shoppingCart.apply")}
            </button>
          )}
          <div className={styles.row}>
            <p className={styles.titleSubtotal}>
              {t("pages.shop.shoppingCart.taxes")}:
            </p>
            <span className={styles.dataSubtotal}>
              {currencyFormat(returnTax(), "COP", 0)}
            </span>
          </div>
          <div className={styles.rowSubtotal}>
            <p className={styles.titleSubtotal}>
              {t("pages.shop.shoppingCart.totalCost")}
            </p>
            <span className={styles.dataSubtotal}>
              {currencyFormat(
                parseFloat(totalPrice) -
                  parseFloat(returnDiscount() || 0) +
                  parseFloat(returnTax()),
                "COP",
                0
              )}
            </span>
          </div>
          <button className={styles.submitButton} onClick={showConfirmDialog}>
            {t("pages.shop.shoppingCart.finishPay")}
          </button>
        </div>
      </div>
    </Layout>
  );
};

export default ShoppinCart;
