import React from "react";
import { RemoveShoppingCart, Block } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import moment from "moment";

import PartnerService from "../../services/partner";
import LocalStorage from "../../utils/localStorage";
import ItemEvent from "../../components/ItemEvent";
import pageStyles from "./styles/pluginStyles";
import { Store } from "../../hooks/main_store";
import * as Routes from "../../utils/routes";
import Model from "../../hooks/Model";
import Layout from "../layout";

const PluginPartner = ({ history }) => {
  const { t } = useTranslation();
  const styles = pageStyles();
  const { state } = React.useContext(Store);

  const [content2render, setContent2render] = React.useState([]);
  const [showError, setShowError] = React.useState(false);
  const [vc, setVc] = React.useState({
    name: state?.userInfo?.name || "",
    email: state?.userInfo?.email || "",
    imageProfile: state?.userInfo?.imageProfile || "",
    password: "",
    phone: state?.userInfo?.phone,
    description: "",
    logo: "",
    created: "",
    events: [],
    products: [],
    uid: "",
    social: {},
  });
  const changeVc = (data) => setVc((ov) => ({ ...ov, ...data }));

  const getPartner = async (uidPartner, viewPartner) => {
    try {
      const infoPartner = await PartnerService.getPartner(uidPartner);
      changeVc(infoPartner?.basicInfo);
      changeVc({ events: infoPartner?.events || [] });
      setContent2render(infoPartner?.events || []);
      Model.setData("infoPartner", {
        ...infoPartner?.basicInfo,
        uid: uidPartner,
      });
      if (!state.fistLoad) {
        Model.setData("isWhiteLabel", !!infoPartner?.basicInfo?.isWhiteLabel);
      }
      Model.setData(
        "eventsPartnerPlugin",
        (infoPartner?.events || [])?.map?.((event) => event?.uid) || []
      );

      if (uidPartner) {
        LocalStorage(state.isIncognito).setItem(
          "token_plugin_partner",
          uidPartner
        );
      }

      if (viewPartner === "tickets") {
        history.push(Routes.MY_TRANSACTIONS);
      }
    } catch (e) {
      Model.updateAlerts({
        message: `${t("pages.partner.plugin.infoSelectedNo")}: [${e}]`,
        variant: "error",
      });
    } finally {
      setTimeout(() => {
        Model.setData("loading", false);
        Model.setData("opacity", 0.7);
      }, 3000);
    }
  };

  const onSelectEvent = (item) => {
    Model.setData("eventSelected", item);
    history.push("/events", { event: item, isOnPluginPartner: true });
  };

  const renderContent = () => {
    if (content2render.length < 1) {
      return (
        <div className={styles.contEmpty}>
          <p className={styles.emptyMsg}>
            {t("pages.partner.plugin.noContentToShow")}
          </p>
          <RemoveShoppingCart />
        </div>
      );
    }
    return content2render
      .sort((a, b) => b.date - a.date)
      .filter((a) => a.date >= moment().format("x"))
      .map((item, index) => (
        <ItemEvent
          key={"item-list-event-" + index}
          item={item}
          onClick={onSelectEvent}
          callback={() => getPartner(vc.uid)}
          customClasses={styles.itemEvent}
        />
      ));
  };

  const getUidPartner = async () => {
    try {
      Model.setData("opacity", 1);
      Model.setData("loading", true);
      const urlParams = new URLSearchParams(window.location.search);
      const tokenPartner = urlParams.get("ref");
      const viewPartner = urlParams.get("view");
      const uid = await PartnerService.getUidPartner(tokenPartner);

      if (uid) {
        changeVc({ uid: uid });
        getPartner(uid, viewPartner);
      } else {
        Model.setData("loading", false);
        setShowError(true);
      }
    } catch (e) {
      setShowError(true);
      history.push("/");
    }
  };

  React.useEffect(() => {
    getUidPartner();
    return () => {
      Model.setData("opacity", 0.7);
    };
    //eslint-disable-next-line
  }, []);

  return (
    <Layout hideFooterLinks>
      {showError ? (
        <div className={styles.containerError}>
          <Block className={styles.iconError} />
          <p className={styles.descriptionError}>
            {t("pages.partner.plugin.thisPluginIsUnavailable")}
          </p>
        </div>
      ) : (
        <div className={styles.container}>
          <div className={styles.listItems}>{renderContent()}</div>
          {content2render.length > 0 && false ? (
            <button className={styles.loadMore}>
              {t("pages.partner.plugin.")}
            </button>
          ) : null}
        </div>
      )}
    </Layout>
  );
};

export default PluginPartner;
