import { makeStyles } from "@material-ui/styles";
import Commons from "../../../utils/styles";
import { colors } from "../../../styleguide";

const styles = makeStyles({
  contentBanner: {
    ...Commons.flexRow,
    backgroundColor: colors.black,
    height: 600,
    position: "relative",
    marginTop: -90,
    paddingTop: 90,
    [Commons.smallQuery]: {
      ...Commons.flexColumn,
      justifyContent: "space-around",
    },
  },
  imageBanner: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    objectFit: "cover",
  },
  filterBg: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    objectFit: "cover",
    backgroundColor: colors.black,
    opacity: 0.4,
  },
  infoBanner: {
    position: "relative",
    flex: 2,
    margin: "0 60px",
    [Commons.smallQuery]: {
      flex: "unset",
      margin: "0 18px",
      marginBottom: -60,
    },
  },
  titleBanner: {
    color: colors.themeColor,
    fontWeight: "700",
    fontSize: 48,
    margin: 0,
    textShadow: colors.textShadow,
    [Commons.smallQuery]: {
      fontSize: 30,
      textAlign: "center",
    },
  },
  descriptionBanner: {
    color: colors.white,
    fontSize: 24,
    margin: 0,
    marginTop: 6,
    [Commons.smallQuery]: {
      fontSize: 17,
      textAlign: "center",
    },
  },
  rowEventsBanner: {
    position: "relative",
    marginRight: 60,
    flex: 4,
    whiteSpace: "nowrap",
    overflowX: "auto",
    width: "100%",
    [Commons.smallQuery]: {
      marginRight: 0,
      flex: "unset",
      paddingLeft: 24,
      width: "calc(100% - 24px)",
    },
  },
  lblEmpty: {
    textAlign: "center",
    color: colors.black,
    fontSize: 18,
    margin: "30px auto",
  },
  itemListEvent: {
    flex: 1,
    minWidth: 280,
    margin: 0,
  },
  itemCardEvent: {
    display: "inline-block",
    height: 390,
    width: 270,
    borderRadius: 24,
    padding: 12,
    boxShadow: colors.shadow,
    backgroundColor: colors.black,
    marginRight: 18,
    cursor: "pointer",
    "& p": {
      height: 0,
    },
    "&:hover p": {
      height: 126,
    },
    [Commons.smallQuery]: {
      height: 270,
      width: 210,
    },
  },
  contPosterCardEvent: {
    height: "90%",
    borderRadius: 24,
    overflow: "hidden",
    position: "relative",
  },
  imagePoster: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "center",
  },
  badgePrice: {
    position: "absolute",
    top: 6,
    right: 6,
    backgroundColor: colors.themeColor,
    color: colors.black,
    fontSize: 14,
    fontWeight: "600",
    padding: "6px 12px",
    borderRadius: 24,
  },
  contNamePoster: {
    position: "absolute",
    bottom: 0,
    left: 0,
    backgroundColor: "rgba(47, 47, 47, 0.75)",
    padding: 12,
    width: "calc(100% - 24px)",
  },
  namePoster: {
    ...Commons.ellipsis,
    color: colors.white,
    fontWeight: "700",
    fontSize: 24,
    margin: 0,
    [Commons.smallQuery]: {
      fontSize: 18,
    },
  },
  descriptionPoster: {
    color: colors.white,
    fontSize: 15,
    margin: 0,
    marginTop: 12,
    width: "100%",
    whiteSpace: "normal",
    maxHeight: 126,
    overflowY: "auto",
    transition: "all 0.2s",
  },
  datePoster: {
    ...Commons.ellipsis,
    color: colors.themeColor,
    fontSize: 15,
    textAlign: "center",
    marginTop: 15,
    [Commons.smallQuery]: {
      fontSize: 13,
      marginTop: 9,
    },
  },
  shadowBanner: {
    width: "100%",
    height: 30,
    background: "linear-gradient(180deg, gray 0%, white 100%)",
    position: "absolute",
    top: "100%",
  },
  rowFilter: {
    ...Commons.flexRow,
    margin: "60px auto 42px auto",
    justifyContent: "center",
    backgroundColor: colors.black,
    width: "fit-content",
    borderRadius: 30,
    padding: "12px 30px",
    boxShadow: colors.shadow,
  },
  contSearch: {
    border: `2px solid ${colors.themeColor}`,
    borderRadius: 24,
    marginRight: 12,
    width: "80vw",
    maxWidth: 600,
    position: "relative",
    height: 42,
    [Commons.smallQuery]: {
      width: "50vw",
    },
  },
  inputSearch: {
    ...Commons.defaultInput,
    height: "100%",
    width: "calc(100% - 84px)",
    padding: "0 12px",
    color: colors.white,
    "&::placeholder": {
      color: colors.themeColor,
    },
  },
  iconSearch: {
    position: "absolute",
    right: 18,
    color: colors.themeColor,
    fontSize: 30,
    top: 6,
  },
  buttonSearch: {
    ...Commons.defaultButton,
    backgroundColor: colors.themeColor,
    color: colors.black,
    borderRadius: 12,
    padding: "12px 18px",
  },
  listOfEvents: {
    ...Commons.flexRow,
    margin: "0px auto 90px auto",
    flexWrap: "wrap",
    gap: 18,
    maxWidth: 1200,
    padding: "0 30px 90px 30px",
    [Commons.smallQuery]: {
      justifyContent: "center",
    },
  },
});

export default styles;
