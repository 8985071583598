import React from "react";
import * as Icons from "@material-ui/icons";

import { Store } from "../../hooks/main_store";
import pageStyles from "./styles";

const ItemTypeEvent = ({
  onClick = () => {},
  value = "",
  item = {},
  defaultValue = "",
  className = "",
  isTag = false,
}) => {
  const { state } = React.useContext(Store);
  const styles = pageStyles();

  const onEvent = (type) => {
    if (onClick) {
      onClick(type);
    }
  };

  const renderIconType = (iconName) => {
    const IconCustom = Icons?.[iconName] || Icons.Mood;
    return <IconCustom />;
  };

  if (isTag) {
    const infoTypedefault =
      state?.eventTypes?.[
        state?.eventTypes?.findIndex(
          (typeArray) => typeArray?.id === defaultValue
        )
      ];

    if (!infoTypedefault) {
      return null; // Abort
    }

    return (
      <div
        className={[styles.itemType, styles.fullOpacity, className].join(" ")}
      >
        <div className={styles.containerItemType}>
          <div className={[styles.iconItemType, "scale"].join(" ")}>
            {renderIconType(infoTypedefault?.icon)}
          </div>
          <span className={styles.labelItemType}>{infoTypedefault?.label}</span>
        </div>
      </div>
    );
  }

  return (
    <button
      className={[
        styles.itemType,
        value === item?.id ? styles.fullOpacity : "",
        className,
      ].join(" ")}
      onClick={() => onEvent(item?.id)}
    >
      <div className={styles.containerItemType}>
        <div className={[styles.iconItemType, "scale"].join(" ")}>
          {renderIconType(item?.icon)}
        </div>
        <span className={styles.labelItemType}>{item?.label}</span>
      </div>
    </button>
  );
};

export default ItemTypeEvent;
