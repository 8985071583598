import api from "./api";

const getMyTeam = async (eventUid) => {
  try {
    const response = await api.post("/admin/getMyTeam", { eventUid });
    if (response.ok && response.status === 200) {
      return response.data || {};
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw e;
  }
};

const addUserMyTeam = async (payload) => {
  try {
    const response = await api.post("/admin/addUserMyTeam", payload);
    if (response.ok && response.status === 200) {
      return response.data || {};
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw e;
  }
};

const deleteUserMyTeam = async (payload) => {
  try {
    const response = await api.post("/admin/deleteUserMyTeam", payload);
    if (response.ok && response.status === 200) {
      return response.data || {};
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw e;
  }
};

const getMySales = async (eventUid) => {
  try {
    const response = await api.post("/admin/getMySales", { eventUid });
    if (response.ok && response.status === 200) {
      return response.data || {};
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw e;
  }
};

const validateNextTicket = async (saleUid) => {
  try {
    const response = await api.post("/admin/validateNextTicket", { saleUid });
    if (response.ok && response.status === 200) {
      return response.data || {};
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw e;
  }
};

const validateQrTicket = async (codeQr) => {
  try {
    const response = await api.post("/admin/validateQrTicket", { codeQr });
    if (response.ok && response.status === 200) {
      return response.data || {};
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw e;
  }
};

const acceptPartner = async (data) => {
  try {
    const response = await api.post("/admin/acceptPartner", data);
    if (response.ok && response.status === 200) {
      return response.data || {};
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw e;
  }
};

const verifyOAuthMP = async (body) => {
  try {
    const response = await api.post("/admin/verifyOAuthMP", body);
    if (response.ok && response.status === 200) {
      return response.data || {};
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw e;
  }
};

const uploadNewTickets = async (body) => {
  try {
    const response = await api.post("/admin/uploadNewTickets", body);
    if (response.ok && response.status === 200) {
      return response.data || "";
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw e;
  }
};

const getInfoMember = async (body) => {
  try {
    const response = await api.post("/admin/getInfoMember", body);
    if (response.ok && response.status === 200) {
      return response.data || "";
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw e;
  }
};

const createPromoCode = async (body) => {
  try {
    const response = await api.post("/admin/createPromoCode", body);
    if (response.ok && response.status === 200) {
      return response.data || "";
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw e;
  }
};

const getPromoCodes = async (body) => {
  try {
    const response = await api.post("/admin/getPromoCodes", body);
    if (response.ok && response.status === 200) {
      return response.data || "";
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw e;
  }
};

const deletePromoCode = async (body) => {
  try {
    const response = await api.post("/admin/deletePromoCode", body);
    if (response.ok && response.status === 200) {
      return response.data || "";
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw e;
  }
};

const getHistoryPromoCode = async (body) => {
  try {
    const response = await api.post("/admin/getHistoryPromoCode", body);
    if (response.ok && response.status === 200) {
      return response.data || "";
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw e;
  }
};

const deteleEvent = async (body) => {
  try {
    const response = await api.post("/admin/deteleEvent", body);
    if (response.ok && response.status === 200) {
      return response.data || "";
    } else {
      throw new Error(response.data);
    }
  } catch (e) {
    throw e;
  }
};

export default {
  getMyTeam,
  addUserMyTeam,
  deleteUserMyTeam,
  getMySales,
  validateNextTicket,
  validateQrTicket,
  acceptPartner,
  verifyOAuthMP,
  uploadNewTickets,
  getInfoMember,
  createPromoCode,
  getPromoCodes,
  deletePromoCode,
  getHistoryPromoCode,
  deteleEvent,
};
