import { makeStyles } from "@material-ui/styles";
import Commons from "../../../utils/styles";
import { colors } from "../../../styleguide";

const styles = makeStyles({
  lblEmpty: {
    textAlign: "center",
    color: colors.black,
    fontSize: 18,
    width: "80%",
    margin: "30px auto",
  },
  paddingList: {
    marginTop: -24,
    [Commons.smallQuery]: {
      marginTop: -12,
    },
  },
  title: {
    color: colors.themeColor,
    fontWeight: "700",
    fontSize: 36,
    margin: 0,
    paddingTop: 24,
    [Commons.smallQuery]: {
      fontSize: 30,
      margin: "0 12px",
    },
  },
  listOfProducts: {
    margin: "30px 0",
    [Commons.smallQuery]: {
      margin: "30px 12px 30px 24px",
    },
  },
  blank: {
    height: 60,
  },
  itemProductList: {
    width: "100%",
    backgroundColor: colors.white,
    boxShadow: colors.shadow,
    borderRadius: 12,
    marginBottom: 12,
  },
  headerItemProduct: {
    ...Commons.flexRow,
    padding: "6px 12px",
    borderBottom: `1px solid ${colors.grayBlack}`,
  },
  dateItemProduct: {
    fontSize: 14,
    color: colors.black,
    flex: 1,
    marginRight: 12,
    fontWeight: "600",
  },
  ctaItemProduct: {
    ...Commons.defaultButton,
    backgroundColor: colors.themeColor,
    color: colors.white,
    textAlign: "center",
    padding: "6px 30px",
    borderRadius: 12,
    fontWeight: "600",
    [Commons.smallQuery]: {
      padding: "6px 12px",
    },
  },
  bodyItemProduct: {
    ...Commons.flexRow,
    alignItems: "center",
    padding: 12,
    [Commons.smallQuery]: {
      flexDirection: "column",
    },
  },
  listItemProduct: {
    flex: 1,
    marginRight: 36,
    [Commons.smallQuery]: {
      marginRight: 0,
      width: "100%",
    },
  },
  rowItemProduct: {
    ...Commons.flexRow,
    marginBottom: 12,
  },
  imageItemProduct: {
    width: 72,
    height: 72,
    objectFit: "contain",
    [Commons.smallQuery]: {
      width: 36,
      height: 36,
    },
  },
  infoItemProduct: {
    flex: 2,
    margin: "0 12px",
  },
  nameItemProduct: {
    fontSize: 18,
    fontWeight: "600",
    color: colors.black,
    margin: 0,
    [Commons.smallQuery]: {
      fontSize: 15,
    },
  },
  typeItemProduct: {
    margin: "6px 0 0 0",
  },
  quantityItemProduct: {
    fontSize: 15,
    color: colors.gray50,
    flex: 1,
  },
  moreInfoItemProduct: {
    textAlign: "right",
    [Commons.smallQuery]: {
      ...Commons.flexRow,
      alignItems: "center",
      width: "100%",
    },
  },
  statusItemProduct: {
    color: colors.themeColor,
    fontSize: 16,
    fontWeight: "600",
    [Commons.smallQuery]: {
      fontSize: 15,
      flex: 1,
      textAlign: "left",
    },
  },
  updatedItemProduct: {
    color: colors.gray50,
    fontSize: 15,
    margin: 0,
    [Commons.smallQuery]: {
      fontSize: 13,
      flex: 1,
    },
  },
});

export default styles;
