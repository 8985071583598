import { makeStyles } from "@material-ui/core/styles";
import { colors } from "../../styleguide";
import Commons from "../../utils/styles";

const useStyles = makeStyles({
  itemListProduct: {
    display: "inline-block",
    width: 210,
    margin: "0px 18px 18px 0px",
    boxShadow: colors.shadow,
    borderRadius: 12,
    padding: 12,
    backgroundColor: colors.black,
    position: "relative",
    cursor: "pointer",
    transition: "all 0.3s",
    "&:hover": {
      opacity: 0.75,
    },
    [Commons.smallQuery]: {
      width: "75vw",
    },
  },
  image: {
    ...Commons.coverBackground,
    height: 240,
    width: "calc(100% - 2px)",
    borderRadius: 12,
    border: `1px solid ${colors.white}`,
  },
  name: {
    textAlign: "center",
    fontSize: 18,
    color: colors.white,
    margin: "12px 0 6px 0",
    fontWeight: "600",
  },
  price: {
    textAlign: "center",
    fontSize: 16,
    color: colors.white,
    margin: "6px 0 12px 0",
    fontWeight: "600",
  },
});

export default useStyles;
