import { makeStyles } from "@material-ui/styles";
import Commons from "../../utils/styles";
import { colors } from "../../styleguide";

const styles = makeStyles({
  cartContainer: {
    position: "absolute",
    top: -600,
    backgroundColor: colors.white,
    right: 6,
    borderRadius: 12,
    boxShadow: "0px 1px 12px 0px rgba(0, 0, 0, 0.3)",
    width: 600,
    padding: 12,
    maxWidth: "90%",
    zIndex: -1,
    transition: "top 0.3s",
    [Commons.smallQuery]: {
      width: "calc(100% - 36px)",
      maxHeight: "calc(100vh - 120px)",
    },
  },
  showCart: {
    top: "120%",
  },
  titleCart: {
    color: colors.black,
    fontWeight: 700,
    fontSize: 24,
    margin: "0 0 12px 0",
  },
  deleteCart: {
    ...Commons.defaultButton,
    color: colors.red,
    position: "absolute",
    top: 18,
    right: 48,
    textDecoration: "underline",
  },
  closeCart: {
    ...Commons.defaultButton,
    color: colors.black,
    position: "absolute",
    top: 14,
    right: 12,
  },
  scrollContainerCart: {
    maxHeight: 396,
    overflow: "auto",
    width: "100%",
    [Commons.smallQuery]: {
      maxHeight: "calc(100vh - 120px)",
    },
  },
  rowItemCart: {
    ...Commons.flexRow,
    alignItems: "center",
    margin: "6px 0",
  },
  imageItemCart: {
    width: 72,
    height: 72,
    objectFit: "contain",
    cursor: "pointer",
    [Commons.smallQuery]: {
      display: "none",
    },
  },
  rowInfoItemCart: {
    margin: "0 12px",
    flex: 1,
  },
  nameItemCart: {
    fontWeight: 700,
    fontSize: 18,
    margin: 0,
    color: colors.black,
    [Commons.smallQuery]: {
      fontSize: 15,
    },
  },
  typeItemCart: {
    fontSize: 12,
    margin: 0,
    color: colors.black,
  },
  actionItemCart: {
    ...Commons.defaultButton,
    color: colors.themeColor,
  },
  quantityItemCart: {
    color: colors.black,
    fontSize: 18,
    margin: "-5px 6px 0 6px",
  },
  totalItemCart: {
    fontWeight: 700,
    fontSize: 18,
    color: colors.black,
    margin: "0 0 0 18px",
    width: 90,
    textAlign: "right",
  },
  emptyLabelCart: {
    textAlign: "center",
    margin: "18px 0",
    color: colors.gray50,
    fontSize: 18,
  },
  rowSubtotalCart: {
    ...Commons.flexRow,
    borderTop: `2px solid ${colors.gray50}`,
    padding: "12px 0",
    justifyContent: "flex-end",
    marginTop: 12,
  },
  labelSubtotalCart: {
    fontWeight: 600,
    fontSize: 18,
    margin: 0,
    color: colors.black,
  },
  valueSubtotalCart: {
    fontWeight: 700,
    fontSize: 24,
    margin: 0,
    marginLeft: 18,
    color: colors.black,
  },
  submitButtonCart: {
    ...Commons.defaultButton,
    textAlign: "center",
    backgroundColor: colors.themeColor,
    borderRadius: 6,
    padding: "12px 0",
    fontSize: 12,
    fontWeight: 700,
    width: "100%",
    color: colors.white,
  },
});

export default styles;
