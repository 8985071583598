import React from "react";
import { useTranslation } from "react-i18next";
import * as Icons from "@material-ui/icons";

import { Store } from "../../hooks/main_store";
import pageStyles from "./styles";
import Item from "./item";

const TypesEvent = ({
  onClick = () => {},
  value = "",
  align = "center",
  hideAll = false,
}) => {
  const { t } = useTranslation();
  const { state } = React.useContext(Store);
  const styles = pageStyles();

  const alignStyles = {
    textAlign: align,
  };

  const onEvent = (type) => {
    if (onClick) {
      onClick(type);
    }
  };

  const renderIconType = (iconName) => {
    const IconCustom = Icons?.[iconName] || Icons.Mood;
    return <IconCustom />;
  };

  return (
    <div className={styles.rowTypes} style={alignStyles}>
      {hideAll ? null : (
        <button
          className={[
            styles.itemType,
            value === "" ? styles.fullOpacity : "",
          ].join(" ")}
          onClick={() => onEvent("")}
        >
          <div className={styles.containerItemType}>
            <div className={[styles.iconItemType, "scale"].join(" ")}>
              {renderIconType("GroupWork")}
            </div>
            <div className={styles.labelItemType}>{t("commons.all")}</div>
          </div>
        </button>
      )}
      {state?.eventTypes?.map((type) => (
        <Item
          key={type?.id}
          {...{
            value,
            onClick: onEvent,
            item: type,
          }}
        />
      ))}
    </div>
  );
};

export default TypesEvent;
