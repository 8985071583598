const platformTax = ["12%", "8%"];

const texts = {
  translation: {
    commons: {
      unknowError: "Error desconocido",
      email: "Correo electrónico",
      password: "Contraseña",
      cancel: "Cancelar",
      accept: "Aceptar",
      all: "Todos",
      format: "Formato",
      yesConfirm: "Si, confirmo",
      agree: "De acuerdo",
      confirm: "Confirmar",
      imAgree: "Estoy de acuerdo",
      artists: "Artistas",
      description: "Descripción",
      promoters: "Promotores",
      price: "Precio",
      availables: "Disponibles",
      quantity: "Cantidad",
      buy: "Comprar",
      name: "Nombre",
      lastName: "Apellido",
      numId: "Nº Identificación",
      ready: "Listo",
      no: "No",
      yes: "Si",
      phone: "Teléfono",
      cellphone: "celular",
      download: "Descargar",
      free: "Gratis",
      date: "Fecha",
      january: "Enero",
      february: "Febrero",
      march: "Marzo",
      april: "Abril",
      may: "Mayo",
      june: "Junio",
      july: "Julio",
      august: "Agosto",
      september: "Septiembre",
      october: "Octubre",
      november: "Noviembre",
      december: "Diciembre",
      continue: "Continuar",
      noSupportVideos: "Tu navegador no soporta videos",
      ok: "OK",
    },
    components: {
      addTicketsList: {
        name: "Nombre",
        lastName: "Apellido",
        numId: "Documento",
        email: "Correo",
        typeTicket: "Tipo ticket",
        quantity: "Cantidad",
        alertUploadInfo: "Alerta carga información",
        rememberUploadInfo:
          "Recuerda que al subir información de ventas se ejecutará el proceso de generación de boletas y envío de QRs para cada usuario, si deseas revertir el proceso se debe hacer manualmente. (RECUERDA: El límite por archivo son 100 tickets, si lo supera se omitirán los demás. Evite duplicación de registros). Puedes descargar el formato de excel para ordenar tus compras.",
        errorUploadList: "Error para subir lista: ",
        confirmBuys: "Confirmar compras",
        rememberConfirmBuys:
          "Recuerda que no puedes revertir este proceso al agregar las nuevas compras de la lista, ¿estas de acuerdo?",
        firstUploadFile: "Primero sube un archivo de Excel",
        cancelProcess: "Cancelar proceso",
        confirmCancelProcess:
          "¿Estas de acuerdo en eliminar las compras agregadas y no subirlas de forma permanente? Esta acción no se puede revertir.",
        thereSomeErrors:
          "Hay {{countErrors}} registro{{multiple}} con errores, revisa nuevamente tu archivo.",
        noFoundValidTicket: "No se encontró ninguna entrada válida",
        errorUploadExcel: "Error para subir Excel: ",
        invalidFile: "Archivo inválido",
        noAddSellsYet: "No se han agregado nuevas compras",
        addTickets: "Agregar tickets",
        useOurFormat:
          "Utiliza nuestro formato de excel para subir tus compras (desde 1 a 100 tickets por archivo), si hay dudas el archivo viene con instrucciones.",
        uploadExcel: "Subir Excel",
        maxPerOne: "Máximo 100 tickets c/u",
        pendingConfirm: "Pendientes por confirmar",
        thisIsPreview:
          "Esta es una previsualización de los tickets que has subido pero que no se han confirmado, cuando desees terminar no olvides confirmar con en botón en la parte superior derecha de la tabla.",
      },
      authDialog: {
        mustFillAllFields: "Debes diligenciar todos los datos.",
        reviewYourInbox:
          "Revisa tu correo y sigue las instrucciones para verificarlo y comenzar a realizar compras.",
        reviewInboxInstructions: "Revisa tu correo y sigue las instrucciones.",
        register: "Registro",
        logIn: "Iniciar sesión",
        onClickAccept: 'Al hacer clic en "Crear cuenta" acepto las',
        useConditions: "Condiciones de Uso",
        the: "la",
        privacyPolicy: "Política de Privacidad",
        receiveNotifications: "recibir novedades y promociones.",
        forgotPassword: "¿Olvidaste tu contraseña?",
        createAccount: "Crear cuenta",
        enterAccount: "Ingresar",
        alreadyHaveAccount: "¿Ya tienes una cuenta? Ingresa aquí",
        createNewAccount: "Crear una nueva cuenta",
        recoverPassword: "Recuperar contraseña",
        enterEmailSendLink:
          "Ingresa tu correo electrónico previamente registrado en nuestra plataforma para enviarte un link con las respectivas instrucciones.",
      },
      bannerEvent: {
        readInfoBeforeBuy:
          "Lee atentamente la siguiente información antes de adquirir tus entradas.",
        eventIsFor: "El evento es para",
        forOld18: "mayores de +18 años",
        forAllAges: "para todas las edades",
        startDate: "Inicio: ",
        endDate: "Cierre: ",
        secretLocation: "Secret location",
        organizerVerified:
          "Organizador verificado para la confianza de nuestros usuarios.",
        organizerNoVerified:
          "Organizador no verificado, realiza la compra si confías que es auténtico.",
        organizer: "Organizador",
        visit: "Visitar",
        cancelledEvent: "EVENTO CANCELADO",
        messageCancelled:
          "Por motivos externos a la plataforma, el organizador ha cancelado esta presentación. Si compraste tickets revisa la información en",
        myShopping: '"Mis compras".',
      },
      bannerHome: {
        easy: "fácil",
        innovative: "innovadora",
        affordable: "asequible",
        secure: "segura",
        friendly: "amigable",
        weOffer: "Ofrecemos la forma",
        wayManageEvents: "para gestionar tus eventos",
        andCentralize: "Y centralizamos información del entretenimiento",
        free: "¡GRATIS!",
        addEvent: "Crear mi evento",
        viewEvents: "Ver eventos",
      },
      contentEvent: {
        viewWebsite: "Ver sitio web",
        cannotBook:
          "La reserva de la reventa ya no puede ser realizada porque no se encuentra disponible.",
        multiplePayments: "Múltiples pagos",
        pendingMultiplePayments:
          "Te notificamos que debes realizar el pago en {{length}} transacciones diferentes porque cada ticket debe ser comprado por separado. RECUERDA: Cada vez que termines una transacción, debes cerrar el cuadro del pago y seleccionar el siguiente pago que tengas pendiente. \n\nCualquier cosa puedes comunicarte con soporte técnico al correo help@fravents.com",
        transationNoAvailable: "Esta transacción ya no está disponible",
        confirmSelection: "Confirmar selección",
        confirmPayResell:
          "{{name}} ¿Deseas adquirir {{quantity}} entrada{{multiples}} en reventa? Serás redirigido a la plataforma de pagos y tendrás máximo 10 minutos para realizar la compra o se eliminará tu reserva.",
        completeDataTry: "Completa los datos e intenta de nuevo",
        enteredEmailBadFormat:
          "El correo ingresado tiene un formato incorrecto",
        mustSelectOffer: "Debes seleccionar alguna oferta para continuar",
        beforeContinue: "Antes de continuar ...",
        agreeBuyResell:
          "¿Estas de acuerdo en comprar las siguientes boletas en reventa? Recuerda que no se aceptan reembolsos, para mayor información leer nuestros [[Términos y Condiciones]].\n{{textSells}}\n\nTotal compra con servicio incluído: {{totalSells}}",
        yet: "Aún",
        havePendingTransactions: "tienes transacciones pendientes",
        retakeProcessFinish:
          "por terminar, retoma tu proceso y finaliza tus compras 🤓",
        resum: "Reanudar",
        anyUserPublish:
          "Ningún usuario ha realizado alguna publicación de reventa 😥",
        alreadyFollowEvent: "Ya sigues este evento",
        youCanCancelSuscription:
          ", puedes cancelar tu suscripción para dejar de recibir notificaciones.",
        comeOnFollow: "Dale seguir",
        thisEventReceiveNotifications:
          " a este evento y recibirás notificaciones cuando hayan nuevas ofertas.",
        termsConditionsEvent: "Términos y condiciones del evento",
        conditionEventFravents:
          "Fravents solo se responsabiliza por los temas relacionados con soporte técnico en la compra y venta de boletería. Fravents no se hace responsable de la ejecución o cualquier tema relacionado con este evento.",
        ticketPrices: "Precios tickets",
        map: "Mapa",
        sales: "🔥 Ofertas 🔥",
        ticketsOnListMembersResell:
          "Los tickets en este listado son de usuarios propios de Fravents, por lo tanto generamos un espacio seguro y rápido para la reventa de boletaría. No se aceptan reembolsos. Para mayor información leer los Términos y Condiciones.",
        resellOffers: "Reventa de tickets ofertados por usuarios",
        taxPlatform: "+ tarifa de servicio de la plataforma",
        stopFollowOffers: "Dejar de seguir ofertas",
        followOffers: "Seguir ofertas",
        moreOffers: "Más eventos del organizador",
        completeSellData: "Completa los datos de compra",
        toContinueFillData:
          "Para continuar con la compra de {{quantity}} entrada{{multiples}} en reventa, por favor ingresa los siguientes datos y al confirmar se reservará tu pedido.",
        sendDataAcceptTerms:
          "Al enviar los datos, aceptas los [[Términos y Condiciones]] de la compra. Para brindarles la mejor seguridad y confianza a nuestros usuarios, los pagos se realizan por medio de la plataforma Mercado Pago, por lo tanto, FRAVENTS no almacena ni manipula ninguna información sensible de los métodos de pagos de los usuarios.",
        sellsInSale: "🔥 Ofertas en reventa 🔥",
        selectTransaction: "Selecciona la transacción que deseas realizar",
      },
      features: {
        participants: "🎟 Participante 🎟",
        participantsDescription:
          "Tienes interés en asistir a un evento de nuestra plataforma y adquirir tus tickets.",
        viewEvents: "Ver eventos",
        reseller: "🤑 Revendedor 🤑",
        resellerDescription:
          "Adquiere tickets en nuestra plataforma y véndelos a un precio diferente de la manera más segura y al instante.",
        goSell: "Ir a vender",
        promoter: "🚀 Promotor 🚀",
        promoterDescription:
          "Busca eventos en nuestra plataforma para promoverlos en tus redes sociales y generar créditos.",
        promoteNow: "Promover ahora",
        eventOrganizer: "😎 Organizador 😎",
        eventOrganizerDescription:
          "Produces eventos y deseas adquirir servicios para la venta y gestión de boletería online.",
        moreInfo: "Más información",
        viewVideoTutorial: "Ver video tutorial",
      },
      footer: {
        begin: "Inicio",
        profile: "Perfil",
        myShopping: "Mis compras",
        siteMap: "Mapa del sitio",
        privacyPolicy: "Política de privacidad",
        termsConditions: "Términos y condiciones",
        organizersNotice: "Aviso Organizadores",
        buyersNotice: "Aviso Compradores",
        tcResale: "T&C Reventa",
        tcMarketplace: "T&C Marketplace",
        dataAuthorization: "Autorización de datos",
        sicLink: "Ir a la SIC",
        contact: "Contacto",
        faq: "Preguntas frecuentes",
        madeIn: "Hecho en Colombia",
        wantPublishEvent: "Quiero publicar un evento",
        communityFraventsPartners: "Comunidad de socios Fravents",
      },
      header: {
        titleWantSell: "¿Deseas vender tus productos en Fravents?",
        descriptionWantSell:
          "Antes de continuar, tu cuenta debe pasar por un proceso de verificación para seguir con el proceso. Este paso es necesario para confiar en que vas a vender productos reales y evitar estafas a nuestros usuarios. Envíanos un correo a help@fravents.com con la mayor cantidad de información relevante de tu marca y nuestro equipo te ayudará en el proceso.",
        logIn: "Ingresar",
        events: "Eventos",
        myShopping: "Mis compras",
        admin: "Administrador",
        myEvents: "Mis eventos",
        shop: "Mi tienda",
        scanQr: "Escaner de QRs",
        hi: "Hola",
        publishEvent: "Publicar mi evento",
        profile: "Perfil",
        verifyHere: "Verifica aquí",
      },
      incognitoAlert: {
        forLiveExp: "Para vivir toda la experiencia",
        noUseIncognito: "no uses la navegación incógnita",
      },
      initialSettingsModal: {
        infoUpdated: "Información actualizada",
        selectAll: "Selecciona todas las preferencias",
        beforeContinue: "Antes de continuar, selecciona tus preferencias",
        spanish: "Español 🇨🇴",
        english: "English 🇺🇸 (beta)",
        language: "Lenguaje",
        currency: "Moneda",
        continue: "Continuar",
      },
      itemEvent: {
        alreadyFollow: "Ahora sigues este evento",
        noFollow: "Ya no sigues este evento",
        errorRefresh: "Error para actualizar: ",
      },
      loading: {
        poweredBy: "Powered by Fravents",
        sorryThereProblem: "Sorry, there was a problem loading the page.",
      },
      navigationEvent: {
        team: "Equipo",
        sells: "Ventas",
        validate: "Validar",
        edit: "Editar",
        delete: "Eliminar",
        deleteEvent: "Eliminar evento",
        doYouAgreeDeleteEvent:
          "¿Estas de acuerdo en eliminar de forma permanente el evento {{event}}?",
        eventSuccessDelete: "El evento se ha eliminado correctamente.",
        youCanNotDeleteEvent:
          "No puedes eliminar un evento con tickets creados. Contáctanos si deseas eliminarlo definitivamente.",
        errorDeleteEvent: "Hubo un error al eliminar el evento: ",
      },
      qrScanModal: {
        validateCodeCompleted: "Validación de código QR completada",
        errorValidateInfo: "Error al validar información: ",
        noAccessCamera:
          "No hay acceso a tu cámara, revisa los permisos e intenta de nuevo o con otro navegador.",
        errorAccessCamera:
          "Error al acceder a tu cámara, revisa los permisos o intenta en otro navegador. ({{errorCamera}})",
        scanCodeInSquare:
          "Coloca el código QR dentro del recuadro, si presentas problemas te recomendamos estar en un lugar iluminado, revisar los permisos de la cámara o usar un navegador diferente.",
        close: "Cerrar",
        transactionInfo: "Información de la transacción",
        codeInvalid: `Este código QR no es válido, por favor verificar la información. [{{error}}]`,
        products: "Productos:\n",
        ticketType: "Tipo ticket:",
      },
      shoppingCart: {
        deleteAllProducts: "Eliminar todos los productos",
        areAgreeDelete:
          "¿Estas de acuerdo en quitar todos los items de tu Shopping Cart?",
        shoppingCart: "Shopping Cart",
        deleteAll: "Eliminar todo",
        noYetAddAnyProduct: "Aún no has agregado ningún producto.",
        goTopay: "IR A PAGAR",
      },
      statsCounter: {
        generatedTickets: "Tickets generados",
        registeredUsers: "Usuarios registrados",
        totalSells: "Total ventas",
      },
      templateEventSelectorModal: {
        freeList: "Listas (Gratis)",
        freeListDescription:
          "Cualquier usuario con acceso al evento puede registrarse sin ningún costo.\n\nIdeal para control de entrada de bares, eventos gratuitos, restaurantes, ...",
        sellByStages: "Venta por etapas",
        sellByStagesDescription:
          "Defines diferentes precios de boletería y se irán habilitando cada vez que se vaya agotando la etapa anterior.\n\nDiseñado para eventos que tienen precios dinámicos según su demanda.",
        withoutBlockers: "Sin restricciones",
        withoutBlockersDescription:
          "Todos los precios de boletería estarán habilitados al tiempo para los usuarios con acceso a la compra.\n\nPara eventos con precios fijos y disponibles desde su publicación.",
        selectFormat: "Selecciona el formato de tu evento",
        indentifyFeatures:
          "Identifica las características de cada uno y escoge el que mejor se acople a tus objetivos.",
        continue: "Continuar",
        lessSelectOption: "Falta seleccionar una opción",
      },
      tutorial: {
        cookiesPolicy: "Política de Cookies",
        ourPlatformUseCookies:
          "Nuestra plataforma web utiliza Cookies para asegurar que nuestros usuarios tengan la mejor experiencia durante su sesión. Cuando haces uso de nuestra plataforma aceptas nuestra política",
        readMore: "Saber más",
        welcome: "¡Bienvenide a Fravents!",
        wecolmeMessage:
          "Hola, yo soy <bold>D.</bold> y estaré aquí para ayudarte. Aquí podrás encontrar eventos de tu interés, información relevante de nuestra plataforma y como ser parte de ella <bold>¿Quieres publicar tus propios eventos?</bold> Es <bold>gratis</bold> para todes",
        lookAllEvents: "Mira los eventos disponibles",
        findEventsFinder:
          "Encuentra el evento al que desees asistir en nuestra selección de destacados o por medio de nuestro buscador",
        ticketsOnHands: "¡Tus tickets a la mano!",
        ticketsOnHandsDescription:
          "Aquí puedes ver la lista completa de tus entradas, los eventos que has guardado en tu historial y las compras de productos. Recuerda que <bold>puedes vender</bold> o <bold>transferir tickets</bold> al instante a cualquier contacto",
        thereAreTickets: "Aquí estan tus entradas",
        thereAreTicketsDescription:
          "Cada entrada tiene un QR único y dínamico que debes presentar el día del evento. También esta la información relevante del evento en cada entrada, puedes vender o transferir cada una a otro contacto",
        hereBeginAll: "Todo comienza aquí",
        hereBeginAllDescription:
          "Utiliza nuestro creador de eventos e ingresa toda la información necesaria y publicalo en <bold>4 sencillos pasos</bold>. También tienes un previsualizador para revisar como va quedando tu evento",
        haveAllPower: "¡Tienes el poder!",
        haveAllPowerDescription:
          "Hemos trabajado fuerte para que la plataforma la puedas gestionar tu, como la <bold>creación</bold> de los eventos, estadísticas de <bold>ventas</bold>, el equipo de <bold>promotores</bold> y de <bold>logística</bold>, entre otras más funcionalidades",
        timeValidateTickets: "Hora de validar tickets",
        timeValidateTicketsDescription:
          "Ofrecemos dos formas de hacerlo. La primera es escanear con tu dispositivo los código QR, damos la tranquilidad que nuestro sistema no permitirá códigos reusados, falsificados o inventados, tenlo por seguro 😉. Y la segunda es por medio de un buscador para validar entradas de forma manual, una por una.",
        buildTeam: "Arma tu equipo",
        buildTeamDescription:
          "Aquí puedes dar permisos a <bold>colaboradores, promotores y logística</bold> para que puedan promocionar tu evento o validar tickets, respectivamente. Solo recuerda que ya deben estar registrados para agregarlos",
        dataOnHand: "¡Estadísticas a la mano!",
        dataOnHandDescription:
          "Centralizamos los datos más relevantes de tu evento para darle seguimiento cuando y donde quieras, como estado de ventas, ingresos, número de tickets generados, entre otras funcionalidades. Recuerda que puedes <bold>agregar tickets de forma manual</bold>, también puedes descargar datos en un Excel y <bold>crear códigos promocionales</bold>",
        perzonalizeProfile: "Personaliza tu perfil",
        perzonalizeProfileDescription:
          "Completa la mayor cantidad de datos para que tu perfil sea el más completo y puedas dar una buena impresión a los usuarios. También puedes <bold>personalizar el color del tema</bold>, participar en nuestro <bold>programa de referidos</bold> y configurar los datos como",
        wantBePartner: "Queremos que seas Partner",
        wantBePartnerDescription:
          "Nos agrada que desees ser parte de nuestra comunidad y utilizar nuestros servicios, solo lee este contenido y aclara tus dudas antes de comenzar. Recuerda que estamos para ayudarte, escríbenos cualquier cosa",
        thisEventWaits: "Este evento te espera",
        thisEventWaitsDescription:
          "Puedes ver toda la información que preparó el organizador sobre su evento, solo debes decidir si deseas ir y cual ticket adquirir. Los medios de pago varían según el país pero en general son <bold>Mercado pago, débito, efectivo y crédito</bold>",
        ourStepStep: "Nuestro paso a paso",
        ourStepStepDescription:
          "En este contenido puedes encontrar un tutorial más dinámico, completo y desglozado para utilizar nuestra plataforma como partner. Cualquier cosa nos puedes escribir",
        finishPayment: "Aquí termina la compra",
        finishPaymentDescription:
          "Gracias por utilizar nuestros servicios, si tienes alguna inquietud con tu compra pueda que se tarde unos minutos en verse reflejada en tu perfil o puedes escribirnos para darte un soporte personalizado",
        bePartCommunity: "Ser parte de la comunidad",
        bePartCommunityDescription:
          "Te agradecemos tu interés en ser parte de nuestra comunidad y vincular tu cuenta de Mercadopago, cuando ya todo esté completo podrás vender de forma inmediata en nuestra plataforma y recibir tus ganancias al instante",
        contactInfo: "Información de tu contacto",
        contactInfoDescription:
          "Revisa la información de referidos o tickets escaneados de tu contacto para que puedas darle un seguimiento más puntual de su actividad",
        partnerInfo: "Información de nuestro Partner",
        partnerInfoDescription:
          "Bienvenide a la página de nuestro partner, donde expone sus datos relevantes de contacto, centraliza sus eventos y productos que ofrece al público, y puedes compartir su información",
        faqs: "Preguntas frecuentes",
        faqsDescription:
          "Encuentra las respuestas a tus dudas sobre como utilizar nuestra plataforma, solucionar algún caso o entender nuestros procesos",
        newPlugin: "Nuevo plugin web!",
        newPluginDescription:
          "Integra nuestro plugin en tu sitio web para que puedas vender desde ahí",
        buyProducts: "Compra de productos",
        buyProductsDescription:
          "Paga por los productos de cada vendedor para reclamar en su punto de venta o recibir un envío",
        createProduct: "Crear producto",
        createProductDescription:
          "Ingresa toda la información de tu producto para luego ser enviado a revisión y, posteriormente, a su publicación",
        shoppingCart: "Shopping Cart",
        shoppingCartDescription:
          "Revisa los productos que deseas comprar, ingresa códigos de descuento y procede al pago en unos pocos clics.",
        sellInfo: "Información de compra",
        sellInfoDescription:
          "En este espacio puedes revisar los detalles de la compra y poder revisar mensajes y notificaciones sobre el estado de envío o solucionar dudas.",
        superViseSells: "Supervisa las ventas",
        superViseSellsDescription:
          "Al ser parte del equipo de supervisores de la tienda, tienes acceso a los reportes y gestionar las compras de cada cliente (cambiar estados de envíos y entrega, y responder mensajes)",
        superViseSellsAdminDescription:
          "Hola Super Admin, aquí puedes gestionar tu equipo de la tienda, los productos disponibles, tienes acceso a los reportes y gestionar las compras de cada cliente (cambiar estados de envíos y entrega, y responder mensajes)",
        partnerProgram: "Programa de socios",
        partnerProgramDescription:
          "Mira la propuesta que tenemos para que generes ingresos con nosotros. Escríbenos si quieres que te contemos más.",
        legalDoc: "Documentos legales",
        legalDocDescription:
          "Aquí tienes el documento legal solicitado, queremos siempre mantener la transparencia con nuestros usuarios. Cualquier duda escríbenos!",
        infoOurPartner: "Información de nuestro Partner",
        infoOurPartnerDescription:
          "Bienvenide a la página de nuestro partner, donde expone sus datos relevantes de contacto, centraliza sus eventos y productos que ofrece al público, y puedes compartir su información",
        eventsOurPartner: "Eventos de nuestro Partner",
        eventsOurPartnerDescription:
          "Bienvenide a la lista de eventos de nuestro partner, donde puedes adquirir directamente tus tickets en unos sencillos pasos",
        tutorialNoFound: "Tutorial no encontrado",
        tutorialNoFoundDescription:
          "Discúlpanos no encontramos información de ayuda para esta página",
        helpYou: "te ayuda",
      },
    },
    constants: {
      titlePublishEvent: "Publicar evento",
      bodyPublishEvent:
        "Puedes crear todos los eventos que quieras, sin limite de asistentes y gestionarlos desde tu dispositivo, sin intermediarios.",
      titleCreateEvent: "Crear tickets",
      bodyCreateEvent:
        "Si vendes tus entradas fuera de la plataforma puedes agregar manualmente las compras y generar los tickets para tus usuarios de forma automatizada.",
      titleScanQr: "Escanear QR",
      bodyScanQr:
        "Nuestros tickets tienen un QR único y dinámico, que se pueden escanear desde cualquier dispositivo en nuestra plataforma, sin complicaciones ni apps adicionales.",
      titleManagePromoters: "Gestionar promotores",
      bodyManagePromoters:
        "Agrega usuarios a tu equipo para que tengan un link único de referido por cada evento y así puedas ver los registros de ventas de cada uno.",
      titleTeamLogistics: "Equipo logística",
      bodyTeamLogistics:
        "Delega los permisos a los usuarios de tu equipo que te ayudarán en el proceso de escanear y validar tickets.",
      titleAnalytics: "Analíticas",
      bodyAnalytics:
        "Cada evento tiene un dashboard de ventas en donde centralizamos toda la información con gráficas y datos relevantes al alcance de tu mano.",
      titleWebsite: "Página web",
      bodyWebsite:
        "Al crear tu perfil se te asigna tu propia página de perfil con tu información, redes sociales y tienda virtual con una url personalizada.",
      titleEarnNow: "Ganancias al instante",
      bodyEarnNow: `Si deseas vender boletas debes vincular tu cuenta de Mercadopago y recibirás tu dinero de cada compra al instante (Promedio de comisión: ${platformTax[0]}).`,
    },
    pages: {
      admin: {
        confirmPartner: {
          messageConfirmPartner:
            "Inscripción correctamente terminada. Gracias por vincular tu cuenta, en pocos minutos quedará configurada tu pasarela de pagos en nuestra plataforma. Cualquier duda o inquietud escribir a help@fravents.com",
          errorConfirmPartner: "No se pudo realizar la acción requerida.",
          returnHome: "Volver a la página principal",
        },
        contactPublish: {
          createTypeEvent:
            "Crea el evento del tipo que tu quieras, esta plataforma es para tod@s.",
          manageDifferentEventTypes:
            "Maneja  diferentes tipos de eventos: Público, Oculto y Privado.",
          enterPlatformAdmin:
            "Accede a la plataforma de Administrador para gestionar tus eventos.",
          linkMercadoPagoAccount:
            "Vincula tu cuenta de Mercado Pago para vender tus boletas y recibir el dinero por medio de nuestra plaforma.*",
          seeAnalyticPlatform:
            "Visualiza en la plataforma de analíticas toda la información de tus ventas y entradas.",
          managePromotersTeam:
            "Gestiona tu equipo de promotores para que puedan promocionar tu evento de la mejor manera y tu puedas hacer el seguimiento de sus ventas.",
          delegateLogictisTeam:
            "Delega tu equipo de Logística para que puedan escanear los códigos QR y verificar entradas el día de tu evento.",
          keepInfoCentralized: "Mantén tu información centralizada y segura.",
          continueWork:
            "... Y seguimos trabajando para ofrecer cada vez más funcionalidades!",
          includeAllPlan: "Incluye todo lo del Plan free.",
          clientAssumeTax:
            "Puedes configurar que este valor lo asuma el cliente al momento de su compra.",
          withoutSuscription:
            "Sin suscripciones ni clausulas de permanencia, cero cobros extraños o letra pequeña.",
          linkMPaccountEarn:
            "Vincula tu cuenta de Mercadopago y recibe tus ganancias al instante.",
          taxIncludeAll: `La tarifa del ${platformTax[0]} incluye la comisión de Mercadopago y Fravents, el resto es tuyo.`,
          offerPaymentMethods:
            "Ofrecemos métodos de crédito, débito, transferencias bancarias y cuentas Mercadopago.",
          sellTicketsWithouLimit:
            "Vende tus tickets sin limite de aforo ni precio. *",
          youHaveOwnWebShop:
            "Tienes tu propia tienda virtual con tu página web de tu perfil y productos.",
          sellAnyProduct:
            "Puedes vender cualquier tipo de producto de tu marca como merchandising y demás. *",
          step: "Paso",
          createAccount: "Crea tu cuenta",
          simpleSteps:
            "En unos sencillos pasos agrega tus datos, verifica tu cuenta y podrás disfrutar de todas nuestras funcionalidades.",
          setUpEvent: "Configura tu evento",
          addNeededInfo:
            "Agrega la información necesaria para crear y publicar tu evento para que comiences a gestionarlo.",
          manageTeam: "Gestiona tu equipo",
          addPromotersLogistics:
            "Puedes agregar los promotores y logística autorizados para gestionar entradas del evento con nuestra seguridad por medio de código QR.",
          supervise: "Supervisa",
          ourPlatformAnalytics:
            "Con nuestra plataforma de analíticas podrás hacerle seguimiento real al estado de tu evento y entradas, viendo toda la información asegurada en nuestra base de datos.",
          enjoyRepeat: "¡Disfruta y repite estos pasos!",
          weWantBest:
            "Queremos que tod@s quienes usen nuestra plataforma puedan aprovechar mejor la experiencia de los eventos con nuestra ayuda. Eres libre de crear los eventos que quieras mientras cumplan nuestros",
          termsConditions: "Términos y condiciones",
          errorSaveParnet: "Error para guardar partner: ",
          beforeContinue: "Antes de continuar ...",
          mustAcceptTerms:
            "Debes aceptar nuestros [[Términos y Condiciones]] para confirmar que harás un buen uso de la plataforma y de su información, de lo contrario se suspenderá tu cuenta y se podrían tomar medidas legales. FRAVENTS no se hace responsable de como le des manejo a tu evento y nos libras de cualquier conflicto de la realización del mismo, solo queremos brindar una herramienta de servicios para optimizar ciertos procesos. ¿Aceptas nuestros Términos y Condiciones?",
          noLater: "No, mejor luego",
          yesAccept: "Si, acepto",
          promoterCode: "Código de promotor referido",
          promoterCodeInfo:
            "Si tu proceso ha sido guiado por uno de nuestros promotores autorizados, por favor ingresa el código de referido que te compartió para que tus beneficios sean activados. Si no tienes ninguno omite este campo.",
          viewAdminAccount: "Ver mi cuenta de Administrador",
          createParnertAccount: "Crear mi cuenta Partner",
          mustVerifyEmail: "Debes verificar tu correo, ve a tu perfil aquí.",
          logInContinue: "Inicia sesión para continuar",
          publishEvent: "Publicar mi evento",
          hiThanks:
            "Hola! Gracias por tu interés en usar nuestros servicios. Queremos ofrecer la mejor plataforma para gestion de eventos y al alcance de la mano de cualquier persona. Por lo tanto, a continuación te mostramos los beneficios de ser parte de nuestra comunidad y lo que ofrecemos sin costos extraños o sobrevalorados. Cualquier inquietud o comunidado, nos puedes escribir a",
          withBriefMessage:
            "con un breve mensaje y datos de contacto para que un agente de nuestro equipo se comunique contigo.",
          bestRegards: "Cordialmente, Equipo de FRAVENTS.",
          lookFuntionalities: "Mira unas de las funcionalidades más relevantes",
          manageTickets: "Gestiona los tickets como desees",
          manual: "Manual",
          ifSellOut:
            "Si vendes por fuera de Fravents, ofrecemos un servicio de creación de tickets manualmente donde solo debes subir un archivo Excel con los datos de cada uno para que la misma plataforma los genere y envíe a cada usuario.",
          refer: "Referido",
          allMemberHaveLink:
            "Todo integrante de tu equipo de Promotores del evento tendrá un link de referido con el cual podrás hacer seguimiento de las ventas que genere cada promotor. Si tu evento es privado, solo podrán adquirir entradas por medio de un link de referido.",
          automatic: "Automático",
          noWorryAboutProcess:
            "No te preocupes por todo el proceso de compra y asignación de tickets, está todo automatizado para que te desentiendas del proceso y solo te concentres en tus métricas que te ofrecemos.",
          stepByStepAccount: "Paso a paso para crear tu evento",
          ourPlans: "Nuestros planes",
          freePlan: "Plan free",
          ourCommunityIsUnique:
            "Nuestra comunidad es una sola, por eso creemos que no deben existir planes que limiten el alcance de nuestro proyecto. Por lo tanto, ofrecemos nuestro plan de:",
          zeroFree: "$0 = GRATIS",
          isYours: "¡Ya es tuyo!",
          payedplan: "Plan pago",
          forInterested:
            "Para los interesados en realizar ventas por medio de nuestra plataforma, ofrecemos pagos al instante con un porcentaje promedio de comisión de:",
          aproxTax: `${platformTax[0]} aprox.*`,
          contactUs: "Contáctanos",
          ifSellInOurPlatform: `* Si se vende por medio de nuestra plataforma de pagos, se realizará un descuento apróximadamente del ${platformTax[0]} por transacción para cubrir gastos de impuestos, pasarela de pagos y cobros operativos (Estos gastos pueden variar dependiendo del método de pago que escojan los usuarios).`,
        },
        detailMember: {
          emptyRegisters: "No hay registros por mostrar",
          withoutEmail: "Sin email",
          refer: "Referido",
          scaned: "Escaneado",
          errorGetInfo: "Error para obtener información: ",
          history: "Historial",
          refers: "Referidos",
          scaneds: "Escaneados",
        },
        eventForm: {
          personalData: "Datos generales",
          data: "Datos",
          contentAndArtists: "Contenido y artistas",
          content: "Contenido",
          pricesAndTc: "Precios y T&C",
          prices: "Precios",
          configuration: "Configuración",
          mustLetOneCoin: "Debes dejar al menos una moneda habilitada",
          enterAllData:
            "Ingresa todos los datos obligatorios (*) para continuar",
          coordsHasFormat: "Las coordenadas tienen un formato incorrecto",
          priceMustMajor: "El precio debe ser mayor a $1000 COP",
          addPriceUsd: "Agrega el precio en USD",
          nameMustBeDif: "El nombre debe ser diferente a los demás",
          enterAllDataPrice: "Ingresa todos los datos del precio",
          linkMustHaveFormat:
            "El link debe tener el formato https (Ej: https://fravents.com)",
          enterTitle: "Ingresa el Título",
          toEmpty: "Vaciar",
          soldOut: "Agotado",
          availableQuantity: "Cantidad disponible",
          available: "disponibles",
          availablePrice: "Precio disponible",
          nextToSell: "Próximo a la venta",
          categoryName: "Nombre categoría",
          copPrice: "Precio $ COP",
          usdPrice: "Precio $ USD",
          quantity: "Cantidad",
          inYourProfileLink: "En tu perfil puedes vincular tus cuentas",
          linkAdded: "LINK AGREGADO",
          addNewArtist: "Agregar nuevo artista (Foto, Nombre y Link)",
          urlPhoto: "URL foto",
          successCreateEvent: "Evento creado correctamente",
          errorCreateEvent: "Error para crear evento",
          successSaveEvent: "Evento guardado correctamente",
          errorSaveEvent: "Error para guardar evento",
          saveEvent: "Guardar evento",
          areYouSureSaveEvent:
            "¿Esta de acuerdo en que ha diligenciado toda la información para guardar el evento?",
          eventName: "Nombre del evento",
          whatIsEventName: "Cómo se llama tu evento",
          poster: "Poster / Banner",
          maxFileSize:
            "El tamaño máximo permitido para cargar cada imagen es de 5Mb",
          dateHourStart: "Fecha y hora de inicio",
          dateHourEnd: "Fecha y hora de cierre",
          select: "Selecciona",
          address: "Dirección",
          hideAddress: "Ocultar dirección",
          isFor18olld: "¿Es para mayores de edad?",
          eventType: "Tipo de evento",
          description: "Descripción",
          moreAboutEvent: "Cuenta más sobre tu evento ...",
          descriptionImage: "Imagen en la descripción",
          termsConditionsEvent: "Términos y condiciones del evento",
          textAddedTerms:
            "Al final siempre va a ir 'Fravents solo se responsabiliza por los temas relacionados con soporte técnico en la compra y venta de boletería. Fravents no se hace responsable de la ejecución o cualquier tema relacionado con este evento.'",
          noEditPrices:
            "Los precios no se pueden editar por seguridad de los clientes, solo puedes editar la cantidad de tiquetes disponibles para la venta. Si deseas cambiar alguno por favor escríbenos por medio de nuestros canales de atención.",
          moneySendToYourAccounts:
            "El dinero de las compras se te enviará directamente a tu cuenta vinculada de Mercado Pago",
          toSellMustLinkAccount:
            "Para cobrar por medio de nuestra plataforma debes vincular tu cuenta de Mercado Pago para recibir el dinero a tu cuenta.",
          acceptCop: "¿Aceptar pagos en pesos ($ COP)?",
          acceptUsd: "¿Aceptar pagos en dólares ($ USD)?",
          addNewPrice: "Agregar nuevo precio (Nombre, Precio y Cantidad)",
          changeDefaultCurrency: "(Cambiar moneda predeterminada)",
          sellStagesOrAll:
            "¿La venta es por etapas o todos los precios estarán disponibles al tiempo?",
          ifIsStages:
            "Si funciona por etapas significa que el sistema automáticamente solo habilitará el primer precio de la lista que tenga cantidad disponible a la venta, si no tiene se tomará el segundo de la lista y así sucesivamente. El sistema te mostrará cual precio estará disponible.",
          allAvailable: "Todos disponibles",
          byStages: "Por etapas",
          pricesHaveTax:
            "¿Los precios incluyen la tarifa de servicio o lo asumen los clientes?",
          yesIncluded: "Si, ya está incluído",
          noClientPay: "No, el cliente lo asume",
          graphicResources:
            "Recursos gráficos - Sería genial que agregaras nuestro logo en tus publicaciones para informarles a los usuarios que somos tu aliado de entradas. RECUERDA NO ALTERAR EL LOGO PARA EVITAR PROBLEMAS LEGALES DE ACUERDO A NUESTROS TÉRMINOS Y CONDICIONES.",
          availablePromoters: "Habilitar promotores",
          wantPromotersVisible:
            "¿Deseas que los promotores sean visibles en el evento?",
          isPrivate:
            "¿Es privado? Solo pueden acceder por medio de un link de referido",
          yesPrivate: "Si, privado",
          noPublic: "No, público",
          wantPermanentVisible:
            "¿Quieres que sea visible de forma permanente? Puede quedar oculto si lo deseas",
          yesVisible: "Si, visible",
          noHide: "No, ocultar",
          addFbPixel: "¿Agregar identificador píxel de Facebook?",
          fbPixel: "Identificador píxel de Facebook",
          loadTestData: "Cargar datos test",
          preview: "Vista previa",
          before: "Anterior",
          save: "Guardar",
          next: "Siguiente",
          activatePayments: "¿Deseas habilitar los pagos online?",
        },
        dashboard: {
          newPlugin: "¡Nuevo plugin!",
          integrateItInWebsite:
            "Intégralo en tu sitio web para vender desde ahí",
          setUpAccount: "Configurar cuenta",
          linkAccounts: "Vincula Mercado Pago, personaliza tu página y perfil",
          team: "Equipo",
          sales: "Ventas",
          validate: "Validar",
          details: "Detalle",
          edit: "Editar",
          noHaveEvents: "No tenemos ningún evento que hayas creado,",
          createNewOne: "crea uno nuevo aquí",
          remaningTickets: "Tickets restantes",
          errorGetEvents: "Error para obtener eventos: ",
          availables: "Disponibles",
          hidden: "Ocultos",
          finished: "Finalizados",
          createEvent: "Crear evento",
          events: "Eventos",
          options: "Opciones",
        },
        productForm: {
          generalData: "Datos generales",
          data: "Datos",
          pricesTc: "Precios y T&C",
          prices: "Precios",
          configuration: "Configuración",
          enterAllData:
            "Ingresa todos los datos obligatorios (*) para continuar",
          priceMajorCop: "El precio debe ser mayor a $1000 COP",
          nameMustBeDifferent: "El nombre debe ser diferente a los demás",
          enterAllPriceData: "Ingresa todos los datos del precio",
          available: "disponibles",
          empty: "Vaciar",
          soldOut: "Agotado",
          sizeColor: "Talla, Color, ...",
          priceCop: "Precio $ COP",
          availableQuantity: "Cantidad disponible",
          successCreateProduct: "Producto creado correctamente",
          errorCreateProduct: "Error para crear producto",
          successSaveProduct: "Producto guardado correctamente",
          errorSaveProduct: "Error para guardar producto",
          saveProduct: "Guardar producto",
          areYouSureSaveProduct:
            "¿Esta de acuerdo en que ha diligenciado toda la información para guardar el producto?",
          maxFileSize:
            "El tamaño máximo permitido para cargar cada imagen es de 5Mb",
          whatIsProductName: "Cómo se llama tu producto",
          images: "Imágenes",
          description: "Descripción",
          tellMoreAbout: "Cuenta más sobre tu producto ...",
          termsConditions: "Términos y condiciones de la compra",
          textAddedTerms:
            "Al final siempre va a ir 'Fravents solo se responsabiliza por los temas relacionados con soporte técnico en la pasarela de pagos del producto. Fravents no se hace responsable de la gestión de entrega o cualquier tema relacionado con este producto.'",
          moneySendToYourAccounts:
            "El dinero de las compras se te enviará directamente a tu cuenta vinculada de Mercado Pago",
          addNewPrice: "Agregar nuevo precio (Referencia, Precio y Cantidad)",
          graphicResources:
            "Recursos gráficos - Sería genial que agregaras nuestro logo en tus publicaciones para informarles a los usuarios que somos tu aliado de ventas. RECUERDA NO ALTERAR EL LOGO PARA EVITAR PROBLEMAS LEGALES DE ACUERDO A NUESTROS TÉRMINOS Y CONDICIONES.",
          sellAvailable: "¿Está habilitado para venta?",
          sendsIncluded: "¿El envío viene incluído?",
          addFbPixel: "¿Agregar identificador píxel de Facebook?",
          fbPixel: "Identificador píxel de Facebook",
          loadTestData: "Cargar datos test",
          preview: "Preview",
          before: "Anterior",
          save: "Guardar",
          next: "Siguiente",
        },
        salesShop: {
          forSend: "Por Enviar",
          pending: "Pendientes",
          rejected: "Rechazadas",
          payed: "Pagadas",
          sent: "Enviados",
          delivered: "Entregados",
          filter: "Filtrar",
          comple: "Comple.",
          pendi: "Pendien.",
          sen: "Enviado",
          deliv: "Entregado",
          rejec: "Rechaz.",
          noMoreSells: "No hay compras por mostrar",
          withoutEmail: "Sin email",
          product: "producto",
          errorUpdateSellStatus: "Error para actualizar estado de ventas: ",
          confirmDelivery: "Confirmar entrega y finalizar pedido",
          areYourSureChangeStatus:
            '¿Estas de acuerdo en cambiar de estado {{length}} venta{{multiple}} a "Entregado"? NO SE PUEDE CAMBIAR DESPUÉS DE CONFIRMAR. Esta acción enviará una notificación al comprador. Recuerda mantener al día a tus clientes por medio del chat de cada venta.',
          confirmReturnDelivery: "Confirmar retorno de envíos",
          areYourSureChangeReturnStatus:
            '¿Estas de acuerdo en cambiar de estado {{length}} venta{{multiple}} a "En espera"? Esta acción enviará una notificación al comprador. Recuerda mantener al día a tus clientes por medio del chat de cada venta.',
          confirmSend: "Confirmar envíos",
          areYourSureChangeSendStatus:
            '¿Estas de acuerdo en cambiar de estado {{length}}} venta{{multiple}} a "Enviado"? Esta acción enviará una notificación al comprador. Recuerda mantener al día a tus clientes por medio del chat de cada venta.',
          thereNoItems: "No hay items por mostrar",
          bought: "comprado",
          totalSells: "Venta total",
          detail: "Detalle",
          totalIncomes: "Total ingresos",
          selledProducts: "Productos vendidos",
          sellsWaiting: "Compras en espera",
          senta: "Enviadas",
          finishedDelivery: "Entregas terminadas",
          completeSells: "Compras completadas",
          pendingSells: "Compras pendientes",
          rejectedSells: "Compras rechazadas",
          deletedCode: "Código eliminado",
          errorPromoCode: "Error código promocional: ",
          deletePromoCode: "Eliminar código promocional",
          areYourSureDeletePromoCode:
            "¿Estas de acuerdo en eliminar de forma permanente el código promocional {{textCode}} y todos sus datos? Esta acción no se puede revertir",
          noExistCode: "No existe el código, por favor intentar de nuevo",
          noCodesToShow: "No hay códigos por mostrar",
          discount: "Descuento",
          created: "creados",
          byUser: "por usuario",
          expDate: "Válido hasta",
          viewDetails: "Ver detalles",
          edit: "Editar",
          delete: "Eliminar",
          viewGraphs: "Ver gráfica",
          data: "Datos",
          used: "Usados",
          remaning: "Restantes",
          viewList: "Ver listado",
          historyUses: "Historial de usos ({{date}})",
          year: "Año",
          fillDataYet:
            "Aún hace falta información, por favor intentar de nuevo",
          editedCode: "Código editado",
          createdCode: "Código creado",
          text: "Texto",
          quantity: "Cantidad",
          limitUseByClient: "Límite de uso por cliente",
          validUntil: "Válido hasta",
          priceCop: "Valor $ (COP)",
          percentage: "Porcentaje (%)",
          name: "Nombre",
          email: "Correo",
          phone: "Teléfono",
          productM: "Producto",
          type: "Tipo",
          numQuantity: "# Unidades",
          sellDate: "Fecha compra",
          status: "Estado",
          promoCode: "Código promocional",
          totalValueCop: "Valor total (COP)",
          fileNameExport:
            "FRAVENTS Tienda Consolidado Ventas de productos - {{dateNow}}",
          sellFraventsShop: "Ventas Tienda FRAVENTS",
          sells: "Ventas",
          alertDownload: "Alerta descarga de datos",
          toDownloadInfoAccept:
            "Al descargar la información de los usuarios registrados en las compras, te haces responsable del uso y destino de los mismos.",
          errorGetPromoCode: "Error para obtener códigos de promoción: ",
          errorGetSells: "Error para obtener ventas: ",
          manageSellsSends: "Gestiona las compras y envíos",
          onWaiting: "En espera",
          reviewAndSelect:
            "Revisa y selecciona las ventas que tienen pago aprobado y están pendientes para gestionar y enviar.",
          return: "Regresar",
          send: "Enviar",
          sendUpdatesConfirm:
            "Envía actualizaciónes del envío y confirma cuando sea entregado. Las ventas finalizadas las puedes consultar abajo en la sección de Registros.",
          delivery: "Entregar",
          sellDataOf: "Datos de ventas de",
          eCommerce: "e-commerce",
          yourEcommerce: " tu e-commerce",
          viewShop: "Ver tienda",
          downloadData: "Descargar datos",
          maxMb: "Máximo 5Mb c/u",
          lastMonth: "Último mes",
          historySells: "Historial de ventas ({{historyDate}})",
          promoCodes: "Códigos promocionales",
          createNewPromoCode: "Crear nuevo código",
          records: "Registros",
          searchBy: "Buscar por nombre, correo o teléfono",
          alla: "Todas",
          search: "Buscar",
          loadMode: "Cargar más",
          infoPromoCode: "Información código promocional",
          addDataPreferences:
            "Agrega los datos y preferencias de uso. Recuerda que después de creado solo se puede editar su fecha de validez y el limite de uso.\n\nRECUERDA: Estos códigos solo funcionarán para pagos en pesos colombianos (COP).",
          textMustBeUnique:
            "El texto debe ser único y debes diligenciar el valor o el porcentaje de promoción",
        },
        salesTeam: {
          teamAccessViewStats:
            "Personal que tiene acceso al dashboard de tus ventas del e-commerce. También tienen acceso a ver reportes de códigos de descuento y gestión de pedidos para cambiar estados de envíos y contestar mensajes de clientes.",
          addSupervisor: "Agregar supervisor",
          supervisorSell: "Supervisor de ventas",
          rememberAddSupervisor:
            "Recuerda que al agregarlo, queda asignado de una vez y obtiene acceso al dashboard",
          supervisor: "SUPERVISOR",
          thisTeamQrAccess:
            "Este personal solo tiene acceso al lector del QR para ser usado en los puntos físicos o domicilio, y validar la entrega de los productos.",
          addValidator: "Agregar validador",
          validator: "Validador",
          eachUserCanScan:
            "Cada integrante tendrá acceso a la funcionalidad de Escanear Código QR para ir validando cada compra.",
          validatorM: "VALIDADOR",
          userAdded: "Usuario agregado",
          userDeleted: "Usuario eliminado",
          confirmAction: "Confirmar acción",
          wantDeleteUser:
            "¿Deseas eliminar de tu equipo de {{name}} el usuario {{email}}?",
          thereNoUser: "No existe ningún usuario en esta lista",
          viewStats: "Supervisar ventas",
          validateQr: "Validar QR",
          wantAddUser:
            "¿Deseas agregar a tu equipo de {{name}} el correo {{email}}?",
          userAlreadyBe: "El usuario ya hace parte del equipo.",
          enterValidEmail: "Ingresar un correo válido",
          errorGetTeam: "Error para obtener equipo: ",
          viewDetails: "Ver detalles",
          delete: "Eliminar",
          add: "Agregar",
          pleaseEnterCompleteEmail:
            "Por favor ingresa el correo completo de la persona que deseas agregar, recuerda que ya debe estar registrada en la plataforma de FRAVENTS.",
        },
        sales: {
          completed: "Completadas",
          pending: "Pendientes",
          rejected: "Rechazadas",
          transfered: "Transferencias",
          scaned: "Escaneadas",
          resell: "Revendidas",
          filter: "Filtrar",
          comple: "Comple.",
          pendi: "Pendien.",
          rejec: "Rechaz.",
          noMoreSells: "No hay compras por mostrar",
          withoutEmail: "Sin email",
          tickets: "tickets",
          onTransfer: "En transferencia",
          transferedS: "Transferida",
          payed: "Pagada",
          pendingS: "Pendiente",
          rejectedS: "Rechazada",
          refunded: "Reembolsada",
          thereNoMembers: "No hay integrantes por mostrar",
          referedUsers: "Referidos",
          totalSell: "Venta total",
          totalIncomes: "Total ingresos",
          generatedTickets: "Tickets generados",
          scanedCodes: "Códigos escaneados",
          resellTickets: "Tickets revendidos",
          completeSells: "Compras completadas",
          pendingSells: "Compras pendientes",
          rejectedSells: "Compras rechazadas",
          deletedCode: "Código eliminado",
          errorPromoCode: "Error código promocional: ",
          deletePromoCode: "Eliminar código promocional",
          areYourSureDeletePromoCode:
            "¿Estas de acuerdo en eliminar de forma permanente el código promocional {{textCode}} y todos sus datos? Esta acción no se puede revertir",
          noExistCode: "No existe el código, por favor intentar de nuevo",
          noCodesToShow: "No hay códigos por mostrar",
          discount: "Descuento",
          created: "creados",
          buyUser: "por usuario",
          expDate: "Válido hasta",
          viewDetails: "Ver detalles",
          edit: "Editar",
          delete: "Eliminar",
          viewGraphs: "Ver gráfica",
          data: "Datos",
          used: "Usados",
          remaning: "Restantes",
          viewList: "Ver listado",
          historyUses: "Historial de usos ({{date}})",
          year: "Año",
          fillDataYet:
            "Aún hace falta información, por favor intentar de nuevo",
          editedCode: "Código editado",
          createdCode: "Código creado",
          text: "Texto",
          quantity: "Cantidad",
          limitUseByClient: "Límite de uso por cliente",
          validUntil: "Válido hasta",
          priceCop: "Valor $ (COP)",
          percentage: "Porcentaje (%)",
          scaneds: "Escaneados",
          name: "Nombre",
          lastName: "Apellido",
          email: "Correo",
          document: "Documento",
          typeTicket: "Tipo ticket",
          numBought: "# Compradas",
          numValidated: "# Validadas",
          sellDate: "Fecha compra",
          statusTransaction: "Estado transacción",
          promoCode: "Código promocional",
          totalValueCop: "Valor total (COP)",
          fileNameExport: "FRAVENTS {{name}} Ventas - {{dateNow}}",
          sellFravent: "Ventas FRAVENTS",
          sells: "Ventas",
          alertDownload: "Alerta descarga de datos",
          toDownloadInfoAccept:
            "Al descargar la información de los usuarios registrados en las compras, te haces responsable del uso y destino de los mismos.",
          errorGetPromoCode: "Error para obtener códigos de promoción: ",
          errorGetSells: "Error para obtener ventas: ",
          invalidData: "Datos inválidos",
          sellData: "Datos de ventas",
          downloadData: "Descargar datos",
          maxMb: "Máximo 5Mb c/u",
          addTickets: "Agregar tickets",
          maxUploadTickets: "Máx. 100 tickets por archivo",
          sellsByType: `Ventas por tipo (COP)`,
          quantityTicketGenerated: `Cantidad ticket generados por tipo`,
          lastMonth: "Último mes",
          historySells: "Historial de ventas ({{historyDate}})",
          promoters: "Promotores",
          logistics: "Logística",
          promoCodes: "Códigos promocionales",
          createNewPromoCode: "Crear nuevo código",
          records: "Registros",
          searchBy: "Buscar por nombre, correo o documento",
          alla: "Todas",
          search: "Buscar",
          loadMode: "Cargar más",
          infoPromoCode: "Información código promocional",
          addDataPreferences:
            "Agrega los datos y preferencias de uso. Recuerda que después de creado solo se puede editar su fecha de validez y el limite de uso.",
          textMustBeUnique:
            "El texto debe ser único y debes diligenciar el valor o el porcentaje de promoción",
        },
        shop: {
          newPlugin: "¡Nuevo plugin!",
          integrateItInWebsite:
            "Intégralo en tu sitio web para vender desde ahí",
          setUpAccount: "Configurar cuenta",
          linkAccounts: "Vincula Mercado Pago, personaliza tu página y perfil",
          successChange: "Cambio exitoso",
          errorChangeProductState:
            "Error para cambiar el estado del producto: ",
          changeProductState: "Cambiar estado de producto",
          areSureChangeShowProduct:
            "¿Estas de acuerdo en {{isAvailable}}habilitar el producto {{name}}? Recuerda que al {{isAvailable}}habilitarlo los usuarios {{isNoYet}} podrán comprarlo.",
          labelAvailable: "{{preLabel}}abilitar",
          validate: "Validar",
          edit: "Editar",
          detail: "Detalle",
          unavailable: "Deshabilitar",
          available: "Habilitar",
          weNoHaveAnyProduct: "No tenemos ningún producto que hayas creado",
          remaningItems: "Items restantes",
          dateCreate: "Fecha de creación",
          errorGetProducts: "Error para obtener productos: ",
          products: "Productos",
          options: "Opciones",
          sells: "Ventas",
          team: "Equipo",
          createNewOne: "Crear nuevo",
        },
        team: {
          teamAccessLink:
            "Personal que tiene acceso al link de referencia del evento.",
          addPromoter: "Agregar promotor",
          promoter: "Promotor",
          rememberAddPromoter:
            "Recuerda que al agregarlo, queda asignado de una vez y obtiene un link de referido para posibles compras",
          promotersM: "PROMOTORES",
          thisTeamQrAccess:
            "Este personal solo tiene acceso al lector del QR del día del evento para ir registrando tickets.",
          addLogistic: "Agregar logística",
          logistic: "Logística",
          eachUserCanScan:
            "Cada integrante de logística tendrá acceso a la funcionalidad de Escanear Código QR el día del evento e ir validando cada ticket.",
          logisticM: "LOGÍSTICA",
          yourColaboratorsHelp:
            "Tus aliados te ayudarán a expandir el alcance de tu evento a nuevos usuarios.",
          addColaborator: "Agregar colaborador",
          colaborator: "Colaborador",
          whenAddColaborator:
            "Cuando agregas colaboradores habilitas la venta de tu evento en el perfil de cada uno. Puedes gestionar el acceso cuando quieras.",
          colaborators: "COLABORADORES",
          userAdded: "Usuario agregado",
          userDeleted: "Usuario eliminado",
          confirmAction: "Confirmar acción",
          wantDeleteUser:
            "¿Deseas eliminar de tu equipo de {{name}} el usuario {{email}}?",
          linkCopied: "Link copiado correctamente",
          thereNoUser: "No existe ningún usuario en esta lista",
          promoters: "Promotores",
          colaboratorsM: "Colaboradores",
          confirmAddUser: "Confirmar adición",
          wantAddUser:
            "¿Deseas agregar a tu equipo de {{name}} el correo {{email}}?",
          userAlreadyBe: "El usuario ya hace parte del equipo.",
          enterValidEmail: "Ingresar un correo válido",
          errorGetTeam: "Error para obtener equipo: ",
          invalidData: "Datos inválidos",
          viewDetails: "Ver detalles",
          copyLink: "Copiar link",
          delete: "Eliminar",
          add: "Agregar",
          pleaseEnterCompleteEmail:
            "Por favor ingresa el correo completo de la persona que deseas agregar, recuerda que ya debe estar registrada en la plataforma de FRAVENTS.",
        },
        validateTicket: {
          noFoundSell: "No se encontró ninguna compra con esos datos",
          enterAtLeast: "Ingresa al menos 3 caracteres",
          available: "disponible",
          ticketValidated: "Ticket validado, ",
          errorValidateTicket: "Error al validar información: ",
          validateTicket: "Validar ticket",
          wantValidateTickets:
            "¿Deseas validar 1 ticket de la compra de {{name}}? (Tiene {{quantity}}) RECUERDA que esta acción no se puede revertir.",
          yesValidate: "Si, validar",
          enterAnyData: "Ingresa algún dato y presiona en 'Buscar'",
          thereNoItems: "No hay items para mostrar",
          idNoRegistered: "Documento no registrado",
          transfered: "Transferido",
          validate: "Validar",
          errorGetInfo: "Error al obtener información: ",
          youCanValidate:
            "Puedes validar tickets por medio del buscador con los datos del comprador (por nombre, correo o documento), o por medio del escaner de códigos QR.\n\n<bold>ATENCIÓN:</bold> Recuerda que al validar cada ticket no se puede revertir la acción.",
          buyerData: "Datos del comprador",
          search: "Buscar",
          scanQrCode: "Escanear código QR",
        },
        videoTutorial: {
          introduction: "Introducción",
          enter: "Ingresa",
          dashboard: "Dashboard",
          createEvent: "Crear evento",
          manageTeam: "Gestiona tu equipo",
          viewAnalytics: "Ver analíticas",
          validateTickets: "Validar entradas",
          final: "Final",
          welcome: "¡Bienvenid@, mira nuestro tutorial!",
          hiHereIsVideo:
            "Hola! A continuación dejamos nuestro video tutorial para explicarte cada una de las funcionalidades que ofrecemos a nuestros administradores. Cualquier inquietud o comunidado, nos puedes escribir a",
          withBriefMessage:
            "con un breve mensaje y datos de contacto para que un agente de nuestro equipo se comunique contigo.",
          bestRegards: "Cordialmente, Equipo de FRAVENTS.",
          videoTutorial: "Video tutorial",
          youCanSkip:
            "Puedes saltar entre las diferentes partes del video según la sección de tu interés:",
        },
      },
      auth: {
        page404: {
          weDidntFindPage: "No hemos encontrado la página que estas buscando",
          getMeOut: "Sácame de aquí",
        },
      },
      dashboard: {
        bookmarks: {
          noHaveEvents: "No tenemos ningún evento que hayas guardado",
          favorites: "Favoritos",
        },
        faq: {
          noFindAny:
            "No encontramos ninguna solución a tu caso, intenta utilizar otras palabras claves",
          errorGetQuestions: "Error para obtener preguntas: ",
          faqs: "Preguntas Frecuentes (FAQs) 🤓",
          searchKeyWords:
            "Búsca por palabras claves la respuesta a tu inquietud. Si nuestras respuestas no logran solucionar tu inconveniente, escríbenos un correo y nuestro equipo de soporte te responderá lo más pronto posible.",
          keyWords: "Palabras claves de tu búsqueda",
          search: "Buscar",
        },
        index: {
          viewMoreInfo: "Ver mas información",
          thereNoEvents: "No hay eventos {{pastEvents}} para mostrar",
          before: "anteriores",
          closed: "cercanos",
          errorGetEvents: "Error para obtener eventos: ",
          whatYouCanDo: "¿Qué puedes hacer aquí?",
          titleNews: "Invertimos en la revolución 🔥",
          descriptionNews:
            "En el 2024 integraremos los pagos y recompensas con el token $FROIN de nuestro actual proyecto blockchain",
          forViewmore: "Para ver más información",
          clickHere: "haz clic aquí",
          weOfferFeatures:
            "Ofrecemos diferentes funcionalidades para que seas parte de nuestra comunidad, <bold>GRATIS y todo al alcance de tu mano</bold>",
          featured: "Destacados",
          eventOnFire: "Nuestro selectivo grupo de eventos on-fire",
          meetOutBenefits:
            "Conoce nuestros beneficios, funcionalidades y precios aquí para <bold>crear tu evento</bold>.",
          soon: "Próximos eventos",
          noKeepWithoutPlan:
            "No te quedes sin plan, anímate a uno de estos eventos",
          viewAll: "Ver todos",
          doItEasy: "Hazlo de la manera más óptima y sencilla posible",
          createYourEvent: "Crea tu evento aquí",
          befored: "Eventos apoyados",
          thanksEvents: "Gracias a los eventos que han confiado en nosotros",
        },
      },
      events: {
        allEvents: {
          weDontHaveEvents: "No tenemos ningún evento que mostrar",
          enterAtLeast: "Ingrese por lo menos 3 caracteres del nombre",
          errorGetEvents: "Error para obtener eventos: ",
          eventsOnFire: "Eventos on-fire",
          theseAndMoreEvents:
            "Estos y más eventos para que no te quedes sin plan. Utiliza el buscador en la siguiente sección para filtrar el contenido y ver los resultados en la lista",
          whatSearch: "¿Qué buscas?",
          search: "Buscar",
        },
        finishpayment: {
          finishedTransaction: "Transacción completada ID: ",
          thanksBuy:
            "Gracias por comprar con FRAVENTS. Ya puedes consultar tus transacciones en la lista de Mis compras. Si no te llega el email de confirmación de compra o tienes algún inconveniente puedes escribirnos a help@fravents.com",
          pendingTransaction: "Transacción pendiente ID: ",
          weAreProcessing:
            "Aún estamos procesando la transacción y esperando respuesta, esta página se recargará cada 10 segundos para revisar nuevamente. Si tienes algún inconveniente puedes escribirnos a help@fravents.com.",
          rejectedTransaction: "Transacción rechazada o con problemas ID: ",
          sorryNoComplete:
            "Lamentamos que no se haya podido completar tu pago. Si tienes problemas no dudes en escribirnos al correo help@fravents.com.",
          noFoundPayment: "No encontramos ningún pago, inténtalo de nuevo.",
          paymentError: "Error de pago: ",
          returnFravents: "Regresar a Fravents",
        },
        indexEvent: {
          nowFollow: "Ahora sigues este evento",
          unFollow: "Ya no sigues este evento",
          errorUpdate: "Error para actualizar: ",
          sellRegistered: "Compra registrada",
          inSomeMinutesEmail:
            "Ya quedó registrada la compra exitosamente y en unos minutos le debe llegar un correo a {{email}} con las respectivas instrucciones. Si hay alguna novedad por favor comunicarsela al Organizador del evento.",
          gotIt: "Entendido",
          noAvailableBooking:
            "La reserva ya no puede ser realizada porque no se encuentra disponible. [Error: {{error}}]",
          errorData: "Datos erroneos",
          beforeContinue: "Antes de continuar ...",
          logInAccount:
            "Para tu compra debes iniciar sesión en Fravents o crear una cuenta, es fácil! Recuerda que debes verificar tu correo, si tienes problemas por favor escribe a help@fravents.com",
          mustVerifyEmail:
            "Para tu compra debes {{label}} verificar tu correo electrónico por seguridad. Puedes verificar tu email en Tu Perfil.",
          createAnd: "crear y",
          goToProfile: "Ir a mi perfil",
          promoCode: "Código promocional",
          youHaveCode:
            "¿Tienes algún código de descuento para este evento? No es obligatorio.",
          completeData: "Completa los datos e intenta de nuevo",
          confirmSelection: "Confirmar selección",
          youWantBuyTickets:
            "{{name}} ¿Deseas {{action}} {{quantity}} entrada{{multiple}} {{ticketName}}? {{description}}.",
          register: "registrar",
          purchase: "adquirir",
          dataWillSave:
            "Los datos serán guardados y se enviará un email al usuario {{email}} con las respectivas instrucciones. ESTA ACCIÓN NO SE PUEDE REVERTIR",
          youWillBeRedirect:
            "Serás redirigido a la plataforma de pagos y tendrás máximo 10 minutos para realizar la compra o se eliminará tu reserva",
          confirmDiscount: "Confirmación descuento",
          infoPromoCodeEntered:
            "El código promocional que ingresaste [{{name}}] te da acceso a un descuento de {{discount}} sobre el valor de las boletas sin impuestos. ¿Deseas continuar?",
          yesPay: "Si, ir a pagar",
          errorPromoCode: "Error código promocional: ",
          emailHasBadFormat: "El correo ingresado tiene un formato incorrecto",
          buyTickets: "Comprar tickets",
          partnerNoCanSell:
            "El organizador no tiene habilitada la compra por medio de Fravents, por lo tanto, debes comprarla directamente con los promotores autorizados por medio de Whatsapp. Cuando registren la compra te debe llegar la confirmación de los tickets que puedes usar en nuestra plataforma.",
          goToWhatsapp: "Ir a Whatsapp",
          errorGetContacts: "Error para obtener contactos: ",
          errorGetEvents: "Error para obtener evento: ",
          errorGetOffers: "Error para obtener ofertas: ",
          errorGetStats: "Error para obtener estadísticas: ",
          linkCopied: "Link copiado correctamente",
          blockRefLink:
            "El organizador no tiene habilitada la función de Promotores",
          linkReadyRef: "¡Link copiado y ahora a referenciar!",
          shareLink:
            "Comparte este link en tus redes sociales y cada vez que un usuario compre por medio de tu link se te abonará a tu saldo de créditos el siguiente monto:{{amount}}\n\nRECUERDA: El valor es apróximado porque varía dependiendo del medio de pago del usuario. Remitirse a los Términos y Condiciones cualquier cosa.",
          refAndEarn: "¡Refiere y Gana!",
          eventFinished: "Evento finalizado",
          getTicketsByLink:
            "Para adquirir tickets debes recibir un link de referencia, escríbele a un promotor del evento para solicitar uno.",
          registerFree: "Inscribirse gratis",
          toBuyVerifyEmail: "Para comprar debes verificar tu correo aquí",
          youAreMember:
            "Eres parte del equipo, estas son las acciones que puedes realizar:",
          boughtTickets: "Tickets comprados por medio de tu link de referido:",
          copyLink: "Copiar link referido",
          linkRefBlocked: "Link referido bloqueado",
          addTicket: "Agregar compra",
          validateTicket: "Validar ticket",
          completeBuyData: "Completa los datos de compra",
          toContinueWithBuy:
            "Para continuar con la compra de {{quantity}} entrada{{multiple}} {{type}}, por favor ingresa los siguientes datos y al confirmar se reservará tu pedido.",
          confirmBuyRef:
            "Confirma que tu comprador ya haya realizado el pago y que los datos estén correctos. Esta acción no se puede revertir y queda disponible para una posible revisión por parte del Organizador.",
          toSendDataAcceptTC:
            "Al enviar los datos confirmas haber leído y aceptado el [[Aviso Compradores]], también aceptas los términos y condiciones del evento. Para brindarles la mejor seguridad y confianza a nuestros usuarios, los pagos se realizan por medio de la plataforma {{provider}}, por lo tanto, FRAVENTS no almacena ni manipula ninguna información sensible de los métodos de pagos de los usuarios.",
        },
        selectQuantity: {
          soldOut: "Agotado",
          soon: "Próximamente",
          available: "disponibles",
          firstSelect: "Primero selecciona un tipo de ticket",
          noMoreQuantity: "No puedes superar la cantidad disponbile",
          alertMaxQuantity: "La cantidad máxima por compra es de 10 tickets",
          changeCurrency: "Cambiar moneda",
          selectOtherCurrency:
            "El método que deseas seleccionar usa otra moneda ({{newCurrency}}) diferente a tus preferencias ({{defaultCurrency}}), ¿deseas continuar?",
          typeTicket: "Tipo de ticket",
          taxBuy: "+ tarifa de servicio de la plataforma {{value}}",
          taxIncluded: "Estos precios ya tienen incluída la tarifa de servicio",
          quantity: "Cantidad",
          totalPrice: "Precio Total",
          withLocalPayment: "Con <bold>métodos locales</bold> pagas:",
          withCryptosPayment: "Con <bold>Criptomonedas</bold> pagas:",
          errorpartnerAccounts:
            "Error: Problema con las cuentas de pago del organizador, por favor reportar el caso.",
          continuePayment: "Continuar al pago",
          remainData: "Faltan datos de la compra",
        },
      },
      layout: {
        selectEvent: "Selecciona el evento de tu preferencia",
        reviewBuy: "Revisa y compra tus tickets",
        hereTickets: "Aquí estan tus tickets",
        signOut: "Cerrar sesión",
      },
      partner: {
        profile: {
          linkCopied: "Link copiado correctamente",
          infoSelectedNo: "La información seleccionada no existe",
          noContentToShow: "No hay contenido para mostrar",
          manageSells: "Gestionar ventas",
          verified: "Verificado",
          noVerified: "No verificado",
          share: "Compartir",
          memberSince: "Miembro desde",
          events: "Eventos",
          shop: "Tienda",
          all: "Todos",
          active: "Activos",
          loadMore: "Cargar más",
        },
        infoPlugin: {
          pluginAvailable: "Plugin habilitado correctamente",
          pluginUnavailable: "Plugin deshabilitado correctamente",
          linkCopied: "Link copiado correctamente",
          onPlugin: "Habilitar plugin",
          areYourSureGoodUse:
            "¿Estas de acuerdo en hacer buen uso de nuestro código y asumirás la responsabilidad de su intergación?",
          offPlugin: "Deshabilitar plugin",
          areYourSureBlock:
            "¿Estas de acuerdo en bloquear el uso de tu código? Recuerda que puedes volver a generar otro código pero siempre será diferente para brindar una mejor seguridad.",
          titleNewPlugin: "Nuevo plugin para tu sitio web 🤓",
          bodyNewPlugin:
            "Aquí puedes habilitar tu plugin para que puedas copiar y pegar el fragmento de código en tu sitio web y así comiences a vender tu contenido de Fravents directamente en tu página sin que tus clientes tengan que salir de ella. Revisa el video tutorial para mayor información.",
          instructions: "Instrucciones",
          youWillFindOptions:
            "A continuación encontrarás las opciones para habilitar, o no, tu plugin. Cuando quieras integrarlo en tu sitio web recuerda no modificar nuestro código para un correcto uso del mismo. Solo copia el siguiente código HTML y pegalo en la sección deseada en tu código fuente.",
          copyCode: "Copiar código",
          rememberEmail:
            "Recuerda contar con el apoyo de algún desarollador web para esta integración, ya que se requiere modificar el código fuente de tu sitio web. Si deseas apoyo con gusto nuestro equipo técnico estará dispuesto para ayudarte si es necesario, solo escribe un correo a",
        },
        plugin: {
          infoSelectedNo: "La información seleccionada no existe",
          noContentToShow: "No hay contenido para mostrar",
          thisPluginIsUnavailable:
            "Este plugin está deshabilitado, para solucionar cualquier problema ponerse en contacto con soporte técnico.",
          loadMore: "Cargar más",
        },
        programInfo: {
          communityFraventsPartners: "Cualquier persona puede ser Socio 🤑",
          descriptionPartners:
            'Antes de continuar, por favor mira el video que hemos preparado para ti en la introducción a nuestro innovador modelo de negocio que te queremos compartir. Después de verlo, se habilitará la opción de "Continuar" para ver los siguientes pasos.',
          continue: "Continuar",
          messageContinue:
            "Gracias por ver nuestro video, ahora lee a detalle el siguiente documento que te ampliará la información anteriormente mostrada.",
          noContinue: "Ups hay algo mal ...",
          messageNoContinue:
            "Aún no has completado de ver el video, no puedes continuar hasta que veas por lo menos 30 segundos de la presentación.",
          showForm: "Ver formulario",
          cannotContinue: "Aún no puedes continuar",
          mustVerifyEmail:
            "Para continuar debes verificar tu correo electrónico por seguridad. Puedes verificar tu email en Tu Perfil.",
          completeDataBefore: "Completa los datos de contacto",
          completeDataMessage:
            "Nos alegra que tengas interés en continuar con el proceso, para ello necesitamos un par de datos y te llegará la información a tu correo en un breve tiempo.",
          subCompleteDataMessage:
            "Al enviar los datos confirmas que has leído y aceptado la [[Autorización de tratamiento de datos]] para continuar con el proceso.",
          formSent:
            "Formulario enviado con éxito, pronto recibirás una respuesta.",
          thanksForSendTitle: "Inscripción finalizada",
          thanksForSendText:
            "Ya quedaste en nuestra lista de invitados a nuestra video conferencia informativa para charlar con nuestro equipo sobre los pasos a seguir, te estaremos acompañando en todo el proceso. Éxitos y nos vemos pronto.",
          selectOneProgram: "Programa de Socios Promotores 🤑",
          descriptionIntro:
            "Hemos diseñado los siguientes programas para que puedas escoger el camino que desees para ser parte de nuestra comunidad de socios. Lee atentamente la descripción de cada uno y continua con sus respectivos pasos, si tienes dudas puedes contactarnos por medio de nuestros canales de atención.",
          referenceEarn: "Refiere y Gana",
          referenceEarnDesription:
            "Si lo único que quieres es compartir tu código de referido y ver como crecen tus ganancias, este programa es para ti.",
          referenceEarnRate: "10% de comisión",
          commissionPercentageCalculated:
            "El porcentaje de la comisión se calcula sobre las ganancias netas de Fravents por cada cliente referenciado.",
          startReferencingInstantly:
            "Puedes empezar a referenciar al instante sin procesos de selección.",
          eachCustomerRefer10:
            "Por cada cliente que refieras, recibirás el 10% de comisión por las ventas de ese cliente por un tiempo de 6 meses, contando desde la primera venta realizada.",
          eachReferralReceives:
            "Cada referido recibe una tarifa preferencial del 5% (normalmente esta en 8%) sobre el servicio en la plataforma Fravents, sin incluir los costos fijos de pasarelas de pago.",
          salesAgent: "Agente de Ventas",
          salesAgentDescription:
            "Para las personas que desean ser parte de nuestro equipo de Promotores, recibir mayor porcentaje de ganancias por más tiempo y un paquete de beneficios, este programa esta diseñado para ustedes.",
          salesAgentRate: "50% de comisión",
          simpleWQuickSelection:
            "Debes pasar por un proceso de selección sencillo y rápido, nuestro equipo te acompañará siempre.",
          eachCustomerRefer50:
            "Por cada cliente que refieras, recibirás el 50% de comisión por las ventas de ese cliente por un tiempo de 2 meses (con opción de extensión *), contando desde la primera venta realizada.",
          accessPrivateCommunity:
            "Acceso a nuestra comunidad privada de Socios Promotores",
          receiveBenefits:
            "Recibirás beneficios como descuentos para eventos, acceso gratuito a eventos de Fravents, merchandising y más!",
          youhavePossibility:
            "Tienes la posibilidad de negociar la tarifa preferencial a tus referidos (entre el 2% al 8%) sobre el servicio en la plataforma Fravents, sin incluir los costos fijos de pasarelas de pago",
          extendCondition:
            "* Si pasan los 2 meses desde la primera venta del cliente referenciado y no se consigue otro cliente, el porcentaje del 50% de cliente actualmente referenciado se irá disminuyendo 5% cada mes hasta que se consiga un nuevo cliente referenciado y que realice ventas.",
          continuePromoter: "Continuar al proceso",
          activateCode: "Activar mi código",
          viewMyCode: "Ver mi código",
          viewMoreBene: "Ver más beneficios",
          beforeContinue: "Antes de continuar ...",
          mustAcceptTerms: "¿Aceptas nuestros [[Términos y Condiciones]]?",
          noLater: "No, mejor luego",
          yesAccept: "Si, acepto",
        },
      },
      profile: {
        index: {
          return: "Volver",
          logOut: "Cerrar sesión",
          wantlogOut: "¿Deseas salir de tu cuenta?",
          infoUpdated: "Información actualizada",
          referProgram: "Programa de referidos",
          beforeContinue: "Antes de continuar ...",
          areSureAcceptTerms:
            "¿Estas de acuerdo en aceptar nuestros [[Términos y Condiciones]] de nuestro programa de referidos?\n\nRECUERDA: Si incumples nuestros términos y condiciones, tu usuario se bloqueará y no podrás volver a unirte al programa.",
          mustVerifyEmail:
            "Debes verificar tu correo electrónico por seguridad. Puedes enviar tu email de verificación en Tu Perfil.",
          noOtherDay: "No, otro día",
          goToMyProfile: "Ir a mi perfil",
          agree: "De acuerdo",
          deletePhoto: "Eliminar foto",
          wantDeletePhoto: "¿Deseas eliminar esta foto de forma permanente?",
          whatIsCompleteName: "¿Cómo es tu nombre completo?",
          whereSendEmail: "¿A dónde enviamos los correos?",
          newPassword: "Nueva contraseña",
          typeHere: "Escribela aquí",
          thanksVerifyEmail: "Gracias por verificar tu correo!",
          ifNoVerifyEmail:
            "Si no verificas tu correo no podrás comprar tickets para los eventos. Para enviar un correo de verificación presiona en el botón de abajo.",
          verified: "(Verificado)",
          noVerified: "(Sin verificar)",
          logo: "Logo",
          banner: "Banner",
          description: "Descripción",
          tellMoreAbout: "Cuentanos un poco sobre ti ...",
          customUrl: "URL personalizada",
          contactUsToGet: "Contáctanos para obtenerla",
          onlyId: "Solo tu ID",
          emailSent: "Correo de verificación enviado",
          errorEmailSent: "Error al enviar correo",
          badEmailFormat: "El formato del correo ingresado esta incorrecto.",
          passwordMustHave: "La contraseña debe contener 6 o más caracteres.",
          pleaseFillAll: "Por favor ingresa todos los datos",
          ifYouWantUnlink: "Si deseas desvincular tu cuenta, contáctanos",
          linkNequi: "Vincular Nequi",
          beforeContinueLinkNewAccount:
            "Antes de continuar, debes verificar tu cuenta para seguir con el proceso. Este paso es necesario para confiar en que vas a vender productos reales y evitar estafas a nuestros usuarios. Envíanos un correo a help@fravents.com con la mayor cantidad de información relevante de tu marca y nuestro equipo te ayudará en el proceso. Al continuar, aceptas los términos y condiciones de la plataforma",
          rememberNequi:
            "Recuerda que al continuar con el proceso aceptas los términos y condiciones de la plataforma al vincular tu cuenta de Nequi en nuestro sistema. No olvides que tus datos serán encriptados para tu mayor seguridad.",
          linkBitcoinCash: "Vincular USDT wallet",
          rememberBitcoinCash:
            "Recuerda que al continuar con el proceso aceptas los términos y condiciones de la plataforma al vincular tu wallet de USDT wallet en nuestro sistema. No olvides que tus datos serán encriptados para tu mayor seguridad.",
          linkMercadoPago: "Vincular Mercadopago",
          beforeContinueLinkMercadoPago:
            "Antes de continuar, debes verificar tu cuenta para seguir con el proceso. Este paso es necesario para confiar en que vas a vender productos reales y evitar estafas a nuestros usuarios. Envíanos un correo a help@fravents.com con la mayor cantidad de información relevante de tu marca y nuestro equipo te ayudará en el proceso.",
          maxFileSize:
            "El tamaño máximo permitido para cargar cada imagen es de 5Mb",
          hereYourName: "[Aquí va tu nombre]",
          hereYourEmail: "[Aquí va tu correo electrónico]",
          yourInfo: "Tu información",
          refers: "Programa Referidos",
          partnerData: "Datos Admin",
          settings: "Configuración",
          activatedBenefits: "Beneficios activados",
          preferentialCommissionRate: "Tarifa preferencial de comisión",
          promoterDiscountDescription: `Esta comisión es sin incluir los proveedores de pago. Normalmente Fravents cobra el ${platformTax[1]} sobre el valor de la compra.`,
          linkAccounts: "Vincular cuentas",
          personalInfo: "Información personal",
          verifyEmail: "Verificar correo electrónico",
          saveNewData: "Guardar datos nuevos",
          yourDataIsUpdated: "Tus datos estan actualizados",
          youHaveInAccount: "Tienes disponible en tu cuenta:",
          untilToday: "Y hasta el día de hoy has referenciado",
          confirmed: "confirmado",
          howCanRefer: "¿Cómo puedo referenciar?",
          inEachEvent:
            "En cada evento disponible encontrarás el aviso de <bold>¡Refiere y Gana!</bold> y si le das clic se copiará automáticamente tu link de referido para ese evento, solo debes compartirlo en tus redes y si alguien compra por medio de ese link se te asignarán tus créditos. <break /> <break /> Gracias por ser parte de nuestro programa 🤝. Recuerda que este dinero lo puedes usar para comprar en nuestra plataforma o nos puedes escribir a",
          toRequest:
            "para solicitar el envío de tu dinero a los medios de pago disponibles.",
          referEventsEarn: "Refiere eventos y gana créditos",
          weWantYouEarn:
            "Queremos que tengas la oportunidad de generar ingresos y por eso con nuestro programa de referidos podrás ganar créditos por cada compra completada por medio del link del evento que refieras. ¡Así de fácil! Solo debes aceptar nuestros",
          termsConditions: "Términos y Condiciones",
          andAvailableFunction:
            "y se te habilitará la función en los eventos disponibles.",
          acceptTC: "Aceptar T&C y comenzar",
          iconVerified: "✅ Verificado",
          iconNoVerified: "❌ No verificado",
          weGiveYouPage:
            'Te damos la posibilidad que tengas tu propia página personalizada con tu contenido segmentado. Ingresa los siguientes datos para completarla y después de guardar los cambios puedes presionar en "Ver mi página" para visualizar los cambios realizados.',
          viewPage: "Ver mi página",
          savePartnerData: "Guardar datos Partner",
          allInfoAboutLinkAccounts:
            "Ofrecemos a nuestra comunidad las siguientes opciones para vincular sus cuentas y recibir el dinero de cada venta realizada en nuestra platafroma. Recuerda, si tienes alguna duda o inconveniente, escribir un correo electrónico a help@fravents.com",
          remeberWhatFroin:
            "Vincula tu cuenta y <bold>aprovecha los beneficios</bold> que solo FROIN te ofrece </bold>, pagando de forma fáci, segura y genera recompensas.",
          remeberWhatMercadoPago:
            "Recuerda que Mercado Pago <bold>solo acepta métodos de pagos nacionales (Colombia)</bold>. Para pagos internacionales deberás realizarlos externamente y luego registrar la compra manualmente.",
          alreadyLink: "Ya vinculaste ",
          linkYour: "Vincula ",
          yourAccount: "tu cuenta de ",
          typeHereYour: "Escribe aquí tu",
          allUsersPayCryptos:
            "Todos los usuarios <bold>podrán pagar con su criptomoneda de elección</bold> y a ti te <bold>llegará el valor en USDT</bold> utilizando la tasa de conversión del mercado del mismo día.",
          walletAddressLinked: "Dirección de wallet vinculada",
          walletAddress: "Dirección de wallet",
          typeHereAddress: "Escribe aquí tu dirección",
          currency: "Moneda",
          language: "Lenguaje",
          themeColor: "Color del tema",
          enterCurrentPassword: "Ingresa tu contraseña actual",
          toUpdateWeNeedPassword:
            "Para poder continuar con la actualización de información necesitamos que ingreses tu contraseña actual para verificar que si eres el dueño de la cuenta.",
          byTickets: "Tickets",
          byClients: "Clientes",
          lastUpdated: "La última actualización de datos fue el ",
          sureEnterCode:
            "Debes sersiorarte que tu referido ingrese tu código en el momento que vaya a aceptar su rol de <bold>Administrador</bold>. Si tu cliente no lo ingresó, por favor escríbenos un correo para validarlo.<break /> <break /> Gracias por ser parte de nuestro programa 🤝. Recuerda que este dinero lo puedes usar para comprar en nuestra plataforma o nos puedes escribir a",
          thisIsYourCode: "Este es tu código único de referido:",
          yourReferralsReceive:
            "Tus referidos reciben una tarifa preferencial del:",
        },
      },
      shop: {
        detailProduct: {
          errorGetProduct: "Error para obtener producto: ",
          productSaved: "Producto guardado",
          productDeleted: "Eliminado de guardados",
          errorUpdate: "Error para actualizar: ",
          completeDataTry: "Completa los datos e intenta de nuevo",
          infoUpdated: "Información actualizada",
          noAddProduct:
            "No puedes agregar productos de otro comercio diferente, primero termina la compra de tus productos seleccionados.",
          viewShop: "Ver Tienda",
          available: "disponible",
          priceSendIncluded: "Precio de envío incluído en ciertas zonas",
          noSendIncluded:
            "No incluye envío, se debe cuadrar por medio del chat",
          startBuy: "Empezar compra",
          delete: "Eliminar ",
          save: "Guardar ",
        },
        myProducts: {
          payInProcess: "Pago en proceso",
          sendInProcess: "En proceso de envío",
          payRejected: "Pago rechazado",
          sent: "Enviado",
          delireved: "Entregado",
          noHaveAnyProduct: "No tenemos ningún producto que hayas comprado",
          viewSell: "Ver compra",
          quantity: "Cantidad",
          updatedAt: "Actualizado el",
          errorGetSells: "Error para obtener tus compras de productos: ",
          productBought: "Productos comprados",
        },
        selectQuantity: {
          soldOut: "Agotado",
          soon: "Próximamente",
          available: "disponible",
          firstSelect: "Primero selecciona un tipo",
          noCanPassQuantity: "No puedes superar la cantidad disponbile",
          quantityMax: "La cantidad máxima por compra es de 10 items",
          selectTypeQuantity: "Selecciona tipo y cantidad",
          plusTax: "+ tarifa de servicio de la plataforma",
          alreadyIncludedTax:
            "Estos precios ya tienen incluída la tarifa de servicio",
          quantity: "Cantidad",
          totalPrice: "Precio Total",
          addCart: "Agregar al carrito",
          continuePay: "Continuar al pago",
        },
        sellDetail: {
          buyer: "Comprador",
          seller: "Vendedor",
          thereNoMessages:
            "No hay mensajes por mostrar. Recuerda que este espacio es para enviar notificaciones del proceso de envío y para que se logren solucionar cualquier inquietud.",
          enterMinText: "Ingresa un texto de mínimo 5 caracteres",
          maxTextLength: "El texto no debe superar los 250 caracteres",
          errorSendMessage: "Error al enviar mensaje",
          errorLoadData: "Error para cargar la información",
          viewQrCode: "Ver código QR",
          visit: "Visitar",
          viewConfiguration: "Ver conversación",
          writeTo: "Escríbele al",
          client: "cliente",
          sellerM: "vendedor",
          updatedAt: "Actualizado el",
          support: "Soporte",
          products: "Productos",
          type: "Tipo",
          price: "Precio",
          eachOne: "c/u",
          quantity: "Cantidad",
          view: "Ver",
          detail: "detalle",
        },
        shoppingCart: {
          noAddItemsYet: "Aún no has agregado items a tus compras",
          mustEnterCode: "Debes ingresar un código",
          firstAddItem: "Primero debes agregar productos",
          errorValidateCode: "Error para validar código de promoción: ",
          beforeContinue: "Antes de continuar ...",
          forYourBuy:
            "Para tu compra debes iniciar sesión en Fravents o crear una cuenta, es fácil! Recuerda que debes verificar tu correo, si tienes problemas por favor escribe a help@fravents.com",
          mustVerifyEmail:
            "Para tu compra debes verificar tu correo electrónico por seguridad. Puedes enviar tu email de verificación en Tu Perfil.",
          continue: "Continuar",
          goToProfile: "Ir a mi perfil",
          bookingNoAvailable:
            "La reserva ya no puede ser realizada porque no se encuentra disponible.",
          errorData: "Datos erroneos",
          someProductsNoHave:
            "Algunos productos ya no tienen disponible la cantidad que deseas, se han eliminado de tu Shopping Cart",
          confirmSell: "Confirmar compra",
          wantGetItems:
            "¿Deseas adquirir {{totalItems}} Item{{multiple}}? Serás redirigido a la plataforma de pagos y tendrás máximo 10 minutos para realizar la compra o se eliminará tu reserva. Si continuas confirmas haber leído y aceptado los [[Términos y Condiciones Marketplace]].",
          remainInfoYet: "Aún te falta información por completar",
          shoppingCart: "Shopping Cart",
          thisSectionNoGuarant:
            "Esta selección aún no garantiza que reservemos los productos para ti. Debes continuar al siguiente paso para asegurar tus productos y terminar el proceso de pago.",
          product: "Producto",
          quantity: "Cantidad",
          price: "Precio",
          subTotal: "Sub-Total",
          sellSummary: "Resumen de compra",
          additionalData: "DATOS ADICIONALES",
          pleaseAddData:
            "Por favor ingresa los datos necesarios sobre tu dirección de envío, indicaciones o peticiones adicionales para comunicarselo al vendedor. Tendrás un chat en privado donde podrán continuar con la conversación.",
          directionCity:
            "Dirección, ciudad y datos adicionales para que el vendedor gestione tu compra",
          promoCode: "CÓDIGO DE DESCUENTO",
          enterYourCode: "Ingresa tu código",
          discountApplied: "Descuento aplicado",
          apply: "Aplicar",
          taxes: "Impuestos y servicios",
          totalCost: "COSTO TOTAL",
          finishPay: "TERMINAR Y PAGAR",
        },
      },
      tickets: {
        detailTickets: {
          yourTicketIsSelling: "Tu ticket ahora está a la venta.",
          errorSellTicket: "Error para vender ticket",
          receivedTicket: "Ticket recibido. Ya eres dueño y puedes usarlo",
          beforeContinue: "Antes de continuar ...",
          rememberTransferTicket:
            "Recuerda que este proceso no se puede revertir, en el momento que se acepta la transferencia del ticket, el anterior usuario dejará de ser dueño de él y no podrá recuperarlo.",
          transferCanceled:
            "Transferencia cancelada. Ya tienes disponible tu ticket",
          rememberCancelTransferTicket:
            "Recuerda que este proceso no se puede revertir, en el momento que se cancela la transferencia del ticket, el nuevo usuario dejará de ser dueño de él y no podrá recuperarlo.",
          completeDataTry: "Completa los datos e intenta de nuevo",
          emailHasBadFormat: "El correo ingresado tiene un formato incorrecto",
          confirmationTransfer: "Confirmación de transferencia",
          areSureTransfer:
            "¿Estas de acuerdo de transferir los tickets al usuario {{email}}? Puedes cancelar la transferencia antes de que el nuevo usuario la acepte.",
          rememberBeforeTransfer:
            "Recuerda que este proceso no se puede revertir, en el momento que se envía el ticket al nuevo usuario y el acepta, dejarás de ser dueño de él y no podrás recuperarlo. Si tienes algún inconveniente contáctate con el organizador del evento.",
          sellCanceled: "Se ha cancelado tu venta.",
          errorToCancelSell: "Error para cancelar venta",
          rememberCancelSell:
            "Recuerda que este proceso no se puede revertir, en el momento que se cancela la venta del ticket volverá a ser de tu propiedad y ya no se ofrecerá a los usuarios.",
          completeValueSell: "Completa el valor del precio de reventa",
          badCopPrice:
            "El valor ingresado es inválido, únicamente se acepta números mayores que $1000 COP.",
          badUsdPrice:
            "El valor ingresado es inválido, únicamente se acepta números mayores que $0 USD.",
          resellConfirmation: "Confirmación de reventa",
          areSureSellTicket:
            "¿Estas de acuerdo de vender este ticket a {{labelPrice}}? Puedes cancelar la oferta antes de que un usuario la compre.",
          enterPriceSell: "Ingresa el precio al que deseas vender",
          mustLinkAccount: "Debes vincular tu cuenta",
          toSellLinkAccounts:
            "Para vender tus tickets por medio de Fravents debes vincular tu cuenta de Mercado Pago para recibir, al instante, tu dinero de la venta.",
          link: "Vincular",
          everySellAccept:
            "Cada vez que vendas tus tickets por medio de nuestra plataforma aceptas nuestros [[Términos y Condiciones]] para el proceso de venta y entrega de tu dinero.",
          mustVerifyEmail:
            "Debes verificar tu correo electrónico por seguridad. Puedes enviar tu email de verificación en Tu Perfil.",
          goToProfile: "Ir a mi perfil",
          sellUpdated: `Compra actualizada`,
          waitRead: "Espera! ... Lee esto antes de continuar",
          rememberThisStep: `Recuerda que este paso no se puede revertir, en el momento que se asigna un promotor no se puede editar más adelante. ¿Estas de acuerdo en asignar al promotor {{name}} a esta compra?`,
          priceInCop: "Precio en pesos ($ COP)",
          priceInUsd: "Precio en dólares ($ USD)",
          referedPromotor: "Promotor referido",
          noPromotorAdd: "No hay promotores, agrega aquí",
          addPromoter: "Asigna un promotor",
          sellSummary: "Resumen de compra",
          eventFinished: "El evento comprado ya ha finalizado.",
          payed: "Pagado",
          payPending: "El pago aún esta siendo procesado.",
          payRejected: "El pago fue rechazado",
          payRefunded: "El pago fue reembolsado",
          sellDate: "Fecha de compra",
          totalPrice: "Precio Total",
          eventTerms: "Términos y condiciones del evento",
          canceled: "CANCELADO",
          fraventsTerms:
            "Fravents solo se responsabiliza por los temas relacionados con soporte técnico en la compra y venta de boletería. Fravents no se hace responsable de la ejecución o cualquier tema relacionado con este evento.",
          viewMore: "Ver más",
          thisTicketMaxTransfer:
            "Este ticket ya ha superado las 10 transferencias permitidas.",
          cancelTransfer: "Cancelar transferencia a",
          noCortesiaTransfer: "Los tickets de Cortesia no se pueden transferir",
          transferTicket: "Transferir ticket",
          publishResell: "Vender y publicar reventa",
          cancelResell: "Cancelar venta",
          priceResell: "Precio de venta",
          redeemTicket: "Redimir entrada",
          location: "Ubicación",
          ticketStatus: "Estado del Ticket",
          noUsed: "NO USADO",
          used: "REDIMIDO",
          scannedDate: "Fecha escaneado",
          whoScanned: "Quien escaneo",
          errorGetSell: "Error para obtener venta: ",
          errorGetEvent: "Error para obtener evento: ",
          rememberNoScreenshot:
            "Recuerda <bold>NO HACER SCREENSHOT O IMPRIMIR</bold> porque son dinámicos y deben ser presentados desde tu dispositivo el día del evento. Si cuando escaneen tu código QR dice inválido, recarga esta pantalla por si hay problemas de cache.",
          refundPayment: "Compra reembolsada",
          date: "Fecha",
          idTransaction: "Id de transacción",
          youCanUseRefundId:
            'Puedes utilizar tu <bold>ID de transacción</bold> para hacerle seguimiento con Mercado Pago en soporte al cliente de su plataforma<break />Recuerda que los tiempos de devolución de Mercado Pago los puedes consultar en esta página dependiendo del medio de pago:<break /><break /><anchor  target="blank"  href="https://www.mercadopago.com.co/ayuda/devolver-dinero-mi-compra_1601">  https://www.mercadopago.com.co/ayuda/devolver-dinero-mi-compra_1601</anchor><break /><break />Ahora debes esperar a que tu dinero se vea reflejado en tu cuenta, cualquier problema con tu reembolso por favor escribirle al organizador del evento.',
          resellTickets: "Reventa de ticket",
          completeDataNewUser: "Completa los datos del nuevo usuario",
          enterWantPriceResell:
            "Ingresa el valor al que deseas vender este ticket a la comunidad. Esta oferta se publicará en el evento y enviaremos una notificación a los usuarios interesados para promocionar tu publicación.",
          remberNoResellBefore:
            "Recuerda que este proceso solo se puede hacer una vez, el nuevo dueño no podrá transferir este ticket, así evitamos la reventa masiva.",
        },
        myTickets: {
          selectPriceUnit:
            "Selecciona el precio por unidad y la cantidad que deseas vender",
          mustLinkAccount: "Debes vincular tu cuenta",
          toSellMustLinkAccount: `Para vender tus tickets por medio de Fravents debes vincular tu cuenta de Mercado Pago para recibir, al instante, tu dinero de la venta.\n\nRECUERDA: Fravents cobra una comisión del ${platformTax[0]} aprox.`,
          link: "Vincular",
          beforeContinue: "Antes de continuar ...",
          everySellAccept:
            "Cada vez que vendas tus tickets por medio de nuestra plataforma aceptas nuestros [[Términos y Condiciones]] para el proceso de venta y entrega de tu dinero.",
          mustVerifyEmail:
            "Debes verificar tu correo electrónico por seguridad. Puedes enviar tu email de verificación en Tu Perfil.",
          goToProfile: "Ir a mi perfil",
          ticketsOnSell: "Tus tickets ahora están a la venta.",
          errorToSell: "Error para vender tickets",
          mustAddPrice:
            "Debes agregar al menos un precio y debe ser mayor a $1.000 COP, y la cantidad a vender debe ser mayor a cero.",
          someSellsNoShow:
            "Algunas ventas no se muestran porque el precio debe ser mayor a $1.000 COP y la cantidad a vender mayor a cero.",
          confirmSelect: "Confirmar selección",
          areSureSellTicket:
            "¿Estas de acuerdo en vender los siguientes tickets? Recuerda que puedes cancelarlo en cualquier momento en el detalle de cada ticket.",
          mustLoginPc:
            "Debes ingresar desde un computador para vender múltiples tickets, de lo contrario solo puedes vender desde el detalle de cada compra.",
          cancelSell: "Cancelar venta",
          areSureCancelSell:
            "¿Estas de acuerdo en cancelar el proceso de venta de tus tickets? Recuerda que perderás los datos ingresados.",
          confirmSell: "Confirmar y vender",
          sellYourTickets: "Vende tus tickets",
          setPrice: "Coloca el precio y recibe el dinero cuando se venda",
          noForMobile: "No disponible para dispositivos móviles",
          refAndEarn: "¡Refiere y Gana!",
          shareLink: "Comparte el link de cada evento y gana por cada compra",
          noHaveAnyEvent: "No tenemos ningún evento que hayas adquirido",
          pending: "Pendiente",
          refunded: "Reembolsado",
          confirm: "Confirmado",
          viewDetails: "Ver detalle",
          weNoHaveEvent: "No tenemos ningún evento que hayas adquirido",
          quantity: "Cantidad",
          all: "Todos",
          next: "Próximos",
          event: "Evento",
          buyDate: "Fecha de compra",
          state: "Estado",
          unitPrice: "Precio unidad",
          quantitySell: "Cantidad a vender",
          qrCode: "Código QR",
          myTickets: "Mis Tickets",
          errorToGetEvents: "Error para obtener eventos: ",
          errorGetTickets: "Error para obtener tickets: ",
          tickets: "Tickets",
          products: "Productos",
          favorites: "Favoritos",
          support: "Soporte",
        },
      },
    },
    validations: {
      invalidEmail: "La dirección de correo electrónico está mal formateada.",
      userNotFound: "No existe un usuario con el correo ingresado.",
      wrongPassword:
        "La contraseña no es válida o el usuario no tiene una contraseña.",
      weakPassword: "La contraseña debe tener 6 caracteres o más.",
      emailAlreadyUse:
        "La dirección de correo electrónico ya está en uso por otra cuenta.",
      userMismatch: "Los datos ingresados no corresponden al usuario.",
      imageTooBigger: "La imagen supera el tamaño de {{size}}Mb",
      free: "Gratis",
      errorTestSite: "Este sitio es de pruebas",
      linkedAccountSuccess: "Cuenta vinculada correctamente",
      errorLinkNequi: "Error al vincular Nequi",
      errorLinkBitcoin: "Error al vincular USDT wallet wallet",
      payed: "Pagada",
      pending: "Pendiente",
      sent: "Enviada",
      delivered: "Entregada",
      rejected: "Rechazada",
    },
    services: {
      auth: {
        hiWelcome: "Hola bienvenid@!",
        weKnowYouFirst:
          "Sabemos que es tu primera vez en la plataforma. Antes de continuar, y que puedas utilizar todas nuestras funcionalidades, debes aceptar las Condiciones de Uso y la Política de Privacidad de nuestro sitio que puedes ver en nuestro documento. Selecciona una de las siguientes opciones:",
        goToDocument: "Ir a documento",
        yesConfirm: "Si, acepto",
        thanksWelcome:
          "Gracias, Bienvenid@ a FRAVENTS! Ya puedes hacer uso de nuestro sitio.",
        errorGetInfo: "Error al obtener información",
        errorSignOut: "Error cerrar sesión",
      },
      events: {
        errorGetEvents: "Error al obtener los eventos",
        errorAddEvent: "Error para agregar evento",
        withoutQuestions: "Sin preguntas",
        withoutEventTypes: "Sin tipos de eventos",
      },
      firebase: {
        infoDidntBeFound: "Información no encontrada",
        errorListenerChat: "Error listener chat: ",
      },
    },
    system: {
      beforeTest:
        "Autenticación\nAntes de continuar, por favor ingresa el código que se te asignó",
      titleOldVersion: "Versión desactualizada",
      errorTestCode: "Código erroneo",
      bodyOldVersion: `Es necesario que recargues la página para obtener las últimas actualizaciones y tengas la mejor experiencia en Fravents. [Versión actual: {{version}} - Actualización: {{liveVersion}}]`,
      reload: "Recargar",
      errorGetSettings: "Error para obtener settings: ",
    },
  },
};

export default texts;
